import { Injectable } from '@angular/core';
import { transformSnakeToCamel } from '@shared/util/helper';
import {
  LabelSize,
  linkTypes,
  nodeSize,
  nodeState,
  nodeTypes,
  nodeTypesColors,
  nodeTypeToColor,
} from '../../link-analysis/shared/link-analysis.model';
import { LinkAnalysisService } from '../../link-analysis/services/link-analysis.service';
import { InvestigationStylesService } from './investigation-styles.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  CryptoGraphResponse,
  CryptoLink,
  CryptoNode,
} from '../models/crypto.models';
import { Link, Node } from '@trg-ui/link-analysis';

@Injectable({
  providedIn: 'root',
})
export class InvestigationCryptoService {
  constructor(
    private linkAnalysisService: LinkAnalysisService,
    private investigationStyleService: InvestigationStylesService,
    private httpClient: HttpClient
  ) {}

  private getNodes(fetchedNodes: CryptoNode[]) {
    const nodes = fetchedNodes?.map((node) => {
      const nodeId = node.id;
      let nodeType: string = node.type;
      let nodeLabel = node.id;
      if (nodeType === 'Email') nodeType = nodeTypes.EMAIL;
      let nodeColor = nodeTypeToColor[nodeType]
        ? nodeTypeToColor[nodeType]
        : nodeTypeToColor[nodeTypes[nodeType]];

      if (node.type === nodeTypes.CRYPTO_ACCOUNT) {
        nodeColor = node.blacklisted
          ? nodeTypesColors.CRYPTO_ACCOUNT_BLACKLISTED
          : nodeTypesColors.CRYPTO_ACCOUNT;
      }
      const nodesize = nodeSize.XSMALL;
      const labelSize = LabelSize.MEDIUM;
      const glyphs = [];

      let nodeData = {};
      nodeType = nodeType.toLowerCase();
      switch (nodeType) {
        case nodeTypes.MSISDN.toLowerCase():
          nodeData = {
            type: nodeTypes.MSISDN,
            label: node.id,
            state: nodeState.EXPANDED,
          };
          break;
        case nodeTypes.EMAIL.toLowerCase():
          nodeData = {
            type: nodeTypes.EMAIL,
          };
          break;
        case nodeTypes.CRYPTO_ACCOUNT.toLowerCase():
          nodeData = { entries: node.entries };
          nodeLabel = `${node.name} - (${node.id})`;
          nodeData['type'] = nodeTypes.CRYPTO_ACCOUNT;
          break;
        case nodeTypes.CRYPTO_WALLET.toLowerCase():
          nodeData = { type: nodeTypes.CRYPTO_WALLET };
          break;
        default:
      }

      return this.linkAnalysisService.createNewNode(
        nodeId,
        nodeColor,
        nodeLabel,
        nodeData,
        nodesize,
        glyphs,
        labelSize
      );
    });
    return nodes;
  }

  private getLinks(fetchedLinks: CryptoLink[]) {
    return fetchedLinks.map((link) => {
      let labelText = '';
      if (link?.metadata?.amount) {
        labelText = `${link.metadata?.amount}${link.metadata?.currency}`;
      }
      return this.linkAnalysisService.getLinkWithLabel(
        link.fromEntity.id,
        link.toEntity.id,
        linkTypes[link.relationType],
        false,
        this.investigationStyleService.getColorById(link.fromEntity.id),
        labelText,
        link.relationType
      );
    });
  }

  public requestByCryptoId(id: string | string[]) {
    const url = '/gw/graph-query-service/crypto/graph';
    const payload = Array.isArray(id) ? id : [id];
    const options = {
      params: {
        seeds: payload,
      },
    };
    return this.httpClient.get<CryptoGraphResponse>(url, options).pipe(
      map((res) => transformSnakeToCamel(res)),
      map((res) => {
        const nodes = this.getNodes(res.nodes);
        const links = this.getLinks(res.edges);
        const graphData: { [key: string]: Node | Link } = {};
        nodes.forEach((node) => (graphData[node.id] = node));
        links.forEach((link) => (graphData[link.id] = link));
        return graphData;
      })
    );
  }
}
