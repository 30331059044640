import { Component, OnInit, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { QueryService } from 'src/app/services/query/query.service';

@Component({
  selector: 'app-request-location-history-imsi-dialog',
  templateUrl: './request-location-history-imsi-dialog.component.html',
  styleUrls: ['./request-location-history-imsi-dialog.component.scss'],
})
export class RequestLocationHistoryImsiDialogComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<RequestLocationHistoryImsiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData,
    private translationService: TranslationService,
    private queryService: QueryService
  ) {
    super();
  }

  ngOnInit() {}

  onClose(): void {
    this.dialogRef.close();
  }

  requestReport() {
    this.queryService.requestLocationHistoryImsi(this.requestData);
    this.showMessage(
      this.translationService.translate('Request sent successfully!')
    );
    this.onClose();
  }
}
