import { Component, Input, OnInit } from '@angular/core';
import { GenericActivityCell } from '../../activity-pattern.component';

@Component({
  selector: 'app-generic-activity-cell',
  templateUrl: './generic-activity-cell.component.html',
  styleUrls: ['./generic-activity-cell.component.scss'],
})
export class GenericActivityCellComponent implements OnInit {
  @Input() activityCell: GenericActivityCell;

  constructor() {}

  ngOnInit(): void {}
}
