import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  LocationProbabilityInsight,
  QueryCommand,
} from '@trg-commons/data-models-ts';
import {
  CQRSBaseEvent,
  InteractionInsightsDto,
  LocationInsightsDto,
} from '@trg-commons/gio-data-models-ts';
import { environment } from 'environment/environment';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  catchError,
  map,
  mergeAll,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import {
  ImeiInsight,
  InsightResults,
  InternationInteractionInsight,
  MeetingPointInsight,
  SuspiciousCallInsight,
  TogetherInsight,
} from '../models/insights.model';
import { InvestigationInsightsStore } from './investigation-insights.store';

@Injectable({
  providedIn: 'root',
})
export class InvestigationInsightsService extends BaseService {
  public listeners: Map<string, Subject<any>> = new Map();
  baseUrl: string;
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private wsManager: WebsocketManagerService,
    private investigationInsightsStore: InvestigationInsightsStore
  ) {
    super(router, snackBar);
    this.initializeListeners();
    this.baseUrl = environment.proxyAPIUri;
  }

  public createInteractionInsightsRequest(
    request: InteractionInsightsDto
  ): Observable<
    CQRSBaseEvent<
      InternationInteractionInsight | SuspiciousCallInsight | ImeiInsight
    >
  > {
    const url = this.baseUrl + `/insights/interaction`;
    const commands = [
      QueryCommand.InternationalInteractions,
      QueryCommand.SuspiciousLongCalls,
      QueryCommand.SuspiciousShortCalls,
      QueryCommand.UseSameImei,
    ];
    return forkJoin(
      this.prepareMultipleStatisticRequestsForInsights(request, url, commands)
    ).pipe(
      switchMap((result) =>
        result.map((clStatistic) => {
          return this.createListenerForInsights(clStatistic.correlationId);
        })
      ),
      mergeAll(),
      map((response) => response.body)
    );
  }

  public createLocationsInsightsRequest(
    request: LocationInsightsDto
  ): Observable<TogetherInsight | MeetingPointInsight> {
    const url = this.baseUrl + `/insights/location`;
    const commands = [
      QueryCommand.MeetingPoints,
      QueryCommand.LivingTogether,
      QueryCommand.WorkingTogether,
    ];
    return forkJoin(
      this.prepareMultipleStatisticRequestsForInsights(request, url, commands)
    ).pipe(
      switchMap((result) =>
        result.map((clStatistic) => {
          return this.createListenerForInsights(clStatistic.correlationId);
        })
      ),
      mergeAll(),
      map((response) => response.body)
    );
  }

  prepareMultipleStatisticRequestsForInsights(
    request,
    url: string,
    commands
  ): Observable<CQRSBaseEvent<any>>[] {
    return commands.map((commandType) => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      return this.postWithHeaders<CQRSBaseEvent<any>>(url, requestBody).pipe(
        catchError((error) => {
          console.warn('Http Failure', error);
          return [];
        })
      );
    });
  }

  protected getConnectionId(): Observable<string> {
    return this.wsManager
      .getServerTsConnection()
      .pipe(map((socket) => socket.id));
  }

  postWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.httpClient.post<T>(url, request, { headers: headers });
      })
    );
  }

  private buildHttpHeaders(connectionId: string): HttpHeaders {
    return new HttpHeaders().append('Ws-Connection-Id', connectionId);
  }

  createListenerForInsights(
    correlationId: string
  ): Observable<CQRSBaseEvent<any>> {
    return this.createListener(correlationId).pipe(
      tap((event) => this.listenInsightsNotifications(event))
    );
  }

  private listenInsightsNotifications(
    event: CQRSBaseEvent<
      | InternationInteractionInsight
      | SuspiciousCallInsight
      | ImeiInsight
      | TogetherInsight
      | MeetingPointInsight
      | LocationProbabilityInsight
    >
  ) {
    let insightResults: InsightResults = { interactions: {}, locations: {} };
    insightResults = this.investigationInsightsStore.getValue();
    const body = transformSnakeToCamel(event.body);
    switch (body.type) {
      case QueryCommand.InternationalInteractions:
        insightResults.interactions = {
          ...insightResults.interactions,
          ...{ internationalInteraction: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.UseSameImei:
        insightResults.interactions = {
          ...insightResults.interactions,
          ...{ imeiInteraction: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.SuspiciousLongCalls:
        insightResults.interactions = {
          ...insightResults.interactions,
          ...{ suspiciousLongCallInsight: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.SuspiciousShortCalls:
        insightResults.interactions = {
          ...insightResults.interactions,
          ...{ suspiciousShortCallInsight: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.MeetingPoints:
        insightResults.locations = {
          ...insightResults.locations,
          ...{ meetingPoints: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.LivingTogether:
        insightResults.locations = {
          ...insightResults.locations,
          ...{ livingTogether: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.WorkingTogether:
        insightResults.locations = {
          ...insightResults.locations,
          ...{ workingTogether: body.insight },
        };
        this.investigationInsightsStore.append(insightResults);
        break;
      case QueryCommand.PredictedHomeLocation:
        insightResults.locations = {
          ...insightResults.locations,
          ...{
            homePredictedLocations: {
              insights: body.insight,
              msisdns: body.msisdns,
              type: body.type,
            },
          },
        };
        this.investigationInsightsStore.append(insightResults);
        this.investigationInsightsStore.locationProbabilityInsights.next({
          type: QueryCommand.PredictedHomeLocation,
          insights: body.insight,
          msisdns: body.msisdns,
        });
        break;
      case QueryCommand.PredictedWorkplaceLocation:
        insightResults.locations = {
          ...insightResults.locations,
          ...{
            workPredictedLocations: {
              insights: body.insight,
              msisdns: body.msisdns,
              type: body.type,
            },
          },
        };
        this.investigationInsightsStore.append(insightResults);
        this.investigationInsightsStore.locationProbabilityInsights.next({
          type: QueryCommand.PredictedWorkplaceLocation,
          insights: body.insight,
          msisdns: body.msisdns,
        });
        break;
      default:
        break;
    }
  }
  private createListener(
    correlationId: string
  ): Observable<CQRSBaseEvent<any>> {
    const subject = new Subject<CQRSBaseEvent<any>>();
    this.listeners.set(correlationId, subject);
    return subject.asObservable();
  }

  private initializeListeners() {
    this.wsManager.getServerTsConnection().subscribe((ws) => {
      ws.on('message', (data: CQRSBaseEvent<any>) => {
        const listener = this.listeners.get(data.correlationId);

        if (!listener) {
          return;
        }

        listener.next(data);
      });
    });
  }

  public createPredictedLocationsInsightsRequest(
    request: LocationInsightsDto
  ): Observable<any> {
    const url = this.baseUrl + `/insights/location`;
    return this.postWithHeaders<CQRSBaseEvent<any>>(url, request)
      .pipe(
        catchError((error) => {
          console.warn('Http Failure', error);
          return [];
        })
      )
      .pipe(
        map((data) => {
          return this.createListenerForInsights(data.correlationId).subscribe();
        })
      );
  }
}
