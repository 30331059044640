import { Injectable } from '@angular/core';
import {
  ActiveToast,
  ComponentType,
  IndividualConfig,
  Toast,
  ToastrService,
} from 'ngx-toastr';
import { BaseToasterIndividualConfig } from './base-toaster-individual-config';
import { IntellectusBaseToasterConfig } from './base-toaster.model';
import { BaseToasterComponent } from './base-toaster.component';

@Injectable({
  providedIn: 'root',
})
export class BaseToasterService {
  constructor(private toastrService: ToastrService) {}

  show(
    toaster: IntellectusBaseToasterConfig,
    config?: Partial<IndividualConfig>
  ): ActiveToast<unknown> {
    return this.toastrService.show(toaster.actionTitle, toaster.title, {
      ...BaseToasterIndividualConfig,
      ...config,
      toastComponent: BaseToasterComponent,
      payload: {
        icon: toaster.icon,
        actions: toaster.actions,
      },
    });
  }

  showFromComponent(
    toastComponent: ComponentType<Toast>,
    config?: Partial<IndividualConfig>
  ): ActiveToast<unknown> {
    return this.toastrService.show(undefined, undefined, {
      ...BaseToasterIndividualConfig,
      ...config,
      toastComponent: toastComponent,
      payload: config?.payload,
    });
  }
}
