import { Platform } from '@shared/schemas/common/platforms';
import { DataSource } from 'datalayer/models/platform-models';

export const SocialPlatformMap = {
  [DataSource.Twitter]: Platform.TWITTER,
  [DataSource.Facebook]: Platform.FACEBOOK,
  [DataSource.Instagram]: Platform.INSTAGRAM,
  [DataSource.LinkedIn]: Platform.LINKEDIN,
  [DataSource.YouTube]: Platform.YOUTUBE,
  [DataSource.Tiktok]: Platform.TIKTOK,
  [DataSource.Truecaller]: Platform.TRUECALLER,
  [DataSource.CallerID]: Platform.CallerID,
  [DataSource.WhatsApp]: Platform.WHATSAPP,
  [DataSource.Telegram]: Platform.TELEGRAM,
  [DataSource.Wechat]: Platform.WECHAT,
  [DataSource.Viber]: Platform.VIBER,
  [DataSource.Tinder]: Platform.TINDER,
  [DataSource.Skype]: Platform.SKYPE,
  [DataSource.Google]: Platform.Google,
  [DataSource.IndoDB]: Platform.IndoDB,
  // SS aka Somedus wich is telegram
  // @trg-commons doesn't have Somedus @trg-commons/data-models-ts/dist/enums/data/data.source.d.ts
  // https://trgint.atlassian.net/browse/INT-13188
  [DataSource.Somedus]: Platform.TELEGRAM,
  // SOMEDUS-TG PLATFORM IS GENERATED AFTER USER IMPORTING A FILE VIA SOMEDUS DX-INTEGRATION FEATURE
  [DataSource.SomedusTg]: Platform.TELEGRAM,
};

export const DataSourceByPlatformMap = {
  [Platform.TWITTER]: DataSource.Twitter,
  [Platform.FACEBOOK]: DataSource.Facebook,
  [Platform.INSTAGRAM]: DataSource.Instagram,
  [Platform.LINKEDIN]: DataSource.LinkedIn,
  [Platform.YOUTUBE]: DataSource.Youtube,
  [Platform.TIKTOK]: DataSource.Tiktok,
  [Platform.TRUECALLER]: DataSource.Truecaller,
  [Platform.CallerID]: DataSource.CallerID,
  [Platform.WHATSAPP]: DataSource.WhatsApp,
  [Platform.TELEGRAM]: DataSource.Telegram,
  [Platform.WECHAT]: DataSource.Wechat,
  [Platform.VIBER]: DataSource.Viber,
  [Platform.TINDER]: DataSource.Tinder,
  [Platform.SKYPE]: DataSource.Skype,
  [Platform.Google]: DataSource.Google,
  [Platform.IndoDB]: DataSource.IndoDB,
};
