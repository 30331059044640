import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { environment } from 'src/environments/environment';

export function getLangPaginatorIntl(translationService: TranslationService) {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel =
    translationService.translate('Items per page');
  paginatorIntl.nextPageLabel = translationService.translate('Next page');
  paginatorIntl.previousPageLabel =
    translationService.translate('Previous page');
  paginatorIntl.firstPageLabel = translationService.translate('First page');
  paginatorIntl.lastPageLabel = translationService.translate('Last page');
  paginatorIntl.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${translationService.translate('of')} ${length}`;
    }

    length = Math.max(length, 0);

    let startIndex = page * pageSize;

    if (environment && environment['enableAdintFlavour']) {
      startIndex = page;
    }

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${translationService.translate(
      'of'
    )} ${length}`;
  };

  return paginatorIntl;
}
