<app-base-modal-component
  [headerDetails]="headerDetails"
  (onPushBack)="pushBack($event)"
  (onClosePressed)="closeParent($event)"
  class="height-100"
>
  <ng-container ngProjectAs="coverContent">
    <div *ngIf="imageUrl" class="image-details-dialog">
      <img
        class="blur-img"
        [src]="imageUrl"
        appFallbackImg="assets/static/images/no_photo.svg"
      />
      <img
        class="bg-img"
        [src]="imageUrl"
        appFallbackImg="assets/static/images/no_photo.svg"
      />
    </div>
  </ng-container>

  <ng-container ngProjectAs="detailsContent">
    <ng-container *ngIf="!moreDetails && !groupDetails">
      <div class="related-people-details">
        <div class="col-12 col-md-12 base-modal-description">
          <div *ngIf="profile.name" class="item">
            <span class="col-md-3 item-title"> {{ 'Name' | translate }}: </span>
            <span class="col-md-9 item-description">
              {{ profile.name }}
            </span>
          </div>
          <div *ngIf="profile.username" class="item">
            <span class="col-md-3 item-title">
              {{ 'Username' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.username }}
            </span>
          </div>
          <div *ngIf="profile.url" class="item">
            <span class="col-md-3 item-title"> {{ 'Url' | translate }}: </span>
            <div
              class="col-md-9 item-description icon"
              (click)="navigateToUrl(this.profile.url)"
            >
              <intellectus-platform-icon
                class="platform-image clickable"
                [platform]="profile.platform"
              ></intellectus-platform-icon>

              {{ profile.url }}
            </div>
          </div>
          <div *ngIf="profile.about && profile.source === 'TT'" class="item">
            <span class="col-md-3 item-title">
              {{ 'About' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.about }}
            </span>
          </div>
          <div *ngIf="profile.telno" class="item">
            <span class="col-md-3 item-title">
              {{ 'MSISDN' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.telno }}
            </span>
          </div>
          <div *ngIf="profile.joinedAt" class="item">
            <span class="col-md-3 item-title">
              {{ 'Date added' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.joinedAt | date: 'dd/MM/yyyy HH:mm:ss' }}
            </span>
          </div>
          <ng-container
            *ngIf="profile.source === 'IG' || profile.source === 'TT'"
          >
            <div class="item">
              <span class="col-md-3 item-title">
                {{ 'Verified' | translate }}:
              </span>
              <span class="col-md-9 item-description">
                {{
                  !profile.verified ? ('No' | translate) : ('Yes' | translate)
                }}
              </span>
            </div>
            <div class="item">
              <span class="col-md-3 item-title">
                {{ 'Private profile' | translate }}:
              </span>
              <span class="col-md-9 item-description">
                <ng-container *ngIf="profile.isPublic; else privateProfile">
                  {{ 'No' | translate }}
                  <img
                    class="value-icon"
                    [src]="'assets/static/images/lock-open.svg'"
                  />
                </ng-container>
                <ng-template #privateProfile>
                  {{ 'Yes' | translate }}
                  <img
                    class="value-icon"
                    [src]="'assets/static/images/lock.svg'"
                  />
                </ng-template>
              </span>
            </div>
          </ng-container>
          <div *ngIf="chats" class="item">
            <span class="col-md-3 item-title align-self-baseline">
              {{ 'Share in common' | translate }}:
            </span>
            <div class="chats-wrapper col-md-9">
              <div class="row">
                <span class="col-md-12 d-flex align-top pl-0 mb-5">
                  <span class="col-md-3 item-title"
                    >{{ 'Chats' | translate }}:</span
                  >
                  <span class="col-md-9 item-description">
                    <span
                      >{{ messagesExchanged || 0 }}
                      {{ 'messages exchanged' | translate }}</span
                    ><br />
                    <span
                      ><a
                        (click)="goToSummary()"
                        class="link"
                        [ngClass]="{ 'disabled-link': !messagesExchanged }"
                        >{{ 'Chat summary' | translate }} ></a
                      ></span
                    >
                  </span>
                </span>
              </div>
              <div class="row mt-2">
                <span class="col-md-12 d-flex align-top pl-0">
                  <span class="col-md-3 item-title"
                    >{{ 'Groups' | translate }}:</span
                  >
                  <span class="col-md-9 item-description">
                    <span
                      >{{ groupMessagesExchanged || 0 }}
                      {{ 'groups' | translate }}</span
                    ><br />
                    <span
                      ><a
                        (click)="goToGroupDetails()"
                        class="link"
                        [ngClass]="{ 'disabled-link': !groupMessagesExchanged }"
                        >{{ 'View details' | translate }} ></a
                      ></span
                    >
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="profile.relationCategory" class="item">
            <span class="col-md-3 item-title">
              {{ 'Relation' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.relationCategory | titlecase }}</span
            >
          </div>
          <div *ngIf="profile.subRelationCategory" class="item">
            <span class="col-md-3 item-title">
              {{ 'Sub relation' | translate }}:
            </span>
            <span class="col-md-9 item-description">
              {{ profile.subRelationCategory | titlecase }}</span
            >
          </div>
          <div *ngIf="profile.totalCalls" class="item">
            <span class="col-md-3 item-title"> {{ 'Calls' | translate }}</span>
            <span class="col-md-9 item-description">
              {{ profile.totalCalls }}</span
            >
          </div>
          <div *ngIf="profile.totalSms" class="item">
            <span class="col-md-3 item-title"> {{ 'SMS' | translate }}</span>
            <span class="col-md-9 item-description">
              {{ profile.totalSms }}</span
            >
          </div>
        </div>
        <div class="actions col-12 col-md-12" mat-dialog-actions>
          <button
            class="cp-btn-primary"
            *ngIf="
              !profile?.profileToTargetInfo?.targetId &&
              (profile.username || profile.url || profile.telno)
            "
            mat-button
            (click)="addToTarget()"
          >
            {{ 'Add to target' | translate }}
          </button>
          <button
            class="cp-btn-primary"
            *ngIf="profile?.profileToTargetInfo?.targetId"
            mat-button
            (click)="navigateToTarget(profile)"
          >
            {{ 'Open target profile' | translate }}
          </button>
          <app-credit-cost-button
            *ngIf="profile.username || profile.url || profile.telno"
            [action]="'advancedOsint'"
            [buttonText]="'Enhance Profile'"
            [active]="true"
            [stylingClasses]="'cp-btn-ghost cp-search-btn advanced-btn'"
            (clicked)="advancedOsint()"
          ></app-credit-cost-button>
          <app-credit-cost-button
            *ngIf="profile.name && profile.relationCategory"
            [action]="'intelSearch'"
            [buttonText]="'Find profiles'"
            [active]="true"
            [stylingClasses]="'cp-btn-primary cp-search-btn advanced-btn'"
            (clicked)="investigateLead()"
          ></app-credit-cost-button>
        </div>
      </div>
      <div
        *ngIf="profile.sourceEntity?.createdAt"
        class="query-date assistant-font"
      >
        {{ 'Data collected at' | translate }}:
        {{ profile.sourceEntity?.createdAt | date: 'dd/MM/yyyy HH:mm' }}
      </div>

      <div
        *ngIf="
          !profile.sourceEntity?.createdAt &&
          profile?.image?.sourceEntity?.createdAt
        "
        class="query-date assistant-font"
      >
        {{ 'Data collected at' | translate }}:
        {{ profile?.image?.sourceEntity?.createdAt | date: 'dd/MM/yyyy HH:mm' }}
      </div>
    </ng-container>
    <ng-container *ngIf="moreDetails">
      <app-chat-summary-details
        [relatedPersonChat]="relatedPersonChat"
        [hideBackButton]="isChatBackButton"
        (goBackEvent)="goToMain()"
      ></app-chat-summary-details
    ></ng-container>
    <ng-container *ngIf="groupDetails">
      <app-group-details
        [groupChats]="groupChats"
        [hideBackButton]="isGroupBackButton"
        (goBackEvent)="goToMain()"
      ></app-group-details
    ></ng-container>
  </ng-container>
</app-base-modal-component>
