import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ApplicationMainPageUrls } from '../../models/application-main-page-urls.enum';

@Component({
  selector: 'app-redirect-snack-bar',
  templateUrl: 'redirect-snack-bar.component.html',
  styleUrls: ['redirect-snack-bar.component.scss'],
})
export class RedirectSnackBarComponent {
  targetId: string;
  htmlTemplate: {
    start: string;
    end: string;
    linkText?: string;
    navigateTo?: string;
  };
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      id?: string;
      html: {
        start: string;
        end: string;
        linkText?: string;
        navigateTo?: string;
      };
    },
    private router: Router
  ) {
    this.targetId = data.id;
    this.htmlTemplate = data.html;
  }

  navigate() {
    this.router
      .navigateByUrl(`/${ApplicationMainPageUrls.CORE}`, {
        skipLocationChange: true,
      })
      .then(() => {
        if (this.htmlTemplate.linkText) {
          return this.router.navigate([
            ApplicationMainPageUrls.CORE,
            this.htmlTemplate.navigateTo,
          ]);
        } else {
          return this.router.navigate([
            ApplicationMainPageUrls.CORE,
            this.targetId,
            'overview',
          ]);
        }
      });
  }
}
