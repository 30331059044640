import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Platform } from '@shared/schemas/common/platforms';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SomedusRequestObj } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { BaseService } from 'src/app/services/base.service';
import {
  transformCamelToSnakeRecursive,
  transformSnakeToCamel,
} from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService extends BaseService {
  private readonly DEFAULT_SOMEDUS_PENDING_STATUSES: JobStatus[] = [
    JobStatus.PENDING,
    JobStatus.REISSUED,
    JobStatus.EXPIRED,
    JobStatus.UPLOADING,
    JobStatus.FAILED,
  ];

  private readonly SOMEDUS_FULL_STATES: JobStatus[] = [
    JobStatus.DONE,
    JobStatus.PENDING,
    JobStatus.REISSUED,
    JobStatus.EXPIRED,
    JobStatus.ABANDONED,
  ];

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  getSomedusStatus(targetId: string): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    this.DEFAULT_SOMEDUS_PENDING_STATUSES.forEach(
      (jobStatus) => (params = params.append('status', jobStatus))
    );
    return this.httpClient
      .get<SomedusRequestObj[]>(
        `${this.fastAPIurl}/features/${targetId}/somedus`,
        {
          params,
        }
      )
      .pipe(map((result) => transformSnakeToCamel(result)));
  }

  getDoneSomedusFeatures(targetId: string): Observable<SomedusRequestObj[]> {
    const params = new HttpParams();
    return this.httpClient
      .get<SomedusRequestObj[]>(
        `${this.fastAPIurl}/features/${targetId}/somedus?status=${JobStatus.DONE}`,
        {
          params,
        }
      )
      .pipe(map((result) => transformSnakeToCamel(result)));
  }

  getUploadingSomedusFeatures(
    isArchived = false
  ): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    [JobStatus.UPLOADING].forEach(
      (jobStatus) => (params = params.append('status', jobStatus))
    );
    params = params.append('is_archived', isArchived);
    return this.httpClient
      .get<SomedusRequestObj[]>(`${this.fastAPIurl}/features`, { params })
      .pipe(map((result) => transformSnakeToCamel(result)));
  }

  getAllSomedusFeatures(isArchived = false): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    this.SOMEDUS_FULL_STATES.forEach(
      (jobStatus) => (params = params.append('status', jobStatus))
    );
    params = params.append('is_archived', isArchived);
    return this.httpClient
      .get<SomedusRequestObj[]>(`${this.fastAPIurl}/features`, { params })
      .pipe(map((result) => transformSnakeToCamel(result)));
  }

  getAllSomedusFeaturesByTargetId(
    targetId: string,
    isArchived = false
  ): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    this.SOMEDUS_FULL_STATES.forEach(
      (jobStatus) => (params = params.append('status', jobStatus))
    );
    params = params.append('is_archived', isArchived);
    return this.httpClient
      .get<SomedusRequestObj[]>(
        `${this.fastAPIurl}/features/${targetId}/somedus`,
        { params }
      )
      .pipe(map((result) => transformSnakeToCamel(result)));
  }

  updateFeatureStatus(requestId: string, status: JobStatus) {
    return this.httpClient.post(
      `${this.fastAPIurl}/somedus/${requestId}?request_status=${status}`,
      {}
    );
  }

  getSomedusData(targetId: string, requestId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.fastAPIurl}/features/${targetId}/somedus?request_id=${requestId}&status=${JobStatus.DONE}`
    );
  }

  requestForSomedus(requestBody: SomedusRequestObj[]): Observable<any> {
    const snakeCaseRequestBody = requestBody
      .filter((item) => !!item.targetId)
      .map((item) => transformCamelToSnakeRecursive(item));
    return this.httpClient
      .post<any>(`${this.fastAPIurl}/somedus/`, snakeCaseRequestBody)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          if (error.status === 402) {
            this.showMessage('Not enough credits');
            return throwError('Not enough credits');
          }
          return throwError(error.message);
        })
      );
  }

  uploadFileForSomedus(
    file: File,
    correlationId: string,
    platform: Platform
  ): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('platform', platform);

    return this.httpClient
      .post<string>(
        `${this.fastAPIurl}/somedus/dx-integration/${correlationId}`,
        formData
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  archiveSomedusRequest(
    featureRequestId: string
  ): Observable<SomedusRequestObj> {
    return this.httpClient
      .post<SomedusRequestObj[]>(
        `${this.fastAPIurl}/features/archive/${featureRequestId}`,
        {}
      )
      .pipe(map((result) => transformSnakeToCamel(result)));
  }
}
