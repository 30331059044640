import { Component, Input } from '@angular/core';
import { ButtonSize } from '../icon-text-button/button-size.enum';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';
import { FontAwesomeFamily } from '../icons/fa-icon-family.enum';
import { DataSource } from '@trg-commons/data-models-ts';

@Component({
  selector: 'intellectus-platform-icon',
  templateUrl: './platform-icon.component.html',
  styleUrls: ['./platform-icon.component.scss'],
})
export class PlatformIconComponent {
  @Input() platform: DataSource | string | undefined = undefined;
  @Input() size: ButtonSize = ButtonSize.Default;
  @Input() inline = false;

  public readonly availableFaBrandIcons: {
    [id: string]: FontAwesomeIcon;
  } = {
    facebook: { name: 'facebook', family: FontAwesomeFamily.BRANDS },
    whatsapp: { name: 'whatsapp', family: FontAwesomeFamily.BRANDS },
    youtube: { name: 'youtube', family: FontAwesomeFamily.BRANDS },
    telegram: { name: 'telegram', family: FontAwesomeFamily.BRANDS },
    tiktok: { name: 'tiktok', family: FontAwesomeFamily.BRANDS },
    twitter: { name: 'twitter-new', family: FontAwesomeFamily.KIT },
    skype: { name: 'skype', family: FontAwesomeFamily.BRANDS },
    instagram: { name: 'instagram', family: FontAwesomeFamily.BRANDS },
    linkedin: { name: 'linkedin-in', family: FontAwesomeFamily.BRANDS },
    viber: { name: 'viber', family: FontAwesomeFamily.BRANDS },
    yahoo: { name: 'yahoo', family: FontAwesomeFamily.BRANDS },
    aol: { name: 'aol', family: FontAwesomeFamily.KIT },
    wechat: { name: 'weixin', family: FontAwesomeFamily.BRANDS },
    person: { name: 'database', family: FontAwesomeFamily.LIGHT },
    callerid: { name: 'caller-id', family: FontAwesomeFamily.KIT },
    calllogs: { name: 'call-logs', family: FontAwesomeFamily.KIT },
    tinder: { name: 'tinder', family: FontAwesomeFamily.KIT },
    calllog: { name: 'call-logs', family: FontAwesomeFamily.KIT },
    truecaller: { name: 'truecaller', family: FontAwesomeFamily.KIT },
    callapp: { name: 'callapp', family: FontAwesomeFamily.KIT },
    unknown: { name: 'circle-question', family: FontAwesomeFamily.SOLID },
  };
}
