import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { ReportSectionImage } from 'src/app/shared/models/report-section-image.model';

import { HtmlToImageService } from '../html-to-image/html-to-image.service';
@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private htmlToImageService: HtmlToImageService
  ) {
    super(router, snackBar);
  }

  transformSectionsToImages<T_SECTION_IDENTIFIER>(
    reportSections: ReportAttributeDirective<T_SECTION_IDENTIFIER>[]
  ): Observable<ReportSectionImage<T_SECTION_IDENTIFIER>[]> {
    return new Observable((subscriber) => {
      if (reportSections.length === 0) {
        subscriber.next([]);
        subscriber.complete();
      }
      Promise.all(
        reportSections.map((section) => {
          return this.htmlToImageService.transform(section);
        })
      ).then((images) => {
        subscriber.next(
          images.map((image, index) => {
            return {
              title: reportSections[index].reportSectionAttribute,
              image: image,
              sectionIdentifier: reportSections[index].sectionIdentifier,
            };
          })
        );
        subscriber.complete();
      });
    });
  }

  downloadPDF(title: string, data: string): void {
    const a = document.createElement('a');
    a.href = data;
    a.download = `${title}_${this.datePipe.transform(
      new Date(),
      'ddMMyyyy_Hmms'
    )}`;
    a.dispatchEvent(new MouseEvent('click'));
  }
}
