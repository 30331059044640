import { BaseDTO } from 'datalayer/services/base';
import { Profile } from 'datalayer/models/social/profile';
import {
  ChatsInfo,
  DataSource,
  EntityRelationType,
  EntityType,
  Gender,
  ProfileStatus,
} from 'datalayer/models/platform-models';
import { Location } from 'src/app/modules/data-layer/models/platform-models/social/location';
import { RecoveryAccountDTO } from '../recovery-account/recovery-account-dto';
import { SocialPlatformMap } from '@shared/models/social-platform-map';
import { Platform } from '@shared/schemas/common/platforms';
import { MediaDTO } from 'datalayer/services/media/media/media-dto';

export class ProfileDTO extends BaseDTO<Profile, ProfileDTO> {
  private is_public: boolean;
  private id: any;
  private about: string;
  private username?: string;
  private telno?: string;
  private url?: string;
  private name?: string;
  private nicknames?: string[];
  private gender?: Gender;
  private dob?: string;
  private email?: string;
  private status?: ProfileStatus;
  private last_seen?: Date;
  private languages?: string[];
  private websites?: string[];
  private mood?: string;
  private interested_in?: string;
  private hometown?: Location;
  private current_city?: Location;
  private name_location?: Location;
  private verified?: boolean;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string;
    created_at: Date;
    parent_id: string;
  };
  private target_id?: string;
  private profile_id?: string;
  private relation_type?: EntityRelationType;
  private image?: Partial<MediaDTO>;
  private location?: string;
  private is_geo_enabled?: boolean;
  private favourites_count?: number;
  private profile_numeric_id?: string;
  private following_count?: number;
  private followers_count?: number;
  private tweets_count?: number;
  private joined_at?: Date;
  private friends_count?: number;
  private hearts_count?: number;
  private videos_count?: number;
  private relationship_status?: string;
  private profile_to_target_info?: {
    target_id?: string;
    alias?: string;
  };
  private recovery_account?: RecoveryAccountDTO;
  private telecom_provider?: string;
  private chats_info?: ChatsInfo;
  private messages_count?: number;
  private groups_count?: number;
  private registration_date?: string;
  private place_of_birth?: string;
  private nik?: string;

  constructor(model?: Partial<ProfileDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Profile): ProfileDTO {
    this.is_public = model.isPublic;
    this.id = model.id;
    this.about = model.about;
    this.username = model.username;
    this.telno = model.telno;
    this.url = model.url;
    this.name = model.name;
    this.gender = model.gender;
    this.dob = model.dob;
    this.email = model.email;
    this.status = model.status;
    this.last_seen = model.lastseen;
    this.languages = model.languages;
    this.websites = model.websites;
    this.mood = model.mood;
    this.interested_in = model.interestedIn;
    this.hometown = model.hometown;
    this.current_city = model.currentCity;
    this.name_location = model.nameLocation;

    this.verified = model.verified;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.nicknames = model.nicknames;
    this.target_id = model.targetId;
    this.profile_id = model.profileId;
    this.relation_type = model.relationType;
    this.image = model.image
      ? new MediaDTO().fromModel(model.image)
      : undefined;
    this.location = model.location;
    this.is_geo_enabled = model.isGeoEnabled;
    this.favourites_count = model.favouritesCount;
    this.profile_numeric_id = model.profileNumericId;
    this.following_count = model.followingCount;
    this.followers_count = model.followersCount;
    this.tweets_count = model.tweetsCount;
    this.hearts_count = model.heartsCount;
    this.videos_count = model.videosCount;
    this.joined_at = model.joinedAt;
    this.friends_count = model.friendsCount;
    this.profile_to_target_info = {
      target_id: model.profileToTargetInfo?.targetId,
      alias: model.profileToTargetInfo?.alias,
    };
    this.recovery_account = new RecoveryAccountDTO().fromModel(
      model.recoveryAccount
    );
    this.telecom_provider = model.telecomProvider;
    this.relationship_status = model.relationshipStatus;
    this.chats_info = model.chatsInfo;
    this.groups_count = model.groupsCount;
    this.messages_count = model.messagesCount;
    this.registration_date = model.registrationDate;
    this.place_of_birth = model.placeOfBirth;
    this.nik = model.nik;

    return this;
  }

  toModel(): Profile {
    return new Profile({
      isPublic: this.is_public,
      id: this.id,
      about: this.about,
      url: this.url,
      name: this.name,
      gender: this.gender,
      dob: this.dob,
      email: this.email,
      status: this.status,
      lastseen: this.last_seen,
      languages: this.languages,
      websites: this.websites,
      mood: this.mood,
      hometown: this.hometown,
      currentCity: this.current_city,
      nameLocation: this.name_location,
      interestedIn: this.interested_in,
      verified: this.verified,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      username: this.username,
      telno: this.telno,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      nicknames: this.nicknames,
      targetId: this.target_id,
      profileId: this.profile_id,
      relationType: this.relation_type,
      image: new MediaDTO(this.image).toModel(),
      location: this.location,
      isGeoEnabled: this.is_geo_enabled,
      favouritesCount: this.favourites_count,
      profileNumericId: this.profile_numeric_id,
      followingCount: this.following_count,
      followersCount: this.followers_count,
      tweetsCount: this.tweets_count,
      videosCount: this.videos_count,
      heartsCount: this.hearts_count,
      joinedAt: this.joined_at,
      friendsCount: this.friends_count,
      profileToTargetInfo: {
        targetId: this.profile_to_target_info?.target_id,
        alias: this.profile_to_target_info?.alias,
      },
      recoveryAccount: new RecoveryAccountDTO(this.recovery_account).toModel(),
      telecomProvider: this.telecom_provider,
      relationshipStatus: this.relationship_status,
      platform: SocialPlatformMap[this.source] || Platform.UNKNOWN,
      chatsInfo: this.chats_info,
      groupsCount: this.groups_count,
      messagesCount: this.messages_count,
      registrationDate: this.registration_date,
      placeOfBirth: this.place_of_birth,
      nik: this.nik,
    });
  }
}
