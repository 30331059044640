import { TranslationDictionary } from '../translationDictionary.interface';

export const id: TranslationDictionary = {
  ' Create a new one ': 'Buat yang baru ',
  ' more': 'lagi',
  '#{clParameterType} entered is not valid. Please try another one':
    '#{clParameterType} yang dimasukkan tidak valid. Silakan coba yang lain',
  "#{msisdn} has been used on target's phone #{imei}":
    '#{msisdn} telah digunakan di ponsel target #{imei}',
  '#{socialType} registration started': '#{socialType} pendaftaran dimulai',
  '#{socialType} registration succeed. Trying to get email otp code':
    'Pendaftaran #{socialType} berhasil. Mencoba mendapatkan kode otp email',
  '1 day': '1 hari',
  '1 hour': '1 Jam',
  '1 minute': '',
  '1 week': '1 minggu',
  '15 minutes': '15 menit',
  '2 days': '2 hari',
  '2-Step Verification': 'Verifikasi 2 Langkah',
  '2-Step Verification is OFF': 'Verifikasi 2 Langkah DIMATIKAN',
  '3 days': '3 hari',
  '30 minutes': '30 menit',
  "A second step after entering your password verifies it's you signing in.":
    'Langkah kedua setelah memasukkan kata sandi Anda memverifikasi bahwa Anda masuk.',
  ACTIVE: 'AKTIF',
  'AD ID': 'ID Ad',
  'ADD ID': 'TAMBAH ID',
  'ADDITIONAL LAYERS': "ADDITIONAL LAYERS' / LAPISAN TAMBAHAN",
  'ALL QUERIES FOUND': 'SEMUA PERTANYAAN DITEMUKAN',
  'ALL UNREACHABLE QUERIES': 'SEMUA KUERI YANG TIDAK DAPAT DIJANGKAU',
  APPs: 'Aplikasi',
  'AT&T post-paid only': 'Khusus pascabayar AT&T',
  Abandon: 'Abaikan',
  About: 'Tentang',
  'Accept friends': 'Terima teman',
  'Accepting requests': 'Menerima permintaan',
  'Access to the target will be free for #{days} days':
    'Akses ke target akan gratis selama #{days} days',
  Account: 'Akun',
  'Account info': '',
  Accounts: 'Akun',
  'Act number': 'Nomor tindakan',
  Action: 'Tindakan',
  'Action has been successfully created': 'Tindakan telah berhasil dibuat',
  'Action is started': 'Aksi dimulai',
  'Action is stopped': 'Aksi dihentikan',
  'Action started by': 'Aksi dimulai dengan',
  Actions: 'Tindakan',
  Activate: 'Aktifkan',
  'Activate 2-step verification': 'Aktifkan verifikasi 2 langkah',
  'Activate Now!': 'Aktifkan Sekarang!',
  'Activate OTP': 'Aktifkan OTP',
  Activated: 'Diaktifkan',
  Active: 'Aktif',
  'Active Locate': 'Lokasi Aktif',
  'Active in': 'Aktif dalam',
  'Active locate': 'Lokasi aktif',
  'Active locate query submitted successfully!':
    'Kueri lokasi aktif berhasil dikirimkan!',
  'Active proximity items': 'Item kedekatan yang aktif',
  Activities: 'Kegiatan',
  Activity: 'Aktivitas',
  'Activity Log': 'Log Aktivitas',
  'Activity Patterns': 'Pola Aktivitas',
  'Activity log': 'Log aktivitas',
  'Activity patterns': 'Pola aktivitas',
  'Activity timeline': 'Garis waktu aktivitas',
  'Ad IDs': 'ID Ad',
  Add: 'Tambahkan',
  Adding: '',
  'Add Group': 'Tambahkan Grup',
  'Add IDs': 'Tambah ID',
  'Add MSISDN': 'Tambahkan MSISDN',
  'Add Profile to My Target': 'Tambahkan Profil ke Target Saya',
  'Add URL': 'Tambahkan URL',
  'Add a Facebook account and click "Enchance target" to retrieve more information':
    'Tambahkan akun Facebook dan klik "Tingkatkan target" untuk mendapatkan informasi lebih lanjut',
  'Add a new note': 'Tambahkan catatan baru',
  'Add a note': 'Tambahkan catatan',
  'Add a valid MSISDN': 'Tambahkan MSISDN yang valid',
  'Add an existing target': 'Tambahkan target yang sudah ada',
  'Add and analyse': 'Tambahkan dan analisis',
  'Add at least 1 MSISDN or IMEI': 'Tambahkan setidaknya 1 MSISDN atau IMEI',
  'Add at least 1 MSISDN or IMEI or IMSI':
    'Tambahkan setidaknya 1 MSISDN atau IMEI atau IMSI',
  'Add case': 'Tambahkan kasus',
  'Add court number': '',
  'Add email': 'Tambahkan email',
  'Add entity': 'Tambahkan entitas',
  'Add group name': 'Tambahkan nama grup',
  'Add hashtag for this query': 'Tambahkan tagar untuk kueri ini',
  'Add media content': 'Tambahkan konten media',
  'Add name and image url': 'Tambahkan nama dan gambar url',
  'Add new': 'Tambah baru',
  'Add new Target': 'Tambahkan target baru',
  'Add new item': 'Tambahkan item baru',
  'Add new keywords list': 'Tambahkan daftar kata kunci baru',
  'Add new place': 'Tambahkan tempat Baru',
  'Add new platform': 'Tambahkan platform baru',
  'Add new value': 'Tambahkan nilai baru',
  'Add note': 'Menambahkan catatan',
  'Add phone number': 'Tambahkan nomor telepon',
  'Add profile to target': 'Tambahkan profil ke target',
  'Add seeds and click "Enchance target" to get "Most common faces"':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mendapatkan "Wajah paling umum"',
  'Add seeds and click "Enchance target" to retrieve employment and education':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mendapatkan pekerjaan dan pendidikan',
  'Add seeds and click "Enchance target" to retrieve instant messaging apps':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mengambil aplikasi pesan instan',
  'Add seeds and click "Enchance target" to retrieve media':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mengambil media',
  'Add seeds and click "Enchance target" to retrieve relationship information':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mengambil informasi hubungan',
  'Add seeds and click "Enchance target" to retrieve social profiles':
    'Tambahkan sumber dan klik "Tingkatkan target" untuk mengambil profil sosial',
  'Add seeds or Perform an OSINT query to retrieve more information':
    'Tambahkan sumber atau Lakukan kueri OSINT untuk mengambil informasi lebih lanjut',
  'Add selected targets': 'Tambahkan target yang dipilih',
  'Add social network': 'Tambahkan jejaring sosial',
  'Add social profiles to the targets to view data':
    'Tambahkan profil sosial ke target untuk melihat data',
  'Add target': 'Tambahkan target',
  'Add targets': 'Tambahkan target',
  "Add targets by searching for target's Alias.":
    'Tambahkan target dengan mencari Alias target.',
  'Add targets by searching for target’s Alias.': '',
  'Add to "My target"': 'Tambahkan ke "Target saya"',
  'Add to an existing target': 'Tambahkan ke target yang ada',
  'Add to case': 'Tambahkan ke kasus',
  'Add to target': 'Tambahkan ke target',
  'Add to Mission': '',
  'Add user': 'Tambahkan Pengguna / Tambahkan User',
  'Add from recent searches': '',
  Added: 'Ditambahkan',
  'Added Details': 'Menambahkan Detail',
  'Added Note': 'Menambahkan Catatan',
  'Added Photo': 'Menambahkan Foto',
  'Added Seed': 'Menambahkan Sumber',
  'Added on': 'Menambahkan',
  Address: 'Alamat',
  'Address(es)': 'Alamat)',
  Addresses: 'Alamat',
  Adint: 'Adint',
  'Adint history': 'Sejarah Adint',
  Admin: 'Admin',
  Advanced: 'Lanjutan',
  'Advanced Geo Query': 'Kueri Geografis Tingkat Lanjut',
  'Advanced Information Request': 'Permintaan Informasi Lanjutan',
  'Advanced Options': 'Opsi Lanjutan',
  'Advanced Webint': 'Webint Tingkat Lanjut',
  'Advanced Webint query created successfully!':
    'Kueri Webint lanjutan berhasil dibuat!',
  'Advanced geolocation': 'Geolokasi tingkat lanjut',
  'Advanced osint': 'Osint tingkat lanjut',
  'Advanced search will add media, relations, insights and more from social profiles':
    'Pencarian lanjutan akan menambahkan media, relasi, wawasan, dan lainnya dari profil sosial',
  'Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more':
    'Pencarian lanjutan akan mengumpulkan lebih banyak informasi dari MSISDN, email & profil sosial dan menganalisis konten mereka seperti media, relasi, postingan, dan lainnya',
  'Advanced webint is already in progress.':
    'Webint tingkat lanjut sedang dalam proses.',
  'Affiliated person': 'Orang yang berafiliasi',
  Afghanistan: 'Afganistan',
  Age: 'Umur',
  'Age range': 'Rentang usia',
  'Age of Location': '',
  Albania: 'Albania',
  'Album name': 'Nama album',
  'Album url': 'Url album',
  Alerts: '',
  Algeria: 'Aljazair',
  Alias: 'Alias',
  'Alias exists. Please use another alias':
    'Alias ada. Harap gunakan alias lain',
  'Alias is too short': 'Alias terlalu pendek',
  Aliases: 'Alias',
  All: 'Semua',
  'All IP addresses': 'Semua alamat IP',
  'All MSISDNs': 'Semua MSISDNs',
  'All NIK': 'Semua NIK',
  'All Site names': 'Semua nama situs',
  'All Types': 'Semua Tipe',
  'All accounts': '',
  'All addresses': 'Semua alamat',
  'All associated domains': 'Semua domain terkait',
  'All caught up': 'Semua sudah ketahuan',
  'All companies': 'Semua perusahaan',
  'All contacts': 'Semua kontak',
  'All courses': 'Semua kursus',
  'All educations': 'Semua pendidikan',
  'All emails': 'Semua email',
  'All family relations': 'Semua hubungan keluarga',
  'All fans': 'Semua penggemar',
  'All friend relations': 'Semua hubungan pertemanan',
  'All groups': 'Semua grup',
  'All hashtags': 'Semua hashtag',
  'All industries': 'Semua industri',
  'All jobs': 'Semua pekerjaan',
  'All landlines': 'Semua sambungan telepon rumah',
  'All locations': 'Semua lokasi',
  'All mentions': 'Semua mentions',
  'All messages': 'Semua pesan',
  'All names': 'Semua nama',
  'All organizations': 'Semua organisasi',
  'All other info': 'Semua info lainnya',
  'All other relations': 'Semua hubungan lainnya',
  'All phone numbers': 'Semua nomor telepon',
  'All places': 'Semua tempat',
  'All sent messages': 'Semua pesan terkirim',
  'All skills': 'Semua keterampilan',
  'All social platforms': 'Semua platform sosial',
  'All sources': 'Semua sumber',
  'All the answers are anonymous.': 'Semua jawaban bersifat anonim.',
  'All the data collected will be completely anonymous.':
    'Semua data yang dikumpulkan akan sepenuhnya anonim.',
  'All types': 'Semua tipe',
  'All urls': 'Semua url',
  'All usernames': 'Semua nama pengguna',
  'All users': 'Semua pengguna',
  'All vehicle ID (VIN)': 'Semua ID kendaraan (VIN)',
  'All vehicle make': 'Semua kendaraan membuat',
  'All vehicle models': 'Semua model kendaraan',
  'All volunteering actions': 'Semua aksi sukarela',
  'All week': 'Semua minggu',
  'All work relations': 'Semua hubungan kerja',
  'Allowed file types: images': 'Jenis file yang diperbolehkan: gambar',
  'Always obtain accurate geolocation information when available':
    'Selalu dapatkan informasi geolokasi yang akurat jika tersedia',
  'Always obtain the peer details and geolocate when available':
    'Selalu dapatkan detail rekan dan geolokasi jika tersedia',
  'Always obtain the peer details when available':
    'Selalu dapatkan detail peer jika tersedia',
  'Always show descriptions': 'Selalu tampilkan deskripsi',
  Amount: 'Jumlah',
  'An email with the QR was sent to': 'Email dengan QR telah dikirim ke',
  'An error has occurred': 'Sebuah kesalahan telah terjadi',
  'An international #{type} to or from #{msisdn} coming from #{country} has happened at least #{frequency} time(s).':
    'Sebuah #{type} internasional ke atau dari #{msisdn} yang berasal dari #{country} telah terjadi setidaknya pada waktu #{frequency}.',
  Analyse: 'Menganalisa',
  'Analysing data': 'Menganalisis data',
  Analysis: 'Analisis',
  'Analysis includes': 'Analisis meliputi',
  Analytics: 'Analitik',
  Analyze: 'Analisis',
  'Analyze in progress': 'Analisis sedang berlangsung',
  'Analyze location': 'Analisis lokasi',
  'Analyze locations': 'Analisis lokasi',
  'Analyzed content': 'Konten yang dianalisis',
  'Analyzing Results': 'Menganalisis Hasil',
  'Analyzing and merging the results for':
    'Menganalisis dan menggabungkan hasil untuk',
  'Analyzing and unmerging the results for':
    'Menganalisis dan memisahkan hasil untuk',
  'Analyzing results': 'Menganalisis hasil',
  And: 'Dan',
  'And more': 'Dan lebih banyak lagi',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Antigua and Barbuda': 'Antigua dan Barbuda',
  Any: 'Apa saja',
  Anytime: 'Kapan saja',
  'AoI Query': 'Kueri AoI',
  Application: 'Aplikasi',
  Applications: 'Aplikasi',
  Apply: 'Terapkan',
  Apps: 'Aplikasi',
  April: 'April',
  Archive: 'Arsip',
  'Are you still in the same investigation?':
    'Apakah Anda masih dalam penyelidikan yang sama?',
  'Are you sure want to clear all the history ?':
    'Apakah Anda yakin ingin menghapus semua riwayat ?',
  'Are you sure want to clear the history ?':
    'Apakah Anda yakin ingin menghapus riwayat ?',
  'Are you sure want to delete the Activity log entry?': '',
  'Are you sure you want to delete case permanently?':
    'Apakah Anda yakin ingin menghapus kasus secara permanen?',
  'Are you sure you want to delete investigation permanently?':
    'Apakah Anda yakin ingin menghapus investigasi secara permanen?',
  'Are you sure you want to delete target permanently?':
    'Apakah Anda yakin ingin menghapus target secara permanen?',
  'Are you sure you want to delete this search?':
    'Apakah Anda yakin ingin menghapus pencarian ini?',
  'Are you sure you want to disable the geofencing?':
    'Apakah Anda yakin ingin menonaktifkan geofencing?',
  'Are you sure you want to leave the program?':
    'Apakah Anda yakin ingin keluar dari program ini?',
  'Are you sure you want to remove target from case permanently?':
    'Apakah Anda yakin ingin menghapus target dari kasus secara permanen?',
  'Are you sure you want to remove target from this case?':
    'Apakah Anda yakin ingin menghapus target dari kasus ini?',
  'Are you sure you want to remove this target?': '',
  'Are you sure you want to reset the user password for':
    'Apakah Anda yakin ingin mengatur ulang kata sandi pengguna untuk',
  'Are you sure you wish to resubmit?':
    'Apakah Anda yakin ingin mengirim ulang?',
  'Are you sure you wish to save this result?':
    'Apakah Anda yakin ingin menyimpan hasil ini?',
  Area: 'Area',
  'Area of Interest Query': 'Pertanyaan Bidang Minat',
  'Area of interest': 'Area yang diminati',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Articles: 'Artikel',
  Ascending: 'Bertambah',
  'Assign to target or add to existing':
    'Tetapkan ke target atau tambahkan ke yang sudah ada',
  'Assigned targets changed': 'Target yang ditetapkan berubah',
  'Associated MSISDN with account': 'MSISDN terkait dengan akun',
  'Associated domains': 'Domain terkait',
  'Associated with': 'Berkaitan dengan',
  'Attended same institution': 'Menghadiri institusi yang sama',
  August: 'Agustus',
  Australia: 'Australia',
  Austria: 'Austria',
  'Authenticating to system': 'Mengautentikasi ke sistem',
  'Auto detect': 'Deteksi otomatis',
  'Autocomplete all': 'Pelengkapan otomatis semua',
  'Automatic interactions': 'Interaksi otomatis',
  'Available Credits': 'Kredit yang Tersedia',
  'Available Data': 'Data yang Tersedia',
  'Available to assign': 'Tersedia untuk ditetapkan',
  Avatar: 'Avatar',
  'Avatar id': 'Avatar id',
  'Avatar in error state, please contact administrators':
    'Avatar error, silakan hubungi administrator',
  'Avatar remote view ended': 'Tampilan avatar remote berakhir',
  Avatars: 'Avatar',
  'Average activity': 'Aktivitas rata-rata',
  'Average matches per session': 'Rata-rata pertandingan per sesi',
  Azerbaijan: 'Azerbaijan',
  'BASE LAYERS': "BASE LAYERS' / LAPISAN DASAR",
  BLOCKED: 'DIBLOKIR',
  BUSY: 'SIBUK',
  Back: 'Kembali',
  'Back to "Cases and targets"': 'Kembali ke "Kasus dan target"',
  'Back to "Core"': 'Kembali ke "Inti"',
  'Back to Call Logs': 'Kembali ke Log Panggilan',
  'Back to Case': 'Kembali ke Kasus',
  'Back to Dashboard': 'Kembali ke Dasbor',
  'Back to login': 'Kembali ke login',
  'Back to previous query': 'Kembali ke kueri sebelumnya',
  Bahrain: 'Bahrain',
  Balance: 'Keseimbangan',
  Bangladesh: 'Bangladesh',
  'Bank details': 'Rincian bank',
  Barbados: 'Barbados',
  Basic: 'Dasar',
  'Basic OSINT': 'OSINT Dasar',
  'Basic OSINT Search': 'Pencarian OSINT Dasar',
  'Basic OSINT will discover more seeds':
    'OSINT Dasar akan menemukan lebih banyak sumber',
  'Basic Osint query created successfully!':
    'Kueri Osint dasar berhasil dibuat!',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Betweenness: 'Betweenness',
  Bhutan: 'Bhutan',
  'Billing error': 'Kesalahan penagihan',
  Bio: 'Bio',
  'Birth date': 'Tanggal lahir',
  'Birth location': '',
  Birthday: 'Hari ulang tahun',
  Blacklist: 'Daftar Hitam',
  'Blacklist number': 'Nomor daftar hitam',
  Blacklisted: '',
  Block: 'Blok',
  Blocked: 'Diblokir',
  Bolivia: 'Bolivia',
  'Bosnia and Herzegovina': 'Bosnia dan Herzegovina',
  'Both screenWidth and screenHeight must be specified':
    'Lebar layar dan Tinggi Layar harus ditentukan',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'Breached results for': 'Hasil yang dilanggar untuk',
  'British Indian Ocean Territory': 'Wilayah Samudra Hindia Inggris',
  'British Virgin Islands': 'Kepulauan Virgin Inggris',
  Bright: '',
  Broadcaster: 'Penyiar',
  Browse: 'Jelajahi',
  'Browse files': 'Mencari berkas',
  Browser: 'Browser',
  Brunei: 'Brunei',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Busy: 'Sibuk',
  'Button to start adding content to it': '',
  CALLEE: 'PANGGILAN',
  CALLER: 'PENELEPON',
  'CDR Statistics': 'Statistik CDR',
  CURP: 'CURP',
  CVE: 'CVE',
  Calculate: '',
  Calculating: '',
  'Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.':
    'Menghitung koneksi diperpanjang dari sebuah node. Dapat mengidentifikasi node dengan pengaruh di seluruh jaringan, dan bukan hanya node yang terhubung langsung dengannya.',
  Call: 'Panggilan',
  'Call Analysis': '',
  'Call Log': 'Log Panggilan',
  'Call Log Analysis': 'Analisis Log Panggilan',
  'Call Log Cancel Request': 'Permintaan Pembatalan Log Panggilan',
  'Call Log MSISDN': 'Log Panggilan MSISDN',
  'Call Log Pending': 'Log Panggilan Tertunda',
  'Call Log Request': 'Permintaan Log Panggilan',
  'Call Log Request Pending': 'Permintaan Log Panggilan Tertunda',
  'Call Log for': 'Log Panggilan untuk',
  'Call Log has been requested': 'Log Panggilan telah diminta',
  'Call Logs': 'Log Panggilan',
  'Call Logs uploaded successfully!': 'Log Panggilan berhasil diunggah!',
  'Call analysis': 'Analisis panggilan',
  'Call detail records for': 'Catatan detail panggilan untuk',
  'Call duration': '',
  'Call in': 'Panggilan masuk',
  'Names from Call ID Apps': '',
  'Call log': 'Laporan panggilan',
  'Call log MSISDN is different from target MSISDN.':
    'Log panggilan MSISDN berbeda dengan MSISDN target.',
  'Call log analysis': 'Analisis log panggilan',
  'Call log map': 'Peta log panggilan',
  'Call log pending': 'Log panggilan tertunda',
  'Call log recommandation archived.': 'Rekomendasi log panggilan diarsipkan.',
  'Call log recommandation unarchived.':
    'Rekomendasi log panggilan tidak diarsipkan.',
  'Call log record': 'Catatan log panggilan',
  'Call log report in PDF': 'Laporan log panggilan dalam PDF',
  'Call log request #{type} for #{duration} months':
    'Permintaan log panggilan #{type} selama #{duration} bulan',
  'Call log request is already in pending':
    'Permintaan log panggilan sudah tertunda',
  'Call log requested': 'Log panggilan yang diminta',
  'Call log successfully processed!': 'Log panggilan berhasil diproses!',
  'Call log top associates': 'Log panggilan rekan teratas',
  'Call log top relation': 'Relasi teratas log panggilan',
  'Call log upload for #{type}': 'Upload log panggilan untuk #{type}',
  'Call log upload request for author':
    'Permintaan unggah log panggilan untuk penulis',
  'requested at': 'diminta di',
  'has been canceled and refunded': 'telah dibatalkan dan dikembalikan',
  'Call logs': 'Log panggilan',
  'Call logs interaction depth': 'Kedalaman interaksi log panggilan',
  'Call logs should be uploaded as a single .csv file':
    'Log panggilan harus diunggah sebagai satu .file csv',
  'Call logs should be uploaded as a single .csv or .xlsx file':
    'Log panggilan harus diunggah sebagai satu .csv atau .berkas xlsx',
  'Call out': 'Panggilan keluar',
  Called: 'Disebut',
  Callee: 'Panggilan',
  Caller: '',
  Callerid: 'penelepon',
  Calls: 'Panggilan',
  'Calls and SMS': 'Panggilan dan SMS',
  'Calls duration': 'Durasi panggilan',
  'Calls number': 'Nomor panggilan',
  Cambodia: 'Kamboja',
  Cameroon: 'Kamerun',
  Canada: 'Kanada',
  Cancel: 'Batal',
  'Cancel Request sent successfully': 'Batalkan Permintaan berhasil dikirim',
  'Cancel all uploads': 'Batalkan semua unggahan',
  'Cancel request sent successfully!': 'Batalkan permintaan berhasil dikirim!',
  Cancelled: '',
  'Cancelled request': 'Permintaan dibatalkan',
  'Cannot update schema': '',
  'Cannot upload file': 'Tidak dapat mengunggah file',
  'Cape Verde': 'Cape Verde',
  'Card number': 'Nomor kartu',
  Cardinal: 'Kardinal',
  Carrier: 'Pembawa',
  Case: 'Kasus',
  'Case Call Analysis': 'Analisis Panggilan Kasus',
  'Case Cdr Statistics': 'Statistik Cdr Kasus',
  'Case Count Analysis': 'Analisis Jumlah Kasus',
  'Case Event By Type': 'Peristiwa Kasus Menurut Jenis',
  'Case Target Activity': 'Aktivitas Target Kasus',
  'Case Top Associates': 'Case Top Associates',
  'Case Top Locations': 'Lokasi Teratas Kasus',
  'Case call logs report': 'Laporan log panggilan kasus',
  'Case colour changed': 'Warna kasus berubah',
  'Case created successfully!': 'Kasus berhasil dibuat!',
  'Case has not been created': 'Kasus belum dibuat',
  'Case has not been edited': 'Kasus belum diedit',
  'Case has not been renewed': 'Kasus belum diperpanjang',
  'Case is expired': 'Kasus kedaluwarsa',
  'Case name changed': 'Nama kasus berubah',
  'Case renewed successfully!': 'Kasus berhasil diperbarui!',
  'Case targets': 'Target kasus',
  'Case updated successfully!': 'Kasus berhasil diperbarui!',
  Cases: 'Kasus',
  'Cases and Targets': 'Kasus dan Target',
  'Cases and targets': 'Kasus dan target',
  'Cases duration': 'Durasi kasus',
  Catcher: 'Penangkap',
  Category: 'Kategori',
  'Cayman Islands': 'Pulau cayman',
  'Cdr Export': 'Ekspor Cdr',
  'Cdr Statistics': 'Statistik Cdr',
  'Cdr Type': 'Tipe Cdr',
  Cell: 'Cell / sel',
  'Cell ECI': 'sel ECI',
  'Cell ENBID': 'Sel ENBID',
  'Cell Id': '',
  'Cell ID': '',
  'Cell LCID': 'Sel LCID',
  'Cell id': 'Cell id',
  'Central African Republic': 'Republik Afrika Tengah',
  'Cell tower': '',
  Certainty: 'Kepastian',
  Chad: 'Chad',
  Change: 'Perubahan',
  'Change OTP method': 'Ubah metode OTP',
  'Change Password': 'Ganti kata sandi',
  'Change language': 'Ganti BAHASA',
  'Change password': 'Ganti kata sandi',
  'Change your password': 'Ubah kata sandi Anda',
  'Changes saved successfully!': 'Perubahan berhasil disimpan!',
  'Chat Sentiment': 'Sentimen Chat',
  'Chat details': 'Detail obrolan',
  'Chat summary': 'Ringkasan obrolan',
  Chats: 'Obrolan',
  'Check for spelling errors': 'Periksa kesalahan ejaan',
  'Check-ins': 'proses mendaftar',
  'Checkin details': 'Detail checkin',
  'Checking Data Availability...': 'Memeriksa Ketersediaan Data...',
  Chile: 'Chili',
  China: 'Cina',
  Choose: 'Pilih',
  'Choose Duration': 'Pilih Durasi',
  'Choose Frequency': 'Pilih Frekuensi',
  'Choose age range': 'Pilih rentang usia',
  'Choose an interval': 'Pilih interval',
  'Choose duration and frequency': 'Pilih durasi dan frekuensi',
  'Choose file to upload': 'Pilih file untuk diunggah',
  'Choose image to upload': 'Pilih gambar untuk diunggah',
  'Choose media for upload': 'Pilih media untuk diunggah',
  'Choose profiles or seeds to build your target':
    'Pilih profil atau sumber untuk membangun target Anda',
  'City name': 'Nama kota',
  Clear: 'Hapus',
  'Clear All': 'Hapus Semua',
  'Clear all': 'Bersihkan semua',
  'Clear all filters': 'Hapus semua filter',
  'Clear filters': 'Hapus filter',
  'Clear map': 'Hapus peta',
  'Clear selection': 'Hapus pilihan',
  'Click for more options': 'Klik untuk opsi lainnya',
  'Click on the button below to renew for #{days} days':
    'Klik tombol di bawah ini untuk memperbarui selama #{days} hari',
  'Click on the button to minimise the search process and continue with your tasks':
    'Klik tombol untuk meminimalkan proses pencarian dan melanjutkan tugas Anda',
  'Click to see details': 'Klik untuk melihat detailnya',
  Close: 'Menutup',
  'Close all': 'Tutup semua',
  'Close session details': 'Tutup detail sesi',
  'Closing this process before the Virtual ID logs in successfully will likely cause issues in operating it.':
    '',
  Collapse: 'Runtuh',
  'Collected Profile URL': 'URL Profil yang Dikumpulkan',
  'Collecting and analysing intelligence for':
    'Mengumpulkan dan menganalisis intelijen untuk',
  Colombia: 'Kolumbia',
  Colony: 'Koloni',
  Color: 'Warna',
  'Column settings': 'Pengaturan kolom',
  Command: 'Command',
  'Comment on posts': 'Komentar pada postingan',
  'Commented on a photo of the target': 'Mengomentari foto target',
  'Commented on a post of the target': 'Mengomentari postingan target',
  Commenters: 'Pengirim komentar',
  'Common Associates': 'Rekan Umum',
  'Common Groups': 'Grup Umum',
  'Common Ips': 'IP Umum',
  'Common Locations': 'Lokasi Umum',
  'Common education places': 'Tempat pendidikan umum',
  'Common faces on photos': 'Wajah umum di foto',
  'Common locations': 'Lokasi umum',
  'Common relations': 'hubungan umum',
  'Common relations on social networks': 'Hubungan umum di jejaring sosial',
  'Common working places': 'Tempat kerja umum',
  'Communication Channel': 'Saluran komunikasi',
  Communities: 'Komunitas',
  'Community Details': 'Detail Komunitas',
  Comoros: 'Komoro',
  Companies: 'Perusahaan',
  Company: 'Perusahaan',
  'Company Employees': 'Karyawan Perusahaan',
  'Company Logo': 'Logo perusahaan',
  'Company Logo Url': 'Url Logo Perusahaan',
  'Company employee': 'Karyawan perusahaan',
  'Company location': 'Lokasi perusahaan',
  Completed: 'Selesai',
  'Completed at': 'Selesai pada',
  'Completed successfully': 'Berhasil diselesaikan',
  Confidence: 'Keyakinan',
  Confidential: 'Rahasia',
  'Confidential content': 'Konten rahasia',
  'Configure keywords list': 'Konfigurasikan daftar kata kunci',
  'Configuring Gmail': 'Mengonfigurasi Gmail',
  'Configuring acc.': 'Mengkonfigurasi acc.',
  Confirm: 'Konfirmasi',
  'Confirm New Password': '',
  'Confirm new password':
    'Konfirmasi Kata Kunci Baru / Konfirmasi Password Baru',
  Confirmation: 'Konfirmasi',
  'Confirmation required': 'Diperlukan konfirmasi',
  'Confirmed Infection': 'Infeksi yang Dikonfirmasi',
  'Confirmed infection': '',
  'Congo-Brazzaville': 'Kongo-Brazzaville',
  'Connected numbers by device for':
    'Nomor yang terhubung berdasarkan perangkat, untuk',
  'Connected numbers by location for':
    'Nomor yang terhubung berdasarkan lokasi, untuk',
  'Connected with MSISDN': 'Terhubung dengan MSISDN',
  'Connected with this location': 'Terhubung dengan lokasi ini',
  Connections: 'Koneksi',
  Consec: 'Konsekuensi',
  'Contact Customer Success': 'Hubungi Customer Success',
  'Contact Us': 'Hubungi kami',
  'Contact’s active groups': 'Grup aktif kontak',
  Contains: 'Berisi',
  Content: 'Isi',
  Continue: 'Melanjutkan',
  'Conversation Type': 'Jenis Percakapan',
  'Cook Islands': 'Pulau Masak',
  Coordinates: 'Koordinat',
  Copied: 'Disalin',
  'Copied!': 'Disalin!',
  Copy: 'Menyalin',
  'Copy URL': 'Salin URL',
  'Copy all': 'Salin semua',
  'Copy link': 'Salin tautan',
  'Copy location': 'Salin lokasi',
  'Copy name': 'Salin nama',
  'Copy password': 'Salin kata sandi',
  'Copy to Clipboard': 'Menyalin ke clipboard',
  'Copy value': 'Salin nilai',
  Core: 'Inti',
  Correlator: 'Korelator',
  'Costa Rica': 'Kosta Rika',
  'Could not determine the target’s status':
    'Tidak dapat menentukan status target',
  'Could not find a person with exact input':
    'Tidak dapat menemukan orang dengan masukan yang tepat',
  'Could not gather data for this report':
    'Tidak dapat mengumpulkan data untuk laporan ini',
  'Could not gather data for this target':
    'Tidak dapat mengumpulkan data untuk target ini',
  'Could not reset password': 'Tidak dapat mengatur ulang kata sandi',
  'Could not save!': 'Tidak dapat menyelamatkan!',
  'Could not update target profile, user id already exists!':
    'Tidak dapat memperbarui profil target, id pengguna sudah ada!',
  'Could not verify that you are human':
    'Tidak dapat memverifikasi bahwa Anda adalah manusia',
  'Could not verify that you are human, please contact your administrator':
    'Tidak dapat memverifikasi bahwa Anda adalah manusia, silakan hubungi administrator Anda',
  Count: 'Menghitung',
  'Count analysis': 'Analisis hitungan',
  Countries: 'Negara',
  Country: 'Negara',
  'Country N/A': 'Negara N/A',
  'Country and region': 'Negara dan wilayah',
  Courses: 'Kursus',
  'Covid vaccination status': 'status vaksinasi covid',
  'Covid vaccine': 'Vaksin covid',
  Create: 'Membuat',
  'Create Place': 'Membuat Tempat',
  'Create Target': 'Buat Target',
  'Create User': 'Membuat User',
  'Create a Geofencing Task (1 week / 15 minutes)':
    'Buat Tugas Geofencing (1 minggu / 15 menit)',
  'Create a Geofencing Task (6 hours / 15 minutes)':
    'Buat Tugas Geofencing (6 jam / 15 menit)',
  'Create a Schedule Task (1 week / 15 minutes)':
    'Buat Jadwal Tugas (1 minggu / 15 menit)',
  'Create a Schedule Task (6 hours / 15 minutes)':
    'Buat Jadwal Tugas (6 jam / 15 menit)',
  'Create a case': 'Buat kasus',
  "Create a case with the target's alias": 'Buat kasus dengan alias target',
  'Create a new target': 'Buat target baru',
  'Create a new target and Enhance profile':
    'Buat target baru dan Tingkatkan profil',
  'Create a new target and Request call log':
    'Buat target baru dan Minta log panggilan',
  'Create a new target and find possible associates, visited places, connections on social media, groups and more.':
    'Buat target baru dan temukan kemungkinan rekanan, tempat yang dikunjungi, koneksi di media sosial, grup, dan lainnya.',
  'Create a target': 'Buat target',
  'Create a temporary user': 'Membuat pengguna sementara',
  'Create case': 'Buat kasus',
  'Create case investigation': 'Buat investigasi kasus',
  'Create group': 'Buat grup',
  'Create new case': 'Buat kasus baru',
  'Create new case or search for existing one':
    'Buat kasus baru atau cari yang sudah ada',
  'Create new keywords list': 'Buat daftar kata kunci baru',
  'Create new list': 'Buat daftar baru',
  'Create new target': 'Buat target baru',
  'Create new target or search for existing one':
    'Buat target baru atau cari target yang sudah ada',
  'Create or add to target': 'Buat atau tambahkan ke target',
  'Create post': 'Buat postingan',
  'Create post was successfully created': 'Create post berhasil dibuat',
  'Create target': 'Buat target',
  Created: 'Dibuat',
  'Created On': 'Dibuat Pada',
  'Created at': 'Dibuat pada',
  Creating: 'Membuat',
  'Credit cards': '',
  'Credit change for request_cdr for IMEI':
    'Perubahan kredit untuk request_cdr untuk IMEI',
  'Credit usage': 'Penggunaan kredit',
  Credits: 'Kredit',
  'Credits Usage Statistics': 'Statistik Penggunaan Kredit',
  'Credits are expired': 'Kredit sudah habis masa berlakunya',
  'Credits will be deducted only if accurate information is found.':
    'Kredit akan dipotong hanya jika informasi yang akurat ditemukan.',
  Critical: 'Kritis',
  Croatia: 'Kroasia',
  'Cross Intel Search': 'Pencarian Lintas Intel',
  Crypto: '',
  Cuba: 'Kuba',
  Cumulative: 'Kumulatif',
  Current: 'Saat ini',
  'Current And Previous Workplaces (Target)':
    'Tempat kerja saat ini dan sebelumnya (target)',
  'Current Call': 'Panggilan Saat Ini',
  'Current Password': 'Kata Sandi Saat Ini',
  'Current call': 'Panggilan saat ini',
  'Current city': 'Kota saat ini',
  'Current password': 'Kata sandi saat ini',
  'Current target has no MSISDN attached!':
    'Target saat ini tidak memiliki MSISDN yang terpasang!',
  'Custom report': 'Laporan khusus',
  Cv: 'CV',
  Cve: '',
  Cyprus: 'Siprus',
  Czechia: 'Czechia',
  "Côte d'Ivoire": '',
  'Côte dIvoire': 'Côte Divoire',
  DATA: 'DATA',
  DEACTIVATED: 'DINONAKTIFKAN',
  DEBUG: 'DEBUG',
  'Daily activity': 'aktivitas sehari-hari',
  'Daily credits assigned': 'Kredit harian yang ditetapkan',
  'Daily credits available': 'Kredit harian tersedia',
  'Daily quota': 'Kuota harian',
  'Daily quota assigned': 'Kuota harian yang ditetapkan',
  Dark: '',
  'Dark Web': 'Dark Web',
  Darkweb: 'Darkweb',
  Dashboard: 'Dasbor',
  Data: 'Data',
  'Data collected at': 'Data collected at',
  'Data saved successfully!': 'Data berhasil disimpan!',
  'Data transferred': 'Data ditransfer',
  Date: 'Tanggal',
  'Date & time': 'Tanggal Waktu',
  'Date Of Birth': 'Tanggal lahir',
  'Date Of Query': 'Tanggal Kueri',
  'Date Period': '',
  'Date added': 'Tanggal Ditambahkan',
  'Date and time': 'Tanggal dan waktu',
  'Date created': 'Tanggal Diciptakan',
  'Date of birth': 'Tanggal lahir',
  'Date of creation': 'Tanggal pembuatan',
  'Date of query': 'Tanggal kueri',
  Dates: 'Tanggal',
  'Dates of birth': 'Tanggal lahir',
  'Day of month': 'Hari dalam sebulan',
  'Day of week': 'Hari dalam seminggu',
  'De Anon': 'De Anon',
  Deactivate: 'Nonaktifkan',
  'Deactivate 2-step verification': 'Nonaktifkan verifikasi 2 langkah',
  'Deactivate OTP': 'Nonaktifkan OTP',
  Dear: 'Sayang',
  December: 'Desember',
  Deep: 'Dalam',
  'Deep Webint': 'Deep Webint',
  'Deep Webint query created successfully!':
    'Kueri Webint mendalam berhasil dibuat!',
  'Deep search will add second degree relations, likers, commenters, reactors and more':
    'Pencarian mendalam akan menambah relasi derajat kedua, penyuka, komentator, reaktor, dan banyak lagi',
  'Define quota': 'Tentukan kuota',
  Degrees: 'Derajat / Gelar',
  Delete: 'Hapus',
  'Delete Group': 'Hapus Grup',
  'Delete Target': 'Hapus Target',
  'Delete case': 'Hapus kasus',
  'Delete file': 'Hapus berkas',
  'Delete photo': 'Menghapus foto',
  'Delete target': 'Hapus target',
  'Deleted!': 'Dihapus!',
  'Democratic Republic of the Congo': 'Republik Demokrasi Kongo',
  Denmark: 'Denmark',
  Descending: 'Berkurang',
  Description: 'Keterangan',
  Destination: 'Tujuan',
  Details: 'Detail',
  'Details for': 'Detail',
  'Details panel': 'Panel rincian',
  Device: 'Perangkat',
  'Device coordinates': '',
  'Device ID': 'ID Perangkat',
  'Device usage': 'Penggunaan perangkat',
  Devices: 'Perangkat',
  Direction: 'Arah',
  Disable: 'Nonaktifkan',
  'Disable drawing': 'Nonaktifkan menggambar',
  'Disable email notifications': 'Nonaktifkan notifikasi email',
  'Disable sms notifications': 'Nonaktifkan notifikasi sms',
  'Disable target heatmap': 'Nonaktifkan heatmap target',
  'Disable target history timeline': 'Nonaktifkan garis waktu riwayat target',
  'Disable traffic layer': 'Nonaktifkan lapisan lalu lintas',
  Disabled: 'Dinonaktifkan',
  'Disabled Feature': 'Fitur yang Dinonaktifkan',
  Discard: 'Buang',
  Disclaimer: 'Pernyataan resmi Tidak Bertanggung Jawab atas sesuatu',
  'Disclaimer content':
    'Konten Pernyataan resmi Tidak Bertanggung Jawab atas sesuatu',
  Discover: 'Menemukan',
  'Discover more seeds': 'Temukan lebih banyak sumber',
  'Discover persons': 'Temukan orang',
  'Discovered persons': 'orang yang ditemukan',
  Discovery: 'Penemuan',
  Discovery2: 'Penemuan2',
  'Discovery will be updated on 12:00':
    'Discovery akan diperbarui pada pukul 12:00',
  'Dislike posts': 'Postingan tidak disukai',
  Distance: 'Jarak',
  'Distance from target': 'Jarak dari target',
  'Distribution of keywords on platforms':
    'Distribusi kata kunci pada platform',
  'Distribution of topics': 'Distribusi topik',
  Djibouti: 'Djibouti',
  'Do you want to extract details about the Call Peer':
    'Apakah Anda ingin mengekstrak detail tentang Call Peer',
  'Do you want to extract the Peer Caller ID?':
    'Apakah Anda ingin mengekstrak  Peer Caller ID?',
  'Do you want to extract the Peer Caller ID? This action will deduct 4 credits.':
    'Apakah Anda ingin mengekstrak  Peer Caller ID? Tindakan ini akan mengurangi 4 Kredit.',
  'Do you want to get cell level location?':
    'Apakah Anda ingin mendapatkan lokasi tingkat sel?',
  'Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.':
    'Apakah Anda ingin melakukan kueri Lokasi Aktif? Tindakan ini mungkin meninggalkan jejak anonim di ponsel target.',
  'Do you wish to perform an intelligence query or view the existing intel?':
    'Apakah Anda ingin melakukan kueri intelijen atau melihat intel yang ada?',
  'Do you wish to perform an intelligence query?':
    'Apakah Anda ingin melakukan kueri intelijen?',
  'Do you wish to reload the chart? Any data created from analysing nodes will be removed':
    'Apakah Anda ingin memuat ulang bagan? Setiap data yang dibuat dari node analisis akan dihapus',
  Domain: 'Domain',
  Dominica: 'Dominika',
  'Dominican Republic': 'Republik Dominika',
  Done: 'Selesai',
  'Double click to expand/collapse node':
    'Klik dua kali untuk memperluas/menciutkan simpul',
  Download: 'Unduh',
  'Download CSV': 'Unduh CSV',
  'Download file': 'Unduh berkas',
  'Download report': 'Unduh laporan',
  Downloading: 'Mengunduh',
  'Drag and Drop file here or click to browse':
    'Seret dan Jatuhkan file di sini atau klik untuk menelusuri',
  'Drag and drop to add an MSISDN to the investigation':
    'Seret dan lepas untuk menambahkan MSISDN ke penyelidikan',
  'Drag and drop to load a case to the investigation':
    'Seret dan lepas untuk memuat kasus ke penyelidikan',
  'Drag and drop to load a target to the investigation':
    'Seret dan lepas untuk memuat target ke investigasi',
  'Drag and drop to upload file for Text analysis':
    'Seret dan lepas untuk mengunggah file untuk analisis Teks',
  'Drag file here': 'Seret berkas ke sini',
  'Drag image here': 'Seret gambar ke sini',
  'Drag media here': 'Seret media ke sini',
  'Drag the map to precise your location':
    'Seret peta untuk menentukan lokasi Anda secara tepat',
  'Drag to link with another node': 'Seret untuk menautkan dengan simpul lain',
  Draw: 'Menggambar',
  'Draw area': 'Gambar area',
  'Draw circle': 'Menggambar lingkaran',
  'Draw marker': 'Menggambar penanda',
  'Drugs and illegal substances': 'Narkoba dan zat ilegal',
  Duplicated: 'Digandakan',
  Duration: 'Durasi',
  'Duration (s)': 'Durasi',
  'During the importing process, please do not log out, close this tab or transition to another website':
    'Selama proses impor, mohon jangan keluar dari sistem, menutup tab ini, atau beralih ke situs web lain.',
  "E.g., 'I work with drug cases in Panama' or 'I work with kidnapping investigations in Mexico'.":
    "Misalnya, 'Saya bekerja dengan kasus narkoba di Panama' atau 'Saya bekerja dengan penyelidikan penculikan di Meksiko'.",
  ERROR: 'KESALAHAN',
  'Earliest activity': 'Aktivitas paling awal',
  'East Timor': 'Timor Timur',
  Ecuador: 'Ekuador',
  Edit: 'Edit',
  'Edit Group': 'Edit Grup',
  'Edit Target': 'Edit Target',
  'Edit a target': 'Sunting target',
  'Edit family member': 'Edit anggota keluarga',
  'Edit name': 'Ubah nama',
  'Edit places': 'Edit tempat',
  Education: '',
  'Education (Target)': 'Pendidikan (Target)',
  Educations: 'Pendidikan',
  Egypt: 'Mesir',
  'Eigen centrality': 'Sentralitas Eigen',
  'El Salvador': 'El Salvador',
  Email: 'Email',
  'Email address': 'Alamat email',
  'Email sent.': 'Email terkirim.',
  'Email to support': 'Email untuk mendukung',
  'Email(s)': 'Email',
  Emails: 'Email',
  Emergency: 'Keadaan Darurat',
  'Employment and education': 'Pekerjaan dan pendidikan',
  'Enable Call log analysis': 'Aktifkan analisis log panggilan',
  'Enable OTP': 'Aktifkan OTP',
  'Enable email notifications': 'Aktifkan notifikasi email',
  'Enable geofencing': 'Aktifkan geofencing',
  'Enable layer': 'Aktifkan lapisan',
  'Enable sms notifications': 'Aktifkan notifikasi sms',
  'Enable support requests': 'Aktifkan permintaan dukungan',
  'Enable target heatmap': 'Aktifkan heatmap target',
  'Enable target history timeline': 'Aktifkan garis waktu riwayat target',
  'Enable traffic layer': 'Aktifkan lapisan lalu lintas',
  'End Date': 'Tanggal Berakhir',
  'End date': '',
  Ending: '',
  English: 'Bahasa inggris',
  'Enhance Profile': 'Tingkatkan Profil',
  'Enhance Profile query created successfully!':
    'Tingkatkan kueri Profil berhasil dibuat!',
  'Enhance profile': 'Tingkatkan profil',
  'Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights':
    'Tingkatkan profil untuk membuat target secara otomatis, temukan lebih banyak informasi intelijen, dan dapatkan wawasan penting',
  'Enhance profile in order to discover more intelligence information and retrieve important insights':
    'Tingkatkan profil untuk menemukan lebih banyak informasi intelijen dan mendapatkan kembali wawasan penting',
  'Enrich Profile': 'Memperkaya profil',
  'Enrich profile': 'Pastikan file dalam format Zip dan berisi file JSON',
  'Enrich target with Somedus data': 'Perkaya target dengan Somedus data',
  'Enter Ad ID(s)': 'Masukkan ID Ad',
  'Enter IMEI': 'Masukkan IMEI',
  'Enter MSISDN': 'Masukkan MSISDN',
  'Enter OTP': 'Masukkan OTP',
  'Enter Password': 'Masukkan kata kunci',
  'Enter URL': 'Masukkan URL',
  'Enter a address': 'Masukkan alamat',
  'Enter a location': 'Masukkan lokasi',
  'Enter a MSISDN or IMSI': '',
  'Enter a number': 'Masukkan nomor',
  'Enter a short description of your investigation':
    'Masukkan deskripsi singkat tentang penyelidikan Anda',
  'Enter a valid IMEI': 'Masukkan IMEI yang valid',
  'Enter a valid IMSI': 'Masukkan IMSI yang valid',
  'Enter a valid TMSI': 'Masukkan TMSI yang valid',
  'Enter a valid number': 'Masukkan nomor yang valid',
  'Enter a valid phone': 'Masukkan telepon yang valid',
  'Enter a valid username':
    'Masukan Nama Pengguna yang Valid / Masukan Username yang Valid',
  'Enter a valid value': 'Masukkan nilai yang valid',
  'Enter a value': 'Masukkan nilai',
  'Enter alias': 'Masukkan alias',
  'Enter coordinates: lat, lon': 'Masukkan koordinat: lat, lon',
  'Enter emails': 'Masukkan email',
  'Enter investigation name': 'Masukkan nama investigasi',
  'Enter notes': 'Masukkan catatan',
  'Enter password': 'Masukkan kata sandi',
  'Enter phone': 'Masuk ke telepon',
  'Enter phone numbers': 'Masukkan nomor telepon',
  'Enter report title': 'Masukkan judul laporan',
  'Enter the OTP code from your device':
    'Masukkan kode OTP dari perangkat Anda',
  'Enter the code received via SMS': 'Masukkan kode yang diterima melalui SMS',
  'Enter the code received via email':
    'Masukkan kode yang diterima melalui email',
  'Enter the complete phone number including country code. Example: #{example}':
    'Masukkan nomor telepon lengkap termasuk kode negara. Contoh: #{example}',
  'Enter the keywords': 'Masukkan kata kunci ',
  'Enter the text': 'Masukkan teks',
  'Enter valid coordinates': 'Masukkan koordinat yang valid',
  'Enter value': 'Masukkan nilai',
  Entities: 'entitas',
  Entity: 'Kesatuan',
  'Entity Type': 'Jenis entitas',
  'Equatorial Guinea': 'Guinea ekuator',
  Equipment: '',
  Eritrea: 'Eritrea',
  'Error occured while attempting to reserve':
    'Terjadi kesalahan saat mencoba memesan',
  'Error while uploading file': 'Terjadi kesalahan saat mengunggah file',
  Errors: 'Kesalahan',
  Estonia: 'Estonia',
  Ethiopia: 'Etiopia',
  Event: 'Event',
  'Event viewer': 'Tampilan Event',
  'Events Type': 'Jenis Event',
  'Events by type': 'Event menurut jenis',
  'Events found': 'Event ditemukan',
  'Every MSISDN should start with + .':
    'Setiap MSISDN harus dimulai dengan + .',
  'Existing target': 'Target yang ada',
  Exit: '',
  'Exit full screen': 'Keluar dari layar penuh',
  'Exit without log in': '',
  Expand: 'Memperluas',
  'Expiration date': 'Tanggal habis tempo',
  Expired: 'Kedaluwarsa',
  'Expired Avatar': 'Avatar Kedaluwarsa',
  'Expired cases will be renewed': 'Kasus yang kedaluwarsa akan diperpanjang',
  'Expired targets will be renewed and added to case':
    'Target yang kedaluwarsa akan diperbarui dan ditambahkan ke case',
  'Expires On': 'Kadaluarsa pada',
  'Expires on': 'Kadaluarsa pada',
  'Expiring date': 'Tanggal kedaluwarsa',
  Export: 'Ekspor',
  'Export CSV': 'Ekspor CSV',
  'Export PDF': 'Ekspor PDF',
  'Export as CSV': 'Ekspor sebagai CSV',
  'Export call log': 'Ekspor log panggilan',
  'Export call log report': 'Ekspor laporan log panggilan',
  'Export current investigation view': 'Ekspor tampilan investigasi saat ini',
  'Export detailed geolocation report': 'Ekspor laporan geolokasi terperinci',
  'Export graph': 'Grafik ekspor',
  'Export selected': 'Ekspor dipilih',
  'Export table': 'Ekspor Tabel',
  'Export to CSV': 'Ekspor ke CSV',
  'Export to pdf': 'Ekspor ke pdf',
  Exporting: 'Mengekspor',
  'Exporting CSV': 'Mengekspor CSV',
  'Exporting call log': 'Mengekspor log panggilan',
  'Exporting file': 'Mengekspor berkas',
  'Exporting table in CSV': 'Mengekspor tabel dalam CSV',
  'Extracted entity from file': 'Entitas yang diekstrak dari file',
  'Extracted event from file.': 'Event yang diekstrak dari file.',
  'ExtractusD Response': 'Tanggapan ExtractusD',
  'ExtractusD request': 'Permintaan EkstrakusD',
  'ExtractusD request by user': 'Permintaan ExtractusD oleh pengguna',
  'ExtractusD requested by user': 'ExtractusD diminta oleh pengguna',
  'Eyes Color': 'Warna mata',
  FACES: 'WAJAH',
  FEED: 'MEMBERI MAKAN',
  'Face & name': 'wajah & nama',
  Facebook: 'Facebook',
  'Facebook Friend': 'Teman Facebook',
  'Facebook Group': 'Grup Facebook',
  'Facebook Significant Other': 'Orang Penting Lainnya di Facebook',
  'Facebook account confirmed. Uploading photos into account':
    'Akun Facebook dikonfirmasi. Mengunggah foto ke akun',
  'Facebook family': 'Keluarga Facebook',
  'Facebook friends': 'Teman Facebook',
  'Facebook group member': 'Anggota grup Facebook',
  'Facebook groups': 'Grup Facebook',
  'Facebook id': 'id facebook',
  'Facial Hair': 'Rambut Wajah',
  Failed: 'Gagal',
  'Failed request': '',
  'Failed to find intel suggestions!': 'Gagal menemukan saran intel!',
  'Failed to retrieve': 'Gagal mengambil',
  'Failed!': 'Gagal!',
  'Falkland Islands': 'Kepulauan Falkland',
  Family: 'Keluarga',
  'Family Members': 'Anggota Keluarga',
  'Family member on Facebook': 'Anggota keluarga di Facebook',
  'Family relations': 'Hubungan keluarga',
  'Fan of': 'Penggemar',
  Fans: 'Fans',
  'Faroe Islands': 'Kepulauan Faroe',
  'Fathers name': 'Nama ayah',
  'Favorite count': 'Jumlah favorit',
  'Favorite results': 'Hasil favorit',
  Feature: 'Fitur',
  Features: 'Fitur',
  February: 'Februari',
  'Federated States of Micronesia': 'Status Mikronesia Federasi',
  Feed: 'Memberi makan',
  Feedback: 'Umpan balik',
  'Feedback is required': 'Umpan balik diperlukan',
  Female: 'Perempuan',
  'Fenced Area': 'Area terbatas',
  'Fetch call logs': 'Ambil log panggilan',
  'Fetch instant messaging profiles': 'Ambil profil pesan instan',
  'Fetch social profiles': 'Ambil profil sosial',
  'Fetching data': 'Mengambil data',
  'Fetching facebook friends': 'Mengambil teman facebook',
  'Field is required': 'Bidang diperlukan',
  Fiji: 'Fiji',
  File: 'Berkas',
  'File error': 'Kesalahan berkas',
  'File name': 'Nama berkas',
  'File name must match #{type} number':
    'Nama file harus cocok dengan nomor #{type}',
  'File size': 'Ukuran file',
  'File upload failed. Please make sure that your file is in Zip format, and contains a properly structured JSON file':
    'File upload failed. Please make sure that your file is in Zip format, and contains a properly structured JSON file',
  'File uploaded successfully!': 'Berkas berhasil diunggah!',
  'Filling OTP': 'Pengisian OTP',
  'Filling personal info': 'Mengisi informasi pribadi',
  'Filling photos': 'Mengisi foto',
  Filter: 'Filter / Saring',
  'Filter by device': 'Filter berdasarkan perangkat',
  'Filter by source type': 'Filter menurut jenis sumber',
  'Filter panel': 'Panel filter',
  'Filter target': 'Filter target',
  'Filter targets': 'Filter target',
  Filters: 'Filters',
  'Financial crime': 'Kejahatan keuangan',
  'Find commons': 'Temukan milik bersama',
  'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.':
    'Temukan kemungkinan rekanan, tempat umum yang dikunjungi, koneksi di media sosial, grup, dan lainnya. Kredit akan dipotong hanya jika informasi yang akurat ditemukan.',
  'Find possible associates, visited places, connections on social media, groups and more.':
    'Temukan kemungkinan rekanan, tempat yang dikunjungi, koneksi di media sosial, grup, dan lainnya.',
  'Find profile': '',
  'Find profiles': 'Temukan profil',
  'Find profiles & locate phone': 'Temukan profil & temukan telepon',
  'Find profiles by': 'Temukan profil menurut',
  'Find profiles by face and name': 'Temukan profil berdasarkan wajah dan nama',
  'Finish adding targets': 'Selesaikan penambahan target',
  'Finish editing': 'Selesai mengedit',
  Finland: 'Finlandia',
  'First message': 'Pesan pertama',
  'First month is free': 'Bulan pertama gratis',
  'First page': 'Halaman pertama',
  Fixed: 'Diperbaiki',
  'Fixed credits assigned': 'Kredit tetap yang ditetapkan',
  'Fixed credits available': 'Kredit tetap tersedia',
  'Fixed quota': 'Kuota tetap',
  Fleet: 'Armada',
  Follow: 'Mengikuti',
  'Follow is already disabled.': 'Ikuti sudah dinonaktifkan.',
  'Follow is already enabled.': 'Ikuti sudah diaktifkan.',
  'Follow started for': 'mulai ikuti untuk',
  'Follow stopped for': 'stop ikuti untuk',
  'Follow with Geofence': 'Ikuti dengan Geofence',
  Follower: 'Pengikut',
  'Follower on Instagram': 'Pengikut di Instagram',
  'Follower on Twitter': 'Pengikut di Twitter',
  Followers: 'Pengikut',
  Following: 'Mengikuti',
  'Following MSISDN - Platform combination is already selected':
    'Mengikuti kombinasi MSISDN-Platform sudah dipilih',
  'Following on Instagram': 'Mengikuti di Instagram',
  'Following on Twitter': 'Mengikuti di Twitter',
  'Following pages': 'Halaman berikut',
  Followings: 'Mengikuti',
  'For authorized use only': 'Hanya untuk penggunaan resmi',
  'For example': 'Contohnya',
  'For generating OTP codes, please download the Authenticator App':
    'Untuk membuat kode OTP, silakan unduh Aplikasi Autentikator',
  Foreign: 'Luar negeri',
  Found: 'Ditemukan',
  'Found for': 'Ditemukan untuk',
  France: 'Perancis',
  Fraud: 'Penipuan',
  'Free text': 'Teks bebas',
  French: 'Perancis',
  Frequency: 'Frekuensi',
  'Frequently communicated with #{receiverMsisdn}':
    'Sering dikomunikasikan dengan #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn}':
    'Sering melakukan komunikasi berturut-turut dengan #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn} within 1-hour time intervals,':
    'Sering melakukan komunikasi berturut-turut dengan #{receiverMsisdn} dalam interval waktu 1 jam,',
  Friday: '',
  Friend: 'Teman',
  'Friend relations': 'Hubungan pertemanan',
  Friends: 'Teman-teman',
  From: 'Dari',
  'From - To': 'Dari-Ke',
  'From Date': 'Dari tanggal',
  'Full Name': 'Nama Lengkap',
  'Full Post': 'Postingan Lengkap',
  'Full name': 'Nama lengkap',
  'Full report': 'Laporan lengkap',
  'Full screen': 'Layar penuh',
  'GEO query': 'kueri GEO',
  GO: 'PERGI',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  'Gather more intel from MSISDN, email & social profiles':
    'Kumpulkan lebih banyak informasi dari MSISDN, email & profil sosial',
  'Gathering activity feed details': 'Mengumpulkan detail umpan aktivitas',
  'Gathering activity patterns': 'Mengumpulkan pola aktivitas',
  'Gathering call analysis': 'Mengumpulkan analisis panggilan',
  'Gathering call log common associates statistics':
    'Mengumpulkan log panggilan statistik rekan umum',
  'Gathering call log entry': 'Mengumpulkan entri log panggilan',
  'Gathering call log statistics': 'Mengumpulkan statistik log panggilan',
  'Gathering call log top associates statistics':
    'Mengumpulkan log panggilan statistik rekan teratas',
  'Gathering call logs report for': 'Mengumpulkan laporan log panggilan untuk',
  'Gathering common locations': 'Pengumpulan lokasi umum',
  'Gathering count analysis': 'Mengumpulkan analisis hitungan',
  'Gathering data for target details': 'Mengumpulkan data untuk detail target',
  'Gathering details for targets': 'Mengumpulkan detail untuk target',
  'Gathering family relation': 'Mengumpulkan relasi keluarga',
  'Gathering images': 'Mengumpulkan gambar',
  'Gathering locations': 'Lokasi berkumpul',
  'Gathering social network details': 'Mengumpulkan detail jejaring sosial',
  'Gathering social network top associates':
    'Mengumpulkan jaringan sosial top associates',
  'Gathering social word details': 'Mengumpulkan detail kata sosial',
  'Gathering target summary report for':
    'Mengumpulkan laporan ringkasan target untuk',
  'Gathering top locations': 'Mengumpulkan lokasi teratas',
  Gender: 'Jenis kelamin',
  Generate: 'Menghasilkan',
  'Generate pdf': 'Hasilkan pdf',
  'Generating call logs report data': '',
  'Generating map': 'Membuat peta',
  'Generating virtual ID': 'Membuat ID virtual',
  'Geo AoI': 'Geo AoI',
  'Geo Country': 'Geo Negara',
  'Geo Follow': 'Ikuti Geografis',
  'Geo Political Entities': 'Entitas Geo Politik',
  'Geo Query': 'Kueri Geografis',
  Geofencing: 'Geofencing / Pembatasan wilayah',
  'Geofencing canceled!': 'Geofencing dibatalkan!',
  'Geoloc Knowledge Base': 'Basis Pengetahuan Geoloc',
  Geolocate: 'Geolokasi',
  'Geolocate phone': 'Geolokasi telepon',
  Geolocation: 'Geolokasi',
  'Geolocation details': 'Detail geolokasi',
  'Geolocation query successfully submitted':
    'Kueri geolokasi berhasil dikirimkan',
  'Geolocation scheduler will start after radical speech detection!':
    'Penjadwal geolokasi akan dimulai setelah deteksi ucapan radikal!',
  Geolocations: 'Geolokasi',
  'Geographic resolution': '',
  Georgia: 'Georgia',
  Germany: 'Jerman',
  'Get Cell Level Location': 'Dapatkan Lokasi Tingkat Sel',
  'Get Facebook Posts': 'Dapatkan Postingan Facebook',
  'Get Facebook Relations': 'Dapatkan Hubungan Facebook',
  'Get Photo Info': 'Dapatkan Info Foto',
  'Get activity alerts': 'Get activity alerts',
  'Get alerts for new posts by the target on social media': '',
  'Get current location of this MSISDN': 'Dapatkan lokasi MSISDN ini saat ini',
  'Get historical data': 'Dapatkan data historis',
  'Get historical locations': 'Dapatkan lokasi bersejarah',
  'Get information': 'Dapatkan informasi',
  'Get information & geolocate': 'Dapatkan informasi & geolokasi',
  'Get sim': 'Dapatkan sim',
  'Get the CallerID': 'Dapatkan CallerID  ',
  'Get the CallerID (4 credits)': 'Dapatkan CallerID (4 kredit)',
  'Get the Neighbour Cells': 'Dapatkan Sel Lingkungan  ',
  'Get the Neighbour Cells (1 credit)': 'Dapatkan Sel Lingkungan (1 Kredit)',
  'Getting OTP': 'Mendapatkan OTP',
  'Getting cookies': 'Mendapatkan cookie',
  Ghana: 'Ghana',
  'GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017':
    'GiO - Layanan Geolokasi-Untuk penggunaan eksklusif oleh lembaga penegak hukum pemerintah: Pemerintah Negara Bagian Nuevo Leon-Didukung oleh EUC-2017',
  Gibraltar: 'Gibraltar',
  Go: 'Pergi',
  'Go to call logs page': 'Buka halaman log panggilan',
  'Go to target page': 'Pergi ke halaman target',
  'Got it': 'Mengerti',
  Graph: 'Grafik',
  'Graph settings': 'Pengaturan grafik',
  Greece: 'Yunani',
  Greenland: 'Tanah penggembalaan',
  Grenada: 'Grenada',
  Group: 'Grup',
  'Group By': 'Kelompokkan Oleh',
  'Group ID': 'ID Grup',
  'Group Member on Facebook': 'Anggota Grup di Facebook',
  'Group Name': 'Nama Grup',
  'Group created successfully!': 'Grup berhasil dibuat!',
  'Group details': 'Detail grup',
  'Group edited successfully!': 'Grup berhasil diedit!',
  'Group has been successfully deleted': 'Grup telah berhasil dihapus',
  'Group has not been created': 'Grup belum dibuat',
  'Group has not been deleted': 'Grup belum dihapus',
  'Group has not been edited': 'Grup belum diedit',
  'Group members': 'Anggota grup',
  'Group messages': 'Pesan grup',
  'Group results': 'Hasil grup',
  Groups: 'banyak Grup',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bissau',
  Guyana: 'Guyana',
  'Hair Color': 'Warna rambut',
  Haiti: 'Haiti',
  'Has Expired Avatar': "Avatar' Telah Kedaluwarsa ",
  Hashtag: 'Tagar',
  'Hashtag added successfully!': 'Hashtag berhasil ditambahkan!',
  'Hashtag has not been added': 'Hashtag belum ditambahkan',
  Hashtags: 'Tagar',
  Hearts: 'Hearts',
  Heatmap: 'Heatmap',
  Height: 'Tinggi',
  'Hide archived': 'Sembunyikan arsip',
  'Hide descriptions': 'Sembunyikan deskripsi',
  'Hide nearby Telegram users': 'Sembunyikan pengguna Telegram terdekat',
  'Hide options': 'Sembunyikan opsi',
  'Hide query history': 'Sembunyikan riwayat kueri',
  'Hide statistics': 'Sembunyikan statistik',
  Hierarchy: 'Hirarki',
  'High activity': 'Aktivitas tinggi',
  'Highlight nodes based on number and type of direct connections':
    'Sorot node berdasarkan jumlah dan jenis koneksi langsung',
  'Highlight nodes that act as "bridges" between the connected network':
    'Sorot node yang bertindak sebagai "jembatan" antara jaringan yang terhubung',
  History: 'Riwayat / Histori',
  Home: 'Rumah',
  'Home Location': 'Lokasi Rumah',
  'Home last sale date': 'Tanggal terakhir penjualan rumah',
  Hometown: '',
  Hometownn: 'kampung halaman',
  Homicide: 'Pembunuhan',
  Honduras: 'Honduras',
  Hour: 'Jam',
  'Hour of day': 'Jam dalam sehari',
  'House number': 'Nomor rumah',
  Hungary: 'Hungaria',
  ID: 'ID',
  IFE: 'IFE',
  IMEI: 'IMEI',
  'IMEI(s)': 'IMEI(s)',
  IMSI: 'IMSI',
  'IMSI(s)': 'IMSI',
  IMSIs: 'IMSIs',
  INFO: 'INFO',
  IP: 'IP',
  'IP found': 'IP ditemukan',
  'IP Location': 'Lokasi IP',
  'IP address': 'alamat IP',
  'IP addresses': 'Alamat IP',
  'IP/Carrier': 'IP / Operator',
  'Find the area of the IP address': '',
  'Get location of the IP': '',
  'This method requires calling the device via instant messaging app, and as a result the person might become suspicious.':
    '',
  'Are you sure you want to continue?': '',
  Request: '',
  IPs: 'IPs',
  Iceland: 'Islandia',
  Id: 'Id',
  'Identification card number': 'nomor kartu identitas',
  'Identifying top profiles': 'Mengidentifikasi profil teratas',
  Idle: 'Diam',
  'If the status is N/A, yet you will have the option to enable the BOT to continue attempts.':
    'Jika statusnya N / A, namun Anda akan memiliki opsi untuk mengaktifkan BOT untuk melanjutkan upaya.',
  'If you dont associated the correct MSISDN with platform account, the request could take up to 144 hours.':
    'Jika Anda tidak mengaitkan MSISDN yang benar dengan akun platform, permintaan dapat memakan waktu hingga 144 jam.',
  'If you edit or delete the MSISDN the Call Log for this target will be removed':
    'Jika Anda mengedit atau menghapus MSISDN, Log Panggilan untuk target ini akan dihapus',
  'If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.':
    'Jika perangkat Anda hilang, setel ulang Verifikasi 2 Langkah dengan menekan tombol setel ulang. Email akan dikirim untuk memandu Anda menambahkan perangkat baru.',
  Image: 'Gambar',
  'Image Analysis': 'Analisis Gambar',
  'Image attached': 'Gambar terlampir',
  Images: '',
  Impersonation: 'Peniruan Identitas',
  Import: 'Impor',
  'Import Historical Locations': 'Impor Riwayat Lokasi',
  'Import Virtual ID by credentials': '',
  'Import files': 'Impor file',
  Imported: 'Impor',
  'Imported date': 'Tanggal impor',
  'Imported successfully': 'Berhasil diimpor',
  Importing: '',
  'Importing data': 'Mengimpor data',
  'Importing historical data': 'Mengimpor data historis',
  'Imsi query': 'Kueri IMSI',
  'In SMS': 'SMS masuk',
  'In call': 'Panggilan masuk',
  'In order to strengthen account security, Two Factor Authentication (2FA) is mandatory for all users.':
    'Untuk memperkuat keamanan akun, Otentikasi Dua Faktor (2FA) wajib bagi semua pengguna.',
  'In progress': 'Sedang dalam proses',
  Incoming: 'Masuk',
  'Incoming SMS': 'SMS masuk',
  'Incoming and Outgoing': 'Masuk dan Keluar',
  'Incoming call': 'Panggilan masuk',
  'Incoming sms': 'sms masuk',
  India: 'India',
  Individual: 'Individu',
  Indonesia: 'Indonesia',
  Indonesian: 'Bahasa Indonesia',
  Industries: 'Industri',
  Industry: 'Industri',
  'Inform Security Personel': 'Informasikan Personel Keamanan',
  'Information available': '',
  'Infrastructure and buildings': 'Infrastruktur dan bangunan',
  'Initialising search for': 'Menginisialisasi pencarian untuk',
  'Insert IMSI': 'Masukkan IMSI',
  'Insert MSISDN': 'Masukkan MSISDN',
  'Insert a single or multiple MSISDNs or IMSIs separated by comma':
    'Sisipkan satu atau beberapa MSISDNS atau IMSI yang dipisahkan dengan koma',
  'Insert phones': 'Masukkan telepon',
  Inserted: 'Dimasukkan',
  'Inside of area': 'Di dalam area',
  'Inside the circle': 'Di dalam lingkaran',
  'Insights about most common and top associates':
    'Wawasan tentang rekanan paling umum dan teratas',
  Instagram: 'Instagram',
  'Instagram handle': 'Penanganan Instagram',
  'Instant messaging': 'Pesan singkat',
  'Related instant messaging apps': '',
  Institution: 'Lembaga',
  'Intel Query': 'Kueri Intel',
  'Intel Search Results': 'Hasil Pencarian Intel',
  'Intel search': 'pencarian intel',
  'Intellectus Knowledge Base': 'Basis Pengetahuan Intellectus',
  Intelligence: 'Intelijen',
  'Intelligence Query': 'Permintaan Intelijen',
  'Intelligence is not available yet': 'Intelijen belum tersedia',
  'Intelligence query': 'Permintaan intelijen',
  'Intelligence search': 'Pencarian intelijen',
  'Intelligence suggestions': 'Saran intelijen',
  'Interacted with': 'Berinteraksi dengan',
  'Interactions/ Relations': '',
  'Interested in': 'Tertarik dengan',
  Invalid: 'Tidak valid',
  'Invalid IMEI provided': 'IMEI yang disediakan tidak valid',
  'Invalid MSISDN': 'MSISDN tidak valid',
  'Invalid OTP code provided': 'Kode OTP yang diberikan tidak valid',
  'Invalid URL. Social network not supported':
    'URL tidak valid. Jejaring sosial tidak didukung',
  'Invalid credentials provided': 'Kredensial yang diberikan tidak valid',
  'Invalid email address': 'Alamat email tidak valid',
  'Invalid file format provided.': 'Format file yang disediakan tidak valid.',
  'Invalid location': 'Lokasi tidak valid',
  'Invalid relationship type': 'Jenis hubungan tidak valid',
  'Invalid type - allowed only png, jpg, jpeg':
    'Jenis tidak valid-hanya diperbolehkan png, jpg, jpeg',
  'Invalid values. Please fill in all fields.':
    'Nilai tidak valid. Harap isi semua kolom',
  'Invalid values. Please try again.': 'Nilai tidak valid. Silahkan coba lagi',
  'Invalid values. Please try again. Username must contain at least one letter.':
    'Nilai tidak valid. Silakan coba lagi. Nama pengguna harus terdiri dari setidaknya satu huruf.',
  Investigate: '',
  'Investigate lead': 'Selidiki petunjuk',
  Investigation: 'Investigasi',
  'Investigation filters': '',
  Investigations: 'Investigasi',
  Iran: 'Iran',
  Iraq: 'Irak',
  Ireland: 'Irlandia',
  'Is dead': 'Mati',
  'Is followed by': 'Diikuti oleh',
  'Is following': 'Mengikuti',
  'Is/was working as': 'Apakah / sedang bekerja sebagai ',
  'Isle of Man': 'Pulau manusia',
  Israel: 'Israel',
  Italy: 'Italia',
  'Item matched': 'Item sesuai',
  Items: 'Item',
  'Items per page': 'Item per halaman',
  Jamaica: 'Jamaika',
  January: 'Januari',
  Japan: 'Jepang',
  Jersey: 'Jersey',
  Job: 'Pekerjaan',
  'Job(s)': 'Pekerjaan',
  Jobs: 'Pekerjaan',
  'Join group': 'Bergabung dengan grup',
  'Join random or specific communities on the selected social network.':
    'Bergabunglah dengan komunitas acak atau spesifik di jejaring sosial yang dipilih.',
  'Joined At': 'Bergabung pada',
  'Joined group': 'Bergabung dengan grup',
  'Joining groups': 'Bergabung dengan grup',
  Jordan: 'Yordania',
  July: 'Juli',
  June: 'Juni',
  Kazakhstan: 'Kazakhstan',
  'Keep in mind that you can make changes later if needed.': '',
  Kenya: 'Kenya',
  Keyword: 'Kata kunci',
  'Keyword lists': 'Daftar kata kunci',
  Keywords: 'Kata Kunci',
  'Keywords list': 'Daftar kata kunci',
  'Keywords monitored': 'Kata kunci dipantau',
  Kidnapping: 'Penculikan',
  Kiribati: 'Kiribati',
  'Knowledge Base': 'Basis Pengetahuan',
  'Knowledge base': 'Basis pengetahuan',
  Kosovo: 'Kosovo',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kyrgyzstan',
  LABELS: 'LABEL',
  LAC: 'LAC',
  'LAC found': 'LAC ditemukan',
  'Location Area Code': 'Código de Localización de Área',
  LANGUAGE: 'BAHASA',
  LOCATION: '',
  'LOG IN': 'Masuk',
  Labels: 'Label',
  'Landline(s)': 'Telepon rumah',
  Landlines: 'Sambungan telepon Rumah',
  Language: 'Bahasa',
  Languages: 'Bahasa',
  Laos: 'Laos',
  'Last 24 hours': '24 jam terakhir',
  'Last 30 days': '30 hari terakhir',
  'Last 7 days': '7 hari terakhir',
  'Last 90 days': '90 hari terakhir',
  'Last Activity': 'Aktivitas Terakhir',
  'Last Hour': 'Jam terakhir',
  'Last Modified': 'Terakhir Dimodifikasi',
  'Last Seen': 'Terakhir terlihat',
  'Last activity': 'Aktivitas terakhir',
  'Last call log location': 'Lokasi log panggilan terakhir',
  'Last hour': 'Jam terakhir',
  'Last known activity': 'Aktivitas terakhir yang diketahui',
  'Last known location': 'Lokasi terakhir yang diketahui',
  'Last message': 'Pesan Terakhir',
  'Last located': '',
  'Last modifications': 'Modifikasi terakhir',
  'Last modified': 'Terakhir diubah',
  'Last name': 'Nama belakang',
  'Last page': 'Halaman terakhir',
  'Last query': 'Kueri terakhir',
  'Last seen': 'Terakhir dilihat',
  'Last seen online': 'Terakhir terlihat online',
  'Last week': 'Minggu lalu',
  Lat: 'Lat',
  'Lat / Lon': 'Lintang / Bujur',
  'Lat/Lon': 'Lintang/Bujur',
  Latest: 'Terbaru',
  'Latest Location': 'Lokasi Terbaru',
  'Latest activity': 'Aktivitas terbaru',
  'Latest search': '',
  'Latest search date': '',
  'Latest Search Date': '',
  'Latest status': '',
  Latitude: 'Latitude',
  Latvia: 'Latvia',
  Law: 'Hukum',
  Layout: 'Tata letak',
  Lebanon: 'Libanon',
  Ledger: 'Buku besar',
  Lens: 'Lens',
  Lesotho: 'Lesotho',
  Level: 'Tingkat',
  List: '',
  'List of searches': '',
  'List of searches for': '',
  Liberia: 'Liberia',
  Libya: 'Libya',
  'License Plate': 'Lisensi Pelat',
  'License Plate(s)': 'Plat Nomor',
  'License Plates': 'Plat Nomor',
  Liechtenstein: 'Liechtenstein',
  'Like group posts': 'Postingan grup disukai',
  'Like posts': 'Postingan disukai',
  Liked: 'Disukai',
  Likers: 'Penyuka',
  'Liking posts': 'Menyukai postingan',
  'Lines parsed': 'Garis diuraikan',
  Link: 'Tautan',
  'Link Analysis': 'Analisis Tautan',
  'Link analysis': 'Analisis tautan',
  'Link analysis v2': 'Analisis tautan v2',
  Linkedin: 'Linkedin',
  'Linkedin Employee': 'Karyawan Linkedin',
  Links: 'Tautan',
  'List View': 'Tampilan Daftar',
  'List name': 'Daftar nama',
  'List of all cases': 'Daftar semua kasus',
  'List of all imported call detail records':
    'Daftar semua catatan detail panggilan yang diimpor',
  'List of all targets': 'Daftar semua target',
  'List of imeis': 'Daftar imeis',
  'List of imsis': 'Daftar imsis',
  'List of msisdns': 'Daftar msisdns',
  'List of periods': 'Daftar periode',
  Lithuania: 'Lithuania',
  'Live Session': 'Sesi Langsung',
  'Live Session has been successfully obtained':
    'Sesi Langsung telah berhasil diperoleh',
  'Live data': 'Data langsung',
  'Live monitoring': 'Pemantauan langsung',
  'Live monitoring started at': 'Pemantauan langsung dimulai pada',
  'Live session request created successfully!':
    'Permintaan sesi langsung berhasil dibuat!',
  'Live tasks': 'Tugas Langsung',
  'Load a case': 'Muat kasus',
  'Load a target': 'Muat target',
  'Load selected data': '',
  Loading: 'Memuat',
  'Loading call log data': 'Memuat data log panggilan',
  'Loading data': 'Memuat data',
  'Loading target details': 'Memuat detail target',
  'Loading the file could not be completed.':
    'Memuat file tidak dapat diselesaikan.',
  Locate: 'Menemukan',
  'Locate a single MSISDN or IMSI': 'Temukan satu MSISDN atau IMSI',
  'Locate all': 'Temukan semua',
  'Locate now': 'Temukan sekarang',
  'Locate phone': 'Temukan telepon',
  Located: '',
  'Located at': 'Berlokasi di',
  Locating: 'Menemukan',
  Location: 'Lokasi',
  'Location copied!': '',
  'Location Heatmap': 'Peta Panas Lokasi',
  'Location History Found': 'Riwayat Lokasi Ditemukan',
  'Location History Not Found': 'Riwayat Lokasi Tidak Ditemukan',
  'Location History Request': 'Permintaan Riwayat Lokasi',
  'Location Info': 'Info Lokasi',
  'Locate Now': '',
  'Location Probalility': 'Probabilitas Lokasi',
  'Location Sources': 'Sumber Lokasi',
  'Location Timeline': 'Garis Waktu Lokasi',
  'Location belongs to the destination': 'Lokasi berdasarkan tujuan',
  'Location belongs to the source': 'Lokasi berdasarkan sumbernya',
  'Location found': 'Lokasi ditemukan',
  'Location history': 'Riwayat lokasi',
  'Location log': 'Log Lokasi',
  'Location probability': 'Probabilitas lokasi',
  'Location sources': 'Sumber lokasi',
  Locations: 'Lokasi',
  LOCATIONS: 'LOKASI',
  'Locations only': '',
  Lock: 'Kunci',
  'Lock Temporary User': 'Kunci Pengguna Sementara',
  'Lock user': 'Kunci pengguna',
  Log: 'Catatan',
  'Log on': 'Masuk',
  'Log on task': 'Tugas masuk',
  'Logged in': '',
  'Logging in': 'Masuk',
  Login: 'Masuk',
  'Login Now': 'Masuk sekarang',
  'Login status': '',
  Logon: 'Logon',
  Logout: 'Keluar',
  Lon: 'Lon',
  Longest: 'Terpanjang',
  'Longest call': 'Panggilan terlama',
  Longitude: 'Longitude',
  'Look for a country, city or address': 'Cari negara, kota, atau alamat',
  'Low activity': '',
  Luxembourg: 'Luksemburg',
  MAPS: 'PETA',
  MCC: 'MCC',
  METADATA: 'METADATA',
  MNC: 'MNC',
  MNOs: 'MNOs',
  'MOBILE NETWORK': 'JARINGAN SELULER',
  MSISDN: 'MSISDN',
  'MSISDN is connected with this instant messaging profile':
    'MSISDN terhubung dengan profil pesan instant ini',
  'MSISDN is not linked with a WhatsApp account': '',
  'MSISDN is not linked with a whatsApp account':
    'MSISDN tidak tertaut dengan akun WhatsApp',
  'MSISDN(s)': 'MSISDN',
  MSISDNs: 'MSISDN',
  Macedonia: 'Makedonia',
  Madagascar: 'Madagaskar',
  Major: 'Besar',
  'Make sure that the file is in Zip format and contains a JSON file':
    'Pastikan file dalam format Zip dan berisi file JSON',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maladewa',
  Male: 'Laki-laki',
  Mali: 'Mali',
  Malta: 'Malta',
  Manage: 'Kelola',
  'Management for a new case is free of charge for #{days} days':
    'Manajemen untuk kasus baru tidak dipungut biaya selama #{days} days',
  'Management for a new target is free of charge for #{days} days':
    'Pengelolaan target baru tidak dipungut biaya selama #{days} days',
  Manager: 'Pengelola',
  Map: 'Peta',
  'Map View': 'Tampilan Peta',
  'Map circle': 'Lingkaran peta',
  'Map default countries': 'default peta negara',
  Maps: 'Peta',
  March: 'Maret',
  'Marital Status': 'Status pernikahan',
  'Mark all as read': 'Tandai semua yang telah dibaca',
  'Marshall Islands': 'Pulau Marshall',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Maximize: 'Maksimalkan',
  'Maximize map': 'Maksimalkan peta',
  Maximum: 'Maksimum',
  'Maximum allowed size:': 'Ukuran maksimum yang diperbolehkan:',
  'Maximum amount of robot queries is': 'Jumlah maksimum kueri robot adalah',
  'Maximum credits assigned': 'Kredit maksimum yang ditetapkan',
  'Maximum credits available': 'Kredit maksimum yang tersedia',
  'Maximum image size is 2MB.': 'Ukuran gambar maksimum adalah 2MB.',
  'Maximum image size is 2MB. Please choose another image.':
    'Ukuran gambar maksimum adalah 2MB. Silakan pilih gambar lain.',
  'Maximum number of targets has been reached':
    'Jumlah target maksimum telah tercapai',
  'Maximum quota': 'Kuota maksimal',
  'Maximum quota assigned': 'Kuota maksimum yang ditetapkan',
  'Max limit reached': '',
  May: 'Mei',
  Media: 'Media',
  'Medical condition': 'Kondisi medis',
  Medium: 'Sedang',
  'Meeting Points': 'Titik Pertemuan',
  'Meeting points': 'Titik pertemuan',
  Members: 'Anggota',
  Mentions: 'Mentions',
  Message: 'pesan',
  'Message to': 'Pesan ke',
  Messages: 'Pesan',
  Method: 'Metode',
  Mexico: 'Meksiko',
  'Middle name': 'Nama tengah',
  Minimize: 'Minimalkan',
  'Minimize map': 'Minimalkan peta',
  Minor: 'Minor',
  Miscellaneous: '',
  'Miscellaneous ': 'Macam-macam',
  'Missing CDRs for this target': 'CDR yang hilang untuk target ini',
  'Missing file to upload': 'File tidak ada untuk diunggah',
  'Mission is empty. Click the': '',
  'Mobile Data': '',
  'Mobile Network': '',
  'Mobile network': 'Jaringan seluler',
  'Mobile Operator': '',
  Model: 'Model',
  Moldova: 'Moldova',
  Monaco: 'Monako',
  Monday: '',
  Money: 'Uang',
  Mongolia: 'Mongolia',
  'Monitor changes': 'Pantau perubahan',
  Monitoring: 'Pemantauan',
  'Monitoring is': 'Pemantauan adalah',
  Montenegro: 'Montenegro',
  'Month of year': 'Bulan dalam setahun',
  'Monthly credits assigned': 'Kredit bulanan yang ditetapkan',
  'Monthly credits available': 'Kredit bulanan tersedia',
  'Monthly quota': 'Kuota bulanan',
  'Monthly quota assigned': 'Kuota bulanan ditetapkan',
  Montserrat: 'Montserrat',
  More: 'Lebih',
  'More filters': 'Lebih banyak filter',
  'More actions for': '',
  Morocco: 'Maroko',
  'Most Active': '',
  'Most Common Relations': 'Hubungan yang Paling Umum',
  'Most active period': 'Periode paling aktif',
  'Most common associates': 'Rekan yang paling umum',
  'Most common faces': 'wajah yang paling umum',
  'Most inactive period': 'Periode paling tidak aktif',
  'Most recent': 'Terbaru',
  'Most relevant': 'Paling relevan',
  'Mostly active': 'Sebagian besar aktif',
  'Mostly inactive': 'Sebagian besar tidak aktif',
  'Mothers name': 'Nama ibu',
  Mozambique: 'Mozambik',
  Multiple: 'Banyak',
  'Multiple cases': 'Beberapa kasus',
  'Multiple profile searches': 'Beberapa pencarian profil',
  'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed':
    'Panjang minimal harus 1 karakter dan karakter maksimum yang diperbolehkan adalah 255, karakter khusus dan spasi sebelumnya atau berikutnya tidak diperbolehkan',
  'Must be at least 10 characters long and special characters are not allowed':
    'Panjangnya harus minimal 10 karakter dan karakter khusus tidak diperbolehkan',
  'Must be at least 3 characters long and maximum characters allowed are 15, special charactes are not allowed':
    'Harus memiliki panjang minimal 3 karakter dan karakter maksimum yang diperbolehkan adalah 15, karakter khusus tidak diperbolehkan',
  'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed':
    'Panjangnya harus minimal 3 karakter dan karakter maksimum yang diperbolehkan adalah 20, karakter khusus dan spasi sebelumnya atau berikutnya tidak diperbolehkan',
  'Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed':
    'Panjangnya harus minimal 3 karakter, karakter khusus dan spasi sebelum atau sesudahnya tidak diperbolehkan',
  Muted: 'Dibisukan',
  'Mutual Facebook Friends': 'Teman Facebook yang sama',
  'My details': 'Detail saya',
  'My places': 'Tempatku',
  'My target': 'Targetku',
  Myanmar: 'Myanmar',
  NIK: 'NIK',
  'Network N/A': 'Jaringan N/A',
  'N/A': 'N/A',
  Name: 'Nama',
  'Name Middle Surname': 'Nama Nama Keluarga Tengah',
  'Name Surname': 'Nama Nama Keluarga',
  'Name cannot be just whitespaces': 'Nama tidak bisa hanya spasi',
  'Name is required': 'Nama diperlukan',
  'Name must contain at least 3 characters':
    'Nama harus mengandung minimal 3 karakter',
  'Name of the area': 'Nama Daerah',
  'Name(s)': 'Nama',
  Names: 'Nama',
  Namibia: 'Namibia',
  Nationalities: 'Kebangsaan',
  Nauru: 'Nauru',
  'Nearest Neighbors': 'Tetangga Terdekat',
  Negative: 'Negatif',
  'Neighbour cell towers with params': 'Neighbour cell towers with params',
  Nepal: 'Nepal',
  Neqative: 'Neqatif',
  Network: 'Jaringan',
  'Network(s)': 'Jaringan(s)',
  Neuter: 'Netral',
  Neutral: 'Netral',
  'Never located': '',
  'New Credits Usage Statistics Dashboard':
    'Dasbor Statistik Penggunaan Kredit Baru',
  'New Discovery': '',
  'New MSISDN': 'MSISDN Baru',
  'New Password': 'Kata Sandi Baru / Password Baru',
  'New Query': 'Kueri Baru',
  'New Zealand': 'Selandia Baru',
  'New call log': 'Log panggilan baru',
  'New case': 'Kasus baru',
  'New entity': 'Entitas baru',
  'New intel query': 'Permintaan intel baru',
  'New investigation': 'Investigasi baru',
  'New note text': 'Teks catatan baru',
  'New password': 'Kata sandi baru',
  'New post': '',
  'New query': 'Kueri baru',
  'New results available': '',
  'New target': 'Target baru',
  'New user #{username} created successfully!':
    'Pengguna baru #{username} berhasil dibuat!',
  'New virtual ID': 'ID virtual baru',
  'New virtual ID ': 'ID virtual baru ',
  Next: 'Selanjutnya',
  'Next page': 'Halaman selanjutnya',
  'Next step': 'Langkah selanjutnya',
  Nicaragua: 'Nikaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  No: 'TIDAK',
  'No crypto transactions': '',
  'No Data': 'Tidak ada data',
  'No Data Available': 'Tidak ada data yang tersedia',
  'No Location found for trilateration query!':
    'Tidak ada Lokasi yang ditemukan untuk kueri trilateration!',
  'No Results': 'Tidak ada Hasil',
  'No records found': '',
  'No activity': 'Tidak ada aktivitas',
  'No activity log for this avatar': 'Tidak ada log aktivitas untuk avatar ini',
  'No archived recommandations available':
    'Rekomendasi yang diarsipkan tidak tersedia',
  'No area is defined on the map.': 'Tidak ada area yang ditentukan di peta.',
  'No available browsers. Please retry':
    'Tidak ada browser yang tersedia. Silakan coba lagi',
  'No available data': 'Tidak ada data yang tersedia',
  'No available data for this section yet':
    'Belum ada data yang tersedia untuk bagian ini',
  'No call log found for this target. Please use the menu to request or upload a call log.':
    'Tidak ditemukan log panggilan untuk target ini. Silakan gunakan menu untuk meminta atau mengunggah log panggilan.',
  'No cases defined': 'Tidak ada kasus yang ditentukan',
  'No conversations': 'Tidak ada percakapan',
  'No credits': 'Tidak ada kredit',
  'No credits assigned': 'Tidak ada kredit yang diberikan',
  'No data': 'Tidak ada data',
  'No data available': 'Tidak ada data yang tersedia',
  'No data yet. Import your files or run a Somedus query for Telegram to see results.':
    'Belum ada datanya. Impor file Anda atau jalankan kueri Somedus untuk Telegram untuk melihat hasilnya.',
  'No data. Make sure you have seeds and click "Enhance profile"':
    'Tidak ada data. Pastikan Anda memiliki benih dan klik"Tingkatkan profil"',
  'No date available': 'Tidak ada tanggal yang tersedia',
  'No date provided': 'Tidak ada tanggal yang disediakan',
  'No entries': 'Tidak ada entri',
  'No groups': 'Tidak ada grup',
  'No image available': 'Tidak ada gambar yang tersedia',
  'No items added': 'Tidak ada item yang ditambahkan',
  'No keyword lists': 'Tidak ada daftar kata kunci',
  'No keyword lists created': 'Tidak ada daftar kata kunci yang dibuat',
  'No keywords': 'Tidak ada kata kunci',
  'No lists selected': 'Tidak ada daftar yang dipilih',
  'No location': 'Tidak ada lokasi',
  'No location data': 'Tidak ada data lokasi',
  'No location found': 'Tidak ada lokasi yang ditemukan',
  'No location found for Telegram users!':
    'Tidak ada lokasi yang ditemukan untuk pengguna Telegram!',
  'No locations found for the target <br> Add seeds to retrieve more information':
    'Tidak ada lokasi yang ditemukan untuk target <br> Tambahkan sumber untuk mengambil informasi lebih lanjut',
  'No locations found for the target <br> Click "#{Request call log}" to retrieve locations':
    'Tidak ada lokasi yang ditemukan untuk target <br> Klik "#{Request call log} "untuk mengambil lokasi',
  'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations':
    'Tidak ada lokasi yang ditemukan untuk target <br> Klik " Locate "atau" #{Request call log} "untuk mengambil lokasi',
  'No locations were found for': 'Tidak ditemukan lokasi untuk',
  'No matches found': 'Tidak ditemukan kecocokan',
  'No more than 1024 characters long':
    'Panjangnya tidak lebih dari 1024 karakter',
  'No more than 255 characters long':
    'Panjangnya tidak lebih dari 255 karakter',
  'No new suggestions available': 'Tidak ada saran baru yang tersedia',
  'No phone links defined': 'Tidak ada tautan telepon yang ditentukan',
  'No places': 'Tidak ada tempat',
  'No platforms were found. Please select the platform from the list bellow.':
    'Tidak ada platform yang ditemukan. Silakan pilih platform dari daftar di bawah ini.',
  'No queries': 'Tidak ada kueri',
  'No recommandations available': 'Tidak ada rekomendasi yang tersedia',
  'No results found': 'Tidak ada hasil yang ditemukan',
  'No seeds were found. The ExtractusD will fetch information related to Target MSISDN(s)':
    'Tidak ada sumber yang ditemukan. ExtractusD akan mengambil informasi yang terkait dengan MSISDN Target',
  'No selected list': 'Tidak ada daftar yang dipilih',
  'No social data for current targets!':
    'Tidak ada data sosial untuk target saat ini!',
  'No statistics available for the selected period.':
    'Tidak ada statistik yang tersedia untuk periode yang dipilih.',
  'No suggestions available': 'Tidak ada saran yang tersedia',
  'No targets': 'Tidak ada target',
  'No targets added.': 'Tidak ada target yang ditambahkan.',
  'No targets defined': 'Tidak ada target yang ditentukan',
  'No targets selected!': 'Tidak ada target yang dipilih!',
  'No transactions found': 'Tidak ditemukan transaksi',
  'No transactions were made in the selected dates':
    'Tidak ada transaksi yang dilakukan pada tanggal yang dipilih',
  'No valid MSISDN': 'Tidak ada MSISDN yang valid',
  'No virtual ID match your criteria':
    'Tidak ada ID virtual yang cocok dengan kriteria Anda',
  'No, I want to continue': 'Tidak, saya ingin melanjutkan',
  'No, remove address from account': 'Tidak, hapus alamat dari akun',
  'No, thank you': 'Tidak, terima kasih',
  'No. of calls': 'Jumlah panggilan',
  Nodes: 'Node',
  Non: 'Non',
  'Non Geo Political Entities': 'Entitas Non Geo Politik',
  'Non expiring': 'Tidak kedaluwarsa',
  None: 'Tidak ada',
  'North Korea': 'Korea Utara',
  Norway: 'Norway',
  'Not Located': 'Tidak Berlokasi',
  'Not active': 'Tidak aktif',
  'Not available at the moment': 'Tidak tersedia saat ini',
  'Not enough credits': 'Kredit tidak cukup',
  'Not enough credits for this request':
    'Kredit tidak cukup untuk permintaan ini',
  'Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?':
    'Data geolokasi atau data log panggilan tidak cukup untuk memprediksi lokasi. Apakah Anda ingin membuat jadwal?',
  'Not enough information': 'Tidak cukup informasi',
  'Not now': 'Tidak sekarang',
  'Not available': '',
  Note: 'Catatan',
  'Note changed': 'Catatan berubah',
  'Note created successfully!': 'Catatan berhasil dibuat!',
  'Note has not been created': 'Catatan belum dibuat',
  'Note was added': 'Catatan telah ditambahkan',
  'Note: Please leave input box empty to remove current hashtag':
    'Catatan: Harap biarkan kotak input kosong untuk menghapus hashtag saat ini',
  'Note: Time periods are not available for Upload call logs ':
    'Catatan: Jangka waktu tidak tersedia untuk Unggah log panggilan ',
  Notes: 'Catatan',
  Notifications: 'Pemberitahuan',
  'Notify me on Updates': 'Beri tahu saya tentang Pembaruan',
  'Notify me when there is a': '',
  November: 'November',
  'Number most contacted': 'Nomor yang paling banyak dihubungi',
  'Number most received': 'Nomor yang paling banyak diterima',
  'Number of sessions': 'Jumlah sesi',
  Numerical: 'Numerik',
  OFF: 'MATI',
  OFFLINE: 'OFFLINE',
  OK: 'OK',
  ON: 'PADA',
  ONLINE: 'ONLINE',
  OPTIONS: 'PILIHAN',
  OSINT: 'OSINT',
  'OSINT completed successfully': 'OSINT berhasil diselesaikan',
  OTP: 'OTP',
  'OTP - One Time Password': 'OTP-Kata Sandi Satu Kali',
  'OTP Channel': 'Saluran OTP',
  'OTP code': 'Kode OTP',
  'OTP configuration completed': 'Konfigurasi OTP selesai',
  'OTP required': '',
  'OVERVIEW ': 'RINGKASAN',
  Object: 'Obyek',
  October: 'Oktober',
  Okay: 'Oke',
  Oman: 'Oman',
  'Once deleted the data cannot be restored':
    'Setelah dihapus, data tidak dapat dipulihkan',
  'Once deleted, the cases cannot be restored.':
    'Setelah dihapus, kasus tersebut tidak dapat dipulihkan.',
  'Once removed the data cannot be restored':
    'Setelah dihapus, data tidak dapat dipulihkan',
  'Once the infrastructure is up, it will take our BOTs up to':
    'Setelah infrastruktur habis, BOT kami akan membutuhkan waktu hingga',
  One: 'Satu',
  'One successful Advanced OSINT': 'Satu OSINT Lanjutan yang berhasil',
  'One successful Intel Search': 'Satu Pencarian Intel yang berhasil',
  'One successful call log request for #{type} #{value} for the period of #{duration} months':
    'Satu permintaan log panggilan berhasil untuk #{type} #{value} untuk jangka waktu #{duration} bulan',
  'One successful import cdr for #{type} #{value}':
    'Satu cdr yang berhasil impor  untuk #{type} #{value}',
  Online: 'On line',
  'Only lowercase letters, numbers, dot (.) and underscore (_).':
    'Hanya huruf kecil, angka, titik (.) dan garis bawah (_).',
  'Only xlsx or csv files are supported':
    'Hanya file xlsx atau csv yang didukung',
  'Open URL': 'Buka URL',
  'Open Web': 'Buka Web',
  'Open in a new tab': 'Buka di tab baru',
  'Open in new page': 'Buka di halaman baru',
  'Open in website': 'Buka di situs web',
  'Open remote view': 'Buka tampilan jarak jauh',
  'Open target profile': 'Buka profil target',
  'Opening browser': 'Membuka peramban',
  Operator: 'Operator',
  'Opt-out': 'Opt-out',
  Options: 'Pilihan',
  Ordinal: 'Ordinal',
  Org: 'Org',
  Organic: 'Organik',
  Organization: 'Organisasi',
  Organizations: 'Organisasi',
  Origin: 'Asal',
  'Origin Position': 'Posisi Asal',
  'Origin position': 'Posisi asal',
  Other: 'Lainnya',
  'Other info': 'Info lainnya',
  'Other recovery accounts': 'Akun pemulihan lainnya',
  'Other relations': 'Hubungan lainnya',
  'Other social profiles': 'Profil sosial lainnya',
  'Other sources': 'Sumber lain',
  Others: 'Yang lain',
  'Otherwise, your progress will not be saved':
    'Jika tidak, progress Anda tidak akan disimpan.',
  'Otp code obtained. Confirming facebook account registration':
    'Kode Otp diperoleh. Mengonfirmasi pendaftaran akun facebook',
  'Out SMS': 'SMS Keluar',
  'Out call': 'Panggilan keluar',
  Outgoing: 'Keluar',
  'Outgoing SMS': 'SMS keluar',
  'Outgoing call': 'Panggilan keluar',
  'Outgoing sms': 'sms keluar',
  'Outside of area': 'Di luar daerah',
  'Outside the circle': 'Di luar lingkaran',
  Overall: 'Keseluruhan',
  'Overall records': 'Catatan keseluruhan',
  Overview: 'Ringkasan',
  Own: 'Sendiri',
  Owner: 'Pemilik',
  'Owner id': 'Id pemilik',
  'PAGES WITH MATCHING IMAGES': 'HALAMAN DENGAN GAMBAR YANG COCOK',
  'PARTIALLY MATCHING IMAGES': 'GAMBAR YANG COCOK SEBAGIAN',
  PASSWORD: '',
  'PDF layout': 'tata letak PDF',
  'PDF settings updated successfully!': 'Setelan PDF berhasil diperbarui!',
  'POI type': 'Jenis POI ',
  'Page numbering': 'Penomoran halaman',
  Pakistan: 'Pakistan',
  Palau: 'Palau',
  Palestine: 'Palestina',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua Nugini',
  Paraguay: 'Paraguay',
  'Parsing error lines': 'Menguraikan baris kesalahan',
  'Parsing errors': 'Kesalahan penguraian',
  Password: 'Kata Sandi / Password',
  'Password does not contain any digits.':
    'Kata sandi tidak mengandung angka apa pun.',
  'Password does not contain lower case letters.':
    'Kata sandi tidak mengandung huruf kecil.',
  'Password does not contain upper case letters.':
    'Kata sandi tidak mengandung huruf besar.',
  'Password expired': 'Kata sandi kedaluwarsa',
  'Password must be at least 8 characters.':
    'Kata sandi harus minimal 8 karakter.',
  'Password provided was already used':
    'Kata sandi yang diberikan sudah digunakan',
  'Password strength': 'Kekuatan kata sandi',
  "Passwords don't match.": 'Kata sandi tidak cocok / password tidak cocok',
  'Passwords not provided': 'Kata sandi tidak disediakan',
  Past: 'Lalu / Lampau',
  'Past tasks': 'Tugas sebelumnya',
  'Paste here the url for the platform':
    'Tempel url di sini untuk platform tersebut',
  'Paste image URL': 'Tempel URL gambar',
  'Paste profile URL': 'Tempel URL profil',
  Peer: 'Peer',
  'Peer interactions': 'Interaksi yang sama',
  'Peer(s) locations': 'Lokasi rekan',
  Pending: 'Tertunda',
  'Pending Request': 'Permintaan Tertunda',
  'Pending call log': 'Log panggilan tertunda',
  'Pending call log request!': 'Permintaan log panggilan tertunda!',
  'Pending request': 'Permintaan Tertunda',
  'Pending request. Click here to cancel':
    'Permintaan tertunda. Klik di sini untuk membatalkan',
  'Pending... Click to cancel': 'Menunggu... Klik untuk membatalkan',
  People: 'Orang',
  'People nearby': 'Orang-orang terdekat',
  Percent: 'Persen',
  'Perform Location History': 'Lakukan Riwayat Lokasi',
  'Perform a follow query for the selected rows':
    'Lakukan kueri ikuti untuk baris yang dipilih',
  'Perform a location history query for the selected rows':
    'Melakukan kueri riwayat lokasi untuk baris yang dipilih',
  'Perform a proximity query for the selected row':
    'Melakukan kueri kedekatan untuk baris yang dipilih',
  'Perform any of the OSINT Stage to retrieve important intelligence information':
    'Lakukan salah satu Tahap OSINT untuk mengambil informasi intelijen yang penting',
  Period: 'Periode',
  'Periods analyzed': 'Periode dianalisis',
  'Permanently delete case': 'Hapus kasus secara permanen',
  'Permanently delete investigation': 'Hapus investigasi secara permanen',
  'Permanently delete target': 'Hapus target secara permanen',
  'Permanently remove target from case':
    'Hapus target secara permanen dari kasus',
  'Permission denied': 'Izin ditolak',
  Person: 'Orang',
  'Persons tagged': 'Orang yang ditandai',
  Peru: 'Peru',
  Philippines: 'Filipina',
  Phone: 'Telepon',
  'Phone Info': 'Info Telepon',
  'Phone Model': 'Model Telepon',
  'Phone Number': 'Nomor Telepon',
  'Phone Numbers': 'Nomor telepon',
  'Phone brand': 'Merek ponsel',
  'Phone links': 'Tautan telepon',
  'Phone model': 'Model telepon',
  'Phone models': 'Model telepon',
  'Phone models / IMEIs': 'Model telepon / IMEIs',
  'Phone number': 'Nomor telepon',
  'Phone numbers': 'Nomor telepon',
  'Phone(s)': 'Telepon',
  Photo: 'Foto',
  'Photo update time': 'Waktu pembaruan foto',
  Photos: 'Foto',
  'Photos uploaded into facebook account. Filling account details':
    'Foto diunggah ke akun facebook. Mengisi detail akun',
  Picture: 'Gambar',
  'Pin this search': 'Sematkan penelusuran ini',
  'Pitcairn Islands': 'Kepulauan Pitcairn',
  Place: 'Tempat',
  'Place Of Birth': 'Tempat Lahir',
  'Place created successfully!': 'Tempat berhasil dibuat!',
  'Place has been successfully deleted': 'Tempat telah berhasil dihapus',
  'Place has not been created': 'Tempat belum dibuat',
  'Place has not been deleted': 'Tempat belum dihapus',
  'Place name': 'Nama tempat',
  'Place of birth': 'Tempat lahir',
  'Place of interest': 'Tempat menarik',
  Places: 'Tempat',
  'Places of interest': 'Tempat-tempat menarik',
  Platform: 'Platform',
  'Platform settings': 'Pengaturan platform',
  'Please Enter Password': 'Silakan masukkan Kata Sandi',
  'Please Enter Username': 'Silakan masukkan Nama Pengguna / Nama User',
  'Please add an email address to the user. A new password will be sent to that email':
    'Harap tambahkan alamat email ke pengguna. Kata sandi baru akan dikirimkan ke email tersebut',
  'Please add at least two fields to create a note.':
    'Harap tambahkan setidaknya dua bidang untuk membuat catatan.',
  'Please add email to enable email notifications':
    'Silakan tambahkan email untuk mengaktifkan notifikasi email',
  'Please add phone or email to enable OTP':
    'Silakan tambahkan telepon atau email untuk mengaktifkan OTP',
  'Please add phone to enable sms notifications':
    'Silakan tambahkan telepon untuk mengaktifkan notifikasi sms',
  'Please choose another daterange.': 'Silakan pilih rentang tanggal lain.',
  'Please complete all fields!': 'Harap lengkapi semua kolom!',
  'Please contact your administrator': 'Silakan hubungi administrator Anda',
  'Please enable location access': 'Harap aktifkan akses lokasi',
  'Please enter 3 or more characters': 'Silakan masukkan 3 karakter atau lebih',
  'Please enter a valid TRG email!': 'Silakan masukkan email TRG yang valid!',
  'Please enter the OTP code to login.': '',
  'Please enter the URL for the specific community you want to be joined':
    'Silakan masukkan URL untuk komunitas tertentu yang ingin Anda ikuti',
  'Please enter the URL for the specific community you want to be joined. &#10;One URL per line.':
    'Silakan masukkan URL untuk komunitas tertentu yang ingin Anda ikuti. &#10; Satu URL per baris.',
  'Please enter the same new password again':
    'Harap masukkan kembali kata sandi baru yang sama',
  'Please fill in all fields': 'Silakan isi semua kolom',
  'Please fill in both fields for the date':
    'Harap isi kedua kolom untuk tanggalnya',
  'Please fill out this field': 'Silakan isi kolom ini',
  'Please how do you want to create the Virtual ID': '',
  'Please insert more than one phones to perform a proximity query':
    'Silakan masukkan lebih dari satu ponsel untuk melakukan kueri kedekatan',
  'Please provide a short description of your work.':
    'Harap berikan deskripsi singkat tentang pekerjaan Anda.',
  'Please provide proper platform link!':
    'Harap berikan tautan platform yang tepat!',
  'Please provide your email address': 'Masukkan alamat email Anda',
  'Please review the local file and upload again.':
    'Harap tinjau file lokal dan upload lagi.',
  'Please select a 3 day range maximum.':
    'Silakan pilih rentang maksimum 3 hari.',
  'Please select a Social Profile!': 'Silakan pilih Profil Sosial!',
  'Please select a date!': 'Silakan pilih tanggal!',
  'Please select a query': 'Silakan pilih kueri',
  'Please select at least 1 entity to create a new investigation':
    'Silakan pilih setidaknya 1 entitas untuk membuat investigasi baru',
  'Please select at least 2 targets for call log analysis':
    'Silakan pilih setidaknya 2 target untuk analisis log panggilan',
  'Please select at least one profile to begin monitoring': '',
  'Please select the time period': 'Silakan pilih periode waktunya',
  'Please select the type of OTP you want to use.':
    'Silahkan pilih jenis OTP yang ingin Anda gunakan.',
  'Please select values for all columns': '',
  'Please take this survey to rate your experience with':
    'Silakan ikuti survei ini untuk menilai pengalaman Anda, dengan',
  'Please use the filters below to define your objective. Keep in mind that you can make changes later if needed.':
    '',
  'Please use upper case letters, lower case letters and numbers':
    'Harap gunakan huruf besar, huruf kecil, dan angka',
  'Please write the URL bellow and press enter if is valid':
    'Silakan tulis URL di bawah dan tekan enter jika valid',
  'Please write the URL bellow and press enter if valid':
    'Silakan tulis URL di bawah dan tekan enter jika valid',
  Poland: 'Polandia',
  Popularity: 'Kepopuleran',
  Portugal: 'Portugal',
  Position: 'Posisi',
  Positive: 'Positif',
  'Possible location for': 'Kemungkinan lokasi untuk',
  'Possibly owns this social profile': 'Mungkin memiliki profil sosial ini',
  Post: 'Pos',
  'Post Information': 'Posting Informasi',
  'Post is created': 'Postingan dibuat',
  'Post is disliked': 'Postingan tidak disukai',
  'Post is liked': 'Postingan disukai',
  'Post is shared': 'Postingan dibagikan',
  'Post preview': 'Posting pratinjau',
  'Post was added': 'Postingan telah ditambahkan',
  'Postal code': 'Kode Pos',
  'Posted on': 'Diposting di',
  Posts: 'Postingan',
  'Potential Infection': 'Potensi Infeksi',
  'Potential infection': '',
  'Power users': '',
  Powerusers: 'Powerusers',
  'Pre registration warmup for #{socialType} started':
    'Pemanasan pra pendaftaran untuk #{socialType} dimulai',
  'Pre-registering': 'Pra-pendaftaran',
  'Predicted Home': 'Prediksi Rumah',
  'Predicted Home Location with probability of': '',
  'Predicted Location': 'Lokasi yang Diprediksi',
  'Predicted Locations': 'Lokasi yang Diprediksi',
  'Predicted Workplace': 'Prediksi Tempat Kerja',
  'Predicted Workplace Location with probability of': '',
  'Predicted location': 'Lokasi yang diprediksi',
  'Predicted locations': 'Lokasi yang diprediksi',
  'Preferred channel': 'saluran pilihan',
  Pregnant: 'Hamil',
  'Preparing PDF preview': 'Mempersiapkan pratinjau PDF',
  Present: 'Hadir',
  Presets: 'Preset',
  Previous: 'Sebelumnya',
  'Previous page': 'Halaman sebelumnya',
  Previously: 'Sebelumnya',
  'Private Profile': 'Profil Pribadi',
  'Private message to': 'Pesan pribadi ke',
  'Private messages': 'Pesan pribadi',
  'Private profile': 'Profil pribadi',
  Probability: 'Kemungkinan',
  Proceed: 'Melanjutkan',
  'Processing the data': 'Memproses data',
  'Processing the data, please wait': 'Memproses data, harap tunggu',
  Product: 'Produk',
  Profile: 'Profil',
  'Profile Search': 'Pencarian Profil',
  'Profile Search Results': 'Hasil Penelusuran Profil',
  'Profile URL': 'URL profil',
  'Profile URLs': 'URL Profil',
  'Profile is associated with this photo': 'Profil dikaitkan dengan foto ini',
  'Profile is associated with this post':
    'Profil dikaitkan dengan postingan ini',
  'Profile photos': 'Foto profil',
  'Profile picture': 'Foto profil',
  'Profile search history': 'Riwayat pencarian profil',
  'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok':
    'Url profil untuk Facebook, Twitter, Instagram, Linkedin, Tiktok',
  'Profile urls': 'Url profil',
  Profiler: 'Profiler',
  'Profiler report': 'Laporan profiler',
  Profileurls: '',
  Profiling: 'Pembuatan profil',
  'Profiling will be updated next month':
    'Profiling akan diperbarui bulan depan',
  Program: 'Program',
  'Property last sale date(s)': 'Tanggal penjualan terakhir properti',
  'Property type': 'Tipe properti',
  'Property type(s)': 'Tipe properti',
  Provider: 'Pemberi',
  Proximity: 'Kedekatan',
  'Proximity Analysis': 'Analisis Kedekatan ',
  Publish: 'Menerbitkan',
  'Purchase and import call log data for':
    'Beli dan impor data log panggilan untuk',
  'Purchase call log': 'Beli log panggilan',
  'Purchase call logs failed!': 'Pembelian log panggilan gagal!',
  'Purchase call logs initiated!': 'Pembelian log panggilan dimulai!',
  'QR code': 'Kode QR',
  Qatar: 'Qatar',
  Quantity: 'Kuantitas',
  Queries: 'Kueri',
  Query: 'Kueri ',
  'Query Date': 'Query / Kueri Tanggal',
  'Query Id': 'Kueri ID',
  'Query MSISDN': 'Kueri MSISDN',
  'Query and schedule': 'Kueri dan jadwal',
  'Query canceled': 'Kueri dibatalkan',
  'Query created successfully!': 'Kueri berhasil dibuat!',
  'Query date': 'Query / Kueri Tanggal',
  'Query has not been canceled': 'Kueri belum dibatalkan',
  'Query has not been created': 'Kueri belum dibuat',
  'Query hashtag': 'Tagar kueri',
  'Query number': 'Kueri Nomor',
  'Query pending': 'Kueri tertunda',
  'Query selected': 'Kueri dipilih',
  'Query successfully resubmitted!': 'Kueri berhasil dikirim ulang!',
  'Query successfully submitted': 'Permintaan berhasil dikirim',
  'Quick filters': 'Filter cepat',
  Quota: 'Kuota',
  'Quota assigned': 'Kuota ditetapkan',
  'Quota unused': 'Kuota tidak terpakai',
  Quotes: 'Kutipan',
  RFC: 'RFC',
  Race: 'Balapan',
  'Radical monitoring': 'Pemantauan radikal',
  'Radical monitoring matched a keyword for':
    'Pemantauan radikal cocok dengan kata kunci untuk',
  'Radical monitoring service is down':
    'Layanan pemantauan radikal sedang down',
  'Radical speech monitoring': 'Pemantauan ucapan radikal',
  Radio: 'Radio',
  'Radio Id': 'ID Radio',
  'Radio Type': '',
  Radius: 'Radius',
  Random: 'Acak',
  'Random actions': 'Tindakan acak',
  'Raw data': 'Data mentah',
  Reactions: 'Reaksi',
  'Reactions count': 'Reaksi dihitung',
  Reactivate: '',
  Reason: 'Alasan',
  'Received Group Messages': 'Menerima Pesan Grup',
  'Received Private Messages': 'Menerima Pesan Pribadi',
  'Received group messages': 'Menerima pesan grup',
  'Received private messages': 'Menerima pesan pribadi',
  'Receiver number': 'Nomor penerima',
  'Recent searches': 'Pencarian terbaru',
  Recipient: 'Penerima',
  'Recovery Accounts': 'Akun Pemulihan',
  'Recovery accounts': 'Akun pemulihan',
  'Recovery data': 'Data pemulihan',
  'Recovery email': 'Email pemulihan',
  'Recovery phone': 'Telepon pemulihan',
  Redraw: 'Menggambar ulang',
  'Redraw area': 'Gambar ulang area',
  Refresh: 'Refresh / Menyegarkan',
  Region: 'Wilayah',
  'registered on': '',
  'Registering FB acc.': 'Mendaftarkan FB acc.',
  'Registration date': 'Tanggal registrasi',
  'Related numbers': 'Nomor terkait',
  'Related people': 'Orang terkait',
  'Related posts': 'Pos terkait',
  Relation: 'Hubungan',
  Relations: 'Hubungan',
  Releasing: 'Merilis',
  Reload: 'Muat ulang',
  'Reload chart with latest target data':
    'Muat ulang bagan dengan data target terbaru',
  'Remaining quota': 'Sisa kuota',
  'Remaining usage': '',
  'Remote view': 'Tampilan jarak jauh',
  Remove: 'Menghapus',
  'Remove Profile': 'Hapus Profil',
  'Remove filters': 'Hapus filter',
  'Remove filters and restore queries': 'Hapus filter dan pulihkan kueri',
  'Remove from case': 'Hapus dari kasus',
  'Remove from history': 'Hapus dari riwayat',
  'Remove from whitelist': 'Hapus dari Whitelist / Hapus dari daftar putih',
  'Remove layer': 'Hapus lapisan',
  'Remove node': 'Hapus node',
  'Remove target': 'Hapus target',
  'Remove Target?': '',
  'Remove target from case': 'Hapus target dari kasus',
  'Removes all filters, keeps new nodes from analyse':
    'Menghapus semua filter, mencegah node baru menganalisis',
  'Removes everything (including nodes from analyse) and reloads all target data':
    'Menghapus semuanya (termasuk node dari analisis) dan memuat ulang semua data target',
  Renew: 'Perbarui',
  'Renew target and Request call log':
    'Perbarui target dan Minta log panggilan',
  'Renew with the credits': 'Perbarui dengan kredit',
  Repeat: 'Ulangi',
  'Repeat interval - hours': 'Interval pengulangan-jam',
  'Request Call Log': 'Minta Catatan Panggilan',
  'Request Call Log by selecting one of the following options':
    'Minta Log Panggilan dengan memilih salah satu opsi berikut',
  'Request Cancelled': 'Permintaan Dibatalkan',
  'Request call log': 'Permintaan Log Panggilan',
  'Request call log limit reached. Please contact support.':
    'Batas permintaan log panggilan tercapai. Silakan hubungi dukungan.',
  'Request expired for': 'Permintaan kedaluwarsa untuk',
  'Request from Support': 'Permintaan dari Dukungan',
  'Request has been sent to support': 'Permintaan telah dikirim ke dukungan',
  'Request has not been sent': 'Permintaan belum terkirim',
  'Request or Upload Call Log by selecting one of the following options':
    'Minta atau Unggah Log Panggilan dengan memilih salah satu opsi berikut',
  'Request sent successfully!': 'Permintaan berhasil dikirim!',
  'Requesting call logs has been disabled':
    'Meminta log panggilan telah dinonaktifkan',
  Required: 'Diperlukan',
  'Reserve browser': '',
  'Reserve browser failed. Please retry':
    'Peramban cadangan gagal. Silakan coba lagi',
  Reserved: 'Dicadangkan',
  Reserving: 'Pemesanan',
  Reset: 'Mengatur Ulang',
  'Reset Password': 'Atur ulang kata sandi / atur ulang password',
  'Resize map': 'Ubah ukuran peta',
  'Rest of the week': 'sisa minggu',
  Restore: 'Pulihkan',
  Resubmit: 'Kirim ulang',
  'Resubmit Cancelled': 'Pengiriman Ulang Dibatalkan',
  'Resubmit query': 'Kirim ulang permintaan',
  'Result type': 'Jenis hasil',
  Results: 'Hasil',
  'Results Found': 'Hasil Ditemukan',
  'Results for': 'Hasil untuk',
  'Results found': 'Hasil ditemukan',
  'Results of upload': 'Hasil unggahan',
  'Retweeted count': 'Jumlah retweet',
  Roaming: 'Jelajah',
  'Roaming Provider': 'Penyedia Jelajah',
  Robbery: 'Perampokan',
  'Robot has not been canceled': 'Robot belum dibatalkan',
  Roles: 'Tugas',
  Romania: 'Rumania',
  'Rows per page': 'Baris per halaman',
  'Rows per page:': 'Baris per halaman:',
  Run: 'Lari',
  'Run method was successfully started': 'Metode jalankan berhasil dimulai',
  Running: 'Berlari',
  Russia: 'Rusia',
  Rwanda: 'Rwanda',
  'SAFE SEARCH': 'PENCARIAN AMAN',
  'SIMILAR IMAGES': 'GAMBAR SERUPA',
  SMS: 'SMS',
  'SMS & calls': 'SMS & panggilan',
  'SMS activity': 'Aktivitas SMS',
  'SMS in': 'SMS masuk',
  'SMS out': 'SMS keluar',
  STATISTICS: 'STATISTIK',
  'Safety Cameras': 'Kamera Keamanan',
  'Sahrawi Arab Democratic Republic': 'Sahrawi Republik Demokratik Arab',
  'Saint Helena, Ascension and Tristan da Cunha':
    'Saint Helena, Ascension dan Tristan da Cunha',
  'Saint Kitts and Nevis': 'Saint Kitts dan Nevis',
  'Saint Lucia': 'Saint Lucia',
  'Saint Vincent and the Grenadines': 'Saint Vincent dan Grenadines',
  'Same location': 'Lokasi yang sama',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  Satellite: 'Satelit',
  Saturday: '',
  'Saudi Arabia': 'Arab Saudi',
  Save: 'Simpan',
  'Save and Locate': 'Simpan dan Temukan',
  'Save area': 'Simpan area',
  'Save area of Interest': 'Simpan area yang diminati',
  'Save as a list from': 'Simpan sebagai daftar dari',
  'Save changes': 'Simpan perubahan',
  'Save data to target': 'Simpan data ke target',
  'Save result': 'Simpan hasil',
  'Saved!': 'Diselamatkan!',
  'Saving data': 'Menyimpan data',
  'Scan QR with your device to generate OTP codes':
    'Pindai QR dengan perangkat Anda untuk menghasilkan kode OTP',
  'Scanned and added to feed': 'Dipindai dan ditambahkan ke umpan',
  Scars: 'Bekas luka',
  Schedule: 'Jadwal',
  'Schedule has been created': 'Jadwal telah dibuat',
  Scheduled: 'dijadwalkan',
  Scheduler: 'Penjadwal',
  'Scrapping photos': 'Menghapus foto',
  'Screen name': 'Nama layar',
  Search: 'Mencari',
  Searches: '',
  'Search & geolocate phone': '',
  'Search & locate phone': 'Cari & temukan telepon',
  'Search Google Maps': 'Cari Google Maps',
  'Search MSISDNs': 'Cari MSISDNs',
  'Search again': 'Mencari lagi',
  'Search by': 'Cari berdasarkan',
  'Search by face and name': 'Cari berdasarkan wajah dan nama',
  'Search by keyword': 'Cari menggunakan kata kunci',
  'Search by name': 'Cari berdasarkan nama',
  'Search by phone number': 'Cari berdasarkan nomor telepon',
  'Search entities': 'Entitas pencarian',
  'Search graph': 'Grafik penelusuran',
  'Search history': 'Riwayat penelusuran',
  'Search in results': '',
  'Search keyword': 'Cari Kata Kunci',
  'Search name, MSISDNs or cases': 'Cari nama, MSISDNs, atau kasus',
  'Search query': 'Permintaan pencarian',
  'Search tips': 'Tips pencarian',
  'Search type': 'Jenis pencarian',
  'Search virtual ID ': 'Cari ID virtual ',
  'Searched sources': 'Sumber yang dicari',
  'Searched sources for': 'Mencari sumber untuk',
  'Searching closed databases': 'Mencari basis data tertutup',
  'Searching dark web': 'Mencari dark web',
  'Searching instant message profiles': 'Mencari profil pesan instan',
  'Searching open databases': 'Mencari basis data terbuka',
  'Searching open web': 'Mencari web terbuka',
  'Searching social media': 'Mencari media sosial',
  'Searching social profiles': 'Mencari profil sosial',
  See: 'Lihat',
  'See a demo investigation ': 'Lihat demo investigasi',
  'See all photos': 'Lihat semua foto',
  'See less': 'Lihat lebih sedikit',
  'See less results': 'Lihat lebih sedikit hasil',
  'See more': 'Lihat lebih banyak',
  'See more results': 'Lihat hasil lainnya',
  'See search history': 'Lihat riwayat penelusuran',
  'Seed copied': 'sumber disalin',
  Select: 'Pilih',
  'Select Dates': 'Pilih Tanggal',
  'Select File': 'Pilih file',
  'Select MSISDN': 'Pilih MSISDN',
  'Select Social Profile': 'Pilih Profil Sosial',
  'Select a range of max 3 days': 'Pilih rentang waktu maksimal 3 hari',
  'Select a target to update': 'Pilih target untuk diperbarui',
  'Select a value': 'Pilih nilai',
  'Select a virtual ID or proceed to the next step to create a new one':
    'Pilih ID virtual atau lanjutkan ke langkah berikutnya untuk membuat yang baru',
  'Select accounts': '',
  'Select action': 'Pilih tindakan',
  'Select age': 'Pilih usia',
  'Select all': 'Pilih semua',
  'Select all available': 'Pilih semua yang tersedia',
  'Select analysis': 'Pilih analisis',
  'Select area': 'Pilih area',
  'Select at least one period file': 'Pilih setidaknya satu periode file',
  'Select atleast one IMEI/Phone model':
    'Pilih setidaknya satu IMEI / model ponsel',
  'Select case': 'Pilih Kasus',
  'Select case targets': 'Pilih target kasus',
  'Select country': 'Pilih negara',
  'Select date': 'Pilih tanggal',
  'Select date range': '',
  'Select dates': 'Pilih tanggal',
  'Select days': 'Pilih hari',
  'Select entity': 'Pilih entitas',
  'Select file': 'Pilih file',
  'Select frequency': 'Pilih frekuensi',
  'Select from Existing Targets': 'Pilih dari Target yang Ada',
  'Select from pre-registered Virtual ID or create a new one': '',
  'Select gender': 'Pilih jenis kelamin',
  'Select layout': 'Pilih tata letak',
  'Select list': 'Pilih daftar',
  'Select location': 'Pilih lokasi',
  'Select more targets': 'Pilih lebih banyak target',
  'Select node to view details': 'Pilih node untuk melihat detail',
  'Select one of multiple msisdns': 'Pilih salah satu dari beberapa msisdns',
  'Select one or multiple imsis': 'Pilih satu atau beberapa imsi',
  'Select one or multiple models': 'Pilih satu atau beberapa model',
  'Select one or multiple msisdns': 'Pilih satu atau beberapa msisdns',
  'Select one or multiple periods': 'Pilih satu atau beberapa periode',
  'Select period': 'Pilih periode',
  'Select platform': 'Pilih platform',
  'Select region': '',
  'Select report type': 'Pilih jenis laporan',
  'Select social accounts': '',
  'Select social network': 'Pilih jejaring sosial',
  'Select target': 'Pilih sasaran',
  'Select targets': 'Pilih target',
  'Select targets from case': 'Pilih target dari kasus',
  'Select the start and end interval for tracking the Ad IDs':
    'Pilih interval awal dan akhir untuk melacak ID',
  'Select timeline analysis': 'Pilih analisis garis waktu',
  'Select timezone': 'Pilih zona waktu',
  'Select type': 'Pilih jenis',
  'Select user': 'Pilih Pengguna',
  'Select/unselect location!': 'Pilih/batalkan pilihan lokasi!',
  Selected: 'Dipilih',
  'Selected date must be in the past!':
    'Tanggal yang dipilih harus sudah lewat!',
  'Selected queue': 'Antrean terpilih',
  Send: 'Mengirim',
  'Send OTP': 'Kirim OTP',
  'Send SMS': 'Kirim SMS',
  'Send friend request': 'Kirim permintaan pertemanan',
  'Send friend requests': 'Kirim permintaan pertemanan',
  'Send friend requests to group members':
    'Kirim permintaan pertemanan ke anggota grup',
  'Send us your feedback!': 'Kirimkan tanggapan Anda kepada kami!',
  Senegal: 'Senegal',
  'Sent Group Messages': 'Pesan Grup Terkirim',
  'Sent Private Messages': 'Mengirim Pesan Pribadi',
  'Sent group messages': 'Pesan grup terkirim',
  'Sent private messages': 'Mengirim pesan pribadi',
  'Sent to groups': 'Dikirim ke grup',
  'Sent to persons': 'Dikirim ke orang',
  Sentiment: 'Sentimen',
  'Sentiment analysis': 'analisis sentimen',
  Sector: '',
  September: 'September',
  Sequencial: 'Berurutan',
  Sequential: 'Berurutan',
  Serbia: 'Serbia',
  Session: 'Sidang',
  'Session Timeout': 'Batas Waktu Sesi',
  'Session end': 'Sesi berakhir',
  'Session has expired!': 'Sesi telah berakhir!',
  'Session length': 'Durasi sesi',
  'Session start': 'Sesi dimulai',
  'Session started on the': 'Sidang dimulai pada',
  'Session will expire in': 'Sesi akan berakhir pada',
  'Set Alerts': '',
  'Set notification for social activity': '',
  'Set notifications for social activity':
    'Set notifications for social activity',
  'Set social activity alerts': '',
  Settings: 'Pengaturan',
  Severity: 'Keparahan',
  Seychelles: 'Seychelles',
  'Share in common': 'Berbagi kesamaan',
  'Share post': 'Bagikan postingan',
  Shared: 'Dibagikan',
  Shortest: 'Terpendek',
  'Shortest call': 'Panggilan terpendek',
  'Show #{count} more': 'Tunjukkan #{count} lainnya',
  'Show Release Notes': 'Tampilkan Catatan Rilis ',
  'Show all': 'Tampilkan semua',
  'Show archived': 'Tampilkan diarsipkan',
  'Show batch query': 'Tampilkan kueri batch',
  'Show call log details': 'Tampilkan detail log panggilan',
  'Show call log map': 'Tampilkan peta log panggilan',
  'Show common IDs': 'Tampilkan ID umum',
  'Show degrees': 'Mostrar grados',
  'Show direct interactions between selected MSISDNs': '',
  'Show existing intel': 'Tampilkan intel yang ada',
  'Show history': 'Tampilkan riwayat',
  'Show international interactions': '',
  'Show less': 'Tampilkan lebih sedikit',
  'Show less cases': 'Tampilkan lebih sedikit kasus',
  'Show Mission Overview': 'Tampilkan Ikhtisar Misi',
  'Show more': 'Menampilkan lebih banyak',
  'Show more cases': 'Tampilkan lebih banyak kasus',
  'Show nearby Telegram users': 'Tampilkan pengguna Telegram terdekat',
  'Show nodes that came in contact with the target by distance of':
    'Tampilkan node yang bersentuhan dengan target berdasarkan jarak',
  'Show nodes that interacted with the target by':
    'Tampilkan node yang berinteraksi dengan target dengan ',
  'Show on map': 'Tampilkan di peta',
  'Show only articles with': 'Hanya tampilkan artikel dengan',
  'Show only posts with': 'Hanya tampilkan postingan dengan ',
  'Show only profiles with': 'Tampilkan hanya profil dengan',
  'Show only starred': 'Tampilkan hanya berbintang',
  'Show predicted': 'Tampilkan prediksi',
  'Show query details': 'Tampilkan detail kueri',
  'Show query history': 'Tampilkan riwayat kueri',
  'Show search': 'Tampilkan pencarian',
  'Show statistics': 'Tampilkan statistik',
  'Show top associates': '',
  'Show unique IDs': 'Tampilkan ID unik',
  'Show updates': 'Tampilkan pembaruan',
  'Sierra Leone': 'Sierra Leone',
  'Sign In': 'Masuk ',
  'Sign out': 'Keluar',
  Sim: 'Sim',
  'Simple OSINT': 'OSINT Sederhana',
  'Simple Query': 'Kueri Sederhana',
  'Simple details': 'Detail sederhana',
  Singapore: 'Singapura',
  'Site Name': 'Nama situs',
  'Site properties': 'Properti situs',
  Skills: 'Keterampilan',
  'Skin tone': 'Warna kulit',
  Skip: 'Melewati',
  'Skip filtering': '',
  Skype: 'Skype',
  'Sleeping time': 'Waktu tidur',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  Sms: 'Sms',
  Social: 'Sosial',
  'Social Activity': '',
  'Social Insurance': 'Asuransi sosial',
  'Social Media': 'Media sosial',
  'Social Media Profiles': 'Profil Media Sosial',
  'Social Profile': 'Profil Sosial',
  'Social URL': 'URL Sosial',
  'Social URLs': 'URL Sosial',
  'Social Urls': 'Url Sosial',
  'Social accounts': 'Akun sosial',
  'Social activity': '',
  'Social groups': 'Kelompok sosial',
  'Social insurance number': 'Nomor asuransi sosial',
  'Social network': 'Jejaring sosial',
  'Social network top associates': 'Jaringan sosial top associates',
  'Social networks': 'Jejaring sosial',
  'Social profile': 'Profil sosial',
  'Social profile details': 'Detail profil sosial',
  'Social profiles': 'Profil sosial',
  'Social relation': 'Hubungan sosial',
  'Social word cloud': 'Social word cloud',
  'Software Information': 'Informasi Perangkat Lunak',
  'Solomon Islands': 'Pulau Solomon',
  Somalia: 'Somalia',
  Somedus: 'Somedus',
  'Somedus Request Submitted': 'Beberapa Permintaan US Diajukan',
  'Somedus retrived information': 'Somedus mengambil kembali informasi',
  'Somedus will be updated on 12:00': 'Somedus akan diperbarui pada 12:00',
  'Something went wrong': 'Ada yang tidak beres',
  'Something went wrong please try again later!':
    'Ada yang tidak beres silakan coba lagi nanti!',
  'Something went wrong with File Text Analysis':
    'Ada yang salah dengan Analisis Teks File',
  'Something went wrong with the photo. Please try again.':
    'Ada yang tidak beres dengan fotonya. Silakan coba lagi.',
  'Something went wrong, please try again.':
    'Ada yang tidak beres, silakan coba lagi.',
  'Something went wrong.': 'Ada yang salah.',
  'Something went wrong. Please try OTP reset again.':
    'Ada yang tidak beres. Silakan coba reset OTP lagi.',
  'Something went wrong. Please try again.':
    'Ada yang salah. Silakan coba lagi.',
  Sort: 'Menyortir',
  'Sort by': 'Urutkan berdasarkan',
  Source: 'Sumber',
  Sources: 'Sumber',
  'South Africa': 'Afrika Selatan',
  'South Georgia and the South Sandwich Islands':
    'Georgia Selatan dan Kepulauan Sandwich Selatan',
  'South Korea': 'Korea Selatan',
  'South Sudan': 'Sudan Selatan',
  Spain: 'Spanyol',
  Spanish: 'Orang Spanyol',
  'Special characters and trailing spaces are not allowed':
    'Karakter khusus dan spasi tambahan tidak diperbolehkan',
  'Special characters are not allowed': 'Karakter khusus tidak diperbolehkan',
  Specific: 'Spesifik',
  'Specific actions': 'Tindakan spesifik',
  'Split View': 'Tampilan Terpisah',
  'Sri Lanka': 'Srilanka',
  Standard: 'Standar',
  'Starred only': '',
  Start: 'Awal',
  'Start Date': 'Mulai tanggal',
  'Start Investigation': 'Mulai Investigasi',
  'Start Scheduler': 'Mulai Penjadwal',
  'Start date': '',
  'Start monitoring': 'Mulai pemantauan',
  'Start now': 'Mulai sekarang',
  'Start scheduler': 'Mulai penjadwal',
  'Start typing job description': 'Mulai mengetik deskripsi pekerjaan',
  'Start typing phone, name, CURP, url, email or username or upload photo':
    'Mulai ketik telepon, nama, CURP, url, email atau nama pengguna atau unggah foto',
  'Start typing phone, name, url, email or username or upload photo':
    'Mulai ketik telepon, nama, url, email atau nama pengguna atau unggah foto',
  'Start typing your keyword': 'Mulai ketik kata kunci Anda',
  'Start your investigation': '',
  'Started at': 'Dimulai dari',
  Starting: 'Memulai',
  Statistics: 'Statistik',
  Status: 'Status',
  'Status ': 'Status ',
  'Status of last 5 searches': '',
  Step: 'Langkah',
  Stop: 'Berhenti',
  'Stop Geofencing Task': 'Hentikan Tugas Geofencing',
  'Stop following': 'Berhenti mengikuti',
  'Stop method was successfully stopped': 'Metode Stop berhasil dihentikan',
  'Stop monitoring': 'Hentikan pemantauan',
  Stopped: 'Berhenti',
  Structural: 'Struktural',
  Studied: 'Dipelajari',
  Submit: 'Kirim',
  Subscription: 'Berlangganan',
  'Succesfull ExtractusD Operation': 'Operasi ExtractusD berhasil Berhasil',
  'Success!': 'Sukses!',
  Successfully: 'Berhasil',
  'Successfully inserted': 'Berhasil disisipkan',
  Sudan: 'Sudan',
  Summary: 'Ringkasan',
  'Summary report': 'Laporan ringkasan',
  Sunday: 'Minggu',
  'Support request': 'Permintaan dukungan',
  'Supported file types': 'Jenis file yang didukung',
  Suriname: 'Suriname',
  Suspicious: 'Mencurigakan',
  'Suspicious Words': 'Kata-kata Mencurigakan',
  Swaziland: 'Swaziland',
  Sweden: 'Swedia',
  'Switch to dark theme': 'Beralih ke tema gelap',
  'Switch to detail view': 'Beralih ke tampilan detail',
  'Switch to light theme': 'Beralih ke tema terang',
  'Switch to standard view': 'Beralih ke tampilan standar',
  Switzerland: 'Swiss',
  Syria: 'Suriah',
  'São Tomé and Príncipe': 'São Tomé dan Príncipe',
  TMSI: 'TMSI',
  Tactical: 'Taktis',
  'Tagged In Same Photo': 'Ditandai Di Foto Yang Sama',
  'Tagged by others': 'Ditandai oleh orang lain',
  'Tagged in': 'Tandai dalam',
  'Tagged in photos of': 'Ditandai pada foto',
  'Tagged in posts of': 'Ditandai pada postingan',
  'Tagged photos owners': 'Pemilik foto yang ditandai',
  'Tagged posts owners': 'Pemilik postingan yang ditandai',
  Taiwan: 'Taiwan',
  Tajikistan: 'Tajikistan',
  Tanzania: 'Tanzania',
  Target: 'Target',
  'Target Activity': 'Aktivitas target',
  'Target Details': '',
  'Target Edit': 'Pengeditan Sasaran',
  'Target Enriched successfully': 'Target Enriched successfully',
  'Target Expired': 'Target Kedaluwarsa',
  'Target Infected': 'Target Terinfeksi',
  'Target LastSeen': 'Target Terakhir dilihat',
  'Target Profile': 'Profil Target',
  'Target called': 'Sasaran disebut',
  'Target cannot be renewed. Not enough Credits':
    'Target tidak dapat diperpanjang. Kredit tidak cukup',
  'Target case': 'Kasus sasaran',
  'Target created successfully!': 'Target berhasil dibuat!',
  'Target details': 'Detail target',
  'Target edited successfully!': 'Target berhasil diedit!',
  'Target export call log report': 'Target ekspor laporan log panggilan',
  'Target has been successfully deleted': 'Target telah berhasil dihapus',
  'Target has not been created': 'Target belum dibuat',
  'Target has not been deleted': 'Target belum dihapus',
  'Target has not been edited': 'Target belum diedit',
  'Target has not been renewed': 'Target belum diperbarui',
  'Target has not been renewed, Not enough credits':
    'Target belum diperpanjang, Kredit belum cukup',
  'Target has not been update': 'Target belum diperbarui',
  'Target is Expired': 'Target Kedaluwarsa',
  'Target is already assigned to the case!':
    'Target sudah ditetapkan untuk kasus ini!',
  'Target is assigned to this case': 'Target ditetapkan untuk kasus ini',
  'Target is connected to the network': 'Target terhubung ke jaringan',
  'Target is currently on a call': 'Target saat ini sedang menelepon',
  'Target is expired': 'Target kedaluwarsa',
  'Target locations': 'Lokasi target',
  'Target messaged': 'Target mengirim pesan',
  'Target number': 'Nomor sasaran',
  'Target peer interactions': 'Targetkan interaksi teman sebaya',
  'Target received a call from': 'Target menerima telepon dari',
  'Target received a message from': 'Target menerima pesan dari',
  'Target renewed successfully!': 'Target berhasil diperbarui!',
  'Target summary': 'Ringkasan target',
  'Target summary report': 'Laporan ringkasan target',
  'Target updated successfully!': 'Target berhasil diperbarui!',
  'Target {target} has a new post': '',
  'Target {target} has a new post with keyword(s)': '',
  Targets: 'Banyak Target',
  'Targets added successfully!': 'Target berhasil ditambahkan!',
  'Targets are now here': '',
  'Targets assigned': 'Target yang ditetapkan',
  'Targets renewed successfully!': 'Target berhasil diperbarui!',
  'Targets unassigned': 'Target belum ditetapkan',
  'Target’s subscription is active': 'Langganan Target aktif',
  'Target’s subscription is active but currently not connected to the network':
    'Langganan target aktif tetapi saat ini tidak terhubung ke jaringan',
  'Target’s subscription is blocked': 'Langganan Target diblokir',
  'Target’s subscription is not activated': 'Langganan Target tidak diaktifkan',
  'Task ID': 'ID Tugas',
  'Task Id': 'Tugas Id',
  'Task for': 'Tugas untuk',
  'Task has been successfully canceled': 'Tugas telah berhasil dibatalkan',
  'Task has not been canceled': 'Tugas belum dibatalkan',
  Tasks: 'Tugas',
  Telegram: 'Telegram',
  'Telegram nearby': 'Telegram terdekat',
  'Telegram users cannot be located now!':
    'Pengguna Telegram tidak dapat ditemukan sekarang!',
  'Telegram users located successfully!':
    'Pengguna Telegram berhasil ditemukan!',
  Telnos: '',
  'Temporary password for #{username}':
    'Kata sandi sementara untuk #{username}',
  'Temporary password sent to: #{email}':
    'Kata sandi sementara dikirim ke: #{email}',
  Tenant: 'Penyewa',
  'Tenant balance': 'Saldo penyewa',
  Text: 'Teks',
  'Text Analysis': 'Analisis Teks',
  'Text analysis': 'Analisis teks',
  'Text analysis the file': 'Analisis teks file',
  Thailand: 'Thailand',
  'Thank you for your message. Your customer success representative will contact you shortly':
    'Terima kasih atas pesan Anda. Perwakilan Customer Success Anda akan segera menghubungi Anda',
  'The Bahamas': 'Bahama',
  'The Cell ID cannot be extracted for':
    'Cell ID tidak dapat diekstraksi untuk',
  'The Netherlands': 'Belanda',
  'The OSINT action for': 'Tindakan OSINT untuk',
  'The files once deleted cannot be restored.':
    'File yang sudah dihapus tidak dapat dikembalikan.',
  'The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:':
    '#{clParameterType} yang dimasukkan sebagian tidak valid. Harap perbarui permintaan Anda dengan #{clParameterType} berikut:',
  'The last x days': 'X hari terakhir',
  'The monitor is collecting data, it will be presented as soon as it is discovered':
    'Monitor sedang mengumpulkan data, itu akan ditampilkan segera setelah ditemukan',
  'The password was changed!': 'Kata sandinya diubah!',
  'The password was reset!': 'Kata sandinya disetel ulang!',
  'The password will be sent to the email address #{email}. Is this address correct?':
    'Kata sandi akan dikirim ke alamat email #{email}. Apakah alamat ini benar?',
  'The quota is changed by #{currentUser}': 'Kuota diubah oleh #{currentUser}',
  'The quota is changed by #{currentUser} - #{quotaType}':
    'Kuota diubah oleh #{currentUser} - #{quotaType}',
  'The request call log is not currently supported for this country':
    'Log panggilan permintaan saat ini tidak didukung untuk negara ini',
  'The requested phone number is not valid with this country code. Please select a different country code from the dropdown menu and try again.':
    '',
  'The system will generate a new Avatar': '',
  'The target changes have been saved and analysis is started. Click':
    'Perubahan target telah disimpan dan analisis dimulai. Klik',
  'The target changes have been saved. Click':
    'Perubahan target telah disimpan. Klik',
  'The whole Facebook registration flow completed':
    'Seluruh alur pendaftaran Facebook selesai',
  'Then you have the ability to:': 'Maka Anda memiliki kemampuan untuk:',
  'There are no MSISDNs added. Please add a':
    'Tidak ada MSISDNs yang ditambahkan. Silakan tambahkan',
  'There are no avatars created. Please add a new avatar':
    'Tidak ada avatar yang dibuat. Silakan tambahkan avatar baru',
  'There are no cases created. Please add a':
    'Tidak ada kasus yang dibuat. Silakan tambahkan',
  'There are no investigations added. Please create a ': '',
  'There are no investigations added. Please create a new investigation':
    'Tidak ada investigasi yang ditambahkan. Harap buat investigasi baru',
  'There are no ip created': 'Tidak ada ip yang dibuat',
  'There are no suspicious words': 'Tidak ada kata-kata yang mencurigakan',
  'There are no targets created. Please add a':
    'Tidak ada target yang dibuat. Silakan tambahkan',
  'There are no virtual IDs assigned to your user':
    'Tidak ada ID virtual yang ditetapkan untuk pengguna Anda',
  'This Virtual ID will expire on': 'ID Virtual ini akan kedaluwarsa pada',
  'This action might leave anonymous traces on target’s phone.':
    'Tindakan ini mungkin meninggalkan jejak anonim di ponsel target.',
  'This action will create a new target and request call log for':
    'Tindakan ini akan membuat target baru dan meminta log panggilan untuk',
  'This action will create the Locate query that is associated with':
    'Tindakan ini akan membuat kueri Cari yang dikaitkan, dengan ',
  'This action will deduct 2 credits.':
    'Tindakan ini akan mengurangi 2 kredit.',
  'This action will deduct 3 credits.':
    'Tindakan ini akan mengurangi 3 kredit.',
  'This action will deduct 4 credits. This may take up to 30 min.':
    'Tindakan ini akan mengurangi 4 SKS. Ini mungkin memakan waktu hingga 30 menit.',
  'This action will enable Call log analysis':
    'Tindakan ini akan mengaktifkan analisis log panggilan',
  'This action will fetch all the Basic OSINT that are associated with':
    'Tindakan ini akan mengambil semua OSINT Dasar yang terkait, dengan',
  'This action will fetch call log information for':
    'Tindakan ini akan mengambil informasi log panggilan untuk',
  'This action will locate the': 'Tindakan ini akan menemukan',
  'This action will purchase and import call log for':
    'Tindakan ini akan membeli dan mengimpor log panggilan untuk',
  'This action will purchase the call logs for':
    'Tindakan ini akan membeli log panggilan untuk',
  'This action will request Call Log for':
    'Tindakan ini akan meminta Log Panggilan untuk',
  'This action will show the accurate position of the Telegram nearby people':
    'Tindakan ini akan menunjukkan posisi akurat Telegram orang terdekat',
  'This associate frequently interacts with the target.':
    'Rekan ini sering berinteraksi dengan target.',
  'This associate possibly met with the target.':
    'Rekan ini mungkin bertemu dengan target.',
  'This case has no targets': 'Kasus ini tidak memiliki target',
  'This case needs at least 2 targets to find common information':
    'Kasus ini membutuhkan setidaknya 2 target untuk menemukan informasi umum',
  'This is a 24hrs temporary user': 'Ini adalah pengguna sementara 24 jam',
  'This monitor did not collect any data':
    'Monitor ini tidak mengumpulkan data apa pun',
  'This option will be available for': 'Opsi ini akan tersedia untuk',
  'This post contains': 'Postingan ini berisi',
  'This process might take approximately':
    'Proses ini mungkin memakan waktu sekitar',
  Thursday: '',
  Tiktok: 'Tiktok',
  Time: 'Waktu',
  'Time Zone': 'Zona waktu',
  'Time left to expiration:': 'Waktu tersisa hingga kedaluwarsa:',
  'Time of collection': 'Waktu pengumpulan',
  'Time of the query': 'Waktu kueri',
  'Time remaining': 'Sisa waktu',
  'Time stamp': 'Time stamp',
  'Timebar type': 'Jenis bilah waktu',
  Timegraph: '',
  timeline: '',
  Timeline: 'Linimasa',
  'Timeline pattern': 'Pola timeline',
  Title: 'Judul',
  'Title content': 'Judul konten',
  'Title exists. Please use another title':
    'Judul ada. Silakan gunakan judul lain',
  'Title is required': 'Judul diperlukan',
  To: 'Ke',
  'To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.':
    'Untuk mencapai hal ini, kami meminta Anda untuk berpartisipasi dalam program pembuatan umpan balik pengguna di mana sesekali kami akan mengajukan pertanyaan sederhana kepada Anda tentang cara Anda menggunakan Intellectus.',
  'To import, please select one or multiple days':
    'Untuk mengimpor, silakan pilih satu atau beberapa hari',
  'To search for a phone number, enter the complete number, including country code. Example: #{example}':
    'Untuk mencari nomor telepon, masukkan nomor lengkap, termasuk kode negara. Contoh: #{example}',
  'To start a monitor': 'Untuk memulai monitor',
  Today: 'Hari ini',
  'Toggle map': 'Alihkan peta',
  'Toggle Mission Overview': '',
  'Toggle photo': 'Alihkan foto',
  Togo: 'Untuk pergi',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  'Tool to analyze different types of intelligence':
    'Alat untuk menganalisis berbagai jenis kecerdasan',
  'Top 5 contacts': '5 kontak teratas',
  'Top Associates': 'Associates teratas',
  'Top Commenteers In Facebook Photos': 'Komentator Teratas Di Foto Facebook',
  'Top Commenteers In Facebook Posts':
    'Komentator Teratas di Postingan Facebook',
  'Top Device Attributes': 'Atribut Perangkat Teratas',
  'Top Facebook Connections': 'Koneksi Facebook Teratas',
  'Top Facebook Photo Shares': 'Foto Facebook yang dibagikan Teratas',
  'Top Facebook Post Shares': 'Postingan Facebook yang dibagikan Teratas',
  'Top Keywords': 'Kata Kunci Teratas',
  'Top Likers In Facebook Photos':
    'Orang yang menyukai Teratas di Foto Facebook',
  'Top Likers In Facebook Posts':
    'Penyuka paling atas Dalam Postingan Facebook',
  'Top Locations': 'Lokasi Teratas',
  'Top Relations': 'Hubungan Teratas',
  'Top associates': 'Rekan kerja teratas',
  'Top connection': 'Koneksi teratas',
  'Top locations': 'Lokasi teratas',
  'Top profiles': 'Profil teratas',
  'Top relationships': 'Hubungan teratas',
  'Top target locations': 'Lokasi target teratas',
  Topic: 'Tema',
  Topics: 'Topik',
  'Total Parsed': 'Total Diurai',
  'Total calls': 'Total panggilan',
  'Total credits': 'Total kredit',
  'Total credits deducted': 'Total kredit dikurangi',
  'Total credits reserved': 'Total kredit yang dipesan',
  'Total distance': 'Total jarak',
  'Total duration': 'Total durasi',
  'Total monitor time': 'Total waktu monitor',
  'Total number of matches': 'Jumlah total pertandingan',
  'Total number of sessions': 'Jumlah total sesi',
  'Total queries': 'Total kueri  ',
  'Total successful queries': 'Total kueri yang berhasil',
  Trace: 'Lacak',
  'Track period': '',
  'Trilateration query completed!': 'Kueri trilateration selesai!',
  'Trinidad and Tobago': 'Trinidad dan Tobago',
  'True Caller': 'True Caller',
  Truecaller: 'Truecaller',
  'Truecaller profile location': 'Lokasi profil Truecaller',
  'Try another search': 'Coba pencarian lain',
  'Try different or less filters':
    'Coba filter yang berbeda atau lebih sedikit',
  'Try fewer keywords': 'Coba lebih sedikit kata kunci',
  Tuesday: '',
  Tunisia: 'Tunisia',
  Turkey: 'Turki',
  Turkmenistan: 'Turkmenistan',
  'Turks and Caicos Islands': 'Kepulauan Turks dan Caicos',
  'Turn Off': 'Matikan',
  'Turn On': 'Nyalakan',
  Tuvalu: 'Tuvalu',
  Tweak: 'Tweak',
  Twitter: 'Twitter',
  'Twitter hashtag': 'Tagar Twitter',
  Type: 'Jenis',
  'Type a name': 'Ketik nama',
  'Type any term you would like to add':
    'Ketik syarat apa pun yang ingin Anda tambahkan',
  'Type of data': '',
  'Type keyword': '',
  'Type keywords you wish to follow': '',
  'Type value here': 'Ketik nilai di sini',
  UNKNOWN: 'TIDAK DIKETAHUI',
  URL: 'URL',
  'USER EQUIPMENT': 'Perlengkapan Pengguna / Perlengkapan User',
  USERNAME: 'Nama user / Nama Pengguna',
  Uganda: 'Uganda',
  Ukraine: 'Ukraina',
  Unauthorized: 'Tanpa izin',
  Unavailable: 'Tidak tersedia',
  'Uncover usernames, open web mentions, email and social media accounts':
    'Temukan nama pengguna, sebutan web terbuka, email, dan akun media sosial',
  'Uncover usernames, open web mentions, email and social media accounts that are associated with':
    'Temukan nama pengguna, sebutan web terbuka, email, dan akun media sosial yang terkait dengan',
  Unfollow: 'Berhenti mengikuti',
  'Unique entries': 'Entri unik',
  'United Arab Emirates': 'Uni Emirat Arab',
  'United Kingdom': 'Britania Raya',
  'United States': 'Amerika Serikat',
  'United States of America': 'Amerika Serikat',
  Unknown: 'Tidak dikenal',
  'Unknown type': 'Jenis tidak dikenal',
  'Unlike group posts': 'Postingan grup tidak disukai',
  'Unlike posts': 'Postingan tidak disukai',
  Unlimited: 'Tidak terbatas',
  Unlock: 'Membuka kunci',
  Unmapped: '',
  'Unmerge results': 'Hasil tidak digabungkan',
  'Unpin this search': 'Lepas sematan pencarian ini',
  Unreachable: 'Tidak dapat dijangkau',
  'Unselect all': 'Batalkan pilihan semua',
  Unspecified: 'Tidak ditentukan',
  'Unveil movement patterns and understand your target’s habits':
    'Mengungkap pola gerakan dan memahami kebiasaan target Anda',
  'Unveil movement patterns and understand your target’s habits and associates':
    'Mengungkap pola gerakan dan memahami kebiasaan dan rekan target Anda',
  Update: 'Memperbarui',
  'Update existing target': 'Perbarui target yang ada',
  'Update link analysis': 'Perbarui analisis tautan',
  'Update target': 'Perbarui target',
  'Update the graph': 'Perbarui grafik',
  'Upload Call Log': 'Unggah Log Panggilan',
  'Upload Call Log by selecting one of the following options':
    'Unggah Log Panggilan dengan memilih salah satu opsi berikut',
  'Upload another file': 'Unggah file lain',
  'Upload call log': 'Unggah log panggilan',
  'Upload call log for: #{value}': 'Unggah log panggilan untuk: #{value}',
  'Upload existing call': 'Unggah panggilan yang ada',
  'Upload existing call log': 'Unggah log panggilan yang ada',
  'Upload image': 'Unggah gambar',
  'Upload photo': 'Mengunggah foto',
  'Uploaded on': 'Diunggah pada',
  Url: 'Url',
  Usage: 'Penggunaan',
  'Use at least 8 characters. Don’t use a password from another site, or something too obvious':
    'Gunakan setidaknya 8 karakter. Jangan gunakan kata sandi dari situs lain, atau sesuatu yang terlalu jelas',
  'Use comma to separate the MSISDNs.':
    'Gunakan koma untuk memisahkan MSISDNs.',
  'Use comma or enter to add MSISDNs or IMSIs': '',
  'Use the search filters': 'Gunakan filter pencarian',
  'Use this option to search for a pre-existing Virtual ID suitable to your mission, or create a new Virtual ID':
    '',
  'Used by': 'Digunakan oleh',
  User: 'Pengguna',
  'User Equipment': 'Peralatan Pengguna / Peralatan User',
  'User ID': 'ID Pengguna',
  'User Management': 'Manajemen Pengguna / Manajemen User',
  'User created with': 'Pengguna dibuat dengan',
  'User edited successfully!':
    'Pengguna berhasil diedit / user berhasil diedit',
  'User equipment': 'Peralatan pengguna',
  'User has been successfully deleted':
    'Pengguna / user telah berhasil dihapus',
  'User has no wallets': '',
  'User has not been created': "Pengguna belum dibuat' / User belum dibuat",
  'User has not been edited': 'Penggina belum diedit / user belum diedit',
  'User locked successfully!': 'Pengguna berhasil dikunci!',
  'User management': 'Manajemen pengguna',
  'User manual': 'Panduan pengguna',
  'User not found': 'Pengguna tidak ditemukan',
  'User options': 'Opsi pengguna',
  'User settings': 'Pengaturan pengguna',
  'User unlocked successfully!': 'Pengguna berhasil membuka kunci!',
  'User:': '',
  Username: 'Nama User',
  'Username already exists. Please try again.':
    'Nama pengguna sudah ada. Silakan coba lagi.',
  'Username must contain at least one letter':
    'Nama pengguna harus mengandung setidaknya satu huruf',
  'Username(s)': 'Nama pengguna',
  Usernames: 'Nama User / Nama pengguna',
  Users: 'Pengguna / user',
  'Valid email entered': 'Email yang valid dimasukkan',
  'Valid phone number entered.': 'Nomor telepon yang valid dimasukkan.',
  Value: 'Nilai',
  'Vehicle ID': 'ID Kendaraan',
  'Vehicle ID (VIN)': 'ID Kendaraan (VIN)',
  'Vehicle make': 'Pembuatan kendaraan',
  'Vehicle model': 'Model kendaraan',
  'Vehicle models': 'Model kendaraan',
  'Vehicle year': 'Tahun kendaraan',
  Vendor: 'Penjual',
  Verified: 'Diverifikasi',
  Video: 'Video',
  Videos: 'Video',
  View: 'Tampilan',
  'View All History': '',
  'View Call Log analysis': 'Lihat analisis Log Panggilan',
  'View Cases': 'Lihat Kasus',
  'View Dashboard': 'Lihat Dasbor',
  'View Log': 'Lihat Log',
  'View Targets': 'Lihat Target',
  'View all commenters': 'Lihat semua pemberi komentar',
  'View all likers': 'Lihat semua penyuka',
  'View all queries': 'View all queries',
  'View call log': 'Lihat log panggilan',
  'View call log analysis': 'Lihat analisis log panggilan',
  'View details': 'Melihat rincian',
  'View full profile': 'Lihat profil lengkap',
  'View geolocation details': 'Lihat detail geolokasi',
  'View in Link Analysis': 'Lihat dalam Analisis Tautan',
  'View in fullscreen': 'Lihat dalam layar penuh',
  'View in link analysis': 'Lihat dalam analisis tautan',
  'View item': 'Lihat item',
  'View less': 'Lihat lebih sedikit',
  'View map': 'Lihat peta',
  'View more': 'Lihat lebih banyak',
  'View note details': 'Lihat detail catatan',
  'View on map': 'Lihat di peta',
  'View in Google maps': '',
  'View original post': 'Lihat postingan asli',
  'View photo': 'Lihat foto',
  'View place': 'Lihat tempat',
  'View post': 'Lihat postingan',
  'View post details': 'Lihat detail postingan',
  'View profile': 'Lihat profil',
  'View results': 'Lihat hasil',
  'View session details': 'Lihat detail sesi',
  'View Settings': '',
  'View short profile': 'Lihat profil singkat',
  'View target': 'Lihat target',
  'View the list': 'Lihat daftar',
  'View the results': 'Lihat hasilnya',
  'View the target': 'Lihat targetnya',
  Violence: 'Kekerasan',
  'Virtual ID': 'Virtual ID',
  'Virtual ID  management': 'Manajemen ID Virtual',
  'Virtual ID #{fullName} expired on #{formatedDate}. Click on the button below to renew for #{numberOfDays} days.':
    'ID Virtual #{fullName} kedaluwarsa pada #{formatedDate}. Klik tombol di bawah untuk memperbarui selama #{numberOfDays} hari.',
  'Virtual ID could not be renewed. Please try again.':
    'ID Virtual tidak dapat diperpanjang. Silakan coba lagi.',
  'Virtual ID creation wizard': 'Panduan pembuatan ID Virtual',
  'Virtual ID information': 'Informasi ID Virtual',
  'Virtual ID management': 'Manajemen ID Virtual',
  'Virtual ID renewed successfully.': 'ID virtual berhasil diperbarui.',
  'Virtual ID selection': 'Pemilihan ID Virtual',
  'Virtual ID was created': 'ID Virtual telah dibuat',
  'Virtual ID was updated': 'ID Virtual telah diperbarui',
  'Virtual IDs': 'ID Virtual',
  'Virtual IDs have expired': 'ID virtual telah kedaluwarsa',
  'Virtual IDs will expire in 24 hours':
    'ID Virtual akan kedaluwarsa dalam 24 jam',
  'Virtual id': 'Virtual id',
  'Visit Target': 'Kunjungi Target',
  Voice: 'Suara',
  Volunteering: 'Relawan',
  'Volunteering actions': 'Aksi sukarela',
  'Voter ID': 'ID Pemilih',
  'Voter Id': 'Id Pemilih',
  'WEB ENTITIES': 'ENTITAS WEB',
  WEBINT: 'WEBINT',
  'Waiting for CSV to be downloaded': 'Menunggu CSV diunduh',
  'Wall posts': 'Postingan dinding',
  'Wallet addresses': '',
  'Wanted for': 'Dicari untuk',
  Warning: '',
  'We are always looking for the ways to improve our product for it to be even more useful in your daily work.':
    'Kami selalu mencari cara untuk meningkatkan produk kami agar lebih berguna dalam pekerjaan sehari-hari Anda.',
  'We couldn’t find any results for': 'Kami tidak dapat menemukan hasil apapun',
  'We found': 'Kami menemukan',
  "We found an anomaly! This person used the target's mobile device.":
    'Kami menemukan anomali! Orang ini menggunakan perangkat seluler target.',
  'We found available historical data':
    'Kami menemukan data historis yang tersedia',
  'We found available historical data for':
    'Kami menemukan data historis yang tersedia untuk',
  'We found that': 'Kami menemukan bahwa',
  'We have successfully merged all the collected data to a':
    'Kami telah berhasil menggabungkan semua data yang dikumpulkan menjadi',
  'We now support search by CURP ID':
    'Kami sekarang mendukung pencarian berdasarkan CURP ID',
  'We sent a verification code to the email address:':
    'Kami mengirimkan kode verifikasi ke alamat email:',
  'We sent a verification code to the phone number:':
    'Kami mengirimkan kode verifikasi ke nomor telepon:',
  'We were not able to bring company employees at the moment.':
    'Kami tidak dapat membawa karyawan perusahaan saat ini.',
  'We were not able to bring mutual facebook friends at the moment.':
    'Kami tidak dapat membawa teman facebook bersama saat ini.',
  'We will notify you once the enrichment is completed':
    'Kami akan memberi tahu Anda ketika pengayaan selesai',
  Webint: 'Webint',
  'Webint features are disabled. Please contact your customer success representative.':
    'Fitur Webint dinonaktifkan. Silakan hubungi perwakilan Customer Success Anda.',
  'Webint search history': 'Riwayat penelusuran Webint',
  Wednesday: '',
  Week: 'Minggu',
  Weekdays: '',
  Weekend: 'Akhir pekan',
  Weekends: 'Akhir pekan',
  'Weekly activity': 'aktivitas mingguan',
  Weight: 'Berat',
  'What is the objective of your investigation?': '',
  'What type of investigation are you working on?':
    'Jenis investigasi apa yang sedang Anda kerjakan?',
  Whatsapp: 'Whatsapp',
  When: 'Kapan',
  'When using the search filters, the results will be more accurate':
    'Saat menggunakan filter pencarian, hasilnya akan lebih akurat',
  Where: 'Di mana',
  Whitelist: 'Whitelist / Daftar Putih',
  'Whitelist Number': 'Whitelist Nomor / Nomor Daftar Putih',
  'Whitelist Numbers': 'Whitelist Nomor / Nomor Daftar Putih',
  Who: 'Siapa',
  'Word Cloud': 'Word Cloud',
  Wordcloud: 'Wordcloud',
  Work: 'Bekerja',
  'Work Location': 'Tempat kerja',
  'Work Place': 'Tempat kerja',
  'Work Place(s)': 'Tempat Kerja',
  'Work of art': 'Karya seni',
  'Work place': 'Tempat kerja',
  'Work relations': 'Hubungan kerja',
  'Working time': 'Waktu kerja',
  Workplace: 'Tempat kerja',
  Workplaces: 'Tempat Kerja',
  'Would you like to a create a schedule': 'Apakah Anda ingin membuat jadwal',
  'Would you like to help us become even better?':
    'Apakah Anda ingin membantu kami menjadi lebih baik?',
  'Would you like to import more data':
    'Apakah Anda ingin mengimpor lebih banyak data',
  'Would you like to obtain AGPS/GPS location for':
    'Apakah Anda ingin mendapatkan lokasi AGPS/GPS untuk',
  'Would you like to obtain more accurate location for':
    'Apakah Anda ingin mendapatkan lokasi yang lebih akurat, untuk',
  'Would you like to save the changes you made in this investigation?':
    'Apakah Anda ingin menyimpan perubahan yang Anda buat dalam penyelidikan ini?',
  'Would you like to save the latest changes you made in link analysis?':
    'Apakah Anda ingin menyimpan perubahan terbaru yang Anda buat dalam analisis tautan?',
  'Would you like to see target profile':
    'Apakah Anda ingin melihat profil target',
  'Wrong Credentials': 'Kredensial Salah',
  'Wrong password': 'Kata sandi salah',
  'Wrong template.': 'Templat salah.',
  'X hours and Y minutes.': 'X jam dan Y menit.',
  'X minutes': 'X menit',
  'Y hours': 'Y jam',
  Yes: 'Ya',
  'Yes, I want to cancel': 'Ya, saya ingin membatalkan',
  'Yes, I want to participate': 'Ya, saya ingin berpartisipasi',
  Yesterday: 'Kemarin',
  'You are investigating': '',
  ' call logs.': '',
  'From the available CDR files, select the data you would like to load into the Explorer':
    '',
  'You can select multiple nodes by holding down Ctrl key and clicking on a node.':
    'Anda dapat memilih beberapa node dengan menahan tombol Ctrl dan mengklik sebuah node.',
  'You can turn off': 'Anda dapat mematikan',
  'You can use the Apps button to navigate to the Cases & Targets page. By clicking done, you will permanently hide this message':
    'Anda dapat menggunakan tombol Aplikasi untuk membuka halaman Kasus & Target. Dengan mengeklik selesai, Anda akan menyembunyikan pesan ini secara permanen',
  'You do not have access rights or the url is invalid':
    'Anda tidak memiliki hak akses atau url tidak valid',
  "You don't have any monitoring created":
    'Anda tidak memiliki pemantauan apa pun yang dibuat',
  'You dont have permissions to use this feature':
    'Anda tidak memiliki izin untuk menggunakan fitur ini',
  'You have': 'Anda memiliki',
  'You have entered some invalid ad ids.':
    'Anda telah memasukkan beberapa ad id yang tidak valid.',
  'You have more than 1024 characters':
    'Anda memiliki lebih dari 1024 karakter',
  'You have more than 255 characters': 'Anda memiliki lebih dari 255 karakter',
  'You have reached': 'Anda telah mencapai',
  'You have reached the limit of cases used in the system. To add a new case, please go to the list of cases to delete one or contact your local administrator.':
    'Anda telah mencapai batas kasus yang digunakan dalam sistem. Untuk menambahkan kasus baru, silakan buka daftar kasus yang ingin dihapus atau hubungi administrator lokal Anda.',
  'You have reached the limit of targets used in the system. To add a new target, please go to the list of targets to delete one or contact your local administrator.':
    'Anda telah mencapai batas target yang digunakan dalam sistem. Untuk menambahkan target baru, silakan buka daftar target yang ingin dihapus atau hubungi administrator lokal Anda.',
  'You have reached the limit of the Cases used in the system.To add a new one, please go to the list of Cases and delete one.':
    'Anda telah mencapai batas Kasus yang digunakan dalam system. Untuk tambahkan yang baru, silakan buka daftar Kasus dan hapus satu.',
  'You have reached the limit of the Targets used in the system.To add a new one, please go to the list of targets and delete one.':
    'Anda telah mencapai batas Target yang digunakan dalam system. Untuk tambahkan yang baru, silakan masuk ke daftar target dan hapus satu.',
  'You have reached the limit of the cases used in the system. Contact your local administrator':
    'Anda telah mencapai batas kasus yang digunakan dalam sistem. Hubungi administrator lokal Anda',
  'You have reached the limit of the targets and cases used in the system. Contact your local administrator':
    'Anda telah mencapai batas target dan kasus yang digunakan dalam sistem. Hubungi administrator lokal Anda',
  'You have reached the limit of the targets used in the system. Contact your local administrator.':
    'Anda telah mencapai batas target yang digunakan dalam sistem. Hubungi administrator lokal Anda.',
  'You have to save the investigation before uploading a file':
    'Anda harus menyimpan penyelidikan sebelum mengunggah file',
  'You ran out of available cases. Contact your local administrator.': '',
  'You ran out of available cases. To add a new case, please go to the':
    'Anda kehabisan kasus yang tersedia. Untuk menambahkan kasus baru, silakan buka',
  'You ran out of available targets and cases. Contact your local administrator':
    'Anda kehabisan target dan kasus yang tersedia. Hubungi administrator lokal Anda.',
  'You ran out of available targets and cases. Contact your local administrator.':
    '',
  'You ran out of available targets. Contact your local administrator.': '',
  'You ran out of available targets. To add a new target, please go to the':
    'Anda kehabisan target yang tersedia. Untuk menambahkan target baru, silakan kunjungi',
  'You selected to change OTP method from application to the following email address':
    'Anda memilih untuk mengubah metode OTP dari aplikasi ke alamat email berikut',
  'You selected to change OTP method from application to the following phone number':
    'Anda memilih untuk mengubah metode OTP dari aplikasi ke nomor telepon berikut',
  'You selected to change OTP method from email to application':
    'Anda memilih untuk mengubah metode OTP dari email ke aplikasi',
  'You selected to change OTP method from email to the following phone number':
    'Anda memilih untuk mengubah metode OTP dari email ke nomor telepon berikut',
  'You selected to change OTP method from phone number to application':
    'Anda memilih untuk mengubah metode OTP dari nomor telepon ke aplikasi',
  'You selected to change OTP method from phone number to the following email address':
    'Anda memilih untuk mengubah metode OTP dari nomor telepon ke alamat email berikut',
  'You will be redirected to login screen.':
    'Anda akan diarahkan ke layar login.',
  'Your alerts have been set successfully': '',
  'Your browser does not support video': 'Browser Anda tidak mendukung video',
  'Your browser is not capable to view in fullscreen. Please switch to a latest browser.':
    'Browser Anda tidak dapat melihat dalam layar penuh. Silakan beralih ke browser terbaru.',
  'Your feedback is valuable!': 'Tanggapan Anda sangat berharga!',
  'Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!':
    'Umpan balik Anda sangat berharga dan akan membantu kami meningkatkan Intellectus. Kami akan sangat menghargai jika Anda tetap tinggal!',
  'Your opinion is very important': 'Pendapat Anda sangat penting',
  'Your password expired yesterday.': 'Kata sandi Anda kedaluwarsa kemarin.',
  'Your password expires today.': 'Kata sandi Anda kedaluwarsa hari ini.',
  'Your password expires tomorrow.': 'Kata sandi Anda akan kedaluwarsa besok.',
  'Your password has expired #{days} ago.':
    'Kata sandi Anda telah kedaluwarsa dari #{days} yang lalu.',
  'Your password will expire in #{days} days.':
    'Kata sandi Anda akan kedaluwarsa dalam #{days} beberapa hari.',
  'Your query has been queued': 'Kueri Anda telah dimasukkan dalam antrian',
  'Your request for SOMEDUS on': 'Permintaan Anda untuk SOMEDUS di',
  'Your session is about to expire click "Continue" to keep the session active.':
    'Sesi Anda akan kedaluwarsa klik "Lanjutkan" agar sesi tetap aktif.',
  '[trilateration_nearby_people]': '[trilateration_nearby_people]',
  "a playback of the target's timeline": 'pemutaran garis waktu target',
  'account has been submitted.': 'akun telah dikirimkan.',
  active: 'aktif',
  'active members': 'anggota aktif',
  'active relationships found': 'hubungan aktif ditemukan',
  'active relationships found out of': 'hubungan aktif ditemukan',
  actions: 'tindakan',
  add: 'tambahkan',
  addresses: 'alamat',
  'added to': '',
  advanced: 'lanjutan',
  advanced_osint: 'advanced_osint',
  all: 'semua',
  and: 'dan',
  'and delete one.': 'dan hapus satu.',
  'and ended on': 'dan berakhir pada',
  'and ended on the': 'dan berakhir pada ',
  'annual quota': '',
  area: 'daerah',
  at: 'pada',
  'at year #{year}': 'pada tahun #{year}',
  authentication: 'autentikasi',
  authored: 'ditulis',
  'available of your queries quota': 'tersedia kuota kueri Anda',
  away: 'jauh',
  'between 50-100 meters': 'antara 50-100 meter',
  'between hours #{hour.start}:00 and #{hour.end}:00':
    'antara jam #{hour.start}: 00 dan #{hour.end}: 00',
  'between the #{day.start}st and #{day.end}th day of a month':
    'antara #{day.start} st dan #{day.end} dalam sebulan',
  'between the #{day.start}th and #{day.end}th day of a month':
    'antara #{day.start} th dan #{day.end} dalam sebulan',
  'birth location': 'lokasi lahir',
  call: 'panggil',
  call_info: 'info_panggilan',
  call_log_purchase: 'pembelian_call_log',
  call_log_request_msisdn: 'panggilan_log_permintaan_msisdn',
  call_log_upload_imei: 'upload log panggilan imei',
  call_log_upload_msisdn: 'upload log panggilan imei',
  calls: 'panggilan',
  cancel: 'membatalkan',
  case: '',
  case_management: 'Manajemen_kasus',
  cases: 'kasus',
  'cases and targets': 'kasus dan target',
  cell_id: 'cell_id',
  changed: 'diubah',
  'check in': 'mendaftar',
  'click to expand.': 'klik untuk memperluas.',
  'closed databases': 'database tertutup',
  comments: 'komentar',
  countries: 'negara',
  'create a new target': 'buat target baru',
  'create-user': 'buat-pengguna',
  created: 'dibuat',
  'created:': 'dibuat:',
  credit: 'kredit',
  credits: 'kredit',
  'credits assigned': 'kredit yang ditetapkan',
  'credits available': 'kredit tersedia',
  'credits by': 'kredit oleh',
  'current and previous workplaces (target)':
    'tempat kerja saat ini dan sebelumnya (target)',
  'daily quota': '',
  darkweb: 'darkweb',
  'data activity': 'aktivitas data',
  'data points': 'titik data',
  'data used at': 'data yang digunakan di',
  day: 'hari',
  days: 'hari',
  'days remaining until expiration': 'sisa hari hingga habis masa berlakunya',
  delete: 'menghapus',
  destination: 'tujuan',
  details: 'rincian',
  disabled: 'dinonaktifkan',
  discovery: 'penemuan',
  duration: 'durasi',
  'duration & frequency': 'durasi & frekuensi',
  'education (target)': 'pendidikan (target)',
  educations: 'pendidikan',
  email: 'email',
  emails: 'email',
  emergency: 'darurat',
  empty: '',
  enabled: 'diaktifkan',
  'end of history': 'akhir sejarah',
  'enter keywords': 'masukkan kata kunci',
  entered: 'masuk',
  'entries were imported': 'entri diimpor',
  errors: 'kesalahan',
  'expires at dd/mm/yyy hh:mm': '',
  female: 'perempuan',
  'filter by location': 'filter berdasarkan lokasi',
  'filter out the selected nodes by pressing the Delete key.':
    'saring node yang dipilih dengan menekan tombol Delete.',
  filters: 'filter',
  for: 'untuk',
  'for at least 2 targets from the case in order to see call log analysis':
    'setidaknya untuk 2 target dari kasus untuk melihat analisis log panggilan',
  'for the objective': '',
  'for:': 'untuk:',
  found: 'ditemukan',
  frequency: 'frekuensi',
  friday: 'Jumat',
  from: '',
  'from Platform settings.': 'dari Pengaturan platform.',
  'full name': 'nama lengkap',
  gender: '',
  'geo location': 'lokasi geografis',
  'geo-fencing': 'geo-fencing',
  geolocation: 'geolokasi',
  group: 'kelompok',
  groups: 'kelompok',
  has: 'memiliki',
  'has been captured on': 'telah ditangkap pada',
  'has failed': '',
  'has family relation with': 'memiliki hubungan keluarga dengan',
  'has finished': 'telah selesai',
  'has terminated': 'telah berakhir',
  'has used by the same device': 'telah digunakan oleh perangkat yang sama',
  here: 'Di Sini',
  high: 'tinggi',
  histogram: 'histogram',
  'home location': '',
  home_mcc: 'home_mcc',
  home_mnc: 'home_mnc',
  hour: 'jam',
  hours: 'jam',
  'hours to respond with success status.':
    'jam untuk merespons dengan status sukses.',
  idle: 'menganggur',
  image: 'gambar',
  images: 'gambar',
  imei: 'imei',
  imeis: 'imei',
  import_cdr: 'import cdr',
  import_historical_locations: 'impor lokasi bersejarah',
  imsi: 'imsi',
  in: 'di dalam',
  'in order to see call log analysis': 'untuk melihat analisis log panggilan',
  inactive: 'tidak aktif',
  incoming: 'masuk',
  'incoming & outgoing': 'masuk & keluar',
  info: 'info',
  inside: 'di dalam',
  "insights about your target's top associates":
    'wawasan tentang rekan teratas target Anda',
  intel: 'intel',
  intel_search: 'intel search',
  'interacted with': 'berinteraksi dengan ',
  investigation: 'investigasi',
  is: 'adalah',
  'is already included in': '',
  'is Offline. Last Known Location found':
    'sedang Offline. Lokasi Terakhir Diketahui Ditemukan',
  'is deactivated': 'dinonaktifkan',
  'is done': 'sudah selesai',
  'is expired': 'kedaluwarsa',
  'is here': 'ada di sini',
  'is in': 'sedang dalam',
  'is not a valid': 'tidak valid',
  'is not a valid MSISDN .': 'bukan MSISDN yang valid .',
  'is not a valid MSISDN.': 'bukan MSISDN yang valid.',
  'is on emergency': 'Sedang dalam keadaan darurat',
  'is required': 'diperlukan',
  item: 'barang',
  'item selected': 'item yang dipilih',
  items: 'item',
  'items found': 'barang ditemukan',
  'items on': 'item di',
  'items selected': 'item yang dipilih',
  jobs: 'pekerjaan',
  keyword: 'kata kunci',
  'keyword list': 'daftar kata kunci',
  lac: 'lac',
  'last 30 days': '30 hari terakhir',
  'last 60 days': '60 hari terakhir',
  'last 90 days': '90 hari terakhir',
  'last seen': '',
  left: 'kiri',
  less: 'kurang',
  'less than 50 meters': 'kurang dari 50 meter',
  likes: 'suka',
  links: 'tautan',
  'list of cases': 'daftar kasus',
  'list of targets': 'daftar target',
  'list selected': 'daftar yang dipilih',
  'lists selected': 'daftar yang dipilih',
  live: 'hidup',
  'locate all': 'temukan semua',
  located: 'terletak',
  'located at #({latitude},{longitude})':
    'terletak di #({latitude}, {longitude})',
  location: 'lokasi',
  'location found': 'lokasi ditemukan',
  locked: 'terkunci',
  login: 'masuk',
  logon_query_location_offline: 'kueri masuk lokasi offline',
  logout: 'keluar',
  low: 'rendah',
  male: '',
  media: 'media',
  medium: 'sedang',
  members: '',
  mentions: 'menyebutkan',
  messages: 'pesan',
  'messages exchanged': 'pesan dipertukarkan',
  meters: 'meter',
  'meters or less': 'meter atau kurang',
  minimize: 'minimalkan',
  minute: 'minute',
  minutes: 'menit',
  Mission: 'Misi',
  monday: 'Senin',
  month: '',
  'monthly quota': '',
  months: 'bulan',
  more: 'lagi',
  'move the selected nodes by dragging using the mouse or with the keyboard arrows.':
    'pindahkan node yang dipilih dengan menyeret menggunakan mouse atau dengan panah keyboard.',
  msisdn: '',
  name: '',
  'name middle surname': 'nama nama keluarga tengah',
  'name surname': 'nama nama keluarga',
  names: '',
  'nearby profiles found': 'profil terdekat ditemukan',
  'nearby towers found': 'menara terdekat ditemukan',
  neighbour_cell_towers: 'neighbor_cell_towers',
  new: 'baru',
  'new MSISDN': 'MSISDN baru',
  'new case': 'kasus baru',
  'new investigation': '',
  'new post': '',
  'new target': 'target baru',
  nmr: 'nmr',
  nodes: 'node',
  'not located': 'tidak terletak',
  notifications: 'pemberitahuan ',
  'number could not be located': 'nomor tidak dapat ditemukan',
  'number successfully located': 'nomor berhasil ditemukan',
  of: 'dari',
  'of your queries quota': 'dari kuota kueri Anda',
  on: 'pada',
  'on #{dayName}s': 'di #{dayName} s',
  'on #{monthName}': 'di #{monthName}',
  or: 'atau',
  'or ': 'atau ',
  'otp-email': 'otp-email',
  'otp-phone': 'otp-telepon',
  'out of': 'keluar dari',
  outgoing: 'keluar',
  outside: 'di luar',
  'over 100 meters': 'lebih dari 100 meter',
  overall: 'secara keseluruhan',
  'overall activity': 'aktivitas keseluruhan',
  'para el objetivo': 'untuk tujuan',
  'passwords found': 'kata sandi ditemukan',
  'people nearby': 'orang-orang terdekat',
  'persons tagged': 'orang yang ditandai',
  phone: '',
  phone_model: 'Model Telepon',
  photo: 'foto',
  'photo details': 'detail foto',
  'photo(s) together': 'foto bersama',
  photos: 'foto',
  'platform selected': 'platform dipilih',
  'platforms selected': 'platform yang dipilih',
  post: 'post',
  'post the last': '',
  posts: '',
  'posts the last': '',
  'profile location': 'lokasi profil',
  profileUrls: 'profileUrls',
  profiles: 'profil',
  published: 'diterbitkan',
  queries: 'kueri',
  query: '',
  query_foreign_location: 'query_foreign_location',
  query_location: 'kueri lokasi',
  query_location_offline: 'kueri_lokasi_offline',
  queue: 'antrian',
  quota: '',
  'quota reset to initial balance': 'kuota direset ke saldo awal',
  'ramining targets': 'menjarah target',
  reactions: 'reaksi',
  recipient: 'penerima',
  records: 'catatan',
  register: 'daftar',
  'remaining cases': 'kasus yang tersisa',
  'remaining daily quota': 'sisa kuota harian',
  'remaining monthly quota': 'sisa kuota bulanan',
  'remaining targets': '',
  'remote browser': 'browser jarak jauh',
  'removed successfully': '',
  renewed: 'diperbarui',
  'request cdr': 'Permintaan cdr',
  reset: 'Mengatur Ulang',
  'reset-password': 'setel ulang kata sandi',
  results: 'hasil',
  'results found': 'hasil ditemukan',
  roles: 'peran ',
  saturday: 'Sabtu',
  saved: 'disimpan',
  'scheduled task has finished': 'Tugas terjadwal telah selesai',
  'screenHeight must not be less than 480':
    'Tinggi layar tidak boleh kurang dari 480',
  'screenWidth must not be less than 640':
    'Lebar layar tidak boleh kurang dari 640',
  'search history': 'Sejarah pencarian',
  'search results found for': 'hasil pencarian ditemukan untuk',
  second: 'second',
  seconds: 'detik',
  seeds: 'sumber',
  'seeds selected': 'sumber yang dipilih',
  'select all': 'Pilih Semua',
  selected: 'dipilih',
  sender: 'pengirim',
  set: 'atur',
  shares: 'bagikan',
  'show less': 'tampilkan lebih sedikit',
  'show more': '',
  'single unique profile': 'profil unik tunggal',
  'social platforms': 'platform sosial',
  source: '',
  sources: 'sumber',
  space: 'ruang',
  'started at': 'dimulai dari',
  'started on': 'dimulai pada',
  starting: 'memulai',
  status: 'status',
  sub: 'sub',
  successful: 'sukses',
  successfully: 'berhasil',
  'sucessfully logged in': 'berhasil masuk',
  'sucessfully logged out': 'berhasil keluar',
  sunday: 'Minggu',
  target: 'target',
  'target photo(s)': 'foto target',
  'target post(s)': 'pos target',
  "target's common used routes": 'rute target yang umum digunakan',
  "target's predicted locations": 'lokasi target yang diprediksi',
  target_management: 'target_management',
  targets: 'target',
  'targets found': 'target ditemukan',
  telno: 'telno',
  telnos: 'telnos',
  'text analysis': 'analisis teks',
  'the extention supported are:': 'ekstensi yang didukung adalah:',
  'the geofenced area': 'area geofence',
  thursday: 'Kamis',
  time: '',
  'time(s)': 'waktu)',
  times: 'waktu',
  'times or more': 'kali atau lebih',
  to: 'ke',
  'to be monitored, or create a': 'untuk dipantau, atau membuat',
  'to reuse and select': 'untuk menggunakan kembali dan memilih',
  'to the target to be able to use this feature':
    'ke target untuk dapat menggunakan fitur ini',
  'to view the target profile': 'untuk melihat profil target',
  trilateration_nearby_people: 'trilateration orang-orang terdekat',
  tuesday: 'Selasa',
  type: 'jenis',
  'unknown type': 'tipe tidak diketahui',
  unlocked: 'tidak terkunci',
  'update-user': 'perbarui-pengguna',
  'upload existing call log': 'unggah log panggilan yang ada',
  url: 'url',
  user: 'pengguna',
  username: '',
  usernames: '',
  via: 'melalui',
  videos: 'video',
  views: 'tampilan',
  'voice activity': 'aktivitas suara',
  was: 'adalah',
  'was Succesfull': 'berhasil',
  'was extracted successfully. Information was also added in Target Call Log Analysis':
    'berhasil diekstraksi. Informasi juga ditambahkan dalam Analisis Log Panggilan Target',
  'was found': 'ditemukan',
  'was found near': 'ditemukan dekat',
  'was located during a call': 'ditemukan saat menelepon',
  'was located during a call.': 'ditemukan selama panggilan.',
  'was successfully delivered': 'berhasil dikirimkan',
  'was turned off': 'dimatikan',
  'was turned on': 'dihidupkan',
  wednesday: 'Rabu',
  week: 'minggu',
  weekdays: 'hari kerja',
  weekend: 'weekend',
  weekends: 'akhir pekan',
  'will be updated at 12:00am': '',
  'will be updated next month': '',
  'will expire soon': 'akan segera berakhir',
  with: 'dengan',
  'with ID:': 'dengan ID:',
  'with a duration of': 'dengan durasi',
  'with msisdn': 'dengan msisdn',
  'workplace location': '',
  'x out of y': 'x dari y',
  'you want to be monitored': 'anda ingin diawasi',
  'your browser': 'peramban Anda',
  'All of your request for SOMEDUS on':
    'Semua permintaan Anda untuk SOMEDUS aktif',
  'Once the infrastructure for each request is up, it will take our BOTs up to':
    'Setelah infrastruktur untuk setiap permintaan selesai, BOT kami akan menyelesaikannya',
  'You have already requested Somedus for this target':
    'Kamu sudah melakukan permintaan Somedus untuk target ini',
  'Try out the new and mobile friendly Discovery!': '',
  'Display on map': '',
  'Latest location for': '',
  'Location for': '',
  '#{numberOf} days ago': '',
  '#{numberOf} hours ago': '',
  '#{numberOf} minutes ago': '',
  '#{numberOf} seconds ago': '',
  'a few seconds ago': '',
  'display on map': '',
  'No profiles found': '',
  QUERIES: 'KUERI',
  'Latest profile search': '',
  Track: '',
  'a minute ago': '',
  'an hour ago': '',
  'a day ago': '',
  subscription: 'berlangganan',
  network: 'jaringan',
  'Meet the new Discovery!': '',
  "On our quest to enhance your experience we're updating the discovery module to match your needs and expectations.":
    '',
  'Focus on your mission': '',
  'Track the movements of several targets simultaneously on a single map.': '',
  'Explore key moments of your Mission': '',
  'With the time bar, you can easily focus on specific times in a mission to reveal important information.':
    '',
  'Missions on the Go': '',
  'Take your missions with you on any device, mobile or tablet.': '',
  'Dive Right In!': '',
  'Ready to explore? Start adding new and historical MSISDNs to the mission screen.':
    '',
  'Find Profile': '',
  'New Search': 'Nueva búsqueda',
  Tracking: '',
  'Tracking information': '',
  Every: '',
  until: '',
  'You are currently tracking': '',
  'based on the below parameters.': '',
  'Locating for': '',
  'Locating every': '',
  'Scheduled Searches': '',
  'Executed Searches': '',
  'Locations Found': '',
  'Terminate tracker': '',
  'Tracking terminated for': '',
  scheduled: '',
  '#{numberOf} minutes': '',
  '#{numberOf} hours': '',
  '#{numberOf} days': '',
  'Maximum count of phone numbers inside the mission has been reached.': '',
  'On a call with': '',
  Retrieve: '',
  Retrieving: '',
  'On a call': '',
  'Incoming call from': '',
  'Outgoing call to': '',
  logon: '',
  'Something went wrong during termination of tracker for': '',
  'End when completed': '',
  'Terminate if location found': '',
  'Add label': '',
  'Edit label': '',
  Label: '',
  'Add label to': '',
  'Edit label to': '',
  'Nearby cell towers': '[Nearby cell towers]',
  available: '[available],',
  TERMINATED: '',
  COMPLETED: '',
  'Scheduled Type': '',
  'Scheduled searches': '',
  'Executed searches': '',
  'Locations found': '',
  'Terminated by user': '',
  Terminated: '',
  'Active Trackers only': '',
  Terminate: '',
  'Terminate Tracker': '',
  'You are about to terminate the tracking of': '',
  'Click Terminate to continue or Close to go back': '',
  'Locate phone & find profiles': '',
  'Set up scheduled tracking for': '',
  'Set up scheduled tracking': '',
  'Specify tracking duration and frequency': '',
  'Locate for': '',
  'Locate every': '',
  'Terminate tracking when first location is found': '',
  'Start Tracking': '',
  'Tracking started for': '',
  'Case is empty. Click the': '',
  'Maximum count of phone numbers inside the case has been reached.': '',
  'Focus on your case': '',
  'Explore key moments of your Case': '',
  'With the time bar, you can easily focus on specific times in a case to reveal important information.':
    '',
  'Cases on the Go': '',
  'Take your cases with you on any device, mobile or tablet.': '',
  'Ready to explore? Start adding new and historical MSISDNs to the cases screen.':
    '',
  'Show Case Overview': '',
  'Please enter 3 or more characters (up to 20 characters in total)': '',
  'Add to mission': '',
  'Schedule Completed': '',
  'Subscription deactivated': '',
  'Terminated by system': '',
  'Query info': '',
  'Query performed by': '',
  'Submitted on': '',
  'Query submitted on': '',
  'Query ID': '',
  'Query ID copied!': '',
  'Unexpected platform error.': '',
  'The registered country of this number is not included in your license.': '',
  'The operator of this number is not included in your license.': '',
  'This number currently cannot be located.': '',
  'Your limit of location searches has been reached.': '',
  'You do not have sufficient credits.': '',
  'Number cannot be found.': '',
  'Number found, but location could not be identified.': '',
  'Operator  is not currently covered.': '',
  'Cell ID found but coordinates are not available.': '',
  'This feature is not included in your license.': '',
  'We have been notified. Please try again later.': '',
  'Not possible to locate the number.': '',
  'Wait until your limit is reset and try again.': '',
  'Please contact your administrator.': '',
  'Please try again later.': '',
  'Location of': '',
  'Tracking of': '',
  'found (Tracking terminated)': '',
  'is on a call. Retrieve caller?': '',
  'Caller successfully retrieved': '',
  'Sector successfully calculated': '',
  'Nearby cell towers successfully retrieved': '',
  'Subscription of': '',
  'deactivated. (Tracking terminated)': '',
  completed: '',
  'Not possible to locate this number.': '',
  '': '',
  'has been added.': '',
  'has been removed.': '',
  'Subscription has been DEACTIVATED': '',
  'view details': 'melihat rincian',
  'Add group to Case': 'Tambahkan grup ke Kasus',
  'Create or add to case': 'Buat atau tambahkan ke kasus',
  'The group has been saved to case "#{caseName}". Click view to go to the case.':
    'Grup telah disimpan ke huruf "#{caseName}". Klik lihat untuk melihat kasusnya.',
  'Advertisement intelligence': 'Advertisement intelligence',
  'New draft': 'New draft',
  'ADD NEW MSISDN/IMSI AND TARGETS': '',
  'Enter a MSISDN, IMSI or target': '',
  'MSISDN/IMSI or a Target': '',
  'MSISDN/IMSI/TARGET': '',
  'Case name': 'Nombre del Caso',
  'Enter the case name': '',
  'Case name is required': '',
  'Enter a short description of the case': '',
  'A short description is required': '',
  'Please add targets before create new Case': '',
  'We saved your current case and we are redirecting you to the selected case.':
    '',
  'We saved your current case and we are redirecting you to the new draft.': '',
  'We saved your current case.': '',
  'Something went wrong during saving case process. Please try again.': '',
  'Select Case': '',
  'Case not found': '',
  'Request type': 'Jenis permintaan',
  Discrete: 'Diskrit',
  Explicit: 'Eksplisit',
  'Accept risk and proceed': 'Terima risiko dan lanjutkan',
  'Are you sure you would like to proceed?':
    'Apakah Anda yakin ingin melanjutkan?',
  'You have selected #{explicitRequestsCount} #{requestWord} to run explicitly. Explicit Somedus requests may finish faster. However, it increases the risk that the target will become aware of a takeover attempt, which will risk the activity. In case of an unsuccessful explicit attempt, it is possible that the requested accounts will not be available for additional requests.':
    'Anda telah memilih #{explicitRequestsCount} #{requestWord} permintaan untuk dijalankan secara eksplisit. Permintaan Somedus eksplisit mungkin selesai lebih cepat. Namun, hal ini meningkatkan risiko bahwa target akan mengetahui upaya pengambilalihan, yang akan membahayakan aktivitas tersebut. Jika upaya eksplisit gagal, ada kemungkinan akun yang diminta tidak akan tersedia untuk permintaan tambahan',
  request: 'meminta',
  requests: 'permintaan',
  'Select an account for which you would like to execute a Somedus request':
    'Pilih akun yang ingin Anda jalankan untuk permintaan proses Somedus',
  "Target's account": 'Akun target',
  'First name': 'Nama depan',
  optional: 'opsional',
  'Execution type': 'Jenis eksekusi',
  'Select email': 'Pilih email',
  control: 'kontrol',
  Control: 'Kontrol',
  'type first name': 'ketikkan nama depan',
  'type last name': 'ketikkan nama belakang',
  'Your request for Somedus on': 'Permintaan Anda untuk Somedus aktif',
  'account has been submitted': 'akun telah dikirimkan',
  'to respond with success status': 'untuk merespons dengan status sukses',
  'associated accounts': 'akun terkait',
  'Open in Discovery': '',
  'has been removed from case.': '',
  'e.g. Kidnapping': 'Penculikan',
  'Short description': 'Deskripsi Singkat',
  Saving: 'Penghematan',
  'view group details': 'melihat detail grup',
  'Show targets': 'Tampilkan target',
  'Plot groups': 'Kelompok plot',
  'Select groups': 'Pilih grup',
  'Group name': 'Nama grup',
  Participants: 'Peserta',
  'MSISDN in multiple groups': 'MSISDN dalam beberapa grup',
  'Search participant': 'Cari peserta',
  'Group Analyser': 'Penganalisis Grup',
  'GROUP DETAILS': 'DETAIL GRUP',
  'No content': 'Tidak ada konten',
  Chat: 'Obrolan',
  'Data analysis is underway.': 'Analisis data sedang berlangsung',
  'Thank you for your patience as we process the information.':
    'Terima kasih atas kesabaran Anda saat kami memproses informasi.',
  'Top keywords': 'Kata kunci teratas',
  'Word cloud': 'Awan kata',
  Multimedia: 'Multimedia',
  'Search chat': 'Cari obrolan',
  'View group details': 'Lihat detail grup',
  'Group Details': 'Detail Grup',
  'Add group to an existing case flow': 'Tambahkan grup ke alur kasus yang ada',
  'Add to existing case': 'Tambahkan ke kasus yang ada',
  'view more': 'lihat lebih banyak',
  view: 'lihat',
  participants: 'peserta',
  'Link copied': 'Tautan disalin',
  'MSISDN copied': 'MSISDN disalin',
  'User removed': 'Pengguna dihapus',
  'MFA Password/key Protected Account':
    'Kata Sandi MFA/Akun yang Dilindungi Kunci',
  'A linked email must be provided for this account':
    'Email tertaut harus disediakan untuk akun ini',
  'A correct fullname must be provided for this account':
    'Nama lengkap yang benar harus diberikan untuk akun ini',
  'The selected account is not valid': 'Akun yang dipilih tidak valid',
  'MSISDN is Deactivated': 'MSISDN Dinonaktifkan',
  'No account found for the requested MSISDN':
    'Tidak ditemukan akun untuk MSISDN yang diminta',
  'Takeover blocked due to spam, retry in 2 days':
    'Pengambilalihan diblokir karena spam, coba lagi dalam 2 hari',
  'Account does not support SMS 2FA': 'Akun tidak mendukung SMS 2FA',
  'No data found for this account': 'Tidak ditemukan data untuk akun ini',
  'Must perform Control first': 'Harus melakukan Control terlebih dahulu',
  'Provided name was invalid': 'Nama yang diberikan tidak valid',
  'Latest location': '',
  'Area selected': '',
  'Please select an area on the map': '',
  'Retrieving the data': '',
  'Start analysing': '',
  Searching: '',
  'Name of the search': '',
  'Use the map tools to draw an Area of Interest': '',
  'Analyse specific Ad IDs': '',
  'Enter Ad ID': '',
  'Enter specific ad ids': '',
  'Select start and end date': '',
  'Customized Search': '',
  'Select Parameter': '',
  Parameter: '',
  'Customise your search in the Area of interest by adding metadata as parameters with their value':
    '',
  'App used': '',
  'Device type': '',
  'Device ip': '',
  'Connection type': '',
  'Session results are ready': '',
  'Type the value': '',
  'Get deeper insights. Start running Somedus requests today':
    'Dapatkan wawasan lebih dalam. Mulai jalankan permintaan Somedus hari ini',
  Refine: '',
  'Total data points': '',
  'Copy and paste the url of an image here':
    'Salin dan tempel url gambar di sini',
  'Add the name of the person that depict in the uploaded image':
    'Tambahkan nama orang yang digambarkan dalam gambar yang diunggah',
  'Search by image': 'Cari gambar',
  'Search image': 'Cari gambar',
  'the extension supported are: .jpg, jpg, .png':
    'ekstensi yang didukung adalah: .jpg, jpg, .png',
  'Remove file': 'Hapus file',
  'Add to': '',
  Rename: '',
  'Remove Session?': '',
  'Are you sure you want to remove this session?': '',
  'Session has been removed': '',
  TRACKERS: '',
  'Discovery History': '',
  'Created At': 'Dibuat Pada',
  failed: 'gagal',
  pending: 'tertunda',
  expired: 'kedaluwarsa',
  'access granted': 'akses diberikan',
  'Traveled to': '',
  'but no location found': '',
  'Target was last located in': '',
  'Target was last seen in': '',
  ago: '',
  'Return back to': '',
  'The previous location of the target was in': '',
  'History of locations abroad': '',
  'Traveled from': '',
  'Target was last located abroad in': '',
  before: '',
  'History of devices': '',
  'Copy ID': '',
  'Retrieved MSISDNs': '',
  'Changed device': '',
  License: 'Lisensi',
  'Photo details': 'Detail foto',
  'Would you like to save the changes you made in this case?': '',
};
