import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvestigationStylesService {
  availableColors: string[] = [
    '2f8dfa',
    '1fd0bf',
    'eb648b',
    'f8c753',
    'eb7e30',
    'a93790',
    '049587',
    '1553b6',
    '55008c',
    '8b572a',
    'd5001a',
    'ff6a00',
    'ffb507',
    'ffd972',
    'ffe9af',
    'daf173',
    'b2ddcc',
    '42b3d5',
    '27539b',
    '182276',
  ];
  defaultColor = '000000';

  currentColorIndex = 0;
  idToGroup: { [id: string]: string } = {};
  groupToColor: { [groupId: string]: string } = {};
  highlightedGroups: string[] = [];

  highlightedIdsOldValues: Map<string, { groupId: string; color: string }> =
    new Map();
  updatedId: BehaviorSubject<string> = new BehaviorSubject(null);
  changeColorForGroupIds: { [id: string]: boolean } = {
    topAssociate: true,
    other: true,
  };
  constructor() {}

  addIdToGroup(id: string, groupId: string): void {
    this.idToGroup[id] = groupId;
    if (!this.groupToColor[groupId]) {
      this.createColorGroup(groupId);
    }
  }

  createColorGroup(groupId: string): void {
    if (this.currentColorIndex >= this.availableColors.length) {
      this.currentColorIndex = 0;
    }
    this.groupToColor[groupId] = this.availableColors[this.currentColorIndex];
    this.currentColorIndex = this.currentColorIndex + 1;
  }

  getColorById(id: string): string {
    const color = this.groupToColor[this.idToGroup[id]];
    // if (!color) {
    //   console.warn('Could not retreive color for id: ', id, this.idToGroup, this.groupToColor);
    // }
    return color || this.defaultColor;
  }

  getColorByGroup(groupId: string): string {
    let color = this.groupToColor[groupId];
    if (!color) {
      this.createColorGroup(groupId);
      color = this.groupToColor[groupId];
    }
    return color || this.defaultColor;
  }

  getGroupAndColor(id: string): { groupId: string; color: string } {
    const groupId = this.idToGroup[id] || 'other';
    const color = this.groupToColor[groupId] || this.groupToColor['other'];
    return { groupId, color };
  }

  highlightIds(ids: string[]): void {
    const toBeDeletedPinnedIds = new Map(this.highlightedIdsOldValues);
    let updatedId;
    ids.forEach((id) => {
      if (toBeDeletedPinnedIds.has(id)) {
        toBeDeletedPinnedIds.delete(id);
        return;
      }
      const groupAndColor = this.getGroupAndColor(id);
      this.highlightedIdsOldValues.set(id, groupAndColor);
      if (this.changeColorForGroupIds[groupAndColor.groupId]) {
        this.addIdToGroup(id, `highlight-${id}`);
      }
      updatedId = id;
    });
    toBeDeletedPinnedIds.forEach((value, key) => {
      this.addIdToGroup(key, value.groupId);
      this.highlightedIdsOldValues.delete(key);
      updatedId = key;
    });
    this.updatedId.next(updatedId);
  }

  public getColorsState(): string {
    return JSON.stringify({
      ids: this.idToGroup,
    });
  }

  public setColorsState(state: string) {
    if (!state) {
      return;
    }
    this.idToGroup = JSON.parse(state).ids;
  }
}
