import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core-toastr-body',
  templateUrl: './core-toastr-body.component.html',
  styleUrls: ['./core-toastr-body.component.scss'],
})
export class CoreToastrBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
