import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { remove } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AreaOfInterest } from 'src/app/shared/models/area-of-interest.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { BaseService } from 'src/app/services/base.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class PlacesService extends BaseService {
  allAreas: AreaOfInterest[] = [];
  areasChanged = new Subject<AreaOfInterest[]>();

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  getAllAreas(): Observable<AreaOfInterest[]> {
    return this.httpClient.get<any>(`${this.url}/areas`).pipe(
      map((data) => {
        this.allAreas = transformSnakeToCamel(data.result);
        return data.result;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  createPlace(area: AreaOfInterest): Observable<AreaOfInterest> {
    // TODO: remove createdBy. It should happen automatically.
    return this.httpClient
      .post<any>(`${this.url}/areas`, {
        name: area.name,
        radius_meters: area.radius * 1000,
        latitude: area.latitude,
        longitude: area.longitude,
      })
      .pipe(
        map((data) => {
          // TODO: - change BE to return object instead of array of object
          this.allAreas.push(transformSnakeToCamel(data.result[0]));
          this.areasChanged.next(this.allAreas.slice());
          return data.result;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  removePlace(currentArea) {
    return this.httpClient.delete(`${this.url}/areas/${currentArea.id}`).pipe(
      map((data: any) => {
        remove(this.allAreas, ['id', currentArea.id]);
        this.areasChanged.next(this.allAreas.slice());
      }),
      catchError((error) => this.handleError(error))
    );
  }
}
