import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ChartData, ChartDataset, ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-doughnut',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnChanges {
  @Input() chartData: ChartDataset<ChartType, number[]>;
  @Input() chartTitle: string;
  @Input() chartLabels: string[];
  @Input() chartColors: string[];

  public doughnutChartType: ChartType = 'doughnut';
  private defaultChartColors: string[] = [
    '#C5DA03',
    '#F1453D',
    '#339AED',
    '#9400D3',
  ];
  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  private defaultDataSetConfig: Partial<ChartDataset> = {
    backgroundColor: this.defaultChartColors,
    hoverBackgroundColor: this.defaultChartColors,
    hoverOffset: 2,
    borderWidth: 0,
  };

  public doughnutChartData: ChartData<'doughnut'> = {
    datasets: [],
  };
  constructor(private cdr: ChangeDetectorRef) {}
  public chartClicked(e: any): void {}

  public chartHovered(e: any): void {}

  ngOnChanges() {
    if (this.chartData) {
      const chartData = {
        ...this.defaultDataSetConfig,
        ...this.chartData,
      };

      this.doughnutChartData.labels = this.chartLabels;
      this.doughnutChartData.datasets.push(
        <ChartDataset<'doughnut', number[]>>chartData
      );
      this.cdr.markForCheck();
    }
  }
}
