<img
  *ngIf="imageUrl && !imageError"
  [src]="imageUrl"
  (error)="handleAvatarError()"
/>
<ngx-avatar
  class="target-item-avtar"
  *ngIf="nameInitials"
  value="{{ nameInitials }}"
></ngx-avatar>
