import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AreaOfInterest } from 'src/app/shared/models/area-of-interest.model';
import { PlacesService } from 'src/app/services/places/places.service';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { QueryType } from 'src/app/shared/models/query-item.model';
import { QueryService } from 'src/app/services/query/query.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Circle } from 'src/app/modules/mapV2/models/map.models';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { Angulartics2 } from 'angulartics2';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss'],
})
export class GeofencingComponent implements OnInit, OnDestroy, OnChanges {
  date: Date[];
  frequency: number;
  areaOfInterestForm: UntypedFormGroup;
  countryForm: UntypedFormGroup;
  places: AreaOfInterest[];
  outside = new UntypedFormControl();
  public todayDate = new Date();
  showError = false;
  numbersToBeQueried;
  skin;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];

  @Output() selectedCountry = new EventEmitter<string>();
  @Output() showSearchTool = new EventEmitter<boolean>();
  @Output() showDrawingTools = new EventEmitter<boolean>();
  @Output() clearQueryMap = new EventEmitter<boolean>();
  @Output() drawAreaOfInterestFromComponent = new EventEmitter<Circle>();
  @ViewChild('radiusInput', { static: true }) radiusInput: MatInput;
  @ViewChild('latInput', { static: true }) latInput: MatInput;
  @ViewChild('lngInput', { static: true }) lngInput: MatInput;
  @Input() areaOfInterestDrawn: Circle;

  constructor(
    private placesService: PlacesService,
    private queryService: QueryService,
    private dashboardService: DashboardService,
    private applicationStateService: ApplicationStateService,
    private translationService: TranslationService,
    private angulartics2: Angulartics2,
    private snackBar: MatSnackBar
  ) {
    this.skin = this.applicationStateService.getSkin();
    this.initForm();
  }

  ngOnInit() {
    const dateSubscription = this.queryService.dateRange.subscribe(
      (dateRange) => {
        this.date = dateRange;
      }
    );
    const frequencySubscription = this.queryService.frequency.subscribe(
      (frequency) => {
        this.frequency = frequency;
      }
    );
    const formSubmissionSubscription =
      this.queryService.advancedDataFlag.subscribe((flag) => {
        if (
          flag &&
          this.queryService.advancedQueryType.value === QueryType.FENCED_AREA
        ) {
          this.getCommonQueryData(this.areaOfInterestForm);
          this.onSubmitArea();
        } else if (
          flag &&
          this.queryService.advancedQueryType.value === QueryType.FENCED_COUNTRY
        ) {
          this.getCommonQueryData(this.countryForm);
          this.onSubmitCountry();
        }
      });
    const clearFormsSubscription =
      this.queryService.clearAdvancedInputs.subscribe((flag) => {
        if (flag) {
          this.resetForm();
        }
      });
    this.subscriptions.push(
      dateSubscription,
      frequencySubscription,
      formSubmissionSubscription,
      clearFormsSubscription
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const areaOfInterestDrawn = changes.areaOfInterestDrawn.currentValue;
    if (areaOfInterestDrawn && areaOfInterestDrawn.radiusMeters) {
      this.areaOfInterestForm.controls['lat'].setValue(
        areaOfInterestDrawn.center.lat.toFixed(3)
      );
      this.latInput.focus();
      this.areaOfInterestForm.controls['lng'].setValue(
        areaOfInterestDrawn.center.lng.toFixed(3)
      );
      this.lngInput.focus();
      this.areaOfInterestForm.controls['radius'].setValue(
        (areaOfInterestDrawn.radiusMeters / 1000).toFixed(3)
      );
      this.radiusInput.focus();
      this.showError = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initForm() {
    this.countryForm = new UntypedFormGroup({
      queryType: new UntypedFormControl(QueryType.FENCED_COUNTRY),
      startAt: new UntypedFormControl('', Validators.required),
      endAt: new UntypedFormControl('', Validators.required),
      frequency: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      outside: new UntypedFormControl(),
    });
    this.areaOfInterestForm = new UntypedFormGroup({
      queryType: new UntypedFormControl(QueryType.FENCED_AREA),
      name: new UntypedFormControl(''),
      lat: new UntypedFormControl('', [
        Validators.required,
        Validators.min(-90),
        Validators.max(90),
      ]),
      lng: new UntypedFormControl('', [
        Validators.required,
        Validators.min(-180),
        Validators.max(180),
      ]),
      radius: new UntypedFormControl('', Validators.required),
      startAt: new UntypedFormControl('', Validators.required),
      endAt: new UntypedFormControl('', Validators.required),
      outside: new UntypedFormControl(),
      frequency: new UntypedFormControl('', Validators.required),
    });
  }

  savePlace() {
    if (this.areaOfInterestForm.valid) {
      const {
        name,
        radius,
        lat: latitude,
        lng: longitude,
      } = this.areaOfInterestForm.value;
      this.placesService
        .createPlace({ name, radius, latitude, longitude })
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate(
                'Area Of Interest created successfully!'
              )
            );
            this.resetForm();
          },
          () => {
            this.showMessage(
              this.translationService.translate(
                'Area Of Interest has not been created'
              )
            );
          }
        );
    } else {
      this.showMessage(
        this.translationService.translate(
          'Invalid values. Please fill in all fields.'
        )
      );
    }
  }

  getCommonQueryData(form) {
    form.controls['startAt'].setValue(this.date[0]);
    form.controls['endAt'].setValue(this.date[1]);
    form.controls['frequency'].setValue(this.frequency);
    form.controls['outside'].setValue(this.outside.value);
  }

  resetForm() {
    this.areaOfInterestForm.reset();
    this.clearQueryMap.emit(true);
    this.countryForm.reset();
    this.outside.reset();
  }

  onChange(input) {
    if (!input) {
      this.clearQueryMap.emit(true);
    }
  }

  drawPlace() {
    const { radius, lat, lng } = this.areaOfInterestForm.value;
    if (radius && lat && lng) {
      this.showError = false;
      this.clearQueryMap.emit(true);
      this.drawAreaOfInterestFromComponent.emit(
        new Circle({
          id: uuidv4(),
          center: { lat: +lat, lng: +lng },
          radiusMeters: radius * 1000,
        })
      );
    } else {
      this.showError = true;
      this.showMessage(
        this.translationService.translate('Invalid values. Please try again.')
      );
    }
  }

  onCountryChange(event) {
    if (event?.iso2) {
      this.countryForm.controls['country'].setValue(event.iso2);
      this.selectedCountry.emit(event.iso2.toUpperCase());
    }
  }

  setGeofenceType(index) {
    let geoType = QueryType.FENCED_COUNTRY;
    if (index === 1) {
      geoType = QueryType.FENCED_AREA;
      this.showSearchTool.emit(true);
      this.showDrawingTools.emit(true);
      this.selectedCountry.emit(null);
    } else {
      this.showSearchTool.emit(false);
      this.showDrawingTools.emit(false);
    }
    this.queryService.advancedQueryType.next(geoType);
  }

  checkForNumbersToBeQueried() {
    const queriesSubmission = this.queryService.numbersToBeQueried.subscribe(
      (numbers) => {
        if (numbers) {
          this.numbersToBeQueried = numbers;
        }
      }
    );
    this.subscriptions.push(queriesSubmission);
  }

  onSubmitCountry() {
    this.checkForNumbersToBeQueried();
    if (this.countryForm.valid && this.numbersToBeQueried.length) {
      const countryQuerySubscription = this.queryService
        .createCountryQuery(this.countryForm.value)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.changeView('logView');
          },
          () => {
            this.showMessage(
              this.translationService.translate('Query has not been created')
            );
          },
          () => {
            this.angulartics2.eventTrack.next({
              action: matomoActions.submitGeofenceQuery,
              properties: {
                category: matomoCategories.gioAdvancedQuery,
              },
            });
          }
        );
      this.subscriptions.push(countryQuerySubscription);
      this.queryService.advancedDataFlag.next(false);
      this.clearQueryMap.emit(true);
    } else {
      this.showError = true;
    }
  }

  onSubmitArea() {
    this.checkForNumbersToBeQueried();
    if (this.areaOfInterestForm.valid && this.numbersToBeQueried.length) {
      const areaQuerySubscription = this.queryService
        .createAreaOfInterestQuery(this.areaOfInterestForm.value)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
            this.resetForm();
            this.changeView('logView');
          },
          () => {
            this.showMessage(
              this.translationService.translate('Query has not been created')
            );
          }
        );
      this.subscriptions.push(areaQuerySubscription);
      this.queryService.advancedDataFlag.next(false);
      this.clearQueryMap.emit(true);
      this.showSearchTool.emit(false);
    } else {
      this.showError = true;
      this.queryService.queriesFlag.next(false);
    }
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }

  protected showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar'],
    });
  }
}
