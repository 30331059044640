import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Chat } from 'datalayer/models/chat';
import { DataSource } from 'datalayer/models/platform-models';
import { Profile } from 'datalayer/models/social';
import { switchMap, take } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseModalHeader } from 'src/app/shared/components/base-modal-component/base-modal-component.component';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { ProfilerService } from '../../services/profiler.service';
import { RelatedPeopleService } from '../../services/related-people.service';
import { PlatformImages } from '../../shared/models/profiler-dashboard-sections.model';
import {
  RelatedPeopleActionModel,
  RelatedPeopleDialogModel,
  RelatedPeopleHelper,
} from './related-people.helper';

@Component({
  selector: 'app-related-people-dialog',
  templateUrl: './related-people-dialog.component.html',
  styleUrls: ['./related-people-dialog.component.scss'],
  providers: [RelatedPeopleHelper],
})
export class RelatedPeopleDialogComponent implements OnInit {
  @Output() closePressed = new EventEmitter<boolean>();
  imageUrl: string;
  public platformImages = PlatformImages;
  headerDetails: BaseModalHeader;
  theme: string;
  performAction: EventEmitter<{ key: string; profile: Profile }> =
    new EventEmitter<{
      key: string;
      profile: Profile;
    }>();
  profile: Profile;
  chats: Chat[];
  messagesExchanged = 0;
  groupMessagesExchanged = 0;
  moreDetails = false;
  groupDetails = false;
  relatedPersonChat: Chat;
  groupChats: Chat[] = [];
  dataSources = DataSource;
  isChatBackButton = false;
  isGroupBackButton = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public relatedPeopleData: RelatedPeopleDialogModel,
    public dialogRef: MatDialogRef<RelatedPeopleDialogComponent>,
    private imageService: ImageService,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    private router: Router,
    private profilerService: ProfilerService,
    private targetService: TargetService,
    private targetRenewalService: TargetRenewalService,
    private webintDisabledService: WebintDisabledService,
    private relatedPeopleService: RelatedPeopleService,
    private relatedPeopleDialogHelper: RelatedPeopleHelper
  ) {
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.chats = this.relatedPeopleData.chats;
    this.moreDetails = this.relatedPeopleData.moreDetails;
    this.groupDetails = this.relatedPeopleData.groupDetails;
    this.isChatBackButton = this.relatedPeopleData.isChatBackButton;
    this.isGroupBackButton = this.relatedPeopleData.isGroupBackButton;
  }

  ngOnInit(): void {
    if (this.relatedPeopleData?.profile?.relations?.length) {
      const { source, profileUrl } =
        this.relatedPeopleDialogHelper.createProfileUrl(
          this.relatedPeopleData.profile.relations
        );
      this.relatedPeopleData.profile.url = profileUrl;
      this.relatedPeopleData.profile.source = source;
    }

    this.profile = this.relatedPeopleData.profile;
    this.imageUrl =
      this.profile.image &&
      typeof this.profile.image === 'string' &&
      this.profile.relations
        ? this.profile.image
        : this.getPhoto(this.profile);

    this.generateHeaderDetails();
    this.groupMessagesExchanged =
      this.profile?.chatsInfo?.group_messages_count || undefined;
    if (this.groupMessagesExchanged) {
      this.groupChats = this.relatedPeopleService.getGroups(
        this.profile,
        this.chats
      );
    }
    this.messagesExchanged =
      this.profile?.chatsInfo?.personal_messages_count || undefined;
    this.generateChatSummaryData(this.chats);
  }

  getPhoto(media, isThumbnail = false): string {
    return <string>(
      (!isEmpty(media.image)
        ? this.imageService.getPhotoUrl(media?.image?.url, isThumbnail)
        : media.imageUrl
        ? this.imageService.getPhotoUrl(media.imageUrl, isThumbnail)
        : 'assets/static/images/user.svg')
    );
  }

  close() {
    this.dialogRef.close();
  }

  addToTarget(): void {
    this.performAction.emit({
      key: RelatedPeopleActionModel.CREATE_TARGET,
      profile: this.profile,
    });
  }

  advancedOsint(): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    this.performAction.emit({
      key: RelatedPeopleActionModel.ADVANCED_OSINT,
      profile: this.profile,
    });
  }

  investigateLead(): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    this.performAction.emit({
      key: RelatedPeopleActionModel.INVESTIGATE_LEAD,
      profile: this.profile,
    });
  }

  closeParent(event) {
    if (event) {
      this.dialogRef.close();
      this.closePressed.emit(event);
    }
  }

  pushBack(event) {
    this.dialogRef.close();
  }

  navigateToUrl(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

  navigateToTarget(profile: Profile): void {
    if (!profile?.profileToTargetInfo?.targetId) {
      return;
    }
    this.getTargetData(profile?.profileToTargetInfo?.targetId);
  }

  getTargetData(targetId: string): void {
    this.targetService
      .getTarget(targetId)
      .pipe(
        take(1),
        switchMap((response) =>
          this.targetRenewalService.isTargetExpiredShowMessageOrRenew(
            [response],
            true
          )
        )
      )
      .subscribe((isTargetActive) => {
        if (!isTargetActive) {
          return;
        }
        this.router.navigate(['targets', targetId, 'overview']);
        this.profilerService.targetData.next(null);
        this.dialogRef.close();
      });
  }

  goToSummary(): void {
    this.moreDetails = true;
  }

  goToGroupDetails(): void {
    this.groupDetails = true;
  }

  goToMain(): void {
    this.moreDetails = false;
    this.groupDetails = false;
  }

  private generateHeaderDetails() {
    if (this.profile.source === DataSource.Somedus) {
      this.headerDetails = {
        platform: { id: DataSource.Telegram, url: '', imgOnly: true },
        label: this.profile.name,
      };
    } else {
      if (this.profile?.telno) {
        this.headerDetails = {
          label: `${this.translationService.translate('Target')} - ${
            this.profile.profileToTargetInfo.alias
          }`,
        };
      } else {
        this.headerDetails = {
          label: this.profile.name,
          platform: {
            id: this.profile.source,
            url: this.profile.url,
          },
          hasParent: this.relatedPeopleData.hasParent,
        };
      }
    }
  }

  private generateChatSummaryData(chats: Chat[]) {
    this.relatedPersonChat =
      this.relatedPeopleDialogHelper.generateChatSummaryData(
        chats,
        this.profile
      );
  }
}
