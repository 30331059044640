import { Component, Input, OnChanges } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexGrid,
} from 'ng-apexcharts';

type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  grid: ApexGrid;
  colors: string[];
};

@Component({
  selector: 'app-apx-radial-bar-chart',
  templateUrl: './apx-radialbar-chart.component.html',
  styleUrls: ['./apx-radialbar-chart.component.scss'],
})
export class ApxRadialBarChartComponent implements OnChanges {
  @Input() labels: string[];
  @Input() series: number[];
  @Input() colors: string[];
  @Input() totalLabel: string;
  @Input() totalValue = 0;

  public chartOptions: Partial<ChartOptions> = {
    series: [],
    colors: [],
    chart: {
      height: 200,
      type: 'radialBar',
      parentHeightOffset: 0,
      width: 175,
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '15px',
          },
          value: {
            fontSize: '15px',
          },
          total: {
            show: true,
            label: 'Total',
            fontSize: '12px',
            fontWeight: 'normal',
            color: 'black',
            formatter: () => `${this.totalValue}%`,
          },
        },
      },
    },
    labels: [],
  };

  constructor() {}

  ngOnChanges(): void {
    this.chartOptions.series = [...this.series];
    this.chartOptions.labels = [
      ...this.labels.map((label) => this.cutLabel(label)),
    ];
    this.chartOptions.colors = [...this.colors];
    this.chartOptions.plotOptions.radialBar.dataLabels.total.label =
      this.cutLabel(this.totalLabel);
  }

  private cutLabel(label: string): string {
    const limit = 15;
    const dotsLength = 3;
    return label.length > 15
      ? `${label.slice(0, limit - dotsLength)}...`
      : label;
  }
}
