import { Injectable, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AnchorPosition,
  Marker,
  TemplateMarker,
} from 'src/app/modules/mapV2/models/map.models';
import {
  CallInfoDirection,
  Query,
} from 'src/app/shared/models/query-item.model';
import { randomString } from 'src/app/shared/util/helper';
import { MapHelperService } from '../map-helper/map-helper.service';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root',
})
export class ExtractPeerMarkerGenerator {
  private readonly DISTANCE_M: number = 2000;
  private readonly BEARING: number = 90;

  constructor(
    private queryService: QueryService,
    private mapHelperService: MapHelperService,
    private extractPeerInfoTemplateRef: TemplateRef<{ query: Query }>,
    private extractPeerRequestTemplateRef: TemplateRef<{ query: Query }>
  ) {}

  public getExtractPeerMarkerAndQuery(
    selectedQuery: Query
  ): Observable<[Marker, Query]> {
    return this.queryService.getChildQueryById(selectedQuery.id).pipe(
      switchMap((query: Query) => {
        if (query) {
          return of(query);
        }
        return selectedQuery?.parentId
          ? this.queryService
              .getLatestQueryById(selectedQuery.parentId)
              .pipe(catchError(() => of(null)))
          : of(null);
      }),
      map((queryFound: Query) =>
        queryFound?.location?.coordinates.length ? queryFound : null
      ),
      map((queryFound: Query) => {
        let marker: Marker;
        if (queryFound) {
          marker = this.generateExtractPeerResultMarker(queryFound);
        }

        if (!queryFound && this.queryHasExtractedPeer(selectedQuery)) {
          marker = this.generateExtractPeerRequestMarker(selectedQuery);
        }
        return [marker, queryFound];
      })
    );
  }

  private queryHasExtractedPeer(query: Query): boolean {
    return (
      !!query?.callInfo?.callDirection &&
      !!query?.callInfo?.oncallNumber &&
      'billingId' in query?.callInfo
    );
  }

  private generateExtractPeerRequestMarker(query: Query): Marker {
    const [fakeLat, fakeLng] = this.mapHelperService.generateFakeGeoPoint(
      query.location.coordinates[1],
      query.location.coordinates[0],
      this.DISTANCE_M,
      this.BEARING
    );

    return new TemplateMarker({
      id: `no-gps-data-request-marker-${randomString()}`,
      lat: fakeLat,
      lng: fakeLng,
      popupHTML: '',
      isPopupWindowOpen: true,
      extendMapBounds: true,
      anchorPosition: AnchorPosition.MIDDLE,
      getEmbeddedView: () =>
        this.extractPeerRequestTemplateRef.createEmbeddedView({
          query: query,
        }),
    });
  }

  private generateExtractPeerResultMarker(query: Query): Marker {
    return new TemplateMarker({
      id: `no-gps-data-info-marker-${randomString()}`,
      lat: query.location.coordinates[1],
      lng: query.location.coordinates[0],
      popupHTML: '',
      isPopupWindowOpen: true,
      extendMapBounds: true,
      anchorPosition: AnchorPosition.MIDDLE,
      getEmbeddedView: () =>
        this.extractPeerInfoTemplateRef.createEmbeddedView({
          query: query,
        }),
    });
  }

  public generateMarkersPolyline(query: Query, peerQuery: Query) {
    const [startFakeLat, startFakeLng] =
      this.mapHelperService.generateFakeGeoPoint(
        query.location.coordinates[1],
        query.location.coordinates[0],
        350,
        this.BEARING
      );

    const [endFakeLat, endFakeLng] = this.mapHelperService.generateFakeGeoPoint(
      query.location.coordinates[1],
      query.location.coordinates[0],
      this.DISTANCE_M - 100,
      this.BEARING
    );

    const lineSymbol = {
      path: 'M 0,-1 0,-1',
      strokeOpacity: 1,
      scale: 2,
      strokeColor: '#404040',
      fillColor: '#404040',
      fillOpacity: 1,
    };

    const arrowIcon = {
      icon: {
        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
        fillColor: 'transparent',
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeColor: '#404040',
      },
      offset: '0%',
    };

    const polylineBody: any = {
      path: [
        {
          lat: peerQuery ? query.location.coordinates[1] : startFakeLat,
          lng: peerQuery ? query.location.coordinates[0] : startFakeLng,
        },
        {
          lat: peerQuery ? peerQuery.location.coordinates[1] : endFakeLat,
          lng: peerQuery ? peerQuery.location.coordinates[0] : endFakeLng,
        },
      ],
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: '0%',
          repeat: '5px',
        },
      ],
    };

    if (
      (!!query?.callInfo?.billingId &&
        query?.callInfo?.callDirection === CallInfoDirection.OUTGOING) ||
      (!!peerQuery?.callInfo?.billingId &&
        peerQuery?.callInfo?.callDirection === CallInfoDirection.INCOMING)
    ) {
      arrowIcon.icon.strokeColor = '#396aff';
      polylineBody.icons[0].icon.strokeColor = '#396aff';
      polylineBody.icons[0].icon.fillColor = '#396aff';
      polylineBody.icons.push(arrowIcon);
      polylineBody.icons.push({ ...arrowIcon, offset: '50%' });
      polylineBody.icons.push({ ...arrowIcon, offset: '100%' });
    }

    if (
      (!!query?.callInfo?.billingId &&
        query?.callInfo?.callDirection === CallInfoDirection.INCOMING) ||
      (!!peerQuery?.callInfo?.billingId &&
        peerQuery?.callInfo?.callDirection === CallInfoDirection.OUTGOING)
    ) {
      arrowIcon.icon.strokeColor = '#ff9800';
      polylineBody.icons[0].icon.strokeColor = '#ff9800';
      polylineBody.icons[0].icon.fillColor = '#ff9800';
      polylineBody.icons.push(arrowIcon);
      polylineBody.icons.push({ ...arrowIcon, offset: '50%' });
      polylineBody.icons.push({ ...arrowIcon, offset: '100%' });
      polylineBody.path.reverse();
    }

    return new google.maps.Polyline(polylineBody);
  }
}
