import { Shape, Point } from '../../models/map.models';
import { MarkerFactory } from 'src/app/modules/mapV2/vanilla-google-map/shared/marker.factory';
import {
  Rectangle,
  Circle,
  Polygon,
} from 'src/app/modules/mapV2/models/map.models';
import { Injectable, ApplicationRef } from '@angular/core';
import { GoogleCircle } from './google-circle';
import { GooglePolygon } from './google-polygon';
import { GoogleShape } from './google-shape';
@Injectable({
  providedIn: 'root',
})
export class ShapeFactory {
  map: google.maps.Map;

  constructor(private appRef: ApplicationRef) {}

  setMap(map: google.maps.Map) {
    this.map = map;
  }

  createCircle(circle: GoogleCircle): google.maps.Circle {
    const gCircle = new google.maps.Circle({
      strokeColor: circle.strokeColor,
      strokeOpacity: circle.strokeOpacity || 0.8,
      strokeWeight: circle.strokeWeight || 2,
      fillColor: circle.fillColor || circle.strokeColor,
      fillOpacity: circle.fillOpacity || 0.2,
      center: new google.maps.LatLng(circle.center.lat, circle.center.lng),
      editable: false,
      draggable: false,
      radius: circle.radiusMeters,
    });
    circle.nativeShapeObject = gCircle;

    if (circle.popupHTML || circle.getPopupEmbeddedView) {
      this.addInfoWindow(circle, gCircle);
    }

    return gCircle;
  }

  createPolygon(polygon: GooglePolygon): google.maps.Polygon {
    const gPolygon = new google.maps.Polygon({
      paths: polygon.points,
      strokeOpacity: polygon.strokeOpacity || 0.8,
      strokeWeight: polygon.strokeWeight || 2,
      fillColor: polygon.fillColor || polygon.strokeColor,
      fillOpacity: polygon.fillOpacity || 0.2,
      strokeColor: polygon.strokeColor,
    });
    polygon.nativeShapeObject = gPolygon;

    if (polygon.popupHTML || polygon.getPopupEmbeddedView) {
      this.addInfoWindow(polygon, gPolygon);
    }

    return gPolygon;
  }

  private openInfoWindow(shape: Shape, infoWindow: google.maps.InfoWindow) {
    let position = null;
    if (shape instanceof Polygon) {
      position = this.getHighestPoint((shape as any).points);
    } else if (shape instanceof Circle) {
      const center = new google.maps.LatLng(shape.center.lat, shape.center.lng);
      position = google.maps.geometry.spherical.computeOffset(
        center,
        shape.radiusMeters,
        0
      );
    } else if (shape instanceof Rectangle) {
      // Todo: Improve position calculation
      position = shape.northEast;
    }

    infoWindow.setPosition(position);
    infoWindow.open(this.map);
  }

  private addInfoWindow(shape: GoogleShape, gShape: google.maps.MVCObject) {
    const infoWindow = new google.maps.InfoWindow({
      content: shape.popupHTML || '',
    });

    gShape.addListener('click', (evt) => {
      if (shape.getPopupEmbeddedView && !shape.popupHTML) {
        const embeddedViewRef = shape.getPopupEmbeddedView();
        embeddedViewRef.markForCheck();
        shape.popupHTML = embeddedViewRef.rootNodes[0];
        infoWindow.setContent(shape.popupHTML);
        this.appRef.attachView(embeddedViewRef);
      }

      if (shape.click) {
        shape.click(shape, gShape);
      }

      if (MarkerFactory.isInfoWindowOpen(infoWindow)) {
        infoWindow.close();
      } else {
        this.openInfoWindow(shape, infoWindow);
      }
    });

    if (shape.isPopupWindowOpen) {
      google.maps.event.trigger(gShape, 'click');
    }

    /*
     * Close the info window when shape is removed from the map
     * Overwrite setMap method
     */
    const objectPrototype = Object.getPrototypeOf(gShape);
    const defaultSetMapMethod = objectPrototype.setMap;
    objectPrototype.setMap = function (map: any) {
      if (!map) {
        infoWindow.close();
      }

      defaultSetMapMethod.call(this, map);
    };

    (gShape as any).openInfoWindow = (show: boolean) => {
      if (show) {
        this.openInfoWindow(shape, infoWindow);
      } else {
        infoWindow.close();
      }
    };

    gShape.set('infoWindow', infoWindow);
  }

  private getHighestPoint(coors: Point[]) {
    let lat = -5000,
      lng = 0,
      i = 0,
      n = coors.length;

    for (; i !== n; ++i) {
      if (coors[i].lat > lat) {
        lat = coors[i].lat;
        lng = coors[i].lng;
      }
    }
    return { lat: lat, lng: lng };
  }
}
