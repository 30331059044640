import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CssOverwritesService {
  constructor() {}

  defaultMainContainerWidth = '100%';
  defaultMainContainerMaxWidth = '1460px';
  mainContainerWidth$ = new BehaviorSubject<string>(
    this.defaultMainContainerWidth
  );
  mainContainerMaxWidth$ = new BehaviorSubject<string>(
    this.defaultMainContainerMaxWidth
  );

  changeMainContainerWidth(width: string) {
    this.mainContainerWidth$.next(width);
    this.mainContainerMaxWidth$.next('100%');
  }

  resetMainContainerWidth() {
    this.mainContainerWidth$.next(this.defaultMainContainerWidth);
    this.mainContainerMaxWidth$.next(this.defaultMainContainerMaxWidth);
  }
}
