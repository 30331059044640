import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewRef,
} from '@angular/core';
import { DayEnum, EveryTwoHoursEnum } from '@trg-commons/data-models-ts';
import { flatten, isEmpty, range } from 'lodash-es';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
@Component({
  selector: 'app-activity-pattern',
  templateUrl: './activity-pattern.component.html',
  styleUrls: ['./activity-pattern.component.scss'],
})
export class ActivityPatternComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() activities: ActivityPattern;
  @Input() showCalendarDays = true;
  @Input() activityLists: { name: string }[] = [];
  activityCell: { top: string; left: string; cell: BaseActivityCell };
  weekDays: string[] = [];
  hoursInterval: string[] = [];
  legend: number[] = [];
  max: number;
  @Output() activityPatternComplete = new EventEmitter<void>();
  enumActivityCellType = EnumActivityCellType;
  private mouseOverTimerId: NodeJS.Timeout;
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.activities &&
      changes.activities.currentValue &&
      !isEmpty(changes.activities.currentValue)
    ) {
      this.generateCalendar(changes.activities.currentValue);
    }
  }

  ngOnInit(): void {
    if (isEmpty(this.activities)) {
      return;
    }
    this.generateCalendar(this.activities);
    this.hideTooltipOnScroll();
  }

  generateCalendar(activities: ActivityPattern): void {
    const values = flatten(
      Object.values(activities).map((v) =>
        Object.values(v).map((val) => val.value || 0)
      )
    );
    this.max = Math.max(...values);
    const step = this.max / 7;
    this.legend = range(7).map((v, i) => Math.ceil((i + 1) * step));
    this.addMissingDays(activities);
    this.generateDaysOfWeek();
    this.generateHoursInterval();
    this.activityPatternComplete.emit();
  }

  generateDaysOfWeek(): void {
    if (this.weekDays && this.weekDays.length > 0) {
      return;
    }
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.weekDays = Object.keys(this.activities).sort(
      (a, b) => days.indexOf(a) - days.indexOf(b)
    );
  }

  generateHoursInterval(): void {
    if (this.hoursInterval && this.hoursInterval.length > 0) {
      return;
    }
    const firstDayHourInterval = Object.values(this.activities)[0];
    Object.keys(firstDayHourInterval).forEach((hour) => {
      this.hoursInterval.push(hour);
    });
  }

  onMouseOver(mouseEventElement: MouseEvent, cell: BaseActivityCell): void {
    if (this.mouseOverTimerId) {
      return;
    }
    this.mouseOverTimerId = setTimeout(() => {
      this.activityCell = {
        left: `${mouseEventElement.clientX}px`,
        top: `${mouseEventElement.clientY + 5}px`,
        cell: cell,
      };
      this.cdRef.markForCheck();
    }, 150);
  }

  onMouseLeave(): void {
    clearTimeout(this.mouseOverTimerId);
    this.mouseOverTimerId = null;
    this.activityCell = null;
    this.cdRef.markForCheck();
  }

  hideTooltipOnScroll(): void {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroyed$), throttleTime(500))
      .subscribe(() => {
        this.activityCell = null;
      });
  }

  private addMissingDays(data: ActivityPattern): void {
    for (const day in DayEnum) {
      if (!data.hasOwnProperty(day)) {
        this.initializeEmptyDay(day as DayEnum, data);
      }
    }
  }

  private initializeEmptyDay(day: DayEnum, data: ActivityPattern): void {
    // the reason we are forced to use any here is because the EveryTwoDays enum
    // has strings as enum identifier instead of compilation tokens, therefore we
    // cannot create an object by pointing directly to the enum value.
    // ideally, we should be able to use EveryTwoDaysEnum.0_2
    const futureTargetActivity: any = {};
    for (const hours in EveryTwoHoursEnum) {
      if (!futureTargetActivity.hasOwnProperty(hours)) {
        futureTargetActivity[hours] = {};
      }
    }
    data[day] = futureTargetActivity;
  }

  ngOnDestroy(): void {
    this.activities = null;
    this.activityCell = null;
  }
}

class BaseActivityCell {
  value: number;
  type: EnumActivityCellType;
  protected constructor(value: number, type: EnumActivityCellType) {
    this.value = value;
    this.type = type;
  }
}

export class GenericActivityCell extends BaseActivityCell {
  details: ActivityCellBreakDown;
  constructor(value: number, details: ActivityCellBreakDown) {
    super(value, EnumActivityCellType.generic);
    this.details = details;
  }
}
export class TemplateActivityCell extends BaseActivityCell {
  templateGenerator: () => ViewRef;
  constructor(value: number, template: () => ViewRef) {
    super(value, EnumActivityCellType.template);
    this.templateGenerator = template;
  }
}
export class ValueOnlyActivityCell extends BaseActivityCell {
  constructor(value: number) {
    super(value, EnumActivityCellType.valueOnly);
  }
}
class ActivityCellBreakDown {
  [key: string]: string;
}

enum EnumActivityCellType {
  generic = 'generic',
  template = 'template',
  valueOnly = 'valuesOnly',
}

export type ActivityPattern = {
  [day in DayEnum]?: { [hour in EveryTwoHoursEnum]?: BaseActivityCell };
};
