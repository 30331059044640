import { Router } from '@angular/router';
import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { head } from 'lodash-es';
import {
  SearchFilters,
  Age,
  IntelFilters,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { jobsList } from 'src/app/modules/search-intel/jobs-list';
import { MapService } from 'src/app/modules/mapV2/vanilla-google-map/shared/map.service';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Angulartics2 } from 'angulartics2';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';

const matomoEventsMap = {
  [IntelFilters.AGE]: matomoActions.age,
  [IntelFilters.LOCATION]: matomoActions.location,
  [IntelFilters.MOREFILTERS]: matomoActions.more,
};

@Component({
  selector: 'app-intel-filters',
  templateUrl: './intel-filters.component.html',
  styleUrls: ['./intel-filters.component.scss'],
})
export class IntelFiltersComponent implements OnChanges {
  @Output() emitFilters = new EventEmitter<SearchFilters>();
  @Input() importFilters: SearchFilters;
  @Input() closeAllFilters: boolean;

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('jobInput') jobInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoJob') matAutocomplete: MatAutocomplete;

  // For handling Each Menu Behaviour
  @ViewChild('locationMenuTrigger') locationMenu: MatMenuTrigger;
  @ViewChild('ageMenuTrigger') ageMenu: MatMenuTrigger;
  @ViewChild('filterMenuTrigger') moreFilterMenu: MatMenuTrigger;
  @ViewChild('currentCityTrigger') currentCityMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) allMenu: MatMenuTrigger;

  theme: string;
  isGeolocTheme = false;
  isWhiteTheme = false;
  filterType = IntelFilters;

  selectedLocation: { country?: string; city?: string; rawAddress?: string } =
    {};
  locationValue: { country?: string; city?: string; rawAddress?: string } = {};
  sliderRange = [13, 90];
  ageFilter: Age = {};
  moreFiltersCount = 0;

  // MoreFilters
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  jobCtrl = new UntypedFormControl();
  locationInput = new UntypedFormControl();
  filteredJobs: Observable<string[]>;
  allJobs = jobsList;
  connections: string[] = [];
  selectedJobs: string[] = [];
  freeTexts: string[] = [];
  searchByKeywordToggle: boolean;
  searchByKeywordFeatureEnabled = false;

  options = {
    types: [],
    // componentRestrictions: { country: 'UA' }
  };
  filterBehaviour = false;
  coords: Point;

  constructor(
    private route: Router,
    private mapService: MapService,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
    private angulartics2: Angulartics2,
    private appConfigService: AppConfigService
  ) {
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.searchByKeywordFeatureEnabled =
      this.appConfigService.getConfigVariable('enableSearchByKeyword');

    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }
    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    // Filteration for Jobs Chip
    this.filteredJobs = this.jobCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map((job: string | null) =>
        job ? this._filter(job) : this.allJobs.slice()
      )
    );

    this.filterBehaviour =
      this.route.url === `/${ApplicationMainPageUrls.WEBINT}`;
  }

  ngOnChanges() {
    if (this.importFilters) {
      this.setImportedFilters();
    }
    if (!this.closeAllFilters && this.allMenu) {
      this.closeAllMenu();
    }
  }

  closeAllMenu() {
    this.locationMenu.closeMenu();
    this.ageMenu.closeMenu();
    this.moreFilterMenu.closeMenu();
  }

  setImportedFilters() {
    if (this.importFilters.location) {
      this.locationInput.setValue(this.importFilters.location.rawAddress);
      this.selectedLocation = this.importFilters.location;
    }
    if (this.importFilters.age) {
      this.sliderRange = [
        this.importFilters.age.min,
        this.importFilters.age.max,
      ];
      this.ageFilter.max = this.importFilters.age.max;
      this.ageFilter.min = this.importFilters.age.min;
    }
    if (this.importFilters.connections) {
      this.connections = this.importFilters.connections;
    }
    if (this.importFilters.jobs) {
      this.selectedJobs = this.importFilters.jobs;
    }
    if (this.importFilters.searchText) {
      this.freeTexts = this.importFilters.searchText;
    }
    this.moreFiltersCount =
      this.connections.length +
      this.selectedJobs.length +
      this.freeTexts.length;
  }

  getCurrentCity() {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        this.coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.getPlace(this.coords);
      },
      (error) => {
        this.showMessage(
          this.translationService.translate('Please enable location access')
        );
      }
    );

    this.currentCityMenu.closeMenu();
    event.stopPropagation();
  }

  getPlace(coords) {
    this.mapService
      .reverseGeocodingGetPlace(coords)
      .subscribe((location: any) => {
        const locationCity =
          location.address.city ||
          location.address.state_district ||
          location.address.country;
        if (locationCity) {
          this.locationValue.city = locationCity;
          this.locationValue.rawAddress = locationCity;
          this.locationInput.setValue(this.locationValue.city);
        }
      });
  }

  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
    });
  }

  addChips(event: MatChipInputEvent, addType: string): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.moreFiltersCount++;
      switch (addType) {
        case IntelFilters.CONNECTIONS:
          this.connections.push(value.trim());
          break;

        case IntelFilters.JOBS:
          this.selectedJobs.push(value.trim());
          break;

        case IntelFilters.FREETEXTS:
          this.freeTexts.push(value.trim());
          break;
      }
    }

    if (input) {
      input.value = '';
    }

    this.jobCtrl.setValue(null);
  }

  removeChips(removeValue: string, removeType: string): void {
    let index: number;
    this.moreFiltersCount--;

    switch (removeType) {
      case IntelFilters.CONNECTIONS:
        index = this.connections.indexOf(removeValue);
        if (index >= 0) {
          this.connections.splice(index, 1);
        }
        break;

      case IntelFilters.JOBS:
        index = this.selectedJobs.indexOf(removeValue);
        if (index >= 0) {
          this.selectedJobs.splice(index, 1);
        }
        break;

      case IntelFilters.FREETEXTS:
        index = this.freeTexts.indexOf(removeValue);
        if (index >= 0) {
          this.freeTexts.splice(index, 1);
        }
        break;
    }
  }

  selectedChips(event: MatAutocompleteSelectedEvent): void {
    this.selectedJobs.push(event.option.viewValue);
    this.jobInput.nativeElement.value = '';
    this.jobCtrl.setValue(null);
    this.moreFiltersCount++;
  }

  public handleAddressChange(address: Address) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(address.adr_address, 'text/html');
    const cityHtml = parsedHtml.getElementsByClassName('locality');
    const countryHtml = parsedHtml.getElementsByClassName('country-name');
    const rawAddress = address.formatted_address;
    if (head(cityHtml)) {
      this.locationValue.city = cityHtml[0].textContent;
    }

    if (head(countryHtml)) {
      this.locationValue.country = countryHtml[0].textContent;
    }
    this.locationValue.rawAddress = rawAddress;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allJobs.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getSearchFilters(): SearchFilters {
    const filters: SearchFilters = {};
    if (this.selectedLocation.rawAddress) {
      filters.location = this.selectedLocation;
    }
    if (Object.keys(this.ageFilter).length) {
      filters.age = this.ageFilter;
    }
    if (this.connections.length) {
      filters.connections = this.connections;
    }
    if (this.selectedJobs.length) {
      filters.jobs = this.selectedJobs;
    }
    if (this.freeTexts.length) {
      filters.searchText = this.freeTexts;
    }
    filters.searchByKeyword = !!this.searchByKeywordToggle;
    return filters;
  }

  onSelectKeywordOption(checked: boolean): void {
    this.searchByKeywordToggle = checked;
    this.exportFilters();
  }

  onApplyFilters(filter: string) {
    this.angulartics2.eventTrack.next({
      action: matomoEventsMap[filter],
      properties: {
        category: matomoCategories.landingPageFilters,
      },
    });
    switch (filter) {
      case IntelFilters.LOCATION:
        this.selectedLocation = this.locationValue;
        this.exportFilters();
        this.locationMenu.closeMenu();
        break;

      case IntelFilters.AGE:
        this.ageFilter = {
          min: this.sliderRange[0],
          max: this.sliderRange[1],
        };
        this.exportFilters();
        this.ageMenu.closeMenu();
        break;

      case IntelFilters.MOREFILTERS:
        this.moreFiltersCount =
          this.connections.length +
          this.selectedJobs.length +
          this.freeTexts.length;
        this.exportFilters();
        this.moreFilterMenu.closeMenu();
        break;
    }
  }

  clearFilters(filter: string) {
    switch (filter) {
      case IntelFilters.LOCATION:
        this.selectedLocation = {};
        this.locationValue = {};
        this.locationInput.setValue('');
        this.exportFilters();
        this.locationMenu.closeMenu();
        break;

      case IntelFilters.AGE:
        this.sliderRange = [13, 90];
        this.ageFilter = {};
        this.exportFilters();
        this.ageMenu.closeMenu();
        break;

      case IntelFilters.MOREFILTERS:
        this.moreFiltersCount = 0;
        this.connections = [];
        this.selectedJobs = [];
        this.freeTexts = [];
        this.exportFilters();
        this.moreFilterMenu.closeMenu();
        break;
    }
  }

  handleMenuBehaviour(filter: string) {
    switch (filter) {
      case IntelFilters.LOCATION:
        this.ageMenu.closeMenu();
        this.moreFilterMenu.closeMenu();
        break;

      case IntelFilters.AGE:
        this.locationMenu.closeMenu();
        this.moreFilterMenu.closeMenu();
        break;

      case IntelFilters.MOREFILTERS:
        this.locationMenu.closeMenu();
        this.ageMenu.closeMenu();
        break;
    }
  }

  exportFilters() {
    const filters: SearchFilters = this.getSearchFilters();
    this.emitFilters.emit(filters);
  }
}
