import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Link } from '@trg-ui/link-analysis';
import {
  directionType,
  filterSliderConfig,
  linkTypes,
  msisdnFilters,
  personFilters,
  relationTypes,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
@Component({
  selector: 'app-la-relation-filters',
  templateUrl: './la-relation-filters.component.html',
  styleUrls: ['./la-relation-filters.component.scss'],
})
export class LaRelationFiltersComponent implements AfterViewInit, OnChanges {
  @Input() data: { relationType?: string; data?: [] };
  @Input() filteredTypes!: linkTypes[];
  @Input() filteredSliderSections!: {
    type: linkTypes;
    selectedValue: number;
  }[];
  @Output() filteredSliderSectionsChange = new EventEmitter<
    { type: linkTypes; selectedValue: number }[]
  >();
  @Output() filteredTypesChange = new EventEmitter<linkTypes[]>();

  imagesPath = 'assets/static/images/';
  personFilters: {
    label: string;
    value: string;
    linkType: linkTypes;
    relation: relationTypes;
  }[] = [];
  msisdnFilters: {
    label: string;
    value: string;
    linkType: linkTypes;
    filterBySlider: boolean;
  }[] = [];
  relationFilterElements: { label: string; filters: any }[] = [];
  showFilterSlider = false;
  filterSliderConfig = filterSliderConfig;
  filterSliderData: {
    type: linkTypes;
    data: Link[];
    direction: directionType;
  } = {
    type: null,
    data: null,
    direction: null,
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filteredSliderSections.currentValue.length) {
      this.showFilterSlider = false;
      this.filterSliderData = { type: null, data: null, direction: null };
    }
  }

  public addToFilteredTypes(
    checked: boolean,
    value: linkTypes,
    relation: relationTypes
  ) {
    if (checked) {
      this.filteredTypes.push(value);
    } else {
      const index = this.filteredTypes.indexOf(value);
      this.filteredTypes.splice(index, 1);
      const filteredSliderSectionIndex = this.filteredSliderSections.findIndex(
        (section) => section.type === value
      );
      this.filteredSliderSections.splice(filteredSliderSectionIndex, 1);
      this.showFilterSlider = false;
    }
    this.filteredTypesChange.emit(this.filteredTypes);
    this.filteredSliderSectionsChange.emit(this.filteredSliderSections);
  }

  public initializeFilters() {
    this.setupPersonFilters();
    this.setupMsisdnFilters();
    this.setupRelationFilterElements();
  }

  public prepareSliderData(
    type: linkTypes,
    label: string,
    showSlider: boolean,
    event?: MatCheckboxChange
  ) {
    if ((event && !event.checked) || !showSlider) {
      return;
    }
    const data = this.data[type];
    const index = this.filteredSliderSections.findIndex(
      (section) => section.type === type
    );
    this.filterSliderConfig.max = data[0].d.count;
    this.filterSliderConfig.min = data[data.length - 1].d.count;
    if (index > -1) {
      this.filterSliderConfig.value =
        this.filteredSliderSections[index].selectedValue;
    } else {
      this.filterSliderConfig.value = data[data.length - 1].d.count;
    }
    this.filterSliderConfig.titleStart =
      'Show nodes that interacted with the target by';
    this.filterSliderConfig.titleEnd = 'times or more';
    this.filterSliderData.direction = type.includes('incoming')
      ? directionType.INCOMING
      : type.includes('outgoing')
      ? directionType.OUTGOING
      : directionType.UNKNOWN;
    this.filterSliderData.type = type;
    this.filterSliderData.data = data;
    this.showFilterSlider = true;
    this.filteredSliderSectionsChange.emit(this.filteredSliderSections);
  }

  public onFilterSliderChange(value: number) {
    this.filterSliderConfig.value = value;
    const index = this.filteredSliderSections.findIndex(
      (section) => section.type === this.filterSliderData.type
    );
    if (index > -1) {
      this.filteredSliderSections[index].selectedValue = value;
    } else {
      this.filteredSliderSections.push({
        type: this.filterSliderData.type,
        selectedValue: value,
      });
    }
  }

  private setupPersonFilters() {
    this.personFilters = [
      {
        label: 'Following on Twitter',
        value: personFilters.TWITTER_FOLLOWING,
        linkType: linkTypes.TWITTER_FOLLOWING,
        relation: relationTypes.TWITTER,
      },
      {
        label: 'Follower on Twitter',
        value: personFilters.TWITTER_FOLLOWER,
        linkType: linkTypes.TWITTER_FOLLOWER,
        relation: relationTypes.TWITTER,
      },
      {
        label: 'Following on Instagram',
        value: personFilters.INSTAGRAM_FOLLOWING,
        linkType: linkTypes.INSTAGRAM_FOLLOWING,
        relation: relationTypes.INSTAGRAM,
      },
      {
        label: 'Follower on Instagram',
        value: personFilters.INSTAGRAM_FOLLOWER,
        linkType: linkTypes.INSTAGRAM_FOLLOWER,
        relation: relationTypes.INSTAGRAM,
      },
      {
        label: 'Facebook Friend',
        value: personFilters.FACEBOOK_FRIEND,
        linkType: linkTypes.FACEBOOK_FRIEND,
        relation: relationTypes.FACEBOOK,
      },
      {
        label: 'Family member on Facebook',
        value: personFilters.FACEBOK_FAMILY,
        linkType: linkTypes.FACEBOK_FAMILY,
        relation: relationTypes.FACEBOOK,
      },
      {
        label: 'Linkedin Employee',
        value: personFilters.LINKEDIN_EMPLOYEE,
        linkType: linkTypes.LINKEDIN_EMPLOYEE,
        relation: relationTypes.LINKEDIN,
      },
      {
        label: 'Group Member on Facebook',
        value: personFilters.FACEBOOK_GROUP_MEMBER,
        linkType: linkTypes.FACEBOOK_GROUP_MEMBER,
        relation: relationTypes.FACEBOOK,
      },
      {
        label: 'Following on Tiktok',
        value: personFilters.TIKTOK_FOLLOWING,
        linkType: linkTypes.TIKTOK_FOLLOWING,
        relation: relationTypes.TIKTOK,
      },
      {
        label: 'Follower on Tiktok',
        value: personFilters.TIKTOK_FOLLOWER,
        linkType: linkTypes.TIKTOK_FOLLOWER,
        relation: relationTypes.TIKTOK,
      },
    ];
  }

  private setupMsisdnFilters() {
    this.msisdnFilters = [
      {
        label: 'Incoming call',
        value: msisdnFilters.INCOMING_CALL,
        linkType: linkTypes.INCOMING_CALL,
        filterBySlider: true,
      },
      {
        label: 'Outgoing call',
        value: msisdnFilters.OUTGOING_CALL,
        linkType: linkTypes.OUTGOING_CALL,
        filterBySlider: true,
      },
      {
        label: 'Incoming sms',
        value: msisdnFilters.INCOMING_SMS,
        linkType: linkTypes.INCOMING_SMS,
        filterBySlider: true,
      },
      {
        label: 'Outgoing sms',
        value: msisdnFilters.OUTGOING_SMS,
        linkType: linkTypes.OUTGOING_SMS,
        filterBySlider: true,
      },
      {
        label: 'Unknown type',
        value: msisdnFilters.UNKNOWN,
        linkType: linkTypes.UNKNOWN,
        filterBySlider: false,
      },
    ];
  }

  private setupRelationFilterElements() {
    this.relationFilterElements = [
      {
        label: 'Person',
        filters: this.personFilters,
      },
      {
        label: 'MSISDN',
        filters: this.msisdnFilters,
      },
    ];
  }
}
