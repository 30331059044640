<ng-container
  *ngIf="{ discoveryV2Enabled: (discoveryV2Enabled$ | async) } as userSettings"
>
  <mat-slide-toggle
    class="toggle"
    [checked]="userSettings.discoveryV2Enabled"
    [labelPosition]="'before'"
    (toggleChange)="toggleDiscoveryModule(userSettings.discoveryV2Enabled)"
  >
    <span class="toggle-label" *ngIf="displayLabel">{{
      'New Discovery' | translate
    }}</span>
    <span class="text-danger"> [BETA]</span>
  </mat-slide-toggle>
</ng-container>
