<div class="change-password-wrp">
  <form
    class="change-password-form assistant-font"
    [formGroup]="changePasswordForm"
  >
    <div class="form-wrp">
      <div class="field-group" *ngIf="currentPassword === ''">
        <label>{{ 'Current password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            formControlName="currentPassword"
            placeholder="{{
              ('Enter' | translate) + ' ' + ('Current password' | translate)
            }}"
            required
          />

          <intellectus-icon-text-button
            class="eye-icon"
            [buttonIcon]="
              this.visibility
                ? fAavailableIcons.eyeVisible
                : fAavailableIcons.eyeHidden
            "
            [matTooltip]="'Show password' | translate"
            buttonSize="xsmall"
            (buttonClick)="this.visibility = !this.visibility"
          >
          </intellectus-icon-text-button>
        </mat-form-field>
      </div>

      <div class="field-group">
        <label>{{ 'New password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            data-qa="NEW_PASSWORD"
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            name="new-password"
            formControlName="newPassword"
            autocomplete="new-password"
            placeholder="{{
              ('Enter' | translate) + ' ' + ('New password' | translate)
            }}"
            required
          />

          <intellectus-icon-text-button
            class="eye-icon"
            [buttonIcon]="
              this.visibility
                ? fAavailableIcons.eyeVisible
                : fAavailableIcons.eyeHidden
            "
            [matTooltip]="'Show password' | translate"
            buttonSize="xsmall"
            (buttonClick)="this.visibility = !this.visibility"
          >
          </intellectus-icon-text-button>
        </mat-form-field>
        <span class="error" *ngIf="errorMsg"
          >{{ errorMsg | translate }}<br />
          {{
            'Please use upper case letters, lower case letters and numbers'
              | translate
          }}</span
        >
      </div>

      <div class="password-strength">
        <p class="strength flex-wrp">
          {{ 'Password strength' | translate }}:
          <mat-progress-bar
            mode="determinate"
            [value]="strengthBar"
          ></mat-progress-bar>
        </p>
        <p class="input-desc">
          {{
            'Use at least 8 characters. Don’t use a password from another site, or something too obvious'
              | translate
          }}
        </p>
      </div>

      <div class="field-group">
        <label>{{ 'Confirm new password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            data-qa="CONFIRM_PASSWORD"
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            [class.disabled]="!isValid"
            formControlName="confirmPassword"
            (keyup)="(validate.match.start)"
            [readonly]="!isValid"
            required
            placeholder="{{
              ('Enter' | translate) + ' ' + ('New password' | translate)
            }}"
            (keydown.enter)="onPasswordChange.emit()"
          />
          <intellectus-icon-text-button
            class="eye-icon"
            [buttonIcon]="
              this.visibility
                ? fAavailableIcons.eyeVisible
                : fAavailableIcons.eyeHidden
            "
            [matTooltip]="'Show password' | translate"
            buttonSize="xsmall"
            (buttonClick)="this.visibility = !this.visibility"
          >
          </intellectus-icon-text-button>
        </mat-form-field>
        <span
          data-qa="NEW_PASSWORD_VALIDATION_ERROR"
          class="error"
          *ngIf="!validate.match.start"
          >{{ 'Please enter the same new password again' | translate }}</span
        >
      </div>
    </div>
  </form>
</div>
