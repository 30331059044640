import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { uniqBy } from 'lodash-es';
import { from, of } from 'rxjs';
import { concatMap, delay, map, retry, switchMap, tap } from 'rxjs/operators';
import { QueryTypeUiPipe } from 'src/app/components/query-list-item/query-type-pipe';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ImageService } from 'src/app/services/image/image.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { AvatarWithPlatform } from 'src/app/shared/components/multiple-avatars/multiple-avatars.component';
import {
  deviceStateDetails as DeviceStateDetails,
  Query,
} from 'src/app/shared/models/query-item.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { RecommendationResponseBody } from 'src/app/shared/modules/recommendations/models/socket-responses.interface';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InvestigationInsightsService } from 'src/app/modules/visual-investigation/services/investigation-insights.service';
import { LocationInsightsDto } from '@trg-commons/gio-data-models-ts';
import { QueryCommand } from '@trg-commons/data-models-ts';
import { InvestigationInsightsStore } from 'src/app/modules/visual-investigation/services/investigation-insights.store';
import { PredictedLocations } from 'src/app/modules/visual-investigation/models/insights.model';

@Component({
  selector: 'app-geolocation-details',
  templateUrl: './geolocation-details.component.html',
  styleUrls: ['./geolocation-details.component.scss'],
  providers: [QueryTypeUiPipe],
})
export class GeolocationDetailsComponent
  extends BaseComponent
  implements OnInit
{
  @Output() toggleSideNav = new EventEmitter<void>();
  @Output() filesDownloadState = new EventEmitter<boolean>();
  @Output() viewOnMap = new EventEmitter<string>();
  @Output() toggleInsights = new EventEmitter<boolean>();
  @Input() recommendations: RecommendationResponseBody;

  query: Query;
  phoneInfo;
  deviceNetworkStatus: string;
  providerSubscriptionStatus: string;
  callDirection: string;
  mobileInfo = [];
  allLocationInfo = [];
  interceptionInfo = [];
  multiSelectQueries = [];
  callInfo = [];
  multiselectData = {
    previousQuery: '',
    nextQuery: '',
    index: 0,
  };
  isMobileResolution;
  serviceIsRunning = false;
  target: TargetItem;
  queryId: string;
  locationDetails = [];
  theme: Themes;
  public avatars: { imProfiles: ImProfile[]; photos: AvatarWithPlatform[] };
  public env = environment;
  public targetDetailsIsFetching: boolean;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  hasWorkLocation = false;
  hasHomeLocation = false;
  workplaceLocationDetails = [];
  homeLocationDetails = [];
  geolocationInsights = false;

  constructor(
    private queryService: QueryService,
    private applicationStateService: ApplicationStateService,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private userBehaviorService: UserBehaviorService,
    private targetService: TargetService,
    private appConfigService: AppConfigService,
    public instantMessagesStore: InstantMessagesStore,
    private imageService: ImageService,
    private translationService: TranslationService,
    private investigationInsightsService: InvestigationInsightsService,
    private investigationInsightsStore: InvestigationInsightsStore
  ) {
    super();
    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  generatePdfReport() {
    this.userBehaviorService
      .userBehavior('geo_export_location_details_pdf')
      .subscribe();
    this.queryService.createPdfReport(this.query).subscribe((data) => {
      this.saveBlob(data, { ...this.query });
    });
  }

  exportSelectedQueries() {
    this.filesDownloadState.emit(true);
    let emissionCount = -1;

    from(this.multiSelectQueries)
      .pipe(
        concatMap((query: Query) => {
          emissionCount++;
          this.userBehaviorService
            .userBehavior('geo_export_location_details_pdf')
            .subscribe();
          return this.queryService
            .createPdfReport(query)
            .pipe(retry(3), delay(800));
        })
      )
      .subscribe(
        (data: Blob) =>
          this.saveBlob(data, { ...this.multiSelectQueries[emissionCount] }),
        (error) => this.filesDownloadState.emit(false),
        () => this.filesDownloadState.emit(false)
      );
  }

  private saveBlob(data: Blob, query: Query) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const { queryArgs = {} } = query;
    const formattedDate = format(new Date(), 'ddMMyyyy_HHmmss');
    saveAs(
      blob,
      `${
        this.queryService.stripPlusSign(queryArgs.telno) || queryArgs.imsi
      }_${formattedDate}.pdf`
    );
  }

  private requestLocationProbabilityInsights(msisdn: string) {
    if (!msisdn) {
      return;
    }

    const commands = [
      QueryCommand.PredictedHomeLocation,
      QueryCommand.PredictedWorkplaceLocation,
    ];
    commands.forEach((command) => {
      const locationsRequest: LocationInsightsDto = {
        msisdns: [msisdn],
        command,
      };
      this.subscriptions.push(
        this.investigationInsightsService
          .createPredictedLocationsInsightsRequest(locationsRequest)
          .subscribe()
      );
    });
  }

  private subscribeToLocationProbabilityInsights() {
    this.subscriptions.push(
      this.investigationInsightsStore.locationProbabilityInsights.subscribe(
        (data) => {
          if (data?.insights?.length) {
            this.setInsightLocation(data);
          }
        }
      )
    );
  }

  setInsightLocation(data: PredictedLocations) {
    if (data.msisdns[0] !== this.query.provider.telno) {
      return;
    }

    for (let i = 0; i < data.insights.length; i++) {
      let probability = data.insights[i].probability * 100;
      if (probability > 95) {
        probability = 95;
      }
      if (
        data.type === QueryCommand.PredictedHomeLocation &&
        data.insights[i].probability > 0.6
      ) {
        this.homeLocationDetails = [];
        this.homeLocationDetails.push(
          {
            label: 'Home',
            value:
              data.insights[i].latitude.toFixed(4) +
              ',' +
              data.insights[i].longitude.toFixed(4),
          },
          { label: 'Probability', value: probability + '%' }
        );
        this.hasHomeLocation = true;
      } else if (
        data.type === QueryCommand.PredictedWorkplaceLocation &&
        data.insights[i].probability > 0.6
      ) {
        this.workplaceLocationDetails = [];
        this.workplaceLocationDetails.push(
          {
            label: 'Work',
            value:
              data.insights[i].latitude.toFixed(4) +
              ',' +
              data.insights[i].longitude.toFixed(4),
          },
          { label: 'Probability', value: probability + '%' }
        );
        this.hasWorkLocation = true;
      }
    }
  }

  loadInsights(query: Query): void {
    if (query == null) {
      return;
    }

    this.requestLocationProbabilityInsights(
      query.queryArgs?.telno ? query.queryArgs.telno : query.provider?.telno
    );
  }

  ngOnInit() {
    const selectedQuerySubscription = this.queryService.onQuerySelection
      .pipe(
        tap((query) => {
          if (!query) {
            return;
          }
          this.targetDetailsIsFetching = true;
          this.target = null;
          this.query = query;
          this.initGeolocationDetails(this.query);
        }),
        switchMap((q) => {
          if (q?.alias && q?.targetId) {
            return this.targetService.getTarget(q.targetId).pipe(
              tap((target) => {
                this.target = target;
              })
            );
          }
          return of(q).pipe(delay(100));
        }),
        switchMap(() => this.targetPhotosCollection$())
      )
      .subscribe((imProfiles) => {
        this.targetDetailsIsFetching = false;
        this.avatars = imProfiles;
      });

    const multipinsSubscription = this.queryService.multiquerySelection
      .pipe(map((data: Query[]) => uniqBy(data, 'id')))
      .subscribe((data: Query[]) => {
        this.multiSelectQueries = data;
        if (this.multiSelectQueries.length > 1) {
          this.multiselectData.index = 0;
          this.multiselectData.nextQuery = this.multiSelectQueries[
            this.multiselectData.index + 1
          ].queryArgs.telno
            ? this.multiSelectQueries[this.multiselectData.index + 1].queryArgs
                .telno
            : this.multiSelectQueries[this.multiselectData.index + 1].queryArgs
                .imsi;
          this.queryService.onQuerySelection.next(
            this.multiSelectQueries[this.multiselectData.index]
          );
        } else if (this.multiSelectQueries.length === 1) {
          this.queryService.onQuerySelection.next(this.multiSelectQueries[0]);
        }
      });

    this.subscriptions.push(selectedQuerySubscription, multipinsSubscription);
    this.subscribeToLocationProbabilityInsights();
  }

  private filterLocationInfo = ({ label, value }) => {
    if (!value) {
      return false;
    }

    if (
      (label === 'Radius' || label === 'Radio' || label === 'Rayon') &&
      value === '-1m'
    ) {
      return false;
    }

    if (
      label === 'Query Id' ||
      label === 'ID de consulta' ||
      label === 'ID de requête' ||
      label === 'Kueri ID'
    ) {
      this.queryId = value;
    }

    return !(
      label === 'Query Id' ||
      label === 'ID de consulta' ||
      label === 'ID de requête' ||
      label === 'Kueri ID'
    );
  };

  async initGeolocationDetails(query) {
    const data = this.queryService.getGeolocationDetails(query);
    this.mobileInfo = data.mobileInfo;
    this.allLocationInfo = data.locationInfo;
    this.phoneInfo = data.phoneInfo;
    this.interceptionInfo = data.interceptionInfo;
    this.callInfo = data.callInfo;
    this.deviceNetworkStatus = this.query?.device?.networkStatus
      ? DeviceStateDetails[query.device.networkStatus].text
      : null;
    this.providerSubscriptionStatus = this.query?.provider?.subscriptionStatus
      ? DeviceStateDetails[query.provider.subscriptionStatus].text
      : null;
    this.locationDetails = this.allLocationInfo.filter(this.filterLocationInfo);
    this.hasHomeLocation = false;
    this.hasWorkLocation = false;
    this.homeLocationDetails = [];
    this.workplaceLocationDetails = [];
    this.loadInsights(query);
  }

  async chargeCallInfoGeoQuery() {
    this.serviceIsRunning = true;
    await this.sleep(2000).then();
    this.queryService.chargeCallInfoGeoQuery(this.query.id).subscribe(
      (query) => {
        if (query.id === this.query.id && query.callInfo) {
          this.query.callInfo = query.callInfo;
          this.initGeolocationDetails(this.query);
        }
        this.serviceIsRunning = false;
      },
      (err) => {}
    );
  }

  nextMultiquery() {
    this.multiselectData.index = this.multiselectData.index + 1;
    this.multiselectData.previousQuery = this.multiSelectQueries[
      this.multiselectData.index - 1
    ].queryArgs.telno
      ? this.multiSelectQueries[this.multiselectData.index - 1].queryArgs.telno
      : this.multiSelectQueries[this.multiselectData.index - 1].queryArgs.imsi;
    this.queryService.onQuerySelection.next(
      this.multiSelectQueries[this.multiselectData.index]
    );
    if (this.multiselectData.index + 1 === this.multiSelectQueries.length) {
      return;
    }
    this.multiselectData.nextQuery = this.multiSelectQueries[
      this.multiselectData.index + 1
    ].queryArgs.telno
      ? this.multiSelectQueries[this.multiselectData.index + 1].queryArgs.telno
      : this.multiSelectQueries[this.multiselectData.index + 1].queryArgs.imsi;
  }

  previousMultiquery() {
    if (this.multiselectData.index > 0) {
      this.multiselectData.index = this.multiselectData.index - 1;
      this.queryService.onQuerySelection.next(
        this.multiSelectQueries[this.multiselectData.index]
      );
      this.multiselectData.nextQuery = this.multiSelectQueries[
        this.multiselectData.index + 1
      ].queryArgs.telno
        ? this.multiSelectQueries[this.multiselectData.index + 1].queryArgs
            .telno
        : this.multiSelectQueries[this.multiselectData.index + 1].queryArgs
            .imsi;
      if (this.multiselectData.index === 0) {
        return;
      }
      this.multiselectData.previousQuery = this.multiSelectQueries[
        this.multiselectData.index - 1
      ].queryArgs.telno
        ? this.multiSelectQueries[this.multiselectData.index - 1].queryArgs
            .telno
        : this.multiSelectQueries[this.multiselectData.index - 1].queryArgs
            .imsi;
    }
  }

  sleep(duration): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, duration);
    });
  }

  fileReader(sms) {
    Swal.fire({
      title: 'SMS',
      text: `${sms}`,
      showCloseButton: true,
    });
  }

  audioPlayer(conversation) {
    Swal.fire({
      title: 'Audio',
      html: `<audio controls>
              <source src="${conversation.file}" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
            <div>
              ${conversation.audioText}
            </div>`,
      showCloseButton: true,
    });
  }

  hideMobileViewGeoDetails() {
    this.dashboardService.showMobileGeoDetails.next(false);
  }

  copyInputMessage() {
    this.showMessage(this.translationService.translate('Copied'));
  }

  viewPointOnMap(position: string) {
    this.viewOnMap.emit(position);
  }
  private targetPhotosCollection$() {
    const telno = this.query?.queryArgs?.telno || this.query?.provider?.telno;
    if (!telno) {
      return of({
        imProfiles: [],
        photos: [],
      });
    }
    return this.instantMessagesStore.filterByMsisdn(telno).pipe(
      map((fetchedProfiles) => {
        const imCollection = this.query?.instantMessageProfiles?.length
          ? this.query.instantMessageProfiles
          : fetchedProfiles;

        const imPhotos = [];
        imCollection?.forEach((profile) => {
          profile.photos?.forEach((photo) => {
            imPhotos.push({
              src: this.imageService.getPhotoUrl(photo.thumbnail) as string,
              platform: profile.platform,
            });
          });
        });

        const targetPhotos =
          this.target?.photos?.map((p) => ({
            src: this.imageService.getPhotoUrl(p) as string,
            platform: null,
          })) || [];

        return {
          imProfiles: imCollection,
          photos: [...targetPhotos, ...imPhotos],
        };
      })
    );
  }

  private changeGeolocationInsights(flag: boolean): void {
    this.geolocationInsights = flag;
    this.toggleInsights.emit(flag);
  }
}
