import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { PurchaseNotificationResponse } from 'datalayer/models/background-jobs/purchase-notifications';

export abstract class GroupNotification {
  public processNotification(
    message:
      | OsintSearchNotificationResponse
      | FeatureNotificationResponse
      | PurchaseNotificationResponse
  ): void {}
  protected cleanAllToasts(): void {}
  protected addToast(
    message:
      | OsintSearchNotificationResponse
      | FeatureNotificationResponse
      | PurchaseNotificationResponse
  ): void {}
}
