import { BillingLimitType, LimitType } from './billing-action.model';

export enum PoolFeatures {
  DEFAULT = 'DefaultPool',
  GEOLOCATION = 'Geolocation',
  WEBINT = 'WEBINT (ONLINE)',
  CALL_LOG = 'Call Log',
  SOMEDUS = 'Somedus',
  OFFLINE_WEBINT = 'WEBINT (OFFLINE)',
  FOREIGN_GEOLOCATION = 'Foreign Discovery',
}

export enum CreditPoolTypes {
  GEOLOCATION = 'geolocation',
  WEBINT = 'webint',
  CALLLOG = 'callLog',
  SOMEDUS = 'somedus',
  OFFLINE_WEBINT = 'offlineWebint',
  FOREIGN_GEOLOCATION = 'foreignGeolocation',
  TOTAL = 'total',
}

export enum WebIntPoolType {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export type CreditPools = {
  [key in CreditPoolTypes]?: number;
};

export const PoolFeaturesLabelsMap: { [key: string]: PoolFeatures } = {
  geolocation: PoolFeatures.GEOLOCATION,
  webint: PoolFeatures.WEBINT,
  callLog: PoolFeatures.CALL_LOG,
  somedus: PoolFeatures.SOMEDUS,
  offlineWebint: PoolFeatures.OFFLINE_WEBINT,
  foreignGeolocation: PoolFeatures.FOREIGN_GEOLOCATION,
};

export const ConcurrentPoolFeaturesLabelsMap: { [key: string]: string } = {
  geolocation: 'Discovery',
  webint: 'Profiling',
  somedus: 'Somedus',
  offlineWebint: 'Profiling',
  callLog: 'Call Log',
  foreignGeolocation: 'Foreign Discovery',
};

export const LimitTypeLabelsMap: { [key in BillingLimitType]: LimitType } = {
  [BillingLimitType.DAILY]: LimitType.DAILY,
  [BillingLimitType.MONTHLY]: LimitType.MONTHLY,
  [BillingLimitType.MAXIMUM]: LimitType.MAXIMUM,
  [BillingLimitType.FIXED]: LimitType.FIXED,
};

export interface AvailablePool {
  value: string;
  label: PoolFeatures;
  unassignCredits: number;
}
