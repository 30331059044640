import { Injectable } from '@angular/core';
import { DataSource } from '@trg-commons/data-models-ts';
import { RequestStatus } from '@trg-commons/gio-data-models-ts';
import { Link, Node } from '@trg-ui/link-analysis';
import { Entity, Event } from '@trg-ui/timeline-visualization';
import { Person } from 'datalayer/models';
import { Chat } from 'datalayer/models/platform-models/chat/chat';
import {
  Company,
  Education,
  Group,
  Post,
  Profile as SocialProfile,
} from 'datalayer/models/social';
import { Place } from 'datalayer/models/social/place';
import { ChatService } from 'datalayer/services/chat/chat.service';
import { PersonService } from 'datalayer/services/person/person/person.service';
import { GroupService } from 'datalayer/services/social/group/group.service';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import {
  EntityType,
  Media,
} from 'src/app/modules/data-layer/models/platform-models';
import { InvestigationDataSourceType } from 'src/app/modules/analysis/shared/models/case-investigations.model';
import { MediaService } from 'src/app/modules/data-layer/services/media/media/media.service';
import { CompanyService } from 'src/app/modules/data-layer/services/social/company/company.service';
import { EducationService } from 'src/app/modules/data-layer/services/social/education/education.service';
import { PlaceService } from 'src/app/modules/data-layer/services/social/place/place.service';
import { PostService } from 'src/app/modules/data-layer/services/social/post/post.service';
import { ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { LinkAnalysisService } from 'src/app/modules/link-analysis/services/link-analysis.service';
import {
  GlyphSize,
  LabelSize,
  commonCustomGroups,
  facebookSpecificCustomGroups,
  linkTypes,
  nodeSize,
  nodeState,
  nodeSubtypes,
  nodeTypes,
  nodeTypesColors,
  relationTypes,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import {
  SocialDataRequests,
  getCheckinFilters,
  getEducationFilter,
  getInstantMessagingProfiles,
  getPhotoFilters,
  getSocialConnectionsFilter,
  getSocialGroupsFilter,
  getSocialPostFilters,
  getSocialProfilesFilter,
  getSomedusConnectionsFilter,
  getTelegramChatFilter,
  getWorkplacesFilter,
} from 'src/app/modules/visual-investigation/models/investigation.model';
import { InvestigationStylesService } from 'src/app/modules/visual-investigation/services/investigation-styles.service';
import { ImageService } from 'src/app/services/image/image.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ClTimelineEntityType } from 'src/app/shared/modules/call-logs-shared/models/cl-timeline-visualization.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { GraphEntitiesDto } from '../models/graph-entities.dto';
import { InvestigationIntelRequest } from '../models/investigation-intel-request.interface';
import { DataGrouping } from '../models/osint-query.model';
import { InvestigationIntelService } from './investigation-intel.service';
import { InvestigationCryptoService } from './investigation-crypto.service';

@Injectable({
  providedIn: 'root',
})
export class DataGatheringService {
  graphData: BehaviorSubject<{ [key: string]: Node | Link }> =
    new BehaviorSubject({});
  entities: BehaviorSubject<{ [key: string]: Entity }> = new BehaviorSubject(
    {}
  );
  events: BehaviorSubject<{ [key: string]: Event }> = new BehaviorSubject({});
  loadedSocialProfiles: { [key: string]: string } = {};
  loadTargetIds: Subject<string[]> = new Subject();
  locationPosts: Subject<Post[]> = new Subject();
  clearingData: Subject<void> = new Subject();
  private locationCheckins: BehaviorSubject<Place[]> = new BehaviorSubject([]);
  public locationCheckins$: Observable<Place[]> =
    this.locationCheckins.asObservable();
  public callLogsFilters$ = new BehaviorSubject<any>(null);
  constructor(
    private socialProfilesService: SocialProfilesService,
    private linkAnalysisService: LinkAnalysisService,
    private investigationStyleService: InvestigationStylesService,
    private translationService: TranslationService,
    private placeService: PlaceService,
    private imageService: ImageService,
    private postsService: PostService,
    private mediaService: MediaService,
    private groupService: GroupService,
    private workplacesService: CompanyService,
    private eduService: EducationService,
    private investigationIntelService: InvestigationIntelService,
    private personService: PersonService,
    private telegramService: ChatService,
    private cryptoService: InvestigationCryptoService
  ) {}

  public clearAll() {
    this.graphData.next({});
    this.entities.next({});
    this.events.next({});
    this.locationPosts.next([]);
    this.loadTargetIds.next([]);
    this.loadedSocialProfiles = {};
  }

  private getFeaturesConnections(targetId: string, profileId: string) {
    this.personService
      .getAll(getSomedusConnectionsFilter(targetId))
      .pipe(map((result) => Object.values(result)))
      .subscribe((persons: Person[]) => {
        if (!persons?.length) {
          return;
        }
        this.createPersonConnections(targetId, persons, profileId);
      });
  }

  public getTargetSocialProfiles(target: TargetItem) {
    return this.socialProfilesService
      .getAll(getSocialProfilesFilter(target.id))
      .pipe(
        tap((result) => {
          this.createTargetSocialProfileNodes(target, Object.values(result));
          this.createSocialProfileNodesFromTarget(target);
        })
      );
  }

  public getTargetInstantMessaginglProfiles(targetId: string) {
    return this.socialProfilesService
      .getAll(getInstantMessagingProfiles(targetId))
      .pipe(
        map((result) => {
          this.createInstantMessagingNodes(targetId, Object.values(result));
        })
      );
  }

  public performOsintQuery(
    request: InvestigationIntelRequest
  ): Observable<{ [key: string]: Node | Link }> {
    return this.investigationIntelService.postRequest(request).pipe(
      map((data) => {
        return new GraphEntitiesDto(
          { nodes: data.nodes, edges: data.edges, entityId: data.entityId },
          this.linkAnalysisService,
          this.investigationStyleService,
          this.translationService
        ).toModel();
      }),
      filter((data) => Object.keys(data)?.length > 0)
    );
  }

  public getCurrentOsintQueriesByStatus(
    requestStatus: RequestStatus,
    groupingId: DataGrouping
  ): Observable<{ [key: string]: Node | Link }[]> {
    return this.investigationIntelService
      .getRequest(requestStatus, groupingId)
      .pipe(
        map((graphData) => {
          return graphData.map((data) => {
            return new GraphEntitiesDto(
              { nodes: data.nodes, edges: data.edges, entityId: data.entityId },
              this.linkAnalysisService,
              this.investigationStyleService,
              this.translationService
            ).toModel();
          });
        }),
        map((data) => {
          return data.filter((element) => Object.keys(element)?.length > 0);
        })
      );
  }

  public getTargetEducationAndWorkplacesData(targetId: string) {
    const callsObj = {};
    callsObj[SocialDataRequests.WORKPLACES] = this.workplacesService
      .getAll(getWorkplacesFilter(targetId))
      .pipe(map((result) => Object.values(result)));
    callsObj[SocialDataRequests.EDUCATION] = this.eduService
      .getAll(getEducationFilter(targetId))
      .pipe(map((result) => Object.values(result)));
    forkJoin(callsObj).subscribe((results) => {
      Object.entries(results).forEach(([key, value]: any[]) => {
        if (key === SocialDataRequests.WORKPLACES) {
          this.createWorkplacesNodes(value, targetId);
        } else {
          this.createEducationNodes(value, targetId);
        }
      });
    });
  }

  private getTelegramChats(targetId: string, profileId: string) {
    this.telegramService
      .getAll(getTelegramChatFilter(targetId))
      .pipe(map((result) => Object.values(result)))
      .subscribe((result) => {
        this.createTelegramChannels(profileId, result);
      });
  }

  public populateOsintDataFromAOI(posts: Post[]) {
    this.handlePosts(posts, '');
  }

  private createSocialProfileLink(
    targetId: string,
    profileId: string,
    color: string,
    platform: relationTypes
  ): Link {
    const link: Link = this.linkAnalysisService.getLink(
      targetId,
      profileId,
      linkTypes.SOCIAL_PROFILE,
      false,
      `#${this.investigationStyleService.getColorById(targetId)}`
    );
    link.c2 = `#${color}`;
    link.d['relation'] = platform;
    return link;
  }

  private createSocialProfileNodeAndEntity(
    target: TargetItem,
    profileId: string,
    platform: relationTypes,
    color: string
  ): { node: Node; entity: Entity } {
    const nodeData = {
      profileId: profileId,
      platform: platform,
      type: nodeTypes.SOCIAL_PROFILE,
      relation: platform,
      parent: target.id,
    };
    const node = this.linkAnalysisService.createNewNode(
      profileId,
      `#${color}`,
      profileId || `${target.alias} ${platform} profile`,
      nodeData,
      nodeSize.SMALL,
      [
        this.linkAnalysisService.getGlyph(`link-analysis/${platform}.svg`, 120),
        this.linkAnalysisService.getIconGlyph('remove_circle', 'ne'),
      ],
      LabelSize.MEDIUM
    );
    node.d.state = nodeState.EXPANDED;
    const entity = {
      type: ClTimelineEntityType.TARGET,
      label: profileId,
      data: {
        target: target.alias,
        type: nodeData.platform,
        profileId: nodeData.profileId,
        profileRelation: nodeData.relation,
        targetData: target,
      },
      color: `#${color}`,
      glyph: true,
    };
    return { node, entity };
  }

  private createSocialProfileNodesFromTarget(target: TargetItem): void {
    const graphData = this.graphData.getValue();
    const entities = this.entities.getValue();
    let updateGraph = false;
    target.socialProfiles?.forEach((targetLink) => {
      const profileId = targetLink.userId;
      if (!this.loadedSocialProfiles[profileId]) {
        const compoundGroupId = `${target.id}-${profileId}`;
        this.investigationStyleService.addIdToGroup(profileId, compoundGroupId);
        const color =
          this.investigationStyleService.getColorByGroup(compoundGroupId);
        const platform = targetLink.platform as relationTypes;
        const link = this.createSocialProfileLink(
          target.id,
          profileId,
          color,
          platform as relationTypes
        );
        graphData[link.id] = link;
        updateGraph = true;
        this.loadedSocialProfiles[profileId] = `#${color}`;
        const { node, entity } = this.createSocialProfileNodeAndEntity(
          target,
          profileId,
          platform,
          color
        );
        graphData[node.id] = node;
        if (!entities[profileId]) {
          entities[profileId] = entity;
        }
      }
    });
    if (updateGraph) {
      this.graphData.next({ ...graphData });
      this.entities.next({ ...entities });
    }
  }

  private createTargetSocialProfileNodes(
    target: TargetItem,
    socialProfiles: SocialProfile[]
  ) {
    const graphData = this.graphData.getValue();
    const entities = this.entities.getValue();
    socialProfiles.forEach((profile) => {
      if (!profile?.profileId || profile?.profileId?.includes('undefined')) {
        return;
      }
      const profileId = profile.profileId.toLowerCase();
      const compoundGroupId = `${target.id}-${profileId}`;
      this.investigationStyleService.addIdToGroup(profileId, compoundGroupId);
      const color =
        this.investigationStyleService.getColorByGroup(compoundGroupId);
      const platform = this.linkAnalysisService.parsePlatform(profile.source);
      const link: Link = this.linkAnalysisService.getLink(
        target.id,
        profileId,
        linkTypes.SOCIAL_PROFILE,
        false,
        `#${this.investigationStyleService.getColorById(target.id)}`
      );
      link.c2 = `#${color}`;
      link.d['relation'] = platform;
      graphData[link.id] = link;
      if (!this.loadedSocialProfiles[profileId]) {
        this.loadedSocialProfiles[profileId] = `#${color}`;
        const groupingItems = this.createCustomGroupingNodes(
          profile.source,
          profileId,
          color
        );
        groupingItems.forEach((item) => {
          graphData[item.id] = item;
        });
        const nodeData = this.linkAnalysisService.getSocialProfileNodeData(
          target.id,
          target.id,
          profile
        );
        const node = this.linkAnalysisService.createNewNode(
          profileId,
          `#${color}`,
          profile.name || `${target.alias} ${platform} profile`,
          nodeData,
          nodeSize.SMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120
            ),
            this.linkAnalysisService.getIconGlyph('remove_circle', 'ne'),
          ],
          LabelSize.MEDIUM
        );
        node.d.state = nodeState.EXPANDED;
        graphData[node.id] = node;
        if (!entities[profileId]) {
          entities[profileId] = {
            type: ClTimelineEntityType.TARGET,
            label: profileId,
            data: {
              target: target.alias,
              type: nodeData.type,
              profileName: nodeData.label,
              profileImage: nodeData.image,
              profileId: nodeData.userId,
              profileRelation: nodeData.relation,
              targetData: target,
            },
            color: `#${color}`,
            glyph: true,
          };
        }
      }
      this.graphData.next({ ...graphData });
      this.entities.next({ ...entities });
      this.getAllTargetSocialProfileData(target.id, profile.profileId);
    });
  }

  private createInstantMessagingNodes(
    targetId: string,
    profiles: SocialProfile[]
  ) {
    const graphData = this.graphData.getValue();
    profiles.forEach((profile) => {
      if (profile.source === 'TG') {
        this.getFeaturesConnections(targetId, profile.sourceEntity.id);
        this.getTelegramChats(targetId, profile.sourceEntity.id);
      }
      let image: string;
      if (profile.image?.url) {
        image = this.imageService.getPhotoUrl(
          profile.image.url,
          true
        ) as string;
      }
      const data = {
        type: nodeTypes.INSTANT_MESSAGING,
        label: profile.name,
      };
      this.investigationStyleService.addIdToGroup(
        profile.profileId,
        profile.source
      );
      const color = `#${this.investigationStyleService.getColorById(
        profile.profileId
      )}`;
      const platform = this.linkAnalysisService.parsePlatform(profile.source);
      const node = this.linkAnalysisService.createNewNode(
        profile.sourceEntity.id,
        color,
        profile.name,
        data,
        nodeSize.XSMALL,
        [
          this.linkAnalysisService.getGlyph(
            `link-analysis/${platform}.svg`,
            120
          ),
        ],
        LabelSize.MEDIUM,
        image
      );
      graphData[node.id] = node;
      if (profile.telno) {
        const link = this.linkAnalysisService.getLink(
          profile.telno,
          node.id,
          linkTypes.INSTANT_MESSAGING,
          false,
          color
        );
        graphData[link.id] = link;
      } else if (profile.email) {
        const emailNode = this.linkAnalysisService.createNewNode(
          profile.email,
          color,
          profile.email,
          { type: nodeTypes.EMAIL },
          nodeSize.XSMALL,
          [],
          LabelSize.SMALL
        );
        const link = this.linkAnalysisService.getLink(
          node.id,
          emailNode.id,
          linkTypes.EMAIL,
          false,
          color
        );
        const elink = this.linkAnalysisService.getLink(
          profile.targetId,
          emailNode.id,
          linkTypes.EMAIL,
          false,
          color
        );
        graphData[emailNode.id] = emailNode;
        graphData[link.id] = link;
        graphData[elink.id] = elink;
      } else {
        const link = this.linkAnalysisService.getLink(
          node.id,
          profile.targetId,
          linkTypes.INSTANT_MESSAGING,
          false,
          color
        );
        graphData[link.id] = link;
      }
    });
    this.graphData.next({ ...graphData });
  }

  private async getAllTargetSocialProfileData(
    targetId: string,
    profileId: string
  ) {
    const callsObj = {};
    callsObj[SocialDataRequests.CHECKINS] = this.placeService
      .getAll(getCheckinFilters(targetId, profileId))
      .pipe(map((result) => Object.values(result)));
    callsObj[SocialDataRequests.POSTS] = this.postsService
      .getAll(getSocialPostFilters(targetId, profileId))
      .pipe(map((result) => Object.values(result)));
    callsObj[SocialDataRequests.PHOTOS] = this.mediaService
      .getAll(getPhotoFilters(targetId, profileId))
      .pipe(map((result) => Object.values(result)));
    callsObj[SocialDataRequests.CONNECTIONS] = this.socialProfilesService
      .getAll(getSocialConnectionsFilter(targetId, profileId))
      .pipe(map((result) => Object.values(result)));
    callsObj[SocialDataRequests.GROUPS] = this.groupService
      .getAll(getSocialGroupsFilter(targetId, profileId))
      .pipe(map((result) => Object.values(result)));
    forkJoin(callsObj).subscribe((results) => {
      Object.entries(transformSnakeToCamel(results)).forEach(
        ([key, value]: any[]) => {
          if (key === SocialDataRequests.CONNECTIONS) {
            this.createSocialConnections(targetId, value);
          } else if (key === SocialDataRequests.CHECKINS && value?.length > 0) {
            this.handleCheckins(value, targetId);
          } else if (key === SocialDataRequests.POSTS) {
            this.handlePosts(value, targetId);
          } else if (key === SocialDataRequests.PHOTOS) {
            this.handlePhotos(value, targetId);
          } else if (key === SocialDataRequests.GROUPS) {
            this.handleFacebookGroups(value, targetId);
          }
        }
      );
    });
  }

  private createSocialConnections(targetId: string, profiles: SocialProfile[]) {
    const graphData = this.graphData.getValue();
    profiles.forEach((profile) => {
      const existingNodeId = profile.profileId.toLowerCase();
      const parentProfileId = profile.sourceEntity?.parentId?.toLowerCase();
      let node: Node;
      const connectionType: linkTypes =
        this.linkAnalysisService.getConnectionType(
          profile.source,
          profile.relationType
        );
      const connectTo = `${this.linkAnalysisService.parsePlatform(
        profile.source
      )}_${profile.relationType.toLowerCase()}_${profile.sourceEntity?.parentId?.toLowerCase()}`;
      graphData[connectTo].hi = false;
      graphData[connectTo].d.emptyCustomGroup = false;
      const groupIdColor = `${targetId}-${profile.sourceEntity?.parentId?.toLowerCase()}`;
      const color =
        this.loadedSocialProfiles[parentProfileId] ||
        `#${this.investigationStyleService.getColorByGroup(groupIdColor)}`;
      const link = this.linkAnalysisService.getSocialConnectionLink(
        connectionType,
        profile.name,
        connectTo,
        profile.profileId.toLowerCase(),
        color
      );
      if (graphData[existingNodeId]) {
        const parent = graphData[existingNodeId].d.parent;
        if (!parent.includes(connectTo)) {
          graphData[existingNodeId].d.parent = [...parent, connectTo];
        }
      } else {
        node = this.linkAnalysisService.getSocialConnectionNode(
          profile,
          connectTo,
          color
        );
        node.d.parent = [node.d.parent];
        if (profile.profileToTargetInfo?.targetId) {
          this.loadTargetIds.next([profile.profileToTargetInfo.targetId]);
        }
        graphData[node.id] = node;
      }
      graphData[link.id] = link;
    });
    this.graphData.next({ ...graphData });
  }

  private createCustomGroupingNodes(
    source: string,
    profileId: string,
    color: string
  ): (Node | Link)[] {
    const graphItems = [];
    let items = commonCustomGroups;
    if (source === DataSource.Facebook) {
      items = items.concat(facebookSpecificCustomGroups);
    }
    const platform = this.linkAnalysisService.parsePlatform(source);
    items.forEach((item) => {
      const node = this.linkAnalysisService.createNewNode(
        `${platform}_${item.type}_${profileId}`,
        `#${color}`,
        this.translationService.translate(item.label),
        {
          type: nodeTypes.CUSTOM_CLUSTER,
          state: nodeState.EXPANDED,
          parent: profileId,
          emptyCustomGroup: true,
        },
        nodeSize.SMALL,
        [
          this.linkAnalysisService.getGlyph(
            `link-analysis/${platform}.svg`,
            120,
            false,
            GlyphSize.SMALL
          ),
        ],
        LabelSize.MEDIUM
      );
      node.hi = true;
      const link = this.linkAnalysisService.getLink(
        node.id,
        profileId,
        linkTypes.GROUP_BELONGS_TO_PROFILE,
        false,
        `#${color}`,
        item.label
      );
      link.a2 = false;
      graphItems.push(node, link);
    });
    return graphItems;
  }

  private handlePosts(posts: Post[], targetId: string) {
    const graphData = this.graphData.getValue();
    const events = this.events.getValue();
    const entities = this.entities.getValue();
    const locationPosts = [];
    posts.forEach((post) => {
      if (post.coordinates?.coordinates?.length) {
        locationPosts.push(post);
      }
      const platform = this.linkAnalysisService.parsePlatform(post.source);
      const data = {
        text: post.title && post.title !== '' ? post.title : post.body,
        url: post.url,
        date: post.publishedAt ? new Date(post.publishedAt) : null,
        post,
        targetId,
        type: EntityType.Post,
      };
      const connectToNode = `${platform}_post_${post.sourceEntity?.parentId?.toLowerCase()}`;
      if (graphData[connectToNode]) {
        graphData[connectToNode].hi = false;
        graphData[connectToNode].d.emptyCustomGroup = false;
      }
      const connectTo = `${post.sourceEntity?.parentId?.toLowerCase()}`;
      const groupIdColor = targetId
        ? `${targetId}-${post.sourceEntity?.parentId?.toLowerCase()}`
        : post.sourceEntity?.parentId?.toLowerCase();
      this.investigationStyleService.addIdToGroup(connectTo, groupIdColor);
      const color =
        this.loadedSocialProfiles[post.sourceEntity?.parentId?.toLowerCase()] ||
        `#${this.investigationStyleService.getColorByGroup(groupIdColor)}`;
      if (
        graphData[post.id] &&
        !graphData[post.id].d.parent?.includes(connectTo)
      ) {
        graphData[post.id].d.parent = [
          ...graphData[post.id].d.parent,
          connectTo,
        ];
      } else {
        const node = this.linkAnalysisService.createNewNode(
          post.id,
          color,
          this.translationService.translate(post.type),
          { ...data, type: nodeTypes.POST, parent: [connectTo] },
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120,
              false,
              GlyphSize.SMALL
            ),
          ],
          LabelSize.SMALL
        );
        graphData[node.id] = node;
      }
      const link = this.linkAnalysisService.getLink(
        post.id,
        connectTo,
        linkTypes.POST_AUTHOR,
        false,
        color
      );
      if (!entities[connectTo]) {
        entities[connectTo] = {
          type: ClTimelineEntityType.SOCIAL_POSTS_NEARBY,
          label: connectTo,
          color: color,
          glyph: true,
        };
      }
      if (post.publishedAt && !isNaN(new Date(post.publishedAt).getTime())) {
        link.d.date = new Date(post.publishedAt);
        events[post.id] = {
          entityIds: [post.sourceEntity?.parentId.toLowerCase() || targetId],
          type: InvestigationDataSourceType.post,
          time: new Date(post.publishedAt),
          data,
        };
      }
      graphData[link.id] = link;
    });
    this.locationPosts.next([...locationPosts]);
    this.graphData.next({ ...graphData });
    this.events.next({ ...events });
    this.entities.next({ ...entities });
  }

  private handlePhotos(photos: Media[], targetId: string) {
    const graphData = this.graphData.getValue();
    const events = this.events.getValue();
    photos.forEach((photo) => {
      if (!graphData[photo.sourceEntity?.parentId?.toLowerCase()]) {
        return;
      }
      const platform = this.linkAnalysisService.parsePlatform(photo.source);
      const connectTo = `${platform}_photo_${photo.sourceEntity?.parentId?.toLowerCase()}`;
      graphData[connectTo].hi = false;
      graphData[connectTo].d.emptyCustomGroup = false;
      const data = {
        image: this.imageService.getPhotoUrl(photo.url),
        text: photo.title || photo.description,
        url: photo.sourceUrl,
        date: photo.publishedAt ? new Date(photo.publishedAt) : null,
        platform,
        targetId,
        media: photo,
        type: EntityType.Photo,
      };
      const groupIdColor = `${targetId}-${photo.sourceEntity?.parentId?.toLowerCase()}`;
      const color =
        this.loadedSocialProfiles[
          photo.sourceEntity?.parentId?.toLowerCase()
        ] || `#${this.investigationStyleService.getColorByGroup(groupIdColor)}`;
      if (
        graphData[photo.id] &&
        !graphData[photo.id].d.parent?.includes(connectTo)
      ) {
        graphData[photo.id].d.parent = [
          ...graphData[photo.id].d.parent,
          connectTo,
        ];
      } else {
        const node = this.linkAnalysisService.createNewNode(
          photo.id,
          color,
          this.translationService.translate(photo.type),
          { ...data, type: nodeTypes.IMAGE, parent: [connectTo] },
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120,
              false,
              GlyphSize.SMALL
            ),
          ],
          LabelSize.SMALL
        );
        graphData[node.id] = node;
      }
      const link = this.linkAnalysisService.getLink(
        photo.id,
        connectTo,
        linkTypes.POSTED_PHOTO,
        false,
        color
      );
      if (photo.publishedAt && !isNaN(new Date(photo.publishedAt).getTime())) {
        link.d.date = new Date(photo.publishedAt);
        events[photo.id] = {
          entityIds: [photo.sourceEntity?.parentId.toLowerCase() || targetId],
          type: InvestigationDataSourceType.photo,
          time: new Date(photo.publishedAt),
          data,
        };
      }
      graphData[link.id] = link;
    });
    this.graphData.next({ ...graphData });
    this.events.next({ ...events });
  }

  private handleCheckins(places: Place[], targetId: string) {
    const graphData = this.graphData.getValue();
    const events = this.events.getValue();
    places.forEach((place) => {
      const colorGroupId = `${targetId}-${place.sourceEntity?.parentId?.toLowerCase()}`;
      const platform = this.linkAnalysisService.parsePlatform(place.source);
      const connectTo = `${platform}_${place.type.toLowerCase()}_${place.sourceEntity?.parentId?.toLowerCase()}`;
      this.investigationStyleService.addIdToGroup(connectTo, colorGroupId);
      const color = `#${this.investigationStyleService.getColorByGroup(
        colorGroupId
      )}`;
      const nodeId = place.id || place.name.toLowerCase().trim();
      const link: Link = this.linkAnalysisService.getLink(
        connectTo,
        nodeId,
        linkTypes.PLACE,
        false,
        color,
        'Check-in'
      );
      link.c2 = this.investigationStyleService.getColorById(connectTo);
      if (
        graphData[nodeId] &&
        !graphData[nodeId].d.parent?.includes(connectTo)
      ) {
        const existingNodeParent = this.graphData[nodeId].d.parent;
        graphData[nodeId].d.parent = [...existingNodeParent, connectTo];
      } else {
        const node: Node = this.linkAnalysisService.createNewNode(
          nodeId,
          color,
          `Check-in: ${place.name}`,
          {
            type: nodeTypes.PLACE,
            label: `Check-in: ${place.name}`,
            parent: [connectTo],
          },
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120,
              false,
              GlyphSize.SMALL
            ),
          ],
          LabelSize.MEDIUM
        );
        graphData[node.id] = node;
      }
      graphData[connectTo].hi = false;
      graphData[connectTo].d.emptyCustomGroup = false;
      if (place.visitedAt && !isNaN(new Date(place.visitedAt).getTime())) {
        link.d.date = new Date(place.visitedAt);
        events[place.id] = {
          entityIds: [place.sourceEntity.parentId || targetId],
          type: InvestigationDataSourceType.checkin,
          time: new Date(place.visitedAt),
          data: {
            name: place.name,
            image: place.image?.url,
            url: place.url,
            platform: platform,
          },
        };
      }
      graphData[link.id] = link;
    });
    this.locationCheckins.next(places.filter((place) => place.coordinates));
    this.events.next({ ...events });
    this.graphData.next({ ...graphData });
  }

  private handleFacebookGroups(groups: Group[], targetId: string) {
    const graphData = this.graphData.getValue();
    groups.forEach((group) => {
      const platform = this.linkAnalysisService.parsePlatform(group.source);
      const groupIdColor = `${targetId}-${group.sourceEntity?.parentId?.toLowerCase()}`;
      const color = `#${this.investigationStyleService.getColorByGroup(
        groupIdColor
      )}`;
      const connectTo = `${platform}_group_${group.sourceEntity?.parentId?.toLowerCase()}`;
      if (
        graphData[group.groupId] &&
        !graphData[group.groupId].d.parent?.includes(connectTo)
      ) {
        graphData[group.groupId].d.parent = [
          ...graphData[group.groupId].d.parent,
          connectTo,
        ];
      } else {
        const node = this.linkAnalysisService.createNewNode(
          group.groupId,
          color,
          group.name,
          {
            type: nodeTypes.FACEBOOK_GROUP,
            image: this.imageService.getPhotoUrl(
              group.image?.url,
              true
            ) as string,
            label: group.name,
            url: group.url,
            description: group.description,
            membersCount: group.membersCount,
            platform: platform,
            parent: [connectTo],
          },
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120,
              false,
              GlyphSize.SMALL
            ),
          ],
          LabelSize.MEDIUM
        );
        graphData[node.id] = node;
      }
      graphData[connectTo].hi = false;
      graphData[connectTo].d.emptyCustomGroup = false;
      const link = this.linkAnalysisService.getLink(
        group.groupId,
        connectTo,
        linkTypes.FACEBOOK_GROUP,
        false,
        color
      );
      graphData[link.id] = link;
    });
    this.graphData.next({ ...graphData });
  }

  private getCompanyNodeData(company: Company) {
    return {
      type: nodeTypes.ORGANIZATION,
      label: company.name,
      subtype: nodeSubtypes.WORKPLACE,
      industry: company.industry,
      url: company.companyUrl,
      companyId: company.companyId,
    };
  }

  private getOrganizationLinkData(organization: Company | Education) {
    const data = {
      type: linkTypes.ORGANIZATION,
      startDate: organization.startDate,
      endDate: organization.endDate,
      organizationName: organization.name,
    };
    if (organization.type === EntityType.Company) {
      data['positionInOrganization'] = (organization as Company).jobTitle;
    } else {
      data['degree'] = (organization as Education).degree;
    }
    return data;
  }

  private createWorkplacesNodes(companies: Company[], targetId: string) {
    const graphData = this.graphData.getValue();
    companies.forEach((company) => {
      const nodeId = company.companyId || company.name;
      const platform = this.linkAnalysisService.parsePlatform(company.source);
      const color = `#${this.investigationStyleService.getColorById(
        company.sourceEntity.parentId.toLowerCase() || targetId
      )}`;
      if (!graphData[nodeId]) {
        const node = this.linkAnalysisService.createNewNode(
          company.companyId || company.name,
          color,
          company.name,
          this.getCompanyNodeData(company),
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120,
              false,
              GlyphSize.SMALL
            ),
          ],
          LabelSize.MEDIUM
        );
        graphData[node.id] = node;
      }
      const link: Link = this.linkAnalysisService.getLink(
        company.sourceEntity.parentId.toLowerCase() || targetId,
        nodeId,
        linkTypes.ORGANIZATION,
        false,
        color
      );
      link.id = `${link.id}_${company.jobTitle}`;
      link.d = this.getOrganizationLinkData(company);
      graphData[link.id] = link;
      if (company.location) {
        const locationNode: Node = this.linkAnalysisService.createNewNode(
          company.location.toLowerCase(),
          nodeTypesColors.PLACE,
          company.location,
          {
            type: nodeTypes.PLACE,
            label: company.location,
            parent: [nodeId],
          },
          nodeSize.XSMALL,
          [],
          LabelSize.MEDIUM
        );
        graphData[locationNode.id] = locationNode;
        const locationLink = this.linkAnalysisService.getLink(
          nodeId,
          locationNode.id,
          linkTypes.PLACE,
          false,
          nodeTypesColors.PLACE
        );
        graphData[locationLink.id] = locationLink;
      }
    });
    this.graphData.next({ ...graphData });
  }

  private createEducationNodes(educationItems: Education[], targetId: string) {
    const graphData = this.graphData.getValue();
    educationItems.forEach((item) => {
      const platform = this.linkAnalysisService.parsePlatform(item.source);
      const color = `#${this.investigationStyleService.getColorById(
        item.sourceEntity.parentId.toLowerCase() || targetId
      )}`;
      const data = {
        type: nodeTypes.ORGANIZATION,
        label: item.name,
        subtype: nodeSubtypes.EDUCATION,
      };
      const node = this.linkAnalysisService.createNewNode(
        item.name,
        color,
        item.name,
        data,
        nodeSize.XSMALL,
        [
          this.linkAnalysisService.getGlyph(
            `link-analysis/${platform}.svg`,
            120,
            false,
            GlyphSize.SMALL
          ),
        ],
        LabelSize.MEDIUM
      );
      const link: Link = this.linkAnalysisService.getLink(
        item.sourceEntity.parentId.toLowerCase() || targetId,
        node.id,
        linkTypes.ORGANIZATION,
        false,
        color
      );
      link.d = this.getOrganizationLinkData(item);
      graphData[node.id] = node;
      graphData[link.id] = link;
    });
    this.graphData.next({ ...graphData });
  }

  private createPersonConnections(
    targetId: string,
    persons: Person[],
    profileId: string
  ) {
    const graphData = this.graphData.getValue();
    persons.forEach((person) => {
      let image: string;
      if (person.image?.url) {
        image = this.imageService.getPhotoUrl(person.image.url, true) as string;
      }
      const data = {
        type: nodeTypes.INSTANT_MESSAGING,
        label: person.names?.length ? person.names[0] : '',
      };
      const color = `#${this.investigationStyleService.getColorById(
        profileId
      )}`;
      const platform = this.linkAnalysisService.parsePlatform('TG'); // should be person.source
      const node = this.linkAnalysisService.createNewNode(
        data.label,
        color,
        data.label,
        data,
        nodeSize.XSMALL,
        [
          this.linkAnalysisService.getGlyph(
            `link-analysis/${platform}.svg`,
            120
          ),
        ],
        LabelSize.MEDIUM,
        image
      );
      const link = this.linkAnalysisService.getLink(
        profileId,
        node.id,
        linkTypes.TELEGRAM_CONNECTION,
        true,
        color
      );
      graphData[link.id] = link;
      graphData[node.id] = node;
      if (person.telnos?.length) {
        const telnoData = {
          type: nodeTypes.MSISDN,
          label: person.telnos[0],
        };
        const telnoNode = this.linkAnalysisService.createNewNode(
          person.telnos[0],
          color,
          person.telnos[0],
          telnoData,
          nodeSize.XSMALL,
          [],
          LabelSize.MEDIUM
        );
        graphData[telnoNode.id] = telnoNode;
        const telnolink = this.linkAnalysisService.getLink(
          node.id,
          telnoNode.id,
          linkTypes.INSTANT_MESSAGING,
          false,
          color
        );
        graphData[telnolink.id] = telnolink;
      }
    });
    this.graphData.next({ ...graphData });
  }

  private createTelegramChannels(profileId: string, chats: Chat[]) {
    const graphData = this.graphData.getValue();
    const color = `#${this.investigationStyleService.getColorById(profileId)}`;
    const platform = this.linkAnalysisService.parsePlatform('TG');
    chats.forEach((chat) => {
      if (chat.chatType.includes('public')) {
        const data = {
          type: nodeTypes.CHAT_CHANNEL,
          label: chat.name,
        };
        const channelNode = this.linkAnalysisService.createNewNode(
          chat.chatId,
          color,
          chat.name,
          data,
          nodeSize.XSMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              120
            ),
          ],
          LabelSize.MEDIUM
        );
        const channelLink = this.linkAnalysisService.getLink(
          profileId,
          channelNode.id,
          linkTypes.CHANNEL_MEMBER,
          false,
          color
        );
        graphData[channelNode.id] = channelNode;
        graphData[channelLink.id] = channelLink;
      } else {
        this.handlePrivateChat(chat, profileId, color, platform);
      }
    });
    this.graphData.next({ ...graphData });
  }

  private handlePrivateChat(
    chat: Chat,
    profileId: string,
    color: string,
    platform: string
  ) {
    const graphData = this.graphData.getValue();
    const content = [];
    if (chat.chatType === 'personal_chat') {
      let otherProfile;
      chat.messages.forEach((message) => {
        if (!otherProfile) {
          otherProfile =
            `${message.fromId}@telegram` === profileId ? null : message.from;
        }
        content.push({
          owner: `${message.fromId}@telegram` === profileId,
          date: new Date(message.date),
          text: message.text, // TODO: check if text is array, join items
          account: message.from,
        });
        const profile = graphData[message.from];
        if (!profile) {
          const data = {
            type: nodeTypes.INSTANT_MESSAGING,
            label: message.from,
          };
          const profileNode = this.linkAnalysisService.createNewNode(
            message.from,
            color,
            message.from,
            data,
            nodeSize.XSMALL,
            [
              this.linkAnalysisService.getGlyph(
                `link-analysis/${platform}.svg`,
                120
              ),
            ],
            LabelSize.MEDIUM
          );
          const profileLink = this.linkAnalysisService.getLink(
            profileId,
            profileNode.id,
            linkTypes.INSTANT_MESSAGING,
            true,
            color
          );
          graphData[profileNode.id] = profileNode;
          graphData[profileLink.id] = profileLink;
        }
        const link = this.linkAnalysisService.getLink(
          profileId,
          otherProfile,
          linkTypes.INSTANT_MESSAGING,
          true,
          color
        );
        link.g = [
          this.linkAnalysisService.getGlyph(`link-analysis/chat.png`, 120),
        ];
        link.d['chat'] = content;
        graphData[link.id] = link;
      });
    }
    this.graphData.next({ ...graphData });
  }
}
