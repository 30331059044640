import { EnvironmentConstants } from './models';
const FASTAPI_HOST = window.location.host;
const wsScheme = window.location.href.startsWith('https') ? 'wss' : 'ws';
const serverAPIUri = `/api/v1`;
export const environmentConstantsDev: EnvironmentConstants = {
  production: false,
  serverAPIUri: `/api/v1`,
  fastAPIUri: `/fastapi/api/v2`,
  reportAPIUri: '/reports',
  reportV2APIUri: '/reports-v2',
  avatarV1API: '/avatars/v1',
  avatarV2API: '/avatars/v2',
  proxyAPIUri: `/server-ts`,
  argusServiceAPI: window.location.origin + `/gw/adint-service`,
  proxyAPIUWebSocketUri: `${wsScheme}://${FASTAPI_HOST}`,
  platformWSManagerUri: `${wsScheme}://${FASTAPI_HOST}/gw/platform-ws-manager`,
  casesTargetsUri: window.location.origin + '/gw/cases-targets',
  discoveryAPIUri: window.location.origin + `/gw/discovery-service`,
  tenantAPIUri: window.location.origin + `/gw/tenant-service`,
  iamAPIUri: window.location.origin + `/gw/iam-service`,
  cdrServiceAPIUri: window.location.origin + `/gw/cdr-location-history`,
  fileManagerUri: window.location.origin + '/file-manager',
  recaptchaV3SiteKey: '6Le3IWcaAAAAAJpRtWK2LaCrQvTg4DaWrrIRuPMw',
  knowledgeBaseUrl: 'intellectus.knewise.xyz/login',
  kbUsername: 'intellectus',
  kbPassword:
    'YCPTJsoo8RPmFaApCdoNLy6NY3pdVvaNMinYCqF8p79ETF9vkGzzuHor6aCknvut',
  googleAPIKey: 'AIzaSyC0kUxs-BnyTuIS80ks-HCLOGJLtX8cqO0',
  intelTypes: {
    analytics: `${serverAPIUri}/analytics`,
  },
  supportEmailDomain: 'trgint.com',
  grafanaDashboardUrl: window.location.origin + ':8488',
  hotjarKey: '',
  entityQueryServiceUri: window.location.origin + '/gw/entity-query-service',
  graphQueryServiceUri: window.location.origin + '/gw/graph-query-service',
};
