import { FeedItem } from 'src/app/shared/models/feed.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import { Note } from 'src/app/shared/models/note.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { BaseService } from 'src/app/services/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends BaseService {
  notesChanged = new BehaviorSubject<any>([]);

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private userBehaviorService: UserBehaviorService,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  getAllNotes() {
    return this.httpClient.get<any>(`${this.url}/all-notes`).pipe(
      map((data) => {
        this.notesChanged.next(data.result);
        return transformSnakeToCamel(data.result);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  getAllTargetNotes(target) {
    const targetId = target.id;
    const params = {};
    params['entity_id'] = targetId;
    return this.httpClient.get<any>(`${this.url}/notes`, { params }).pipe(
      map((data) => {
        this.notesChanged.next(transformSnakeToCamel(data.result));
        return transformSnakeToCamel(data.result);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  createNote(note: Note, target) {
    const targetId = target.id;
    return this.httpClient
      .post<any>(`${this.url}/notes`, {
        title: note.title,
        description: note.description,
        entity_id: targetId,
        entity_type: 'target',
        created_at: new Date(),
      })
      .pipe(
        map((data) => {
          this.getAllTargetNotes(target).subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
  }

  createNoteProfiler(note: Note): Observable<FeedItem> {
    this.userBehaviorService.userBehavior('osint_feed_add_note').subscribe();
    return this.httpClient
      .post<any>(`${this.url}/notes`, {
        title: note.title || null,
        description: note.description || '',
        entity_id: note.entityId,
        entity_type: note.entityType,
        note_date: note.noteDate || new Date(),
        location: note.location || null,
        image: note.image || null,
        media: note.media || null,
        video: note.video || null,
        article_content: note.articleContent || null,
        article_title: note.articleTitle || null,
        url: note.url || null,
        place: note.place || null,
        retweet_Count: note.retweetCount || null,
        favorite_Count: note.favoriteCount || null,
        published_at: note.publishedAt || null,
        valid_at: note.publishedAt || null,
      })
      .pipe(
        tap((data) => {
          this.getAllNotes().subscribe();
        }),
        map((data) => transformSnakeToCamel(data.result)),
        catchError((error) => this.handleError(error))
      );
  }

  editNote(newNote, currentNoteId, target) {
    return this.httpClient
      .put<any>(`${this.url}/notes/${currentNoteId}`, {
        title: newNote.title,
        description: newNote.description,
      })
      .pipe(
        map((data: any) => {
          this.getAllTargetNotes(target).subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
  }

  deleteNote(id, target) {
    return this.httpClient.delete<any>(`${this.url}/notes/${id}`).pipe(
      map((data: any) => {
        this.getAllTargetNotes(target).subscribe();
      }),
      catchError((error) => this.handleError(error))
    );
  }
}
