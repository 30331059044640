import { Component, HostBinding } from '@angular/core';
import { LayoutFacade } from '@fe-platform/core/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';
import { FontAwesomeFamily } from '../icons/fa-icon-family.enum';
import { FontAwesomeIcon } from '../icons/fa-icon.interface';
import { BaseToasterAction } from './base-toaster.model';
@UntilDestroy()
@Component({
  selector: 'intellectus-base-toaster',
  templateUrl: './base-toaster.component.html',
  styleUrls: ['./base-toaster.component.scss'],
  providers: [LayoutFacade],
})
export class BaseToasterComponent extends Toast {
  public closeIcon: FontAwesomeIcon = {
    family: FontAwesomeFamily.REGULAR,
    name: 'xmark',
  };

  @HostBinding('class') class = '';
  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    private layouFacade: LayoutFacade
  ) {
    super(toastrService, toastPackage);
    this.layouFacade.isMobile$
      .asObservable()
      .pipe(
        untilDestroyed(this),
        tap((isMobile) => {
          this.class = isMobile ? 'is-mobile' : '';
        })
      )
      .subscribe();
  }

  onAction(action: BaseToasterAction) {
    action.callback();
    this.remove();
  }

  close() {
    this.remove();
  }
}
