import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-credit-pools-gauge',
  templateUrl: './credit-pools-gauge.component.html',
  styleUrls: ['./credit-pools-gauge.component.scss'],
})
export class CreditPoolsGaugeComponent implements OnChanges {
  @Input() isMobile: boolean;
  @Input() series: number[] = [];
  @Input() labels: string[] = [];
  @Input() gaugeLabel: string;
  @Input() gaugeValue: number;
  @Input() maxSeries: number[] = [0];

  constructor() {}

  colors = ['#ffcc80', '#ef5680', '#396aff', '#5FD068'];

  mobileColors = ['#4371FD', '#EE5680', '#F7CE8B', '#6DD375'];

  bullets: { label: string; value: number; color: string }[] = [];

  ngOnChanges() {
    this.bullets = this.series.map((value, index) => ({
      label: this.labels[index],
      color: this.isMobile ? this.mobileColors[index] : this.colors[index],
      value: this.getUsedValue(value, this.maxSeries[index]),
    }));

    this.series = this.series.map((value, index) =>
      this.getUsedValue(value, this.maxSeries[index])
    );
  }

  private getUsedValue(givenValue: number, maxValue: number): number {
    return givenValue > 0
      ? Number(((givenValue / maxValue) * 100).toFixed())
      : 0;
  }
}
