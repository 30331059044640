import { IndividualConfig } from 'ngx-toastr';

export const BaseToasterIndividualConfig: IndividualConfig = {
  disableTimeOut: false,
  timeOut: 3000,
  extendedTimeOut: 1000,
  closeButton: true,
  progressBar: false,
  toastClass: 'intellectus-message-toaster',
  progressAnimation: 'increasing',
  enableHtml: true,
  positionClass: 'toast-top-center',
  titleClass: 'toast-title',
  messageClass: 'toast-message',
  easing: 'ease-in',
  easeTime: 300,
  tapToDismiss: false,
  onActivateTick: false,
  newestOnTop: true,
  payload: null,
};
