import { get } from 'lodash-es';

const getNotificationText = (notification) => {
  const { notificationType } = notification;
  return notificationToTextMapper[notificationType]
    ? notificationToTextMapper[notificationType](notification)
    : '';
};

// TODO: translate text
const notificationToTextMapper = {
  'geoquery:area:target': ({ content }) => {
    const { target, area, inside } = content;
    const enteredOrLeft = inside ? 'entered' : 'left';
    return `Target ${target.alias} ${enteredOrLeft} area ${area.name}`;
  },

  'geoquery:area:simple': ({ content }) => {
    const { area, inside } = content;
    const enteredOrLeft = inside ? 'entered' : 'left';
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} ${enteredOrLeft} area ${area.name}`;
  },

  'geoquery:geofence:simple': ({ content }) => {
    const { area, inside } = content;
    const enteredOrLeft = inside ? 'entered' : 'left';
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} ${enteredOrLeft} area ${area.name}`;
  },

  'geoquery:country:simple': ({ content }) => {
    const { countryCode, inside } = content;
    const enteredOrLeft = inside ? 'entered' : 'left';
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} ${enteredOrLeft} country ${countryCode}`;
  },

  'geoquery:poi:simple': ({ content }) => {
    const { poiTypes } = content;
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    const poisText = poiTypes.join(', ');
    return `${telno} near POIs: ${poisText}`;
  },

  'geoquery:proximity:simple': ({ content }) => {
    const { near } = content;
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} near: ${near}`;
  },

  'geoquery:logon:simple': ({ content }) => {
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} logged on`;
  },

  'geoquery:logon:status:off': ({ content }) => {
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} is Offline. Last Known Location found.`;
  },

  'geoquery:logoff:simple': ({ content }) => {
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `${telno} logged off`;
  },

  emergency: ({ content }) => {
    const telno = get(content, 'query.queryArgs.telno', '(telno missing)');
    return `Emergency! ${telno} is on emergency!`;
  },

  'geoquery:schedule:expired': ({ content }) => {
    const queryArgs = content.schedule.payload.queryArgs;
    const target = queryArgs.telno || queryArgs.imsi;
    return `${target} scheduled task has finished!`;
  },

  push: ({ content }) => {
    return `${content.subject} : ${content.text}`;
  },

  releasenotes: ({ content }) => {
    return `${content.subject}`;
  },

  'locationhistory:notfound': ({ content }) => {
    return `Location History Not Found`;
  },

  'locationhistory:notfound:telcell': ({ content }) => {
    return `Location History Not Found`;
  },

  'locationhistory:found': ({ content }) => {
    return `Location History Found : ${content.queriesCount} entries were imported`;
  },

  'tactical:notifyOnCapture': ({ content }) => {
    return `${content.queryArgs.imsi} has been captured on ${content.satellite}.`;
  },

  'radicalmonitoring:notifyOnDetect': ({ content }) => {
    return `${content.content} has been detected on target ${content.targetTelno}.`;
  },

  'querycallinfo:notifyOnDetect': ({ content }) => {
    return `${content.text} has been detected on target`;
  },

  'calllogrequests:notifyUserOnSupportUpload': ({ content }) => {
    const callLogUpload = get(content, 'callLogUpload');
    return `Call log request for ${callLogUpload.targetTelno} has been successfully uploaded.`;
  },

  'advancedgeoquery:notifyUserOnAdvancedQueryResponse': ({ content }) => {
    const advancedGeoQuery = get(content, 'advancedGeoQuery');
    return `${advancedGeoQuery.targetTelno} was located successfully.`;
  },

  'live-session': ({ content }) => {
    return `${content.liveSession.url} pass:${content.liveSession.password}`;
  },
};

export default getNotificationText;
