import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { omit } from 'lodash-es';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { Notifications } from 'datalayer/models/background-jobs/notification-response';

@Injectable({
  providedIn: 'root',
})
export class OsintNotificationsStore {
  private obs$ = new BehaviorSubject<Notifications>({});
  public notifications$ = this.obs$.asObservable();

  private get value() {
    return this.obs$.getValue();
  }

  /**
   *
   * @param value Notifications
   */
  private set value(value: Notifications) {
    this.obs$.next(value);
  }

  /**
   *
   * @param newNotification OsintSearchNotificationResponse
   */
  public addNotification(newNotification: OsintSearchNotificationResponse) {
    const curNotificationsById = this.value[newNotification.target_id];

    this.value = {
      ...this.value,
      [newNotification.target_id]: curNotificationsById
        ? [...curNotificationsById, newNotification]
        : [newNotification],
    };
  }

  /**
   *
   * @param id string
   */
  cleanNotificationsById(id: string) {
    this.value = omit(this.value, id);
  }

  /**
   *
   * @param id string
   */
  hasNotificationWithId(id: string) {
    return this.value.hasOwnProperty(id);
  }

  /**
   *
   * @param id string
   */
  hasDoneNotification(id: string) {
    const curNotificationsById = this.value[id];

    return curNotificationsById
      ? curNotificationsById.some((n) => n.status === JobStatus.DONE)
      : false;
  }
}
