import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { formatDate } from '@angular/common';

type ChartOptions = {
  series: ApexAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-feed-line-chart',
  templateUrl: './feed-line-chart.component.html',
  styleUrls: ['./feed-line-chart.component.scss'],
})
export class FeedLineChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @Input() buildChart: BuildLineChart;
  constructor() {}

  public initChartData(): void {
    const currentDateTime = new Date();
    const formattedDateTime = formatDate(
      currentDateTime,
      'dd-MM-yyyy HH-mm',
      'en-US'
    );
    const featureName = this.buildChart.featureName || 'feature';
    const targetName = this.buildChart.targetName || 'target';

    const baseFilename = `${targetName}-${featureName}-${formattedDateTime}`;

    this.chartOptions = {
      series: this.buildChart.dataSet,
      tooltip: {
        x: {
          format: 'dd/MM/yyyy',
        },
      },
      chart: {
        height: 380,
        type: 'line',
        toolbar: {
          export: {
            csv: { filename: baseFilename },
            svg: { filename: baseFilename },
            png: { filename: baseFilename },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        width: 2,
        dashArray: 0,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      title: {
        text: '',
        align: 'left',
      },
      labels: this.buildChart.labels,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
    };
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.initChartData();
  }
}

export interface BuildLineChart {
  dataSet: { data: number[]; name: string }[];
  labels?: string[];
  featureName?: string;
  targetName?: string;
}
