export type IntelSearchState = {
  count: number;
  searchText: string;
  searchType: string;
  fileName?: string;
  fileNames?: string[];
  updatedAt: Date;
  statuses: Record<string, boolean>;
  hidden: boolean;
  temp: boolean;
  hideNullableSources?: boolean;
  inView: boolean;
};
export const initialSearchState: IntelSearchState = {
  count: 0,
  searchText: '',
  searchType: '',
  statuses: {},
  updatedAt: new Date(),
  hidden: false,
  temp: false,
  inView: false,
};
