import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-analysis-loader',
  templateUrl: './link-analysis-loader.component.html',
  styleUrls: ['./link-analysis-loader.component.scss'],
})
export class LinkAnalysisLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
