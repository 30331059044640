<div class="wrapper">
  <div class="search-container">
    <div class="progress-bar" *ngIf="!completed">
      <ngx-gauge
        [type]="'full'"
        [value]="state.temp ? 10 : progressBarValue"
        [thick]="3"
        [size]="44"
        [cap]="'round'"
        [foregroundColor]="
          progressBarValue || state.temp > 0 ? foregroundColor : backgroundColor
        "
        [backgroundColor]="backgroundColor"
      >
        <ngx-gauge-value class="progress-value">
          <ng-container *ngIf="state.count > 0">{{ state.count }}</ng-container>
        </ngx-gauge-value>
      </ngx-gauge>
    </div>

    <div class="icon" *ngIf="completed">
      <ng-container
        [ngTemplateOutlet]="icon"
        [ngTemplateOutletContext]="{ icon: icons[state.searchType] }"
      ></ng-container>
    </div>

    <div class="search-info">
      <span class="heading">
        <ng-container *ngIf="state.temp; else defaultView">{{
          'Initialising search for' | translate
        }}</ng-container>

        <ng-template #defaultView>
          <ng-container *ngIf="!completed">
            {{ 'Searched sources for' | translate }}
          </ng-container>

          <ng-container *ngIf="completed">
            {{ state.updatedAt | date: 'dd/MM/yyyy HH:mm' }}
          </ng-container>
        </ng-template>
      </span>

      <div
        class="search-query"
        [matTooltip]="
          state.searchType === 'photo'
            ? state.searchText +
              (!!state.fileName ? ' and ' + state.fileName : '')
            : state.searchText
        "
        [matTooltipPosition]="'below'"
        [matTooltipClass]="'search-query-tooltip'"
      >
        <span>{{ state.searchText }}</span>
        <ng-container *ngIf="state.searchType === 'photo'">
          <ng-container *ngFor="let fileName of state.fileNames">
            <span class="and">&nbsp;{{ 'and' | translate }}&nbsp;</span>
            <span>{{ fileName }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="action" *ngIf="completed">
    <span class="link" (click)="viewClicked.emit()">{{
      'View' | translate | lowercase
    }}</span>

    <ng-container
      [ngTemplateOutlet]="icon"
      [ngTemplateOutletContext]="{ icon: faChevronRight }"
    ></ng-container>
  </div>
</div>

<ng-template #icon let-icon="icon">
  <fe-platform-fa-pro-icon
    *ngIf="!!icon"
    [family]="icon.family"
    [name]="icon.name"
  ></fe-platform-fa-pro-icon>
</ng-template>
