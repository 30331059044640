import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IntelSearchState } from '../../../services/intel-search-tracker/type/intel-search-state';
import { calculateCompletedItems } from '../../../services/intel-search-tracker/intel-search-tracker.service';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import { TranslationService } from '../../../services/translation/translation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-intel-search-drawer-header',
  templateUrl: './intel-search-drawer-header.component.html',
  styleUrls: ['./intel-search-drawer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntelSearchDrawerHeaderComponent {
  @Input('state')
  public set setState(state: IntelSearchState) {
    this.state = state;
    const totalItems: number = Object.keys(this.state?.statuses)?.length;
    this.progressBarValue =
      (calculateCompletedItems(this.state) / totalItems) * 100;
  }

  @Input()
  public id: string;

  @Input()
  public completed: boolean;

  @Output()
  public readonly viewClicked: EventEmitter<void> = new EventEmitter<void>();

  public state: IntelSearchState;

  public progressBarValue: number;

  public readonly foregroundColor: string = '#3B7AFF';

  public readonly backgroundColor: string = '#F7F7F7';

  protected readonly faChevronRight: FontAwesomeIcon = {
    name: 'chevron-right',
    family: FontAwesomeFamily.SOLID,
  };

  public readonly icons: Record<string, FontAwesomeIcon> = {
    name: { name: 'user-vneck', family: FontAwesomeFamily.SOLID },
    nameWithSurname: { name: 'user-vneck', family: FontAwesomeFamily.SOLID },
    nameWithMiddleAndSurname: {
      name: 'user-vneck',
      family: FontAwesomeFamily.SOLID,
    },
    username: { name: 'user', family: FontAwesomeFamily.REGULAR },
    telno: { name: 'mobile-screen-button', family: FontAwesomeFamily.SOLID },
    url: { name: 'link', family: FontAwesomeFamily.SOLID },
    email: { name: 'envelope', family: FontAwesomeFamily.REGULAR },
    curp: { name: 'id-card-clip', family: FontAwesomeFamily.SOLID },
    nik: { name: 'id-card-clip', family: FontAwesomeFamily.SOLID },
    photo: { name: 'image', family: FontAwesomeFamily.SOLID },
  };

  constructor(
    private readonly translationService: TranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.translationService.languageChange
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdRef.markForCheck());
  }
}
