import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
})
export class ProgressLoaderComponent implements OnInit {
  @Input() ProgressLoaderValue: number;
  loaderInterval;

  constructor() {}

  ngOnInit() {
    if (!this.ProgressLoaderValue) {
      this.ProgressLoaderValue = 5;
      this.loaderInterval = setInterval(() => {
        if (this.ProgressLoaderValue < 95) {
          this.ProgressLoaderValue += 5;
        } else {
          clearInterval(this.loaderInterval);
        }
      }, 1000);
    }
  }
}
