// here is the default text string
import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@busacca/ng-pick-datetime';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  language = 'en';

  constructor(language: string) {
    super();
    this.language = language;

    // TODO: Needs to fix the DateTimePicker to translate in real-time, currently it only works on hard refresh
    switch (this.language) {
      case 'en':
        this.cancelBtnLabel = 'Cancel';
        this.setBtnLabel = 'Set';
        this.rangeFromLabel = 'From';
        this.rangeToLabel = 'To';
        break;
      case 'es':
        this.cancelBtnLabel = 'Cancelar';
        this.setBtnLabel = 'Seleccionar';
        this.rangeFromLabel = 'De';
        this.rangeToLabel = 'A';
        break;
      case 'fr':
        this.cancelBtnLabel = 'Annuler';
        this.setBtnLabel = 'Ensemble';
        this.rangeFromLabel = 'De';
        this.rangeToLabel = 'À';
      case 'id':
        this.cancelBtnLabel = 'Batal';
        this.setBtnLabel = 'Atur';
        this.rangeFromLabel = 'Dari';
        this.rangeToLabel = 'Ke';
    }
  }
}
