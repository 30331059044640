import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterInvestigationDialogComponent } from '../../shared/components/filter-investigation-dialog/filter-investigation-dialog.component';
import { CdrQueryParamsService } from '../../shared/services/cdr-query-params.service';
import { ProfilerService } from '../../modules/profiler/services/profiler.service';
import { CaseInvestigationService } from '../../modules/analysis/shared/services/case-investigations.service';
import { TargetService } from '../target/target.service';
import { CaseService } from '../case/case.service';
import {
  CallLogsListResponseModel,
  CLPaginatedResponse,
} from '../../modules/analysis/shared/models/targets-list-request.model';
import { head } from 'lodash';
import { AnalysisUtilService } from '../../modules/analysis/shared/services/analysis-util.service';
import { AnalysisService } from '../../modules/analysis/shared/services/analysis.service';
import { isArray } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class OpenFilterInvestigationDialogGuard implements CanActivate {
  constructor(
    public dialog: MatDialog,
    private cdrQueryParamsService: CdrQueryParamsService,
    private profilerService: ProfilerService,
    private targetService: TargetService,
    private caseService: CaseService,
    private analysisUtilService: AnalysisUtilService,
    private analysisService: AnalysisService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.cdrQueryParamsService.formatAndStoreData(route.queryParams);
      const paramKeys = Object.keys(route.queryParams);
      let msisdns = [];
      let key = paramKeys[0];
      if (key === 'returnUrl') {
        key = paramKeys[1];
      }
      switch (key) {
        case 'targetIds':
          for (const id of route.queryParams['targetIds']) {
            const target = await firstValueFrom(
              this.targetService.getTarget(id)
            );
            msisdns.push(target?.telnos);
          }
          break;
        case 'caseIds':
          for (const id of route.queryParams['caseIds']) {
            const caseTargets = await firstValueFrom(
              this.caseService.getCaseTargets(id)
            );
            for (const target of caseTargets) {
              msisdns.push(target?.telnos);
            }
          }
          break;
        case 'requestIds':
          const requestId = route.queryParams['requestId'];
          if (isArray(requestId)) {
            for (const id of route.queryParams['requestIds']) {
              const callLog = await firstValueFrom(
                this.fetchCallLogsRequestData(id)
              );
              msisdns.push(callLog?.msisdns);
            }
          } else {
            const callLog = await firstValueFrom(
              this.fetchCallLogsRequestData(requestId)
            );
            msisdns.push(callLog?.msisdns);
          }
          break;
        default:
          resolve(true);
          return;
      }
      msisdns = msisdns.filter((elem) => elem != '');
      if (msisdns.length === 0) {
        resolve(true);
      } else {
        const { hasCdrs } = await firstValueFrom(
          this.profilerService.checkIfTargetHasCdr(msisdns)
        );

        if (hasCdrs) {
          const dialog = this.dialog.open(FilterInvestigationDialogComponent, {
            width: '600px',
            data: {
              queryParams: this.cdrQueryParamsService.getData(),
            },
          });
          const dialogResult = await firstValueFrom(dialog.afterClosed());
          if (dialogResult === 'applyFilters') {
            resolve(true);
          } else if (dialogResult.key === 'cancel') {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      }
    });
  }
  private fetchCallLogsRequestData(
    requestId: string
  ): Observable<CallLogsListResponseModel> {
    const requestBody = this.analysisUtilService.buildClRequestPayload({
      requestId: requestId,
      pageSize: '1',
      pageNumber: '1',
      createdBy: [],
    });
    return this.analysisService.getCallLogsList(requestBody).pipe(
      map((resp) => {
        const callLogsData: CLPaginatedResponse = head(resp);
        return callLogsData?.paginatedResults[0];
      })
    );
  }
}
