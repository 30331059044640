import { ElementRef, Injectable } from '@angular/core';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { ImageType } from 'src/app/shared/models/image-type.enum';

@Injectable({
  providedIn: 'root',
})
export class HtmlToImageService {
  private webFontCssCache: { [id: string]: string } = {};
  constructor() {}

  public transform<T_SECTION_IDENTIFIER>(
    section: ReportAttributeDirective<T_SECTION_IDENTIFIER>
  ): Promise<string> {
    switch (section.toImageType) {
      case ImageType.JPG: {
        return this.toJpeg(section);
      }
      case ImageType.PNG: {
        return this.toPng(section);
      }
    }
  }

  private toJpeg<T_SECTION_IDENTIFIER>(
    section: ReportAttributeDirective<T_SECTION_IDENTIFIER>
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.webFontCssCache[section.reportSectionAttribute]) {
        this.generateWebFontEmbedCss(section.getElementRef())
          .then((sectionFont) => {
            this.webFontCssCache[section.reportSectionAttribute] = sectionFont;
            resolve(
              htmlToImage.toJpeg(section.getElementRef().nativeElement, {
                quality: 0.95,
                backgroundColor: '#fff',
                fontEmbedCSS: sectionFont,
              })
            );
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(
          htmlToImage.toJpeg(section.getElementRef().nativeElement, {
            quality: 0.95,
            backgroundColor: '#fff',
            fontEmbedCSS: this.webFontCssCache[section.reportSectionAttribute],
          })
        );
      }
    });
  }

  private toPng<T_SECTION_IDENTIFIER>(
    section: ReportAttributeDirective<T_SECTION_IDENTIFIER>
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      html2canvas(section.getElementRef().nativeElement, {
        useCORS: true,
        allowTaint: true,
        scale: 1.01,
        removeContainer: true,
        ignoreElements: (element) => {
          if (
            Array.isArray(section.ignoreClassElements) &&
            section.ignoreClassElements.length > 0
          ) {
            const matchClassElements = section.ignoreClassElements.filter(
              (item) => element.classList.contains(item)
            );
            if (matchClassElements.length) {
              return true;
            }
          }
          return false;
        },
      })
        .then((canvas) => {
          resolve(canvas.toDataURL());
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private generateWebFontEmbedCss(section: ElementRef<any>): Promise<string> {
    return htmlToImage.getFontEmbedCSS(section.nativeElement);
  }
}
