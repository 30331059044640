import { Injectable } from '@angular/core';
import {
  MatLegacyListOption as MatListOption,
  MatLegacySelectionList as MatSelectionList,
} from '@angular/material/legacy-list';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject } from 'rxjs';
import {
  IntelSearchType,
  SearchLabels,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { BaseService } from 'src/app/services/base.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { InputTypes } from './input-types.model';
import { SearchFields } from './search-fields.model';
import { NavbarSearchType } from './search-type.model';

@Injectable({
  providedIn: 'root',
})
export class SearchIntelNavbarService extends BaseService {
  enableCovid19MX: boolean;
  defaultQueryPlaceholder: string;
  searchFields$ = new BehaviorSubject(null);

  phoneNumberUtil = PhoneNumberUtil.getInstance();
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private userBillingService: UserBillingService,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
    this.enableCovid19MX =
      this.appConfigService.getConfigVariable('enableCovid19MX');
    this.defaultQueryPlaceholder = `Start typing phone, name,${
      this.enableCovid19MX ? ' CURP,' : ''
    } url, email or username or upload photo`;
  }

  searchListAutoSelect(
    inputTypes: any[],
    searchList: MatSelectionList,
    searchType: NavbarSearchType
  ): MatListOption[] {
    if (
      inputTypes.length === 0 ||
      !searchList ||
      searchType === IntelSearchType.GE0
    ) {
      return null;
    }
    return searchList.options.toArray();
  }

  userHasEnoughCredits(
    isTelno: boolean,
    searchType: NavbarSearchType
  ): boolean {
    const billingActions: BillingActions[] = [];

    if (IntelSearchType.GE0 === searchType && isTelno) {
      billingActions.push(BillingActions.QUERY_LOCATION);
    }

    if (IntelSearchType.OSINT === searchType && isTelno) {
      billingActions.push(
        BillingActions.QUERY_LOCATION,
        BillingActions.INTEL_SEARCH
      );
    }

    if (!isTelno) {
      billingActions.push(BillingActions.INTEL_SEARCH);
    }

    return this.userBillingService.userHasEnoughCredits(billingActions);
  }

  setQueryInputPlaceHolder(searchType: NavbarSearchType) {
    switch (searchType) {
      case IntelSearchType.GE0:
        return 'Locate a single MSISDN or IMSI';
      case IntelSearchType.OSINT:
        return this.defaultQueryPlaceholder;
      case IntelSearchType.ADINT:
        return 'Location history';
    }
  }

  getSearchLabel(data: MatListOption[], inputTypes: InputTypes): SearchLabels {
    const dataSelectionType = data[0].value.key;
    const dataSelectionLabelObject = inputTypes[dataSelectionType];
    return dataSelectionLabelObject.value as SearchLabels;
  }

  public getSearchValue(data: MatListOption[]) {
    return data[0].value.value;
  }

  public checkExistingLabels(searchFields: SearchFields[]): boolean {
    return searchFields.some((elem) => elem.label === SearchLabels.IMAGE);
  }

  public isTelno(searchInputValue: string, skipLocate?: boolean) {
    return (
      searchInputValue.startsWith('+') &&
      /^\+\d+$/.test(searchInputValue) &&
      !skipLocate
    );
  }

  public isLabelPhone(
    label: SearchLabels,
    searchFields: SearchFields[]
  ): boolean {
    return this.isLabel(label, SearchLabels.PHONE, searchFields);
  }

  public isLabelPossiblePhone(
    label: SearchLabels,
    searchFields: SearchFields[]
  ): boolean {
    return this.isLabel(label, SearchLabels.POSSIBLE_TELNO, searchFields);
  }

  public isLabelName(
    label: SearchLabels,
    searchFields: SearchFields[]
  ): boolean {
    return this.isLabel(label, SearchLabels.NAME, searchFields);
  }

  public isLabel(
    label: SearchLabels,
    labelToCompare: SearchLabels,
    searchFields: SearchFields[]
  ) {
    return label === labelToCompare && searchFields.length <= 1;
  }
}
