import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RedirectSnackBarComponent } from '@shared/components/redirect-snack-bar/redirect-snack-bar.component';
import { TranslationService } from './translation/translation.service';

export const ExceedErrors = [
  {
    type: 'Target limit creation exceeded!',
    start:
      'You ran out of available targets. To add a new target, please go to the',
    linkText: 'list of targets',
    navigateTo: 'targets',
  },
  {
    type: 'Case limit creation exceeded!',
    start:
      'You ran out of available cases. To add a new case, please go to the',
    linkText: 'list of cases',
    navigateTo: 'cases',
  },
];

@Injectable({
  providedIn: 'root',
})
export class RedirectSnackBarService {
  constructor(
    private translationService: TranslationService,
    private snackBar: MatSnackBar
  ) {}

  shouldShowRedirectSnackBar(message: string): boolean {
    const exceedErrorObject = ExceedErrors.find((err) => err.type === message);
    return exceedErrorObject ? true : false;
  }

  showRedirectSnackBar(message: string): void {
    const exceedErrorObject = ExceedErrors.find((err) => err.type === message);
    this.snackBar.openFromComponent(RedirectSnackBarComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        html: {
          start: `${this.translationService.translate(
            exceedErrorObject.start
          )}`,
          linkText: `${this.translationService.translate(
            exceedErrorObject.linkText
          )}`,
          end: `${this.translationService.translate('and delete one.')}`,
          navigateTo: exceedErrorObject.navigateTo,
        },
      },
    });
  }
}
