import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppConfigService } from '@app/config';
import { Observable, of, Subscription, tap } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { BillingService } from 'src/app/services/billing/billing.service';
import { CaseService } from 'src/app/services/case/case.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { AddToCaseModalData, Case } from 'src/app/shared/models/case.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { AnalysisActionsListModel } from '../../models/analysis-actions.model';
import { FeatureTableRow } from 'src/app/modules/analysis/shared/models/feature-table.model';
import { IMGroup } from 'src/app/modules/search-intel/models/group-analyser.model';
import { EntityType } from 'datalayer/models/platform-models';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RedirectButtonSnackBarComponent } from '@shared/components/redirect-button-snack-bar/redirect-button-snack-bar.component';
import { transformSnakeToCamel } from '@shared/util/helper';

@Component({
  selector: 'app-add-to-case',
  templateUrl: './add-to-case.component.html',
  styleUrls: ['./add-to-case.component.scss'],
})
export class AddToCaseComponent extends BaseComponent implements OnInit {
  @ViewChild('caseInput') caseInput: ElementRef<HTMLInputElement>;
  addToCaseForm: UntypedFormGroup;
  casesList$: Observable<any>;
  allCases: Case[];
  selectedCaseItem: Case;
  selectedCases: Case[] = [];
  visible = true;
  selectable = true;
  removable = true;
  caseCreditsChargesEnabled: boolean;
  creditsForExpired: number;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  isUnlimitedTheme = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseCreditsMessage: string;
  expireCaseDays: number;
  public targets: FeatureTableRow[];
  public group: IMGroup;

  constructor(
    private caseService: CaseService,
    @Inject(MAT_DIALOG_DATA)
    public data: AddToCaseModalData,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService,
    public dialogRef: MatDialogRef<AddToCaseComponent>,
    private actionService: ActionService,
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private redirectSnackBarService: RedirectSnackBarService,
    private readonly intelSnackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.targets = this.data.targets;
    this.group = this.data.group;
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.isUnlimitedTheme =
      this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED;
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    this.caseCreditsMessage = this.translationService.interpolate(
      'Management for a new case is free of charge for #{days} days',
      { days: this.expireCaseDays.toString() }
    );
    this.initForm();
    this.billingPlan = this.billingService.getBillingPlan().getValue();

    this.casesList$ = this.addToCaseForm.controls['caseName'].valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      map((value) => {
        return typeof value === 'string' ? value : value?.caseName;
      }),
      switchMap((caseItem: string | null) => {
        if (!caseItem) {
          this.selectedCaseItem = null;
        }
        return caseItem || !!this.group ? this._filter(caseItem) : of([]);
      })
    );
  }

  initForm() {
    this.addToCaseForm = new UntypedFormGroup({
      caseName: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
      ]),
    });
  }

  /**
   * @param  {string} value
   * @returns Case
   */
  private _filter(value: string): Observable<Case[]> {
    const filterValue = value.toLowerCase();
    return this.getCases(filterValue);
  }

  private getCases(caseName: string): Observable<Case[]> {
    return this.caseService
      .getPaginatedCases({ limit: 10, page: 1, filterArg: caseName })
      .pipe(
        map((data) => {
          this.allCases = data.result;
          return data.result;
        })
      );
  }

  caseSelected(e: MatAutocompleteSelectedEvent): void {
    this.selectedCaseItem = <Case>e.option.value;
    const caseItem: Case = this.selectedCaseItem;
    this.selectedCases.push(caseItem);
    this.allCases = this.allCases.filter((caseObj) => caseObj !== caseItem);
    this.caseInput.nativeElement.value = '';

    this.checkRenewalCreditsCount();
  }

  caseRemoved(caseItem: Case): void {
    const index = this.selectedCases.indexOf(caseItem);
    if (index >= 0) {
      this.selectedCases.splice(index, 1);
      this.allCases.unshift(caseItem);
    }
    this.checkRenewalCreditsCount();
  }

  displayFn(caseItem: Case): string {
    return caseItem && caseItem.caseName ? caseItem.caseName : '';
  }

  addToCase(): void {
    if (!this.selectedCaseItem) {
      return;
    }

    if (this.group) {
      this.updateCaseWithSocialEntities();
      return;
    }

    const targetIds = this.targets.map((i) => i.cells.id.content);
    const existingTargetIds = this.selectedCaseItem.assignedTargets.map(
      (i) => i.id
    );
    const assignedTargets = [...new Set(targetIds.concat(existingTargetIds))];
    if (this.caseCreditsChargesEnabled && this.selectedCaseItem.expired) {
      this.renewCase(this.selectedCaseItem.id).subscribe((isRenewed) => {
        if (!isRenewed) {
          return;
        }
        this.updateCaseDetails(this.selectedCaseItem, assignedTargets);
      });
    } else {
      this.updateCaseDetails(this.selectedCaseItem, assignedTargets);
    }
  }

  newCase(): void {
    const caseName = this.addToCaseForm.value.caseName;

    if (this.group) {
      this.createCaseAndAddSocialEntities(caseName);
      return;
    }
    const targetIds = this.targets.map((i) => i.cells.id.content);

    if (caseName && targetIds.length) {
      this.createCase(caseName, targetIds);
    }
  }

  public createCaseAndAddSocialEntities(caseName: string): void {
    let createdCase: Case;
    this.createEmptyCase(caseName)
      .pipe(
        switchMap((result: any): Observable<any> => {
          createdCase = transformSnakeToCamel(result);
          return this.addSocialEntitiesToCase(result.id);
        }),
        take(1)
      )
      .subscribe(
        (result) => {
          if (result) {
            this.openSnackbarWithButton(createdCase);
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        },
        (error: any) => {
          const errorMessage = error?.messages;

          if (
            this.redirectSnackBarService.shouldShowRedirectSnackBar(
              errorMessage
            )
          ) {
            this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
          } else {
            this.showMessage(
              this.translationService.translate(
                error.messages ? error.messages : 'Case has not been created'
              )
            );
          }
        }
      );
  }

  private createEmptyCase(caseName: string): Observable<any> {
    const newCase: Case = {
      caseName: caseName,
      caseColor: '#005CFF',
      caseDescription: '',
      assignedTargets: [],
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    return this.caseService.createCase(newCase);
  }

  private addSocialEntitiesToCase(caseId: string): Observable<any> {
    return this.caseService.addSocialEntitiesToCase({
      case_ids: [caseId],
      social_entities: [
        {
          id: this.group.id,
          type: EntityType.Group,
          source: this.group.source,
        },
      ],
    });
  }

  private updateCaseWithSocialEntities(): void {
    this.addSocialEntitiesToCase(this.selectedCaseItem.id)
      .pipe(take(1))
      .subscribe(
        (result): void => {
          if (result) {
            this.openSnackbarWithButton(this.selectedCaseItem);
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        },
        (error: any) => {
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been edited'
            )
          );
        }
      );
  }

  createCase(caseName: string, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      caseName: caseName,
      caseColor: '#005CFF',
      caseDescription: '',
      assignedTargets: assignedTargets,
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    this.caseService.createCase(obj).subscribe(
      (result) => {
        if (result) {
          this.showMessage(
            this.translationService.translate('Case created successfully!')
          );
          this.dialogRef.close();
          this.actionService.publishAction(
            new Action({
              key: AnalysisActionsListModel.REFRESH_DATA,
              data: null,
            })
          );
        }
      },
      (error: any) => {
        const errorMessage = error?.messages;

        if (
          this.redirectSnackBarService.shouldShowRedirectSnackBar(errorMessage)
        ) {
          this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
        } else {
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been created'
            )
          );
        }
      }
    );
  }

  updateCaseDetails(caseItem: Case, assignedTargets: TargetItem[]): void {
    const obj: Case = {
      ...caseItem,
      assignedTargets: assignedTargets,
    };

    this.caseService
      .addTargetsToCase(obj)
      .pipe(
        tap((result) => {
          if (result) {
            this.showMessage(
              this.translationService.translate('Case updated successfully!')
            );
            this.dialogRef.close();
            this.actionService.publishAction(
              new Action({
                key: AnalysisActionsListModel.REFRESH_DATA,
                data: null,
              })
            );
          }
        }),
        catchError((error: any) => {
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been edited'
            )
          );
          return of(error);
        })
      )
      .subscribe();
  }

  checkRenewalCreditsCount(): void {
    const expiredTargetsCount = this.selectedCases.filter(
      (i) => i.expired
    ).length;
    this.creditsForExpired =
      expiredTargetsCount *
      this.billingPlan[BillingActions.CASE_MANAGEMENT].cost;
  }

  onKeyDown(event) {
    if (this.selectedCases.length === 1) {
      event.preventDefault();
    }
  }

  addChip(input: HTMLInputElement): void {
    if (this.addToCaseForm.valid) {
      this.selectedCases.push({
        caseName: input.value,
        caseColor: '',
        caseDescription: '',
        assignedTargets: [],
        assignedUsers: [],
      });

      input.value = null;
    }
  }

  renewCase(caseId: string): Observable<boolean> {
    return new Observable<boolean>((observable) => {
      const subscription: Subscription = this.caseService
        .renewCase(caseId)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Case renewed successfully!')
            );
            this.selectedCaseItem.expired = false;
            observable.next(true);
          },
          (error) => {
            this.showMessage(
              this.translationService.translate(
                error.messages ? error.messages : 'Case has not been renewed'
              )
            );
            observable.next(false);
          },
          () => {
            observable.complete();
          }
        );
      this.subscriptions.push(subscription);
    });
  }

  private openSnackbarWithButton(targetCase: Case): void {
    this.intelSnackBar.openFromComponent(RedirectButtonSnackBarComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        text: this.translationService.interpolate(
          `The group has been saved to case "#{caseName}". Click view to go to the case.`,
          { caseName: targetCase.caseName }
        ),
        navigateTo: `/case/${targetCase.id}/group-analyser`,
      },
    });
  }
}
