import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { EventChannel } from '@trg-commons/gio-data-models-ts';
import { PurchaseNotificationType } from 'datalayer/models/background-jobs/purchase-notifications';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { catchError, map, scan, tap } from 'rxjs/operators';
import {
  Notification,
  SystemNotificationAction,
  SystemNotificationContent,
} from 'src/app/components/notifications-list/notification.model';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { BaseService } from 'src/app/services/base.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { AuthService } from '../authentication/auth.service';
import { DataLayerWsManagerService } from '../websocket/data-layer-ws-manager.service';
import { MessageSubject } from '../websocket/message-subject.model';
import { ActionType, NotificationType } from './types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  public hasNotificationToneAlreadyRang: boolean;
  constructor(
    private httpClient: HttpClient,
    private websocketService: WsService,
    private proxyService: ProxyWsService,
    private authSessionService: AuthService,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private dataLayerWsService: DataLayerWsManagerService
  ) {
    super(router, snackBar);

    const push$ = this.pushSource
      .asObservable()
      .pipe(map((payload) => ({ type: ActionType.push, payload })));

    const pop$ = this.popSource
      .asObservable()
      .pipe(map(() => ({ type: ActionType.pop })));

    const clearRadicalMonitoring$ = this.clearRadicalMonitoringSource
      .asObservable()
      .pipe(map(() => ({ type: ActionType.clearRadicalMonitoring })));

    const clear$ = this.clearSource
      .asObservable()
      .pipe(map(() => ({ type: ActionType.clear })));

    const markRead$ = this.markReadSource
      .asObservable()
      .pipe(map((payload) => ({ type: ActionType.markAsRead, payload })));

    const system$ = this.systemSource
      .asObservable()
      .pipe(map((payload) => ({ type: ActionType.system, payload })));

    const remove$ = this.removeSource
      .asObservable()
      .pipe(map((payload) => ({ type: ActionType.remove, payload })));

    merge(
      push$,
      pop$,
      clear$,
      markRead$,
      clearRadicalMonitoring$,
      system$,
      remove$
    )
      .pipe(
        scan(
          (acc: Notification[], seed: { payload: any; type: ActionType }) => {
            let systemNotifications = acc.filter(
              (n) => n.notificationType === NotificationType.SYSTEM
            );
            acc = acc.filter(
              (n) => n.notificationType !== NotificationType.SYSTEM
            );
            switch (seed.type) {
              case ActionType.pop:
                acc.pop();
                break;
              case ActionType.system:
                if (
                  !systemNotifications.some((n) => n.id === seed.payload.id)
                ) {
                  systemNotifications.push(seed.payload);
                }
                break;
              case ActionType.push:
                acc.unshift(seed.payload);
                break;
              case ActionType.clear:
                acc = [];
                break;
              case ActionType.clearRadicalMonitoring:
                acc = acc.filter(
                  (e) =>
                    e.notificationType !== NotificationType.RADICAL_MONITORING
                );
                break;
              case ActionType.markAsRead:
                if (Array.isArray(seed.payload) && seed.payload.length) {
                  // mark those ids as read
                  acc = acc.map((n) =>
                    seed.payload.includes(n.id) ? { ...n, isRead: true } : n
                  );
                } else {
                  // mark all as read
                  acc = acc.map((n) => ({ ...n, isRead: true }));
                }
                break;
              case ActionType.remove:
                acc = acc.filter((n) => !seed.payload.includes(n.id));
                systemNotifications = systemNotifications.filter(
                  (n) => !seed.payload.includes(n.id)
                );
                break;
            }

            return [...systemNotifications, ...acc];
          },
          []
        )
      )
      .subscribe((notifications) => {
        this.hasNotificationToneAlreadyRang = false;
        this.messages$.next(notifications);
      });

    this.authSessionService.isAuthenticated.subscribe(() => {
      this.clearSource.next();
      this.initializeRestSubscriptions();
    });

    this.initializeWsSubscriptions();
    this.initializeServerTsWsSubscriptions();
    this.initializeDataLayerWsSubscriptions();
  }

  public messages$ = new BehaviorSubject<Notification[]>([]);

  private pushSource = new Subject<Notification>();
  private popSource = new Subject<void>();
  private clearSource = new Subject<void>();
  private clearRadicalMonitoringSource = new Subject<void>();
  private markReadSource = new Subject<string[]>();

  public systemSource = new Subject<Notification>();
  public removeSource = new Subject<string[]>();

  pushNotification(msg: Notification) {
    this.pushSource.next(msg);
  }

  pushNotifications(msgs: Notification[]) {
    msgs.forEach((m) => this.pushSource.next(m));
  }

  popMessage() {
    this.popSource.next();
  }

  private initializeRestSubscriptions() {
    this.httpClient
      .get<any>(`${this.url}/notifications`)
      .pipe(
        map((data) => {
          data.result.forEach((n) => {
            const camelCasedNotification = transformSnakeToCamel(n);
            this.pushNotification(camelCasedNotification);
          });
        }),
        catchError((error) => this.handleError(error))
      )
      .subscribe();
  }

  private initializeWsSubscriptions() {
    this.websocketService.onEvent('notification').subscribe((msg: any) => {
      this.pushNotification(msg);
    });
  }

  private initializeServerTsWsSubscriptions() {
    this.proxyService.getMessage().subscribe((event) => {
      if (
        Object.values(PurchaseNotificationType).includes(
          event.body?.notificationType
        )
      ) {
        return;
      }
      if (event.channel === EventChannel.Notification) {
        let eventNotificationBody = event.body;
        if (MessageSubject.TargetMonitoringAlert === event.body?.subject) {
          eventNotificationBody = event.body?.body?.payload;
        }
        this.pushNotification(transformSnakeToCamel(eventNotificationBody));
      }
    });
  }

  private initializeDataLayerWsSubscriptions(): void {
    this.dataLayerWsService.appNotification$
      .pipe(
        tap((notification) => {
          this.pushNotification(notification);
        })
      )
      .subscribe();
  }

  public clearNotifications() {
    this.httpClient
      .delete<any>(`${this.url}/notifications`)
      .pipe(
        map((data) => {
          this.clearSource.next();
        }),
        catchError((error) => this.handleError(error))
      )
      .subscribe();
  }

  public get(id: string): Observable<Notification> {
    return this.httpClient
      .get<Notification>(`${this.url}/notification/${id}`)
      .pipe(
        map((data) => transformSnakeToCamel(data)),
        catchError((error) => this.handleError(error))
      );
  }

  public test() {
    this.clearRadicalMonitoringSource.next();
  }

  public markNotificationsAsRead(ids?: string[]) {
    if (ids.some((id) => id.includes('forcedNotification'))) {
      this.markReadSource.next(ids);
      return;
    }
    return this.httpClient
      .put<any>(`${this.url}/notifications`, ids)
      .pipe(
        map((data) => {
          if (data.result.errors.length === 0) {
            this.markReadSource.next(ids);
          }
        }),
        catchError((error) => this.handleError(error))
      )
      .subscribe();
  }

  public systemNotification(
    id: string,
    text: string,
    actions: SystemNotificationAction[] = []
  ) {
    this.systemSource.next({
      id,
      notificationType: NotificationType.SYSTEM,
      createdBy: NotificationType.SYSTEM,
      content: {
        system: {
          text,
          actions,
        } as SystemNotificationContent,
      },
      createdAt: new Date(),
      updatedAt: new Date().toString(),
      isRead: false,
    });
  }

  public removeNotification(id: string) {
    this.removeNotifications([id]);
  }
  public removeNotifications(ids: string[]) {
    this.removeSource.next(ids);
  }
}
