import { Directive, SimpleChanges } from '@angular/core';
import {
  Button,
  Circle,
  DrawMode,
  DrawModeOptions,
  Feature,
  MapTypeId,
  Marker,
  Point,
  Polygon,
  Polyline,
  Rectangle,
  StyledMapTypeOptions,
  TileSource,
} from 'src/app/modules/mapV2/models/map.models';
import { CallLogFeatureData } from 'src/app/shared/models/call-log-feature-data.model';
import { HeatMap } from '../../models/map.models';
import { BaseMapComponent } from './base-map.component';

@Directive()
export abstract class BaseMapImplComponent extends BaseMapComponent {
  defaultTileSource: TileSource = {
    tileUrl: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '',
  };

  constructor() {
    super();
  }

  public MapReady() {
    if (this.zoom) {
      this.setZoom(this.zoom);
    }

    if (this.center && this.center.lat && this.center.lng) {
      this.setCenter(this.center);
    }

    if (this.markers) {
      this.renderMarkers(this.markers);
    }

    if (this.legendIcons) {
      this.createMapLegend(this.legendIcons);
    }

    if (this.rectangles) {
      this.renderRectangles(this.rectangles);
    }

    if (this.polygons) {
      this.renderPolygons(this.polygons);
    }

    if (this.points) {
      this.renderHeatMap(this.points);
    }

    if (this.polyLines) {
      this.renderPolyLines(this.polyLines);
    }

    if (this.circles) {
      this.renderCircles(this.circles);
    }

    if (this.mapTileSource) {
      this.setTileSource(this.mapTileSource);
    }

    if (this.enableClustering) {
      this.toggleClustering(this.enableClustering);
    }

    if (this.historyTimelineEnabled) {
      this.toggleHistoryTimeline(this.historyTimelineEnabled);
    }

    if (this.trafficEnabled) {
      this.toggleTraffic(this.trafficEnabled);
    }

    if (this.nearbyLocationsEnabled) {
      this.toggleNearbyLocations(this.nearbyLocationsEnabled);
    }

    if (this.buttons) {
      this.renderButtons(this.buttons);
    }

    if (this.enableDrawing) {
      this.maybeEnableCorrectDrawMode(this.enableDrawing);
    }

    if (this.feature) {
      this.renderFeature(this.feature);
    }

    if (this.enableSearching) {
      this.toggleSearching(this.enableSearching);
    }

    if (this.freeze) {
      this.toggleFreeze(this.freeze);
    }

    if (this.callLogClusteringData) {
      this.renderCallLogClusteringData(this.callLogClusteringData);
    }

    if (this.heatMaps) {
      this.renderHeatMaps(this.heatMaps);
    }

    if (this.mapTypeId) {
      this.setMaptTypeId(this.mapTypeId);
    }

    if (this.maxZoomClusterDistance) {
      this.setMaxZoomClusterDistance(this.maxZoomClusterDistance);
    }
  }

  OnInit() {}

  OnChanges(changes: SimpleChanges) {
    if (changes.mapTileSource) {
      if (changes.mapTileSource.currentValue) {
        this.setTileSource(changes.mapTileSource.currentValue);
      } else {
        this.setTileSource(this.defaultTileSource);
      }
    }

    if (changes.zoom && changes.zoom.currentValue) {
      this.setZoom(changes.zoom.currentValue);
    }

    if (
      changes.center &&
      changes.center.currentValue &&
      changes.center.currentValue.lat &&
      changes.center.currentValue.lng
    ) {
      this.setCenter(changes.center.currentValue, this.zoom);
    }

    if (changes.markers && changes.markers.currentValue) {
      this.renderMarkers(changes.markers.currentValue);
    }

    if (changes.legendIcons && changes.legendIcons.currentValue) {
      this.createMapLegend(changes.legendIcons.currentValue);
    }

    if (changes.circles && changes.circles.currentValue) {
      this.renderCircles(changes.circles.currentValue);
    }

    if (changes.rectangles && changes.rectangles.currentValue) {
      this.renderRectangles(changes.rectangles.currentValue);
    }

    if (changes.points && changes.points.currentValue) {
      this.renderHeatMap(changes.points.currentValue);
    }

    if (changes.polygons && changes.polygons.currentValue) {
      this.renderPolygons(changes.polygons.currentValue);
    }

    if (changes.polyLines && changes.polyLines.currentValue) {
      this.renderPolyLines(changes.polyLines.currentValue);
    }

    if (changes.enableDrawing) {
      this.maybeEnableCorrectDrawMode(changes.enableDrawing.currentValue);
    }

    if (changes.trafficEnabled) {
      this.toggleTraffic(changes.trafficEnabled.currentValue);
    }

    if (changes.nearbyLocationsEnabled) {
      this.toggleNearbyLocations(changes.nearbyLocationsEnabled.currentValue);
    }

    if (changes.heatmapEnabled) {
      this.toggleHeatMaps(changes.heatmapEnabled.currentValue);
    }

    if (changes.historyTimelineEnabled) {
      this.toggleHistoryTimeline(changes.historyTimelineEnabled.currentValue);
    }

    if (changes.clusteringEnabled) {
      this.toggleClustering(changes.clusteringEnabled.currentValue);
    }

    if (changes.buttons && changes.buttons.currentValue) {
      this.renderButtons(changes.buttons.currentValue);
    }

    if (changes.feature && changes.feature.currentValue) {
      this.renderFeature(changes.feature.currentValue);
    }

    if (changes.enableSearching) {
      this.toggleSearching(changes.enableSearching.currentValue);
    }

    if (changes.freeze) {
      this.toggleFreeze(changes.freeze.currentValue);
    }

    if (changes.showMarkerInfoWindow) {
      this.toggleMarkerInfoWindow(changes.showMarkerInfoWindow.currentValue);
    }

    if (
      changes.callLogClusteringData &&
      changes.callLogClusteringData.currentValue
    ) {
      this.renderCallLogClusteringData(
        changes.callLogClusteringData.currentValue
      );
    }

    if (changes.heatMaps && changes.heatMaps.currentValue) {
      this.renderHeatMaps(changes.heatMaps.currentValue);
    }

    if (changes.mapTypeId && changes.mapTypeId.currentValue) {
      this.setMaptTypeId(changes.mapTypeId.currentValue);
    }

    if (
      changes.maxZoomClusterDistance &&
      changes.maxZoomClusterDistance.currentValue
    ) {
      this.setMaxZoomClusterDistance(
        changes.maxZoomClusterDistance.currentValue
      );
    }
    if (
      changes.styledMapTypeCustomOptions &&
      changes.styledMapTypeCustomOptions.currentValue
    ) {
      this.setStyledMapTypeCustomOptions(
        changes.styledMapTypeCustomOptions.currentValue
      );
    }
    if (changes.mapFitBounds) {
      this.setMapFitBounds();
    }
  }

  abstract setZoom(zoom: number);
  abstract setCenter(center: Point, zoom?: number);
  abstract renderMarkers(markers: Marker[]);
  abstract renderCircles(circles: Circle[]);
  abstract renderPolygons(polygons: Polygon[]);
  abstract renderRectangles(rectangles: Rectangle[]);
  abstract renderPolyLines(polylines: Polyline[]);
  // abstract renderLines(rectangles: Rectangle[]);
  abstract setTileSource(tileSource: TileSource);
  abstract maybeEnableCorrectDrawMode(enabled: DrawMode | DrawModeOptions);
  abstract toggleClustering(enabled: boolean);
  abstract toggleHeatMaps(enabled: boolean);
  abstract renderHeatMap(points: Point[]);
  abstract toggleHistoryTimeline(enabled: boolean);
  abstract toggleTraffic(enabled: boolean);
  abstract toggleNearbyLocations(enabled: boolean);
  abstract renderButtons(buttons: Button[]);
  abstract renderFeature(feature: Feature);
  abstract toggleSearching(enabled: boolean);
  abstract toggleFreeze(enabled: boolean);
  abstract renderCallLogClusteringData(data: CallLogFeatureData);
  abstract toggleMarkerInfoWindow(show: boolean);
  abstract renderHeatMaps(heatMaps: HeatMap[]);
  abstract createMapLegend(legendIcons: { [key: string]: string });
  abstract setMaptTypeId(mapTypeId: MapTypeId);
  abstract setMaxZoomClusterDistance(distance: number);
  abstract setStyledMapTypeCustomOptions(styledMap: StyledMapTypeOptions);
  abstract setMapFitBounds();
}
