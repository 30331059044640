import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-cl-export-base',
  templateUrl: './cl-export-base.component.html',
  styleUrls: ['./cl-export-base.component.scss'],
})
export class ClExportBaseComponent implements OnInit {
  @Input() title: string;

  constructor(public dialogRef: MatDialogRef<ClExportBaseComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
