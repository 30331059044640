import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { UserSettingsService } from 'src/app/services/user/user-settings.service';
import { LayoutService } from '@fe-platform/shared-ui/intellectus';

@Component({
  selector: 'new-discovery-toggle',
  templateUrl: './new-discovery-toggle.component.html',
  styleUrls: ['./new-discovery-toggle.component.scss'],
})
export class NewDiscoveryToggleComponent implements OnInit {
  @Input() displayLabel = true;

  private discoveryNavigationLinks = {
    oldDiscovery: '/discovery',
    newDiscovery: '/account/discovery',
  };
  public toggleActiveNavigationLink;
  private isMobile: boolean;
  public discoveryV2Enabled$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private router: Router,
    private userSettingsService: UserSettingsService,
    private appConfigService: AppConfigService,
    private localStorageService: LocalStorageService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.layoutService.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      this.isMobile = isMobile;
      this.userSettingsService
        .getUserSettings()
        .pipe(
          tap((userSettings) => {
            const discoveryV2Enabled =
              this.appConfigService.getConfigVariable('enableDiscoveryV2') &&
              this.isMobile
                ? typeof userSettings.discoveryV2Enabled === 'undefined'
                  ? true
                  : userSettings.discoveryV2Enabled
                : !!userSettings.discoveryV2Enabled;
            this.discoveryV2Enabled$.next(discoveryV2Enabled as boolean);
            if (typeof userSettings.discoveryV2Enabled === 'undefined') {
              this.toggleDiscoveryModule(!discoveryV2Enabled as boolean);
            }
          }),
          take(1)
        )
        .subscribe();
    });
  }

  toggleDiscoveryModule(discoveryV2Enabled: boolean) {
    this.userSettingsService
      .setUserSettingsValue({ discoveryV2Enabled: !discoveryV2Enabled })
      .pipe(
        take(1),
        map((userSettings) => userSettings.discoveryV2Enabled),
        tap((discoveryV2Enabled) => {
          this.localStorageService.setShowDiscoveryOnboarding(
            discoveryV2Enabled
          );
          this.toggleActiveNavigationLink = discoveryV2Enabled
            ? this.discoveryNavigationLinks.newDiscovery
            : this.discoveryNavigationLinks.oldDiscovery;
          if (
            [
              this.discoveryNavigationLinks.oldDiscovery,
              this.discoveryNavigationLinks.newDiscovery,
            ].some((url) => this.router.url.includes(url))
          ) {
            this.userSettingsService.discoveryV2Enabled.next(
              discoveryV2Enabled
            );
            this.router.navigateByUrl(this.toggleActiveNavigationLink, {
              state: {
                showOnboarding:
                  this.toggleActiveNavigationLink ===
                  this.discoveryNavigationLinks.newDiscovery,
              },
            });
          }
        })
      )
      .subscribe();
  }
}
