import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Language, LanguageOptions } from '@shared/models/site-settings.model';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  constructor() {}

  @ViewChild('languageSelector') languageSelector: MatFormField;

  @Input()
  public selectedLanguage: LanguageOptions;

  @Input()
  public languages: Language[];

  @Output()
  public readonly onLanguageChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  emitLanguageChange($event): void {
    this.languageSelector._elementRef.nativeElement.classList.remove(
      'mat-focused'
    );
    this.onLanguageChange.emit($event);
  }
}
