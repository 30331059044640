import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { IpLocationService } from '../../../../../src/app/services/query/ip-location.service';
import { Query } from '../../models/query-item.model';

@Component({
  selector: 'app-ip-location-tooltip',
  templateUrl: './ip-location-tooltip.component.html',
  styleUrls: ['./ip-location-tooltip.component.scss'],
})
export class IpLocationTooltipComponent implements OnInit {
  @Input() query: Query;
  constructor(
    public elRef: ElementRef,
    private ipLocationService: IpLocationService
  ) {}

  ngOnInit(): void {}

  onLocateWithIp() {
    this.ipLocationService.emitAction(this.query);
  }
}
