import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { Observable, catchError, delay, of, switchMap, throwError } from 'rxjs';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { DiscoveryColorClassToColorHex } from '../discovery-history/shared/toasters/DiscoveryColorClassToColorHex';
import { DiscoveryToasterIcons } from '../discovery-history/shared/toasters/DiscoveryToasterIcons';
import {
  DiscoveryCase,
  DiscoveryCaseTarget,
  DiscoveryCasesAPI,
  DiscoveryCasesAPIParams,
} from '../models/DiscoveryCase.model';
import {
  QueriesHistoryAPI,
  QueriesHistoryAPIParams,
} from '../models/QueryHistory.model';
import { GeolocationQueriesService } from './geolocation-queries.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationMissionsService {
  // ToDo: Change with env
  private colorClasses: string[] = [
    'c1',
    'c2',
    'c3',
    'c4',
    'c5',
    'c6',
    'c7',
    'c8',
    'c9',
    'c10',
    'c11',
    'c12',
  ];
  private maxTargets = 12;
  private delay = 0; // Simulated API delay

  constructor(
    private geoQueriesService: GeolocationQueriesService,
    private translationService: TranslationService,
    private toasterService: BaseToasterService
  ) {}

  init(loadCaseId?: string) {
    const cases = JSON.parse(localStorage.getItem('_cases'));
    if (!cases || (loadCaseId && cases[0].id != loadCaseId)) {
      localStorage.setItem(
        '_cases',
        JSON.stringify([
          {
            id: loadCaseId ? loadCaseId : '0',
            name: 'Case',
            targets: [],
          },
        ])
      );
    }
  }

  getCases(params: DiscoveryCasesAPIParams): Observable<DiscoveryCasesAPI> {
    const cases: DiscoveryCase[] =
      JSON.parse(localStorage.getItem('_cases')) || [];

    return of<DiscoveryCasesAPI>({
      docs: cases,
      page: 1,
      page_counter: 0,
      page_size: params.page_size,
      total_docs: cases.length,
      total_pages: 1,
    }).pipe(delay(this.delay));
  }

  getCase(caseId: string): Observable<DiscoveryCase> {
    const cases: DiscoveryCase[] = JSON.parse(localStorage.getItem('_cases'));
    let caseObj;
    // Find Case By Id
    for (let i = 0; i < cases.length; i++) {
      if (cases[i].id === caseId) {
        caseObj = cases[i];
      }
    }

    if (caseObj) {
      return of<DiscoveryCase>({ ...caseObj }).pipe(delay(this.delay));
    } else {
      if (caseId == '0' && cases.length) {
        return of<DiscoveryCase>({ ...cases[0] }).pipe(delay(this.delay));
      } else if (caseId != '0') {
        return of<DiscoveryCase>({ id: caseId, name: '', targets: [] }).pipe(
          delay(this.delay)
        );
      } else {
        return throwError(() => ({
          status: 404,
          message: 'Case not found',
        }));
      }
    }
  }

  addTarget(caseId: string, targetStr: string): Observable<DiscoveryCase> {
    const isMSISDN = targetStr.startsWith('+');
    const cases: DiscoveryCase[] =
      JSON.parse(localStorage.getItem('_cases')) || [];
    const caseObj = cases.find((m) => m.id === caseId);
    if (caseObj) {
      const targets = caseObj.targets;

      // Build History Request Params
      const params: QueriesHistoryAPIParams = {
        page_size: 1000,
        page: 1,
        sort_by: 'LATEST_SEARCH_DATE',
        sort_order: 'desc',
        from: new Date('1/1/2023 00:00:01').getTime(),
        calculateInsights: false,
      };
      if (isMSISDN) params.msisdn_list = [targetStr];
      else params.imsi_list = [targetStr];

      // Get Target History
      return this.geoQueriesService.getQueriesHistory(params).pipe(
        switchMap((res: QueriesHistoryAPI) => {
          const newTargetHistory = res.docs[0];
          // Check if Target already exists
          for (let i = 0; i < targets.length; i++) {
            if (
              (targets[i].msisdn &&
                newTargetHistory.msisdn &&
                targets[i].msisdn === newTargetHistory.msisdn) ||
              (targets[i].imsi &&
                newTargetHistory.imsi &&
                targets[i].imsi === newTargetHistory.imsi)
            ) {
              let msg = isMSISDN ? `${targetStr} ` : `IMSI ${targetStr} `;
              msg += `is already included in "${caseObj.name}"`;
              if (!isMSISDN && targets[i].msisdn)
                msg += ` (MSISDN ${targets[i].msisdn})`;

              return throwError(() => ({
                status: 400,
                message: msg,
                error: {
                  code: 'PHONE_ALREADY_EXISTS_IN_CASE',
                  currentTarget: targets[i],
                  newTargetStr: targetStr,
                  caseName: caseObj.name,
                },
              }));
            }
          }

          // Check if max limit reached
          if (targets.length === this.maxTargets) {
            return throwError(() => ({
              status: 400,
              message: `Maximum count of phone numbers inside the case has been reached.`,
              error: {
                code: 'CASE_MAX_PHONES_LIMIT_REACHED',
              },
            }));
          }

          // Add Target to Case
          targets.unshift({
            id: new Date().getTime().toString(),
            msisdn: newTargetHistory.msisdn,
            imsi: newTargetHistory.imsi,
            color_class: this.getNextTargetColorClass(targets, targets.length),
          });

          // Save Case
          localStorage.setItem('_cases', JSON.stringify(cases));

          // Return Updated Case
          return of<DiscoveryCase>(caseObj);
        }),
        catchError((err) => {
          return throwError(() => err);
        })
      );
    }

    // Throw Error If Case Not Found
    return throwError(() => ({
      status: 404,
      message: 'Case not found',
      error: {
        code: 'DISCOVERY_CASE_NOT_FOUND',
      },
    }));
  }

  public getNextTargetColorClass(
    targets: DiscoveryCaseTarget[],
    colorIndex: number
  ): string {
    const colorClass = this.colorClasses[colorIndex];
    for (let i = 0; i < targets.length; i++) {
      if (targets[i].color_class === colorClass) {
        return this.getNextTargetColorClass(
          targets,
          (colorIndex + 1) % this.colorClasses.length
        );
      }
    }
    return colorClass;
  }

  removeTarget(caseId: string, targetId: string) {
    const cases: DiscoveryCase[] = JSON.parse(localStorage.getItem('_cases'));

    // Find Case By Id
    for (let i = 0; i < cases.length; i++) {
      if (cases[i].id === caseId) {
        const caseObj = cases[i];

        // Find & Remove Target By Id
        const targets = caseObj.targets;
        for (let j = 0; j < targets.length; j++) {
          if (targets[j].id === targetId) {
            caseObj.targets.splice(j, 1);

            // Save Case
            localStorage.setItem('_cases', JSON.stringify(cases));

            // Return Updated Case
            return of<DiscoveryCase>({
              ...cases[i],
            }).pipe(delay(this.delay));
          }
        }

        // Throw Error If Target Not Found
        return throwError(() => ({
          status: 404,
          message: 'Target not found',
        }));
      }
    }

    // Throw Error If Case Not Found
    return throwError(() => ({
      status: 404,
      message: 'Case not found',
    }));
  }

  handleDiscoveryCaseError(error: HttpErrorResponse) {
    if (error.status >= 400 && error.status <= 500) {
      if (error.error?.code === 'DISCOVERY_CASE_NOT_FOUND') {
        this.toasterService.show({
          title: this.translationService.translate('Case not found'),
        });
      } else if (error.error?.code === 'CASE_MAX_PHONES_LIMIT_REACHED') {
        this.toasterService.show({
          title: `${this.translationService.translate(
            'Maximum count of phone numbers inside the case has been reached.'
          )}`,
        });
      } else if (error.error?.code === 'PHONE_ALREADY_EXISTS_IN_CASE') {
        const currentTarget: DiscoveryCaseTarget = error.error.currentTarget;
        const newTargetStr: string = error.error.newTargetStr;
        const isMSISDN = newTargetStr.startsWith('+');
        const caseName: string = error.error.caseName;

        let title = isMSISDN ? `${newTargetStr} ` : `IMSI ${newTargetStr} `;
        title += `${this.translationService.translate(
          'is already included in'
        )} "${caseName}"`;

        if (!isMSISDN && currentTarget.msisdn)
          title += ` (MSISDN ${currentTarget.msisdn})`;

        this.toasterService.show({
          icon: {
            ...DiscoveryToasterIcons[currentTarget.msisdn ? 'msisdn' : 'imsi'],
            iconBackgroundColor:
              DiscoveryColorClassToColorHex[currentTarget.color_class],
          },
          title: title,
        });
      } else {
        this.toasterService.show({
          title: error.message,
        });
      }
    } else if (error.status === 503 || error.status === 0) {
      this.toasterService.show({
        title: 'Service Unavailable',
      });
    } else {
      this.toasterService.show({
        title: 'An unknown error occurred',
      });
    }
  }

  getMaximumAllowedCaseTargets(): number {
    return this.maxTargets;
  }
}
