import {
  Cdr,
  EntityRelationType,
  EntityType,
  LocationProbabilityInsight,
  QueryCommand,
} from '@trg-commons/data-models-ts';
import { DataSource as LocalDataSource } from 'datalayer/models/platform-models';
import { Link, Node } from '@trg-ui/link-analysis';
import { ChangeReason, TimelineOptions } from '@trg-ui/timeline-visualization';
import { Place, Post } from 'datalayer/models/social';
import { RequestOptions } from 'src/app/modules/data-layer/services/base';
import { nodeTypes } from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { NearbyTelegramProfile } from 'src/app/shared/models/nearby-locations.model';
import { Query } from 'src/app/shared/models/query-item.model';
import { AdIntWsEntity } from '../../ad-ids/shared/models/ad-int-ws-entity.model';
import { InvestigationIconMarker } from './investigation-icon-marker';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { RawEventEntityNode } from './graph-entities.model';
import { DataSource } from 'datalayer/models/platform-models/enums';

export const IMEI_COLOR = '#383738';

export enum radialMenuActions {
  LOCATE = 'locate',
  CALL_LOGS = 'call_logs',
  INTEL_SEARCH = 'intel_search',
  ENHANCE_PROFILE = 'enhance_profile',
  CREATE_TARGET = 'create_target',
  INSTANT_MESSAGING = 'instant_messaging',
  REDIRECT_TO_TARGET = 'redirect_to_target',
  REDIRECT_TO_CALL_LOGS = 'redirect_to_call_logs',
}

const radialMenuOptions = {
  LOCATE: {
    label: 'Locate msisdn',
    action: radialMenuActions.LOCATE,
    enabled: true,
    icon: '',
  },
  CALL_LOGS: {
    label: 'Call logs',
    action: radialMenuActions.CALL_LOGS,
    enabled: true,
    icon: '',
  },
  INTEL_SEARCH: {
    label: 'Intel search',
    action: radialMenuActions.INTEL_SEARCH,
    enabled: true,
    icon: '',
  },
  ENHANCE: {
    label: 'Enhance profile',
    action: radialMenuActions.ENHANCE_PROFILE,
    enabled: true,
    icon: '',
  },
  INSTANT_MESSAGING: {
    label: 'IM profiles',
    action: radialMenuActions.INSTANT_MESSAGING,
    enabled: true,
    icon: '',
  },
  REDIRECT_TARGET: {
    label: 'View page',
    action: radialMenuActions.REDIRECT_TO_TARGET,
    enabled: true,
    icon: '',
  },
  REDIRECT_CALL_LOGS: {
    label: 'View call logs',
    action: radialMenuActions.REDIRECT_TO_CALL_LOGS,
    enabled: false,
    icon: '',
  },
};

export const radialMenuOptionsByType = {
  [nodeTypes.MSISDN]: [
    radialMenuOptions.LOCATE,
    radialMenuOptions.REDIRECT_CALL_LOGS,
    radialMenuOptions.INSTANT_MESSAGING,
    radialMenuOptions.INTEL_SEARCH,
  ],
  [nodeTypes.PERSON]: [
    radialMenuOptions.ENHANCE,
    radialMenuOptions.REDIRECT_TARGET,
  ],
};

enum SocialDataLimits {
  CONNECTIONS = 5000,
  CHECKINS = 30,
  POSTS = 1000,
  PHOTOS = 1000,
}

export enum SocialDataRequests {
  CONNECTIONS = 'socialConnection',
  WORKPLACES = 'workplaces',
  EDUCATION = 'education',
  CHECKINS = 'checkins',
  POSTS = 'posts',
  PHOTOS = 'photo',
  GROUPS = 'groups',
}

export const timelineOptions: TimelineOptions = {
  backgroundColor: '#fff',
  scales: {
    textColor: '#302f2f',
    dateOrder: 'dmy',
    dateTimeFormats: { time: 'HH:mm' },
  },
  highlightColor: '#2964a3',
  focus: {
    backgroundColor: '#dddfe2',
  },
  timeSeriesCharts: { sizePercent: 20 },
};

export enum AvailableDataToMarkerTypes {
  CDRS = 'cdrs',
  QUERY = 'query',
  TELEGRAMLOCATIONS = 'telegram-locations',
  PREDICTEDLOCATIONS = 'predicted-locations',
  POST_LOCATIONS = 'post-locations',
  ADINT = 'adInt-locations',
  FILE_EXTRACTED_LOCATIONS = 'file-extracted-locations',
  LOCATION_CHECKINS = 'location-checkins',
}

export interface InvestigationMarkerFromAvailableDataTypes {
  lat: number;
  lng: number;
  color: string;
  identity: string;
  dataDepth: number;
  groupId: string;
  cdr?: Cdr;
  query?: Query;
  post?: Post;
  adIntEntity?: AdIntWsEntity;
  nearbyLocation?: NearbyTelegramProfile;
  locationInsight?: InvestigationLocationInsight;
  hasLocationHistory?: boolean;
  node?: RawEventEntityNode;
  createdAt?: Date;
  place?: Place;
}

export interface InvestigationInfoWindowRef {
  identity: string;
  groupId: string;
  createdAt: Date;
  sameLocationMarkers: InvestigationIconMarker[];
  sameLocationMarkersCount: number;
  cdr?: Cdr;
  query?: Query;
  nearbyLocation?: NearbyTelegramProfile;
  post?: Post;
  adIntEntity?: AdIntWsEntity;
}

export function getSocialConnectionsFilter(
  targetId: string,
  profileId: string
): RequestOptions {
  return {
    filters: {
      source: [
        DataSource.Twitter,
        DataSource.Facebook,
        DataSource.Instagram,
        DataSource.LinkedIn,
      ],
      targetId,
      type: EntityType.Profile,
      relationType: [
        EntityRelationType.Friend,
        EntityRelationType.Follower,
        EntityRelationType.Following,
        EntityRelationType.Family,
        EntityRelationType.Author,
        EntityRelationType.WorkedAt,
      ],
      limit: SocialDataLimits.CONNECTIONS,
      includeAssociatedTargets: true,
      profileId,
    },
  };
}
export function getSomedusConnectionsFilter(targetId: string): RequestOptions {
  return {
    filters: {
      source: [DataSource.Somedus],
      targetId,
      type: EntityType.Person,
      relationType: [EntityRelationType.Associated],
      includeAssociatedTargets: true,
      limit: 50,
    },
  };
}

export function getTelegramChatFilter(targetId: string): RequestOptions {
  return {
    filters: {
      source: [DataSource.Somedus],
      targetId,
      type: 'Chat',
      limit: 100,
    },
  };
}

export function getWorkplacesFilter(targetId: string): RequestOptions {
  return {
    filters: {
      source: [DataSource.LinkedIn, DataSource.Facebook],
      targetId,
      type: EntityType.Company,
    },
  };
}

export function getEducationFilter(targetId: string): RequestOptions {
  return {
    filters: {
      source: [DataSource.LinkedIn, DataSource.Facebook],
      targetId,
      type: EntityType.Education,
    },
  };
}

export function getCheckinFilters(
  targetId: string,
  profileId: string
): RequestOptions {
  return {
    filters: {
      source: [DataSource.Facebook],
      targetId,
      type: EntityType.Place,
      limit: SocialDataLimits.CHECKINS,
      profileId,
    },
  };
}

export function getSocialPostFilters(
  targetId: string,
  profileId: string
): RequestOptions {
  return {
    filters: {
      source: [DataSource.Facebook, DataSource.Twitter, DataSource.Instagram],
      targetId,
      type: EntityType.Post,
      limit: SocialDataLimits.POSTS,
      profileId,
    },
  };
}

export function getPhotoFilters(
  targetId: string,
  profileId: string
): RequestOptions {
  return {
    filters: {
      source: [DataSource.Facebook, DataSource.Twitter, DataSource.Instagram],
      targetId,
      type: EntityType.Photo,
      limit: SocialDataLimits.PHOTOS,
      profileId,
    },
  };
}

export function getSocialProfilesFilter(targetId: string): RequestOptions {
  return {
    filters: {
      source: [
        DataSource.Twitter,
        DataSource.Facebook,
        DataSource.Instagram,
        DataSource.LinkedIn,
      ],
      targetId,
      type: EntityType.Profile,
      relationType: [EntityRelationType.Plain],
    },
  };
}

export function getInstantMessagingProfiles(targetId: string): RequestOptions {
  return {
    filters: {
      source: [
        DataSource.WhatsApp,
        DataSource.Skype,
        DataSource.Telegram,
        DataSource.Tinder,
        DataSource.Truecaller,
        LocalDataSource.CallerID,
        DataSource.Viber,
        DataSource.Wechat,
      ],
      targetId,
      type: EntityType.Profile,
    },
  };
}

export function getSocialGroupsFilter(
  targetId: string,
  profileId: string
): RequestOptions {
  return {
    filters: {
      source: [DataSource.Facebook],
      targetId,
      type: EntityType.Group,
      profileId,
    },
  };
}

export interface InvestigationDateRange {
  start: Date;
  end: Date;
  eventFrom?: 'timeline' | 'timebar' | 'dateTimePicker';
  why?: ChangeReason;
}

export interface InvestigationLocationInsight {
  probabilityInsight: LocationProbabilityInsight;
  type: QueryCommand;
  identifier: string;
}

export interface InvestigationPredictedLocationTemplateRef {
  locationProbabilityInsight: LocationProbabilityInsight;
  color: string;
  type: QueryCommand;
}

export interface TargetNodeData {
  id: string;
  label: string;
  targetId: string;
  name: string;
  image: string;
  msisdns: string[];
  imsis: string[];
  imeis: string[];
  target: TargetItem;
  alias?: string;
  type?: nodeTypes;
}
