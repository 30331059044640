import { Injectable } from '@angular/core';
import { QueryContext } from '@trg-commons/data-models-ts';
import { OperationRequestDto } from '@trg-commons/gio-data-models-ts';
import { last } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AdIdOperationRequestHistoryService {
  private operationHistory: OperationRequestDto[] = [];

  constructor() {}

  addToHistory(operationRequest: OperationRequestDto) {
    this.operationHistory.push(operationRequest);
  }

  removeFromHistory(correlationId: string) {
    const index = this.operationHistory.findIndex(
      (request) => request.correlationId === correlationId
    );
    if (index !== -1) {
      this.operationHistory.splice(index, this.operationHistory.length);
    }
  }

  getAll(): OperationRequestDto[] {
    return this.operationHistory;
  }

  getActiveOperation(): OperationRequestDto {
    return last(this.operationHistory);
  }

  getPreviousOperation(correlationId: string): OperationRequestDto {
    return last(this.operationHistory);
  }

  getLastMainOperation(): OperationRequestDto {
    const history = [...this.operationHistory].reverse();

    // remove last request
    history.shift();

    return history.find((request) => {
      return (
        request.body.context === QueryContext.AnalyzeLocation ||
        request.body.context === QueryContext.LocationHistory
      );
    });
  }
}
