import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CDRFileUploadResponse } from '@trg/cdr-services-client';
import { EntityType, QueryCommand } from '@trg-commons/data-models-ts';
import {
  AdIdLocationHistoryDto,
  CaseCdrStatisticsDto,
  CdrExportDto,
  CdrStatisticsDto,
  CdrTargetEvent,
  CQRSBaseEvent,
  EventChannel,
  OperationRequestDto,
  PredictedLocationDto,
  PredictedLocationsEvent,
} from '@trg-commons/gio-data-models-ts';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash-es';
import {
  BehaviorSubject,
  forkJoin,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { StatisticModels } from 'src/app/modules/call-logs/services/cl-store-manager.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { ClParameterDataTypeBackendMapper } from 'src/app/shared/models/call-log-request.model';
import { environment } from 'src/environments/environment';
import {
  CallLogUploadRequest,
  FileUploadDataSampleResponseBody,
  ValidateSchemaRequestBody,
} from '../models/call-log-upload-request';
import {
  ClRecommandations,
  ClRecommandationsRequest,
  PurchaseClRecommandation,
} from './../../../../modules/call-logs/models/clr.model';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class CallLogsApiService {
  baseUrl: string;
  public purchaseClPendingState = new BehaviorSubject<string[]>([]);
  public listeners: Map<string, Subject<any>> = new Map();

  public prevRequestHash: string | undefined;
  public prevCorrelationId: string | undefined;

  constructor(
    private wsManager: WebsocketManagerService,
    private http: HttpClient
  ) {
    this.baseUrl = environment.proxyAPIUri;
    this.initializeListeners();
  }

  addToPurchasePendingState(msisdn: string) {
    this.purchaseClPendingState.next([
      ...this.purchaseClPendingState.getValue(),
      msisdn,
    ]);
  }

  createStatisticsRequest(
    request: CdrStatisticsDto
  ): Observable<CQRSBaseEvent<StatisticModels>> {
    const url = this.baseUrl + '/call-logs/cdr-statistics';
    if (!request.command) {
      return this.createMultipleStatisticRequests(request, url);
    }

    const correlationId = uuid();
    const listener = this.createListener(correlationId);
    this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(
      url,
      request,
      correlationId
    )
      .pipe(
        catchError((error) => {
          console.warn('Http Failure', error);
          return of(null);
        })
      )
      .subscribe();
    return listener.pipe(
      finalize(() => {
        this.removeListener(correlationId);
      })
    );
  }

  createStitisticsRequestTargetData(
    request: CdrStatisticsDto
  ): Observable<CQRSBaseEvent<StatisticModels>> {
    const url = this.baseUrl + '/call-logs/cdr-statistics';
    return forkJoin(
      this.prepareMultipleRequestsForTargetData(request, url)
    ).pipe(
      switchMap((result) =>
        result.map((clStatistic) => {
          return this.createListener(clStatistic.correlationId);
        })
      ),
      mergeAll()
    );
  }

  prepareMultipleRequestsForTargetData(
    request: CdrStatisticsDto,
    url: string
  ): Observable<CQRSBaseEvent<StatisticModels>>[] {
    const commands = [QueryCommand.StaticInfo, QueryCommand.ImeiDistribution];

    return commands.map((commandType) => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      console.log(requestBody.command);
      return this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(
        url,
        requestBody
      ).pipe(
        catchError((error) => {
          console.warn('Http Failure', error);
          return [];
        })
      );
    });
  }

  createMultipleStatisticRequests(
    request: CdrStatisticsDto,
    url: string
  ): Observable<CQRSBaseEvent<StatisticModels>> {
    const commands = [
      QueryCommand.CallAnalysis,
      QueryCommand.CountAnalysis,
      QueryCommand.EventByType,
      QueryCommand.TargetActivity,
      QueryCommand.TopAssociates,
      QueryCommand.TopLocations,
      QueryCommand.TargetPeerInteractions,
    ];
    const correlationIds: string[] = [];
    const listeners = commands.map((commandType) => {
      const requestBody = cloneDeep(request);
      const correlationId = uuid();
      correlationIds.push(correlationId);
      requestBody.command = commandType;
      const listener = this.createListener(correlationId);
      this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(
        url,
        requestBody,
        correlationId
      )
        .pipe(
          catchError((error) => {
            console.warn('Http Failure', error);
            return [];
          })
        )
        .subscribe();
      return listener;
    });

    return merge(...listeners).pipe(
      finalize(() => {
        correlationIds.forEach((correlationId) =>
          this.removeListener(correlationId)
        );
      })
    );
  }

  public getTargetsCallLogPeerInteractionsStatistics(msisdns: string[]) {
    const url = `${environment.proxyAPIUri}/call-logs/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, {
      msisdns,
      command: EntityType.TargetPeerInteractions,
    }).pipe(mergeMap((result) => this.createListener(result.correlationId)));
  }

  // is used in target view link analysis
  public getTargetCallLogTopAssociatesStatistics(msisdns: string[]) {
    const url = `${environment.proxyAPIUri}/call-logs/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, {
      msisdns,
      command: EntityType.TopAssociates,
    }).pipe(mergeMap((result) => this.createListener(result.correlationId)));
  }

  // is used in case view link analysis
  public getCaseCallLogTopAssociatesStatistics(targetIds: string[]) {
    const url = this.baseUrl + `/call-logs/case/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, {
      targetIds,
      command: EntityType.CaseTopAssociates,
    }).pipe(mergeMap((result) => this.createListener(result.correlationId)));
  }

  protected getConnectionId(): Observable<string> {
    return this.wsManager
      .getServerTsConnection()
      .pipe(map((socket) => socket.id));
  }

  private buildHttpHeaders(
    connectionId: string,
    correlationId?: string
  ): HttpHeaders {
    let headers = new HttpHeaders().append('Ws-Connection-Id', connectionId);
    if (correlationId) {
      headers = headers.set('correlation-id', correlationId);
    }
    return headers;
  }

  postWithHeadersWithObserve<T>(
    url: string,
    request: unknown
  ): Observable<any> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.http.post<T>(url, request, {
          headers: headers,
          observe: 'events',
          reportProgress: true,
        });
      })
    );
  }

  postWithHeaders<T>(
    url: string,
    request: unknown,
    correlationId?: string
  ): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId, correlationId);
        return this.http.post<T>(url, request, { headers: headers });
      })
    );
  }

  putWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap((connectionId) => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.http.put<T>(url, request, { headers: headers });
      })
    );
  }

  hashRequest(request: AdIdLocationHistoryDto): string {
    return JSON.stringify(
      new AdIdLocationHistoryDto({
        ifas: request.ifas,
        msisdns: request.msisdns,
        startTime: request.startTime,
        endTime: request.endTime,
        limit: request.limit,
        order: request.order,
        imeis: request.imeis,
        batchIds: request.batchIds,
        countryCodes: request.countryCodes,
        regionCodes: request.regionCodes,
      })
    );
  }

  locationHistoryRequest(
    request: AdIdLocationHistoryDto
  ): Observable<CQRSBaseEvent<any>> {
    const url = this.baseUrl + '/call-logs/location-history/filters';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      switchMap((result) => {
        this.prevCorrelationId = result.correlationId;
        return this.createListener(result.correlationId);
      }),
      catchError((error) => {
        console.warn('Http Failure', error);
        return of(null);
      })
    );
  }

  requestClRecommandation(
    request: ClRecommandationsRequest
  ): Observable<ClRecommandations> {
    const url =
      this.baseUrl +
      '/call-log-recommendation/rpc/request-call-log-recommendations';
    const correlationId = uuid();
    return this.postWithHeaders<OperationRequestDto[]>(
      url,
      request,
      correlationId
    ).pipe(
      mergeMap((result) =>
        result.map(() => this.createListener(correlationId))
      ),
      mergeAll(),
      tap((cqrsMessage) => {
        if (cqrsMessage.channel === EventChannel.OperationRequestsStreamEnded) {
          const listener = this.listeners.get(cqrsMessage.correlationId);
          this.waitForOutOfOrderMessagesAndCleanup(
            cqrsMessage.correlationId,
            listener
          );
        }
      }),
      filter(
        (cqrsMessage) =>
          cqrsMessage.channel === EventChannel.CallLogRecommendations
      ),
      map((clRecommandation) => clRecommandation.body)
    );
  }

  purchaseCallLogRequest(telno: string): Observable<PurchaseClRecommandation> {
    const url =
      this.baseUrl + '/call-log-request/rpc/request-call-log-purchase';

    return this.postWithHeaders<OperationRequestDto>(url, {
      msisdn: telno,
    }).pipe(
      tap((result) => this.addToPurchasePendingState(telno)),
      mergeMap((result) => this.createListener(result.correlationId)),
      map((result) => result.body)
    );
  }

  createCaseLocationHistoryRequest(
    request: AdIdLocationHistoryDto
  ): Observable<CdrTargetEvent> {
    const url = this.baseUrl + '/call-logs/case/location-history';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap((result) => this.createListener(result.correlationId)),
      catchError((error) => {
        console.warn('Http Failure', error);
        return of(null);
      })
    );
  }

  createPredictedLocationsRequest(
    request: PredictedLocationDto
  ): Observable<PredictedLocationsEvent> {
    const url = this.baseUrl + '/call-logs/predicted-location';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap((result) => this.createListener(result.correlationId)),
      catchError((error) => {
        console.warn('Http Failure', error);
        return of(null);
      })
    );
  }

  createCasePredictedLocationsRequest(
    request: PredictedLocationDto
  ): Observable<any> {
    const url = this.baseUrl + '/call-logs/case/predicted-location';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap((result) => this.createListener(result.correlationId)),
      catchError((error) => {
        console.warn('Http Failure', error);
        return of(null);
      })
    );
  }

  createExportRequest(request: CdrExportDto): Observable<any> {
    const exportUrl = this.baseUrl + '/call-logs/cdr-export';
    const downloadUrl = this.baseUrl + '/call-logs/download/';

    return this.postWithHeaders<OperationRequestDto>(exportUrl, request).pipe(
      mergeMap((operationRequest) =>
        this.createListener(operationRequest.correlationId)
      ),
      filter(
        (event) => event.channel === EventChannel.OperationRequestsStreamEnded
      ),
      mergeMap((listener) => {
        return this.http.get(downloadUrl + listener.correlationId, {
          responseType: 'blob',
        });
      }),
      mergeMap((fileResponse) => {
        const fileName =
          request.msisdns.join().replace(/\+/g, '').replace(/,/g, '_') +
          '_call_log.csv';
        const blob = new Blob([fileResponse], { type: fileResponse.type });
        saveAs(blob, fileName);
        return of(request);
      })
    );
  }

  uploadCallLogsFile(
    file: File,
    request: CallLogUploadRequest
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const mappedRequest = {
      ...request,
      uploadType: ClParameterDataTypeBackendMapper[request.uploadType],
    };
    Object.entries(mappedRequest).forEach((el) => {
      formData.append(el[0], el[1]);
    });
    return this.postWithHeadersWithObserve(
      this.baseUrl + '/call-logs/upload',
      formData
    ).pipe(
      mergeMap((resp: any) => {
        if (resp instanceof HttpResponse) {
          return this.createListener(resp.body.correlationId);
        }
        return of(resp);
      })
    );
  }

  uploadCallLogsFileAnyFormat(
    file: File,
    request: CallLogUploadRequest
  ): Observable<HttpResponse<CDRFileUploadResponse>> {
    const formData = new FormData();
    formData.append('file', file);
    const mappedRequest = {
      ...request,
      uploadType: ClParameterDataTypeBackendMapper[request.uploadType],
    };
    Object.entries(mappedRequest).forEach((el) => {
      formData.append(el[0], el[1]);
    });
    return this.postWithHeadersWithObserve(
      environment.cdrServiceAPIUri + '/upload',
      formData
    ).pipe(
      mergeMap((resp: any) => {
        if (resp instanceof HttpResponse) {
          return this.createListener(resp.body.correlationId);
        }
        return of(resp);
      })
    );
  }

  getFileUploadDataSample(
    callLogRequestId: string
  ): Observable<FileUploadDataSampleResponseBody> {
    return this.http.get<FileUploadDataSampleResponseBody>(
      `${environment.cdrServiceAPIUri}/call-log-request/data-sample/${callLogRequestId}`
    );
  }

  updateSchemaMapping(
    requestBody: ValidateSchemaRequestBody
  ): Observable<CDRFileUploadResponse> {
    return this.http.patch<CDRFileUploadResponse>(
      environment.cdrServiceAPIUri + '/upload/verify',
      requestBody
    );
  }

  /**
   *
   * @param targetIds string
   */
  public createCdrStatisticRequest(request: CaseCdrStatisticsDto) {
    const url = this.baseUrl + `/call-logs/case/cdr-statistics`;
    if (!request.command) {
      return forkJoin(
        this.prepareMultipleStatisticRequestsForCaseCdr(request, url)
      ).pipe(
        switchMap((result) =>
          result.map((clStatistic) => {
            return this.createListener(clStatistic.correlationId);
          })
        ),
        mergeAll()
      );
    } else {
      return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
        switchMap((result) => this.createListener(result.correlationId))
      );
    }
  }

  prepareMultipleStatisticRequestsForCaseCdr(
    request: CaseCdrStatisticsDto,
    url: string
  ): Observable<CQRSBaseEvent<StatisticModels>>[] {
    const commands = [
      QueryCommand.CommonAssociates,
      QueryCommand.CommonLocations,
      QueryCommand.CaseTopAssociates,
      QueryCommand.CaseTopLocations,
      QueryCommand.CaseTargetActivity,
      QueryCommand.CaseEventByType,
      QueryCommand.CaseCallAnalysis,
      QueryCommand.CaseCountAnalysis,
      QueryCommand.CasePredictedLocations,
      QueryCommand.CaseTargetsInteractions,
    ];
    return commands.map((commandType) => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      return this.postWithHeaders<OperationRequestDto>(url, requestBody).pipe(
        catchError((error) => {
          console.warn('Http Failure', error);
          return [];
        })
      );
    });
  }

  private createListener(
    correlationId: string | undefined
  ): Observable<CQRSBaseEvent<any>> {
    if (!correlationId) throw 'Cannot create listener with no correlationId';
    const listener = this.listeners.get(correlationId);
    if (listener) return listener.asObservable();
    const subject = new ReplaySubject<CQRSBaseEvent<any>>(1);

    this.listeners.set(correlationId, subject);
    return subject.asObservable();
  }

  private initializeListeners() {
    this.wsManager.getServerTsConnection().subscribe((ws) => {
      ws.on('message', (data: CQRSBaseEvent<any>) => {
        const listener = this.listeners.get(data.correlationId);

        if (!listener) {
          return;
        }

        listener.next(data);

        if (
          data.streamEnded &&
          data.channel === EventChannel.OperationRequestsStreamEnded
        ) {
          this.waitForOutOfOrderMessagesAndCleanup(
            data.correlationId,
            listener
          );
        }
      });
    });
  }

  private removeListener(correlationId: string) {
    const listener = this.listeners.get(correlationId);
    if (listener) {
      this.listeners.delete(correlationId);
      listener.complete();
    }
  }

  private waitForOutOfOrderMessagesAndCleanup(
    corId: string,
    listener: Subject<any>
  ) {
    this.listeners.delete(corId);
    listener.complete();
  }
}
