import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { JobType } from 'datalayer/models/background-jobs/background-job-type';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { uniqBy } from 'lodash-es';
import { map, shareReplay, tap } from 'rxjs';
import { BaseComponent } from 'src/app/base/base.component';
import { RequestClParameters } from 'src/app/modules/analysis/shared/models/request-cl-parameters.model';
import { RequestClValidatorService } from 'src/app/modules/analysis/shared/services/request-cl-validator.service';
import { TargetEnhanceService } from 'src/app/modules/analysis/shared/services/target-enhance.service';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { CallLogRequestDialogService } from 'src/app/services/call-logs/call-log-request-dialog.service';
import { ClRequestStatus } from 'src/app/services/call-logs/call-log-requests-messages.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import {
  Confirmation,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { OsintNotificationsStore } from 'src/app/shared/components/osint-group-notifications/osint-notifications.store';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { CostInfoActionDialogModel } from 'src/app/shared/models/cost-info-action-dialog.model';
import { Query } from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { filterOutEnumNumberKeys } from 'src/app/shared/util/helper';

@Component({
  selector: 'app-geolocation-target-actions',
  templateUrl: './geolocation-target-actions.component.html',
  styleUrls: ['./geolocation-target-actions.component.scss'],
})
export class GeolocationTargetActionsComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() query: Query;
  @Input() target: TargetItem;
  @Output() emitExportPdf = new EventEmitter<boolean>();
  @Output() emitRequestCallLog = new EventEmitter<boolean>();
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  targetCreditsChargesEnabled = false;
  expireTargetDays = 0;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  clInProgress = true;
  telno: string;
  isAdvancedOsintLoading = false;
  public enabledNewCallLogsRequest: boolean;
  constructor(
    private queryService: QueryService,
    private appConfigService: AppConfigService,
    private targetService: TargetService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private translationService: TranslationService,
    private profilerService: ProfilerService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private requestClValidatorService: RequestClValidatorService,
    private callLogRequestDialogService: CallLogRequestDialogService,
    private targetEnhanceService: TargetEnhanceService,
    private targetRenewalService: TargetRenewalService,
    private webintDisabledService: WebintDisabledService,
    private userBillingService: UserBillingService,
    private osintNotificationsStore: OsintNotificationsStore,
    private redirectSnackBarService: RedirectSnackBarService
  ) {
    super();
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.enabledNewCallLogsRequest = this.appConfigService.getConfigVariable(
      'enabledNewCallLogsRequest'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      this.getClRequestStatus();
    }

    if (this.query?.targetId) {
      this.checkIfOsintJobsArePending(this.query.targetId);
    } else {
      this.isAdvancedOsintLoading = false;
    }
  }

  getClRequestStatus(): void {
    const requestCl: RequestClParameters = this.prepareRequestCl(this.query);
    const allEmpty = filterOutEnumNumberKeys(requestCl).every(
      (key) => requestCl[key]?.length === 0
    );

    if (allEmpty) {
      return;
    }

    this.requestClValidatorService
      .checkForPendingClRequests(requestCl)
      .subscribe((result: number) => {
        this.clInProgress = result > 0;
      });
  }

  advancedOsint() {
    this.profilerService.targetData.next(null);

    if (this.target) {
      this.targetEnhanceService.advancedOsintForTarget(false, this.target);
    } else {
      const newTarget: TargetItem = {
        alias: this.telno.replace('+', ''),
        telnos: [this.telno],
        user: this.localStorageService.getCurrentUser().identity,
      };

      this.targetService
        .createTargetProfiler(newTarget, {
          createCase: false,
          addImProfiles: true,
        })
        .subscribe(
          (target: TargetItem) => {
            this.targetEnhanceService.advancedOsintForTarget(false, target);
          },
          (error: any) => {
            const errorMessage = error?.messages;

            if (
              this.redirectSnackBarService.shouldShowRedirectSnackBar(
                errorMessage
              )
            ) {
              this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
            } else {
              this.showMessage(
                this.translationService.translate(
                  error.messages
                    ? error.messages
                    : 'Target has not been created'
                )
              );
            }
          }
        );
    }
  }

  private checkIfOsintJobsArePending(targetId: string): void {
    this.osintNotificationsStore.notifications$
      .pipe(
        map((ids) => {
          return uniqBy(ids[targetId], 'platform');
        }),
        tap((ids: OsintSearchNotificationResponse[]) => {
          if (!ids?.length) {
            this.isAdvancedOsintLoading = false;
          }
          this.isAdvancedOsintLoading = ids.some(
            (n) => n.status === JobStatus.PENDING
          );
          if (ids.length) {
            ids.forEach((status) => {
              if (
                status.status === JobStatus.DONE &&
                status.osint_type === JobType.ADVANCED_OSINT
              ) {
                this.isAdvancedOsintLoading = false;
              }
            });
          }
        }),
        shareReplay()
      )
      .subscribe();
  }

  generatePdf() {
    this.emitExportPdf.emit(true);
  }

  onLocate(query) {
    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      this.queryService.locateQuery.next(query);
    }
  }

  intelSearchQuery(query) {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }
    if (!this.telno) {
      return;
    }

    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.INTEL_SEARCH,
      ])
    ) {
      this.queryService.intelQuery.next(query);
    }
  }

  requestCallLog(): void {
    if (!this.clInProgress) {
      const requestCl: RequestClParameters = this.prepareRequestCl(this.query);
      const allEmpty = filterOutEnumNumberKeys(requestCl).every(
        (key) => requestCl[key].length === 0
      );
      if (allEmpty) {
        return;
      }
      this.callLogRequestDialogService
        .openRequestCallLogDialog({ requestCl })
        // TODO - when INT-6516 is done rollback to this
        // .openRequestCallLogDialog({ requestCl, ...(this.target ? { target: this.target } : {}) })
        .subscribe((clRequestStatus: ClRequestStatus) => {
          if (clRequestStatus !== ClRequestStatus.REQUESTED) {
            return;
          }
          this.clInProgress = true;
        });
    }
  }

  navigateToTargetAndRequestCL(targetId) {
    this.profilerService.targetData.next(null);
    this.router.navigate(['targets', targetId, 'new-call-log-analysis'], {
      queryParams: { requestCallLog: true },
    });
  }

  openConfirmationDialog(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'no-padding-popup',
      data: {
        description: message,
        enableCancelBtn: false,
        okText: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((action: Confirmation) => {
      if (action === 'proceed') {
        dialogRef.close();
      }
    });
  }

  openAdvancvedOSINTDialog() {
    if (this.isAdvancedOsintLoading) {
      return;
    }

    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    if (!this.telno) {
      return;
    }
    if (this.target?.expired) {
      this.subscriptions.push(
        this.targetRenewalService
          .isTargetExpiredShowMessageOrRenew([this.target], true)
          .subscribe((isTargetActive: boolean) => {
            if (!isTargetActive) {
              return;
            }
            this.dialogForAdvancedOsint();
          })
      );
    } else {
      this.dialogForAdvancedOsint();
    }
  }

  dialogForAdvancedOsint(): void {
    let message;

    const advancedOSINTMessage = `${this.translationService.translate(
      'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.'
    )}`;
    const targetCreditsMessage = this.translationService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );
    const title = 'Enhance Profile';

    if (this.query.targetId) {
      message = advancedOSINTMessage;
    } else {
      message = `${this.translationService.translate(
        'Create a new target and find possible associates, visited places, connections on social media, groups and more.'
      )}
        ${this.translationService.translate(
          'Credits will be deducted only if accurate information is found.'
        )}`;
    }

    const intelDialog = this.dialog.open(CostInfoActionDialogComponent, {
      data: <CostInfoActionDialogModel>{
        title: title,
        message: `${message}`,
        action: BillingActions.ADVANCED_OSINT,
        actionBtnLabel: 'Enhance Profile',
        footerText: this.targetCreditsChargesEnabled
          ? targetCreditsMessage
          : '',
      },
      panelClass: 'renew-credit',
    });

    intelDialog.afterClosed().subscribe((action) => {
      if (action?.key === 'success') {
        this.advancedOsint();
      }
    });
  }

  /**
   * @param  {string} msg
   * @param  {} okText='OK'
   */
  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
    });
  }

  private prepareRequestCl(query: Query): RequestClParameters {
    if (!query) {
      return {
        msisdns: [],
        imsis: [],
        imeis: [],
      };
    }

    this.telno = query.queryArgs?.telno || query.provider?.telno;
    const imsi = query.queryArgs?.imsi || query.provider?.imsi;
    const imei = query.device?.imei;
    return {
      msisdns: [this.telno]?.filter((msisdn) => msisdn != null) || [],
      imsis: [imsi]?.filter((imsi) => imsi != null) || [],
      imeis: [imei]?.filter((imei) => imei != null) || [],
    };
  }
}
