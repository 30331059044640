export enum DataSource {
  OpenSearch = 'OS',
  SocialSearch = 'SS',
  Facebook = 'FB',
  Instagram = 'IG',
  Twitter = 'TW',
  LinkedIn = 'IN',
  WhatsApp = 'WA',
  Telegram = 'TG',
  Skype = 'SK',
  Truecaller = 'TC',
  CallerID = 'CI',
  Viber = 'VB',
  Wechat = 'WC',
  Interpol = 'INTERPOL',
  Dea = 'DEA',
  FBI = 'FBI',
  INE = 'INE',
  Tinder = 'TIN',
  Youtube = 'YT',
  Tiktok = 'TT',
  DBSearch = 'DBS',
  CallLog = 'CDR',
  GeoLocation = 'GEOLOCATION',
  Other = 'OTHER',
  AdInt = 'ADINT',
  Google = 'GG',
  Somedus = 'SM',
  YouTube = 'YT',
  Unknown = 'UNK',
  IndoDB = 'INDODB',
  // SOMEDUS-TG PLATFORM IS GENERATED AFTER USER IMPORTING A FILE VIA SOMEDUS DX-INTEGRATION FEATURE
  SomedusTg = 'SOMEDUS-TG',
}

export function getDataSourceName(source: DataSource | string): string {
  const sourceEl = Object.entries(DataSource).find(
    ([_name, value]) => value === source
  );

  return sourceEl ? sourceEl[0] : source;
}

export function getDataSource(source: string): DataSource {
  const sourceEl = Object.keys(DataSource).find(
    (key) => key.toLowerCase() === source.toLowerCase()
  );
  return DataSource[sourceEl] || DataSource.Unknown;
}
