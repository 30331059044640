<i
  [class.fa-sharp]="sharpFamily ? 'fa-' + sharpFamily + ' fa-' + name : ''"
  [class]="family ? 'fa-' + family + ' fa-' + name : ''"
  [class.fa-rotate-by]="rotate > 0"
  [class.fa-spin]="spin"
  [class.fa-spin-pulse]="spin && pulse"
  [class.fa-spin-reverse]="spin && reverse"
  [class.fa-shake]="shake"
  [class.fa-flip]="flip"
  [class.fa-bounce]="bounce"
  [class.fa-fade]="fade"
  [class.fa-beat]="beat"
  [style.--fa-primary-color]="primaryColor"
  [style.--fa-secondary-color]="secondaryColor"
  [style.--fa-primary-opacity]="primaryOpacity"
  [style.--fa-secondary-opacity]="secondaryOpacity"
  [style.--fa-rotate-angle]="rotate + 'deg'"
  [style.--fa-fade-opacity]="fadeOpacity"
  [style.--fa-animation-duration]="
    animationDuration ? animationDuration + animationDurationUnit : ''
  "
></i>
