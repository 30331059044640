<div class="d-flex flex-column group-details-section">
  <div class="dialog-section back" *ngIf="!hideBackButton">
    <span
      ><a (click)="goBack()" class="link">< {{ 'Back' | translate }}</a></span
    >
  </div>
  <div class="d-flex header-wrapper">
    <span
      ><strong>{{ 'Common Groups' | translate }} </strong>
      {{ groupChats.length }}</span
    >
    <div class="icon">
      <img src="assets/static/images/telegram_group.png" />
    </div>
  </div>
  <div class="divider"></div>
  <div *ngFor="let group of communityInformationGroup">
    <div class="d-flex justify-content-between group-item">
      <div class="content d-flex flex-column">
        <div class="group-title">{{ group.name }}</div>
        <div class="group-internal-statistics row d-flex">
          <div>
            <div>
              <strong>{{ group.communityInfo.messagesCount }}</strong>
            </div>
            <div>
              <strong>{{ group.communityInfo.membersCount }}</strong>
            </div>
          </div>
          <div class="labels-col">
            <div>{{ 'messages' | translate }}</div>
            <div>{{ 'members' | translate }}</div>
          </div>
          <div>
            <div>
              <strong>{{ group.communityInfo.mediaCount }}</strong>
            </div>
            <div>
              <strong>{{ group.communityInfo.activeMembersCount }}</strong>
            </div>
          </div>
          <div class="labels-col">
            <div>{{ 'media' | translate }}</div>
            <div>{{ 'active members' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="group-status">
        <div class="group-status-box">
          {{ group.isPrivate ? 'Private' : 'Public' }}
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</div>
