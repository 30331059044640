import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { ImageOverlayDirective } from 'src/app/shared/directives/image-overlay.directive';
import { Platform } from 'src/app/shared/schemas/common/platforms';

export interface AvatarWithPlatform {
  src: string;
  platform: Platform;
  overlayDisabled?: boolean;
  customTpl?: boolean;
}

@Component({
  selector: 'app-multiple-avatars',
  templateUrl: './multiple-avatars.component.html',
  styleUrls: ['./multiple-avatars.component.scss'],
})
export class MultipleAvatarsComponent implements OnInit {
  constructor(private queryService: QueryService) {}

  @Input() avatars: Array<AvatarWithPlatform>;
  @Output() imgError = new EventEmitter<AvatarWithPlatform>();
  @ViewChildren('overlayDir') overlayDir: ImageOverlayDirective[];
  @ContentChild('customMainAvatarTplRef')
  customMainAvatarTplRef: TemplateRef<any>;
  public showAllThumbnails: boolean;

  ngOnInit(): void {}

  /**
   *
   * @param platform Platform
   */
  getPlatformImage(platform: Platform) {
    return this.queryService.getPlatformImage(platform);
  }

  /**
   *
   * @param avatar AvatarWithPlatform
   */
  onError(avatar: AvatarWithPlatform) {
    avatar.overlayDisabled = true;
    this.imgError.emit(avatar);
  }

  showRestAvatars() {
    this.showAllThumbnails = true;
  }

  hideRestAvatars() {
    this.showAllThumbnails = false;
    // directly hide imageOverlay if user will click on any of the rest-avatars picture,
    // in such case mouseout event will not be fired and overlayPicture will stay forever
    this.overlayDir.forEach((o) => o.hide());
  }
}
