import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-lightbox-component',
  templateUrl: './lightbox-component.component.html',
  styleUrls: ['./lightbox-component.component.scss'],
})
export class LightboxComponentComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LightboxComponentComponent>) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
