import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { Angulartics2 } from 'angulartics2';
import { OsintService } from 'datalayer/services/osint/osint.service';
import { Observable } from 'rxjs';
import {
  EnhanceProfileAction,
  EnhanceProfilePopupModel,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { EnhanceProfilePopupComponent } from 'src/app/modules/search-intel/shared/enhance-profile-popup/enhance-profile-popup.component';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { TargetLink } from 'src/app/shared/models/social-media.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { matomoActions } from 'src/app/shared/values/matomo-config';
import { AnalysisActionsListModel } from '../models/analysis-actions.model';

@Injectable({
  providedIn: 'root',
})
export class TargetEnhanceService extends BaseService {
  expireTargetDays = 0;
  enableNIK = false;
  targetCreditsChargesEnabled = false;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private actionService: ActionService,
    private angulartics2: Angulartics2,
    private osintService: OsintService,
    private appConfigService: AppConfigService
  ) {
    super(router, snackBar);
    this.enableNIK = this.appConfigService.getConfigVariable('enableNIK');
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
  }

  onEnhanceProfilePopup(
    targets: TargetItem[],
    advancedOsintCost: number
  ): Observable<boolean> {
    const targetsCount = targets.length;
    let credits;
    let eligibleTargetsCount = 0;

    const eligibleTargets: TargetItem[] = [];
    targets.forEach((target) => {
      if (
        this.advancedOsintForTarget(true, target) &&
        !this.isTargetExpired(target)
      ) {
        eligibleTargetsCount += 1;
        eligibleTargets.push(target);
      }
    });

    if (targetsCount === eligibleTargetsCount) {
      credits = advancedOsintCost * targetsCount;
    } else {
      credits =
        advancedOsintCost *
        (targetsCount - (targetsCount - eligibleTargetsCount));
    }

    const enableAdvancedOsint = credits > 0;

    const dialogRef = this.dialog.open(EnhanceProfilePopupComponent, {
      data: <EnhanceProfilePopupModel>{
        enableAdvancedOsint: enableAdvancedOsint,
        fromTarget: true,
        credits: credits,
      },
      minWidth: '460px',
      maxWidth: '600px',
    });

    return new Observable<boolean>((observable) => {
      dialogRef.componentInstance.onEnhanceProfile.subscribe(
        (response: EnhanceProfileAction) => {
          if (!response) {
            dialogRef.close(true);
            observable.next(false);
          }
          switch (response) {
            case EnhanceProfileAction.ADVANCED:
              eligibleTargets.forEach((target) => {
                if (this.advancedOsintForTarget(true, target)) {
                  this.advancedOsintForTarget(false, target);
                }
              });
              dialogRef.close(false);
              observable.next(true);
              break;
            default:
              dialogRef.close(true);
              observable.next(false);
          }
        }
      );
      dialogRef.afterClosed().subscribe((fromOutside: boolean) => {
        if (fromOutside) {
          observable.next(false);
        }
      });
    });
  }

  advancedOsintForTarget(checkArgs, target: TargetItem) {
    const searchArgs = [];

    if (target.telnos?.length) {
      target.telnos.forEach((telno) =>
        searchArgs.push({ arg_type: 'telno', arg_value: telno })
      );
    }

    target.emails?.forEach((email) => {
      searchArgs.push({ arg_type: 'email', arg_value: email });
    });

    if (this.enableNIK) {
      target.nik?.forEach((n) => {
        searchArgs.push({ arg_type: 'nik', arg_value: n });
      });
    }

    if (target.userIds?.length) {
      target.userIds.forEach((userId: string) => {
        if (
          userId.includes(Platforms.FACEBOOK) ||
          userId.includes(Platforms.TWITTER) ||
          userId.includes(Platforms.INSTAGRAM)
        ) {
          searchArgs.push({ arg_type: 'userId', arg_value: userId });
        }

        if (userId.includes(Platforms.LINKEDIN)) {
          searchArgs.push({ arg_type: 'alias', arg_value: userId });
        }

        if (userId.includes(Platforms.TIKTOK)) {
          searchArgs.push({ arg_type: 'userId', arg_value: userId });
        }
      });
    }

    if (target.socialProfiles?.length) {
      target.socialProfiles.forEach((item: TargetLink) => {
        searchArgs.push({ arg_type: 'url', arg_value: item.link });
        let arg_type = 'userId';
        if (item.platform === 'linkedin' && item.userId) {
          arg_type = 'alias';
          searchArgs.push({ arg_type, arg_value: item.userId });
        } else if (item.platform === 'tiktok' && item.userId) {
          searchArgs.push({ arg_type: 'userId', arg_value: item.userId });
        } else if (item.userId) {
          searchArgs.push({ arg_type: 'userId', arg_value: item.userId });
        }
      });
    }

    if (checkArgs) {
      return !!this.osintService.removeDuplicateAndMaskedSeeds(searchArgs)
        .length;
    } else {
      this.angulartics2.eventTrack.next({
        action: matomoActions.advancedOsint,
        properties: {
          category: matomoActions.overview,
        },
      });
      this.osintService
        .advancedOsint({
          query_args: searchArgs,
          target_id: target.id,
        })
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate(
                'Enhance Profile query created successfully!'
              )
            );
            this.actionService.publishAction(
              new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
            );
          },
          (error: Error) => {
            this.showMessage(this.translationService.translate(error.message));
          }
        );
      return true;
    }
  }

  isTargetExpired(target: TargetItem): boolean {
    if (!this.targetCreditsChargesEnabled) {
      return false;
    }

    return target.expired;
  }

  areTargetsEligibleForOsint(
    targets: TargetItem[],
    advancedOsintCost: number
  ): boolean {
    const targetsCount = targets.length;
    let credits;
    let eligibleTargetsCount = 0;
    const eligibleTargets: TargetItem[] = [];
    targets?.forEach((target) => {
      if (this.advancedOsintForTarget(true, target)) {
        eligibleTargetsCount += 1;
        eligibleTargets.push(target);
      }
    });

    if (targetsCount === eligibleTargetsCount) {
      credits = advancedOsintCost * targetsCount;
    } else {
      credits =
        advancedOsintCost *
        (targetsCount - (targetsCount - eligibleTargetsCount));
    }

    return credits > 0;
  }
}
