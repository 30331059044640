import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Observable, Subject, Subscription } from 'rxjs';
import { CreateCaseDialogComponent } from 'src/app/modules/profiler/components/create-case-dialog/create-case-dialog.component';
import { TargetDeleteComponent } from 'src/app/modules/profiler/components/target-delete/target-delete.component';
import { TargetExportComponent } from 'src/app/modules/profiler/components/target-export/target-export.component';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { TargetDialogComponent } from 'src/app/modules/search-intel/components/target-dialog/target-dialog.component';
import { MsisdnConfirmationPopupComponent } from 'src/app/modules/search-intel/shared/msisdn-confirmation-popup/msisdn-confirmation-popup.component';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { RequestCl } from 'src/app/services/call-logs/request-cl.model';
import { CaseService } from 'src/app/services/case/case.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from 'src/app/shared/classes/action.class';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { CreateTargetDialogComponent } from 'src/app/shared/components/create-target-dialog/create-target-dialog.component';
import { NewRequestClDialogComponent } from 'src/app/shared/components/new-request-cl-dialog/new-request-cl-dialog.component';
import { ClRequestType } from 'src/app/shared/components/new-request-cl-dialog/request-cl-dialog.model';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { AddToCaseModalData, Case } from 'src/app/shared/models/case.model';
import { CostInfoActionDialogModel } from 'src/app/shared/models/cost-info-action-dialog.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { isValidMSISDN } from 'src/app/shared/util/helper';
import { BaseComponent } from '../../../../shared/classes/base.component';
import { TargetItem } from '../../../../shared/models/target-item.model';
import { AddToCaseComponent } from '../components/add-to-case/add-to-case.component';
import { AnalysisActionsListModel } from '../models/analysis-actions.model';
import { CallLogsListResponseModel } from '../models/targets-list-request.model';
import { TableActionsUtilService } from './table-actions-util.service';
import { FeatureTableRow } from 'src/app/modules/analysis/shared/models/feature-table.model';

@Injectable({
  providedIn: 'root',
})
export class TableActionsService extends BaseComponent {
  advancedOsintAvailability = true;
  deepOsintAvailability = false;
  expireTargetDays = 0;
  targetCreditsChargesEnabled = false;
  caseCreditsChargesEnabled = false;
  loader$: Subject<boolean> = new Subject<boolean>();
  tabChanged: Subject<boolean> = new Subject<boolean>();
  expireCaseDays = 0;
  callLogsEnabledCountries: string[] = [];
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  pendingRequest: { [key: string]: boolean } = {};

  constructor(
    private translationService: TranslationService,
    private queryService: QueryService,
    protected router: Router,
    public dialog: MatDialog,
    private targetService: TargetService,
    private actionService: ActionService,
    public snackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    private caseService: CaseService,
    private tableActionsUtilService: TableActionsUtilService,
    private profilerService: ProfilerService,
    private userBillingService: UserBillingService
  ) {
    super();
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    this.callLogsEnabledCountries = this.appConfigService.getConfigVariable(
      'callLogsEnabledCountries'
    );
  }

  onLocate(target: TargetItem) {
    if (!target.telnos) return;

    if (
      this.userBillingService.userHasEnoughCredits([
        BillingActions.QUERY_LOCATION,
      ])
    ) {
      target.telnos.forEach((telno) => {
        this.queryService.quickQuery([{ telno: telno }]).subscribe({
          next: () => {
            this.showMessage(
              this.translationService.translate('Query created successfully!')
            );
          },
          error: (err) => {
            this.queryService.handleQueryError(err);
          },
        });
      });
    }
  }

  onGenerateTargetSummaryReport(target: TargetItem): void {
    this.openTargetExportModal(target);
  }

  openTargetExportModal(target: TargetItem): void {
    this.dialog.open(TargetExportComponent, {
      data: {
        targetId: target.id,
      },
      restoreFocus: false,
      panelClass: ['export-pdf-call-logs-modal', 'no-padding-dialog'],
    });
  }

  onDeleteTarget(target: TargetItem): void {
    this.dialog
      .open(TargetDeleteComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type: 'delete-target' },
        panelClass: 'delete-profiler-target-dialog',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.targetService.removeTarget(target).subscribe(() => {
            this.showMessage(this.translationService.translate('Deleted!'));
            this.actionService.publishAction(
              new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
            );
          });
        }
      });
  }

  onDeleteCase(caseItem: Case): void {
    this.dialog
      .open(TargetDeleteComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type: 'delete-case' },
        panelClass: 'delete-profiler-target-dialog',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.caseService.removeCase(caseItem.id).subscribe((res) => {
            this.showMessage(this.translationService.translate('Deleted!'));
            this.actionService.publishAction(
              new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
            );
          });
        }
      });
  }

  createNewTarget() {
    this.dialog
      .open(CreateTargetDialogComponent, {
        width: '530px',
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.actionService.publishAction(
          new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
        );
      });
  }

  addToCaseDialog(selectedTargets: FeatureTableRow[]) {
    this.dialog
      .open<AddToCaseComponent, AddToCaseModalData>(AddToCaseComponent, {
        width: '30vw',
        height: 'auto',
        autoFocus: true,
        data: { targets: selectedTargets },
        panelClass: 'profiler-create-case-dialog',
      })
      .afterClosed()
      .subscribe((res) => {
        this.actionService.publishAction(
          new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
        );
      });
  }

  locateAllTelnos(
    telnos: string[],
    telnosAssignedCase = '0',
    considerNewDiscovery?: boolean
  ) {
    const actionsToCharge: BillingActions[] = telnos.map(
      () => BillingActions.QUERY_LOCATION
    );
    telnos = telnos.filter((elem: string) => isValidMSISDN(elem));
    if (this.userBillingService.userHasEnoughCredits(actionsToCharge)) {
      const dialogRef = this.dialog.open(MsisdnConfirmationPopupComponent, {
        data: telnos,
      });
      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
      const formattedTelnos = telnos.map((elem) => ({ telno: elem }));
      dialogRef.componentInstance.onLocate.subscribe(() => {
        if (formattedTelnos.length) {
          this.loader$.next(true);
          this.queryService
            .isUserOnDiscoveryV2()
            .subscribe((isUserOnDiscoveryV2) => {
              if (considerNewDiscovery && isUserOnDiscoveryV2) {
                this.queryService
                  .locateDiscoveryV2(formattedTelnos, telnosAssignedCase)
                  .subscribe({
                    next: () => {
                      this.router.navigateByUrl('/discovery', {
                        state: {
                          selectedTarget: { telno: formattedTelnos[0].telno },
                        },
                      });
                    },
                    error: () => {
                      this.loader$.next(false);
                    },
                  });
              } else {
                this.queryService.quickQuery(formattedTelnos).subscribe({
                  next: () => {
                    this.showMessage(
                      this.translationService.translate(
                        'Query created successfully!'
                      )
                    );
                    this.loader$.next(false);
                    this.router.navigateByUrl('/discovery');
                  },
                  error: (err) => {
                    this.loader$.next(false);
                    this.queryService.handleQueryError(err);
                  },
                });
              }
            });
        }
      });
    }
  }

  createNewCase() {
    this.dialog
      .open(CreateCaseDialogComponent, {
        width: '560px',
        height: 'auto',
        disableClose: true,
        panelClass: 'profiler-create-case-dialog',
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.actionService.publishAction(
          new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
        );
      });
  }

  createNewMsisdn(callLog?: CallLogsListResponseModel) {
    const callLogStatus = callLog ? callLog.status : null;
    const requestCallLogId = callLog ? callLog.id : null;
    const requestCl: RequestCl = callLog ? this.prepareRequestCl(callLog) : {};
    const targetId: string = callLog?.targets?.filter(
      (target) => target.createdBy === callLog.createdBy
    )[0]?.id;

    this.dialog
      .open(NewRequestClDialogComponent, {
        data: {
          clParameters: requestCl,
          hasUploadCapabilities: true,
          uploadClArgs: {
            callLogStatus: callLogStatus,
            requestCallLogId: requestCallLogId,
            targetId: targetId,
          },
          editable: !callLog,
        },
        panelClass: 'renew-credit',
      })
      .afterClosed()
      .subscribe((action) => {
        if (!action?.data) {
          return;
        }
        if (Object.is(action.data.actionType, ClRequestType.REQUEST_CALL_LOG)) {
          this.tableActionsUtilService.requestCallLog(action.data);
        }
        if (Object.is(action.data.actionType, ClRequestType.FULFILL_CALL_LOG)) {
          this.actionService.publishAction(
            new Action({ key: AnalysisActionsListModel.REFRESH_DATA })
          );
        }
        if (action.key === 'failed') {
          this.showMessage(
            this.translationService.translate(action.data.message)
          );
        }
      });
  }

  addToTargetDialog(callLog: CallLogsListResponseModel) {
    const newTarget: TargetItem = {
      telnos: callLog.msisdns,
      imsis: callLog.imsis,
      imeis: callLog.imeis,
    };

    return this.dialog.open(TargetDialogComponent, {
      data: { seedName: newTarget.telnos, seedCount: 1, newTarget: newTarget },
      autoFocus: true,
      panelClass: 'add-to-target-dialog',
    });
  }

  advancvedOSINTForMsisdn(msisdns: string[]) {
    let message;

    const advancedOSINTMessage = `${this.translationService.translate(
      'Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more'
    )} ${this.translationService.translate('for')}`;

    const targetCreditsMessage = this.translationService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );

    message = advancedOSINTMessage;
    message = `${this.translationService.translate(
      'Create a new target and find possible associates, visited places, connections on social media, groups and more.'
    )} ${this.translationService.translate(
      'Credits will be deducted only if accurate information is found.'
    )}`;

    const intelDialog = this.dialog.open(CostInfoActionDialogComponent, {
      data: <CostInfoActionDialogModel>{
        title: 'Enhance Profile',
        message: `${message}`,
        action: BillingActions.ADVANCED_OSINT,
        actionBtnLabel: 'Enhance Profile',
        footerText: this.targetCreditsChargesEnabled
          ? targetCreditsMessage
          : '',
      },
      panelClass: 'renew-credit',
    });

    intelDialog.afterClosed().subscribe((action) => {
      if (action?.key === 'success') {
        this.tableActionsUtilService.advancedOsintForMsisdn(msisdns);
      }
    });
  }

  navigateToTargetAndRequestCL(targetId: string): void {
    this.profilerService.targetData.next(null);
    this.router.navigate(['targets', targetId, 'new-call-log-analysis'], {
      queryParams: { requestCallLog: true },
    });
  }

  renewMultipleTargets(targetIds: string[]): Observable<boolean> {
    return new Observable<boolean>((observable) => {
      const subscription: Subscription = this.targetService
        .renewMultipleTargets(targetIds)
        .subscribe(
          () => {
            this.showMessage(
              this.translationService.translate('Target renewed successfully!')
            );
            observable.next(true);
          },
          (error) => {
            this.showMessage(
              this.translationService.translate(
                error ? error : 'Target has not been renewed'
              )
            );
            observable.next(false);
          },
          () => {
            observable.complete();
          }
        );
      this.subscriptions.push(subscription);
    });
  }

  isMsisdnAllwedForCallLogs(msisdn: string) {
    const countryCode = this.phoneNumberUtil
      .parse(msisdn, '')
      .getCountryCode()
      .toString();
    return this.callLogsEnabledCountries
      ? this.callLogsEnabledCountries.includes(countryCode)
      : false;
  }

  private prepareRequestCl(callLog: CallLogsListResponseModel): RequestCl {
    const prepareRequestCl: RequestCl = {};
    prepareRequestCl.msisdns = callLog.msisdns?.map(this.mapRequestValue);
    prepareRequestCl.imsis = callLog.imsis?.map(this.mapRequestValue);
    prepareRequestCl.imeis = callLog.imeis?.map(this.mapRequestValue);
    return prepareRequestCl;
  }

  private mapRequestValue = (value) => ({ value, disabled: false });
}
