import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Animations } from '../../animations/animations';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { IQueryParams } from '../../../modules/search-intel/models/query-params.interface';
import { AnalysisService } from '../../../modules/analysis/shared/services/analysis.service';
import { AnalysisUtilService } from '../../../modules/analysis/shared/services/analysis-util.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  CallLogsListResponseModel,
  CLPaginatedResponse,
} from '../../../modules/analysis/shared/models/targets-list-request.model';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { head } from 'lodash';
import { CaseInvestigationService } from '../../../modules/analysis/shared/services/case-investigations.service';
import { Region } from '../../models/region.model';
import { Country } from '../../models/country.model';
import { DataGatheringService } from 'src/app/modules/visual-investigation/services/data-gathering.service';
import { NavigationHistoryService } from '../../services/navigation-history.service';
import { CdrQueryParamsService } from '../../services/cdr-query-params.service';
import { KEYS_ARRAY } from '../../constants/constant';
import { TargetItem } from '../../models/target-item.model';
import { TargetService } from 'src/app/services/target/target.service';
import { InvestigationFiltersHelpersService } from 'src/app/shared/services/investigation-filters-helpers.service';
import { Moment } from 'moment';
@Component({
  selector: 'app-filter-investigation-dialog',
  templateUrl: './filter-investigation-dialog.component.html',
  styleUrls: ['./filter-investigation-dialog.component.scss'],
  animations: [Animations.slideInOut],
})
export class FilterInvestigationDialogComponent
  extends BaseDialogComponent
  implements OnInit, OnDestroy
{
  private ngUnsubscribe = new Subject<void>();
  public country = new UntypedFormControl();
  public regions = new UntypedFormControl();
  public regionList: Region[] = [];
  public countries: Country[] = [];
  public msisdnArray = [];
  public filters: UntypedFormGroup;
  public idsArray;
  public dateFilterFormControl = new UntypedFormControl({
    startTime: Date,
    endTime: Date,
  });
  public msisdnsString: string;
  constructor(
    public dialogRef: MatDialogRef<FilterInvestigationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    router: Router,
    private analysisService: AnalysisService,
    private analysisUtilService: AnalysisUtilService,
    private caseInvestigationService: CaseInvestigationService,
    private formBuilder: UntypedFormBuilder,
    private dataGatherinService: DataGatheringService,
    private navigationHistoryService: NavigationHistoryService,
    private cdrQueryParamsService: CdrQueryParamsService,
    private targetService: TargetService,
    private investigationFiltersHelpersService: InvestigationFiltersHelpersService
  ) {
    super(dialogRef, router);
    this.initForm();
  }

  ngOnInit() {
    const keysArray = KEYS_ARRAY;

    const data = this.cdrQueryParamsService.getData();

    for (const key of keysArray) {
      if (data && data.queryParams && data.queryParams[key]) {
        this.idsArray = data.queryParams[key];
        break;
      }
    }

    if (!this.idsArray) {
      // if idsArray is undefined or null, make it an empty array
      this.idsArray = [];
    }
    if (data?.queryParams?.targetIds) {
      this.getTargets(data.queryParams.targetIds);
    }

    const callLogRequests = this.idsArray.map((id) =>
      this.fetchCallLogsRequestData(id).pipe(
        tap((value) => {
          if (value?.msisdns?.length) {
            this.msisdnArray.push(value?.msisdns[0]); // push the msisdn into the array
            this.filters.patchValue({ msisdns: this.msisdnArray });
            this.msisdnsString = this.msisdnArray.join(', ');
          }
        })
      )
    );
    this.dataGatherinService.callLogsFilters$
      .pipe(
        map(
          ({
            internationalInteractions,
            directInteractions,
            limitInteractionTypes,
            topAssociates,
          }) => ({
            topAssociates,
            internationalInteractions,
            directInteractions,
            limitInteractionTypes,
          })
        ),
        catchError(() => {
          return of({});
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((filters) => {
        this.filters.patchValue(filters);
      });

    const caseInvestigationRequests = this.idsArray.map((id) =>
      this.caseInvestigationService.getCaseInvestigationById(id)
    );

    forkJoin([...callLogRequests, ...caseInvestigationRequests]).subscribe(
      (responses) => {
        const caseInvestigationResponses = responses.slice(
          callLogRequests.length
        ); // take the second half of responses which are case investigation responses

        caseInvestigationResponses.forEach((response) => {
          if (response?.graphFilter) {
            const {
              directInteractions,
              internationalInteractions,
              limitInteractionTypes,
              topAssociates,
            } = response.graphFilter;

            // patchValue does not need to have all the form controls
            this.filters.patchValue({
              directInteractions,
              internationalInteractions,
              topAssociates,
            });

            this.filters
              .get('limitInteractionTypes')
              .patchValue(limitInteractionTypes);
          }
        });

        this.investigationFiltersHelpersService
          .fetchCountries(this.msisdnArray)
          .subscribe({
            next: (countries: Country[]) => {
              this.countries = countries;
            },
            error: (error) => {
              this.countries = [];
            },
          });

        this.investigationFiltersHelpersService
          .fetchRegions(this.msisdnArray)
          .subscribe({
            next: (regions: Region[]) => {
              this.regionList = regions;
            },
            error: (error) => {
              this.regionList = [];
            },
          });
      }
    );
  }

  private initForm(): void {
    this.filters = this.formBuilder.group({
      directInteractions: [false],
      topAssociates: [false],
      internationalInteractions: [false],
      dateFilterFormControl: {
        startTime: undefined,
        endTime: undefined,
      },
      country: [null],
      region: [this.regionList],
      limitInteractionTypes: this.initTypeFormGroup(),
      msisdns: [null],
    });
    this.initFormValueChanges();
  }

  private initFormValueChanges(): void {
    this.filters
      .get('directInteractions')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.updateInteractionTypes(value);
      });

    this.filters
      .get('topAssociates')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.updateInteractionTypes(value);
      });

    this.filters
      .get('internationalInteractions')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.updateInteractionTypes(value);
      });

    this.filters
      .get('limitInteractionTypes')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ voice, sms }) => {
        if (
          !(
            this.filters.value.directInteractions ||
            this.filters.value.topAssociates ||
            this.filters.value.internationalInteractions
          )
        ) {
          this.updateInteractionTypes(true);
        }
      });
    this.filters
      .get('dateFilterFormControl')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.updateInteractionTypes(true);
      });
  }

  private updateInteractionTypes(value: boolean): void {
    if (!this.filters.get('limitInteractionTypes').dirty) {
      this.filters.get('limitInteractionTypes').patchValue(
        {
          voice: value,
          sms: value,
        },
        { emitEvent: false }
      );
    }
  }

  private initTypeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      voice: [false],
      sms: [false],
      mobileData: [false],
    });
  }

  private fetchCallLogsRequestData(
    requestId: string
  ): Observable<CallLogsListResponseModel> {
    // TODO: we have the same in another place, should be used from one place
    const requestBody = this.analysisUtilService.buildClRequestPayload({
      requestId: requestId,
      pageSize: '1',
      pageNumber: '1',
      createdBy: [],
    });
    return this.analysisService.getCallLogsList(requestBody).pipe(
      map((resp) => {
        const callLogsData: CLPaginatedResponse = head(resp);
        return callLogsData?.paginatedResults[0];
      })
    );
  }

  private getTargets(targetIds: string[]) {
    targetIds.forEach((id) => {
      this.subscriptions.push(
        this.targetService.getTarget(id).subscribe((target: TargetItem) => {
          this.msisdnArray.push(...target.telnos);
          this.filters.patchValue({ msisdns: this.msisdnArray });
        })
      );
    });
  }

  navigateToInvestigation(queryParams: IQueryParams): void {
    const currentRoute = this.navigationHistoryService.getCurrentUrl();
    if (currentRoute.includes('/case-investigation')) {
      this.dialogRef.close('applyFilters');
      return;
    }
    this.dialogRef.close('applyFilters');
  }

  public applyFilters(skipFiltering: boolean) {
    if (!skipFiltering) {
      const dateFiltersForm = this.filters.get('dateFilterFormControl');
      const filters = this.filters.value;
      filters.startTime = dateFiltersForm.value.startTime;
      filters.endTime = dateFiltersForm.value.endTime;
      delete filters.dateFilterFormControl;
      this.dataGatherinService.callLogsFilters$.next(filters);
    }
    this.navigateToInvestigation(this.data);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dateTimeChange(event: Moment[]) {
    const start_date = event[0].toISOString();
    const end_date = event[1].toISOString();
    console.log(start_date, end_date);
    this.filters.get('dateFilterFormControl').patchValue(
      {
        startTime: start_date,
        endTime: end_date,
      },
      { emitEvent: false }
    );
    this.updateInteractionTypes(true);
  }
}
