import { UserRoles } from 'src/app/services/user/user.model';
import {
  BalanceType,
  BillingPolicies,
  LimitType,
} from 'src/app/shared/models/billing-action.model';
import { CreditPools } from 'src/app/shared/models/credit-pools.model';

export enum UserDialogFormFields {
  QUOTA = 'quota',
  USERNAME = 'username',
  AVAILABLE_QUOTA = 'availableQuota',
  EMAIL = 'email',
  ROLE = 'role',
  OTP_ENABLED = 'otpEnabled',
  OTP_CHANNEL = 'otpChannel',
  OTP_PHONE = 'otpPhone',
  LIMIT_TYPE = 'limitType',
  FEATURE = 'feature',
}

export const usernameRegex = new RegExp(/^[a-z0-9]+([_s.]?[a-z0-9])*$/);

export const creditsOneDecimalRegexp = new RegExp(/^-?\d*[.]?\d{0,1}$/);

export const emailRegexp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export interface UserForm {
  policies?: BillingPolicies[];
  balanceType?: BalanceType;
  currentBalance?: number | CreditPools;
  initialBalance?: number | CreditPools;
  limitType?: LimitType;
  defaultPool?: number;
  role: UserRoles;
  feature: string;
  otpPhone: string;
  email?: string;
}
