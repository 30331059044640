import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EventChannel,
  GioTargetMetadata,
} from '@trg-commons/gio-data-models-ts';
import KeyLines, {
  Chart,
  ChartData,
  ChartOptions,
  ExportResult,
  Glyph,
  LabelPosition,
  LayoutName,
  LayoutOptions,
  Link,
  Node,
  NodeProperties,
  TimeBar,
  TimeBarOptions,
} from '@trg-ui/link-analysis';
import { Company, Education, Group, Profile } from 'datalayer/models/social';
import { Place } from 'datalayer/models/social/place';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { JobStatus } from 'src/app/modules/data-layer/models/background-jobs/background-job-status';
import {
  DataSource,
  EntityRelationType,
  EntityType,
} from 'src/app/modules/data-layer/models/platform-models';
import { Person } from 'datalayer/models';
import { RequestOptions } from 'src/app/modules/data-layer/services/base';
import { CompanyService } from 'src/app/modules/data-layer/services/social/company/company.service';
import { EducationService } from 'src/app/modules/data-layer/services/social/education/education.service';
import { GroupService } from 'src/app/modules/data-layer/services/social/group/group.service';
import { PlaceService } from 'src/app/modules/data-layer/services/social/place/place.service';
import { ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { LinkAnalysisDataService } from 'src/app/modules/link-analysis/services/link-analysis-data.service';
import { LinkAnalysisService } from 'src/app/modules/link-analysis/services/link-analysis.service';
import {
  DeepOsintLink,
  DeepOsintProfile,
} from 'src/app/modules/link-analysis/shared/deep-osint-event.model';
import {
  actionToContextMenuOption,
  chartOptions,
  contextMenuActions,
  contextMenuOptionsByType,
  darkThemeOptions,
  DEFAULT_COMBO_GLYPH_COLOR,
  DEFAULT_SELECTION_COLOR,
  DegreesAnimation,
  GlyphSize,
  LabelSize,
  LIGHTER_LINK_COLOR,
  lightThemeOptions,
  linkTypes,
  mapOptions,
  mutualFriendsSliderConfig,
  NodeAnimationProperties,
  NodeFilterElement,
  NodeFilterItem,
  nodeFilterSections,
  nodeSize,
  nodeState,
  nodeSubtypes,
  nodeTypes,
  nodeTypesColors,
  nodeTypeToNodeFilterSection,
  quickFilters,
  relationTypes,
  TargetViewCluster,
  targetViewClusterIds,
  timebarOptions,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { ExportService } from 'src/app/modules/visual-investigation/services/export.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { CaseService } from 'src/app/services/case/case.service';
import { ImageService } from 'src/app/services/image/image.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { Message } from 'src/app/services/websocket/websocket.class';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { Case } from 'src/app/shared/models/case.model';
import { TargetLink } from 'src/app/shared/models/social-media.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ClAssociate } from 'src/app/shared/modules/call-logs-shared/models/cl-associate';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PersonService } from 'datalayer/services/person/person/person.service';
import { getCheckinsFromPersons } from 'src/app/modules/profiler/helpers/get-checkins-from-persons';

@Component({
  selector: 'app-link-analysis',
  templateUrl: './link-analysis.component.html',
  styleUrls: ['./link-analysis.component.scss'],
})
export class LinkAnalysisComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('chartTooltip') chartTooltip: ElementRef;
  @ViewChild('chart', { static: true }) chartElement: any; // KlComponent;
  @ViewChild('fullscreenContainer') fullscreenContainer: ElementRef;
  @ViewChild('selectMultipleInfo') infoWindow: ElementRef;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  @ViewChild('inputLabel') inputLabel: ElementRef;
  imagesPath = 'assets/static/images/';
  legendNodeTypes: { type: string; show: boolean; icon: string }[] = [];
  chart: Chart;
  public chartOptions: ChartOptions = chartOptions;
  data: ChartData;
  graphData: (Node | Link)[] = [];
  target: TargetItem;
  showGraph = false;
  graphDataDictionary: { relationType?: string; data?: [] } = {};
  showFilterSidebar = false;
  filteredNodeIds: string[] = [];
  activeFilterTab = 0;
  visibleNodesCounter: number;
  visibleLinksCounter: number;
  showLoader = true;
  latestGraphState: ChartData;
  latestGraphImage: string;
  showDetailsSidebar = false;
  selectedNode: Node;
  selectedLink: Link;
  showTopBarFilters = false;
  activeAnimatedNode = false;
  filteredSocialProfileNodeIds: string[] = [];
  caseView = false;
  caseId: string;
  caseTargets: TargetItem[] = [];
  // stores selected items with their original styling
  clearAnimation: NodeAnimationProperties[] = [];
  clearDegreesAnimation: DegreesAnimation[] = [];
  expandedView = false;
  // case view selected targets Ids
  filteredTargetNodeIds: string[] = [];
  selectedLayout: LayoutName;
  selectedQuickFilterOption: { label: string; value: MessageSubject | string };
  fullscreenView = false;
  enableShowPhotosButton = false;
  // use this number to calculate the link weights based on the heighest score for deepOsintQueries
  heighestLinkScore = 0;
  deepOsintFlagEnabled = false;
  // for filters that we query the analytics service we save the state
  filtersState: { filter?: quickFilters; state?: any } = {};
  topConnectionsTopImportanceScore = 0;
  public graphStateSaved = false;
  selectedNodeOsintCompleted = false;
  // use this number to calculate the link weights based on the heigtest score for case call logs
  caseCallLogsHeighestLinkScore = 0;
  expectedEvents: { graphOwnerId?: string; correlationId?: string } = {};
  inputLabelProperties = { placeholderText: '', top: '', left: '' };
  contextMenuOptions: { name: string; action: contextMenuActions }[] = [];
  // entities drag&drop panel variables
  playgroundView = false;
  showEntitiesPanel = false;
  newEntityCreationInProgress = false;
  playgroundEntities: Node[] = [];
  timebarOptions: TimeBarOptions = timebarOptions;
  timebarData: any = [];
  timebar: TimeBar;
  casePlaygroundView = false;
  minimizedTimebar = true;
  darkMode = false;
  // in case playground view (create new entities) the msisdn ids are db ids. we need a way
  // to convert when we use data from both mongo+arango
  msisdnToDBSimIdDict: { msisdn?: string; id?: string } = {};
  dBSimIdToMsisdnDict: { id?: string; msisdn?: string } = {};
  pendingJobs: { id: string; active: boolean; pending: boolean }[] = [];
  itemsForNodeFilters: NodeFilterItem[] = [];
  filteredTypes: linkTypes[] = [];
  filteredSliderSections: { type: linkTypes; selectedValue: number }[] = [];
  contextMenuAction: contextMenuActions;
  removedItem: Node | Link;
  linkNodesData: { fromNode: Node; toNode: Node; position: LabelPosition };

  @Input() targetId: string;
  chartHeight = 83;
  private graphImageLoaded = new EventEmitter<void>();
  public graphImageLoaded$ = this.graphImageLoaded.asObservable();
  constructor(
    private linkAnalysisService: LinkAnalysisService,
    private linkAnalysisDataService: LinkAnalysisDataService,
    private profilerService: ProfilerService,
    private analyticsService: AnalyticsService,
    private userBehaviorService: UserBehaviorService,
    private router: Router,
    private caseService: CaseService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private imageService: ImageService,
    private localStorageService: LocalStorageService,
    private socialProfilesService: SocialProfilesService,
    private workplacesService: CompanyService,
    private eduService: EducationService,
    private groupService: GroupService,
    private websocketManager: WebsocketManagerService,
    private appConfigService: AppConfigService,
    private placeService: PlaceService,
    private applicationStateService: ApplicationStateService,
    private callLogsApiService: CallLogsApiService,
    private serverPyWsService: ServerPyWsService,
    private changeDetectorRef: ChangeDetectorRef,
    private exportService: ExportService,
    private personService: PersonService
  ) {
    super();
    if (this.route.parent) {
      this.targetId = this.route.parent.snapshot.paramMap.get('id');
    }
    this.deepOsintFlagEnabled =
      this.appConfigService.getConfigVariable('enableDeepOsint');
    this.initializeGraphDictionaryValues();
    this.caseView = this.router.url.includes('/case');
    this.playgroundView = this.router.url.includes(
      `/${ApplicationMainPageUrls.WEBINT}/link-analysis`
    );
    this.casePlaygroundView =
      this.router.url.includes('/case') &&
      this.router.url.includes('/link-analysis-v2');
  }

  ngOnInit() {
    this.userBehaviorService
      .userBehavior('analytics_page_link_analysis_visits')
      .subscribe();
    this.subscribeToRoute();
    this.subscribeToWS();
    if (this.playgroundView) {
      this.drawGraph();
      return;
    }
    if (this.caseView || this.casePlaygroundView) {
      if (this.route.parent) {
        this.caseId = this.route.parent.snapshot.paramMap.get('id');
      }
      this.getLatestGraphState(this.caseId);
    } else {
      this.loadTargetData();
    }

    this.listenForLogout();
  }

  // using HostListener to save the state in case of page refresh or tab/browser closes
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate($event?): Promise<boolean> | boolean {
    const logoutAction =
      this.applicationStateService.waitToSaveGraphOnLogout.getValue();
    if (!this.graphStateSaved && !this.playgroundView) {
      if ($event) {
        $event.returnValue = true;
      }
      return Swal.fire({
        title: this.translationService.translate('Save changes'),
        text: this.translationService.translate(
          'Would you like to save the latest changes you made in link analysis?'
        ),
        showCancelButton: true,
        confirmButtonText: this.translationService.translate('Save'),
        cancelButtonText: this.translationService.translate('Discard'),
      }).then((result) => {
        if (result.value) {
          this.saveGraphState();
        } else {
          this.graphStateSaved = true;
          if (logoutAction.logout) {
            this.applicationStateService.waitToSaveGraphOnLogout.next({
              logout: false,
              proceed: true,
            });
          }
        }
        return !result.value;
      });
    } else {
      if (logoutAction.logout) {
        this.applicationStateService.waitToSaveGraphOnLogout.next({
          logout: false,
          proceed: true,
        });
      }
      return true;
    }
  }

  private listenForLogout() {
    this.subscriptions.push(
      this.applicationStateService.waitToSaveGraphOnLogout.subscribe((data) => {
        if (data.logout) {
          this.canDeactivate();
        }
      })
    );
  }

  private async saveGraphState() {
    this.showLoader = true;
    this.latestGraphState = this.chart.serialize();
    await this.setLatestGraphImage();
    const filtersState = JSON.stringify(this.getFilteredDataToSerialize());
    await this.linkAnalysisDataService
      .saveGraphState(
        this.latestGraphState,
        filtersState,
        this.latestGraphImage,
        this.target ? this.target.id : this.caseId
      )
      .subscribe(() => {
        this.graphStateSaved = true;
        this.showLoader = false;
        this.changeDetectorRef.markForCheck();
        this.showMessage(
          this.translationService.translate('Changes saved successfully!')
        );
        if (
          this.applicationStateService.waitToSaveGraphOnLogout.getValue().logout
        ) {
          this.applicationStateService.waitToSaveGraphOnLogout.next({
            logout: false,
            proceed: true,
          });
        }
      });
  }

  private getLatestGraphState(id: string) {
    const graphStateSubscription = this.linkAnalysisDataService
      .getGraphState(id)
      .subscribe((data: { chart: any; filters: any }) => {
        if (data && data.chart && data.chart.items.length) {
          this.showLoader = true;
          const {
            filteredNodeIds,
            filteredSliderSections,
            filteredTypes,
            filteredSocialProfileIds,
            graphData,
            caseTargets,
            playgroundEntities,
          } = JSON.parse(data.filters);
          this.filteredNodeIds = filteredNodeIds;
          this.filteredTypes = filteredTypes;
          this.filteredSliderSections = filteredSliderSections;
          this.filteredSocialProfileNodeIds = filteredSocialProfileIds;
          this.graphData = graphData;
          this.caseTargets = caseTargets;
          this.playgroundEntities = playgroundEntities;
          const items: (Node | Link)[] = data.chart.items;
          items.forEach((item) => {
            if (item.type === 'link') {
              if (!item.d.type) {
                return;
              }
              this.graphDataDictionary[item.d.type] = this.graphDataDictionary[
                item.d.type
              ].concat([item]);
            } else {
              this.addNodeToNodeFiltersItems(
                item,
                nodeTypeToNodeFilterSection[item.d.type],
                item.d.isTargetNode
              );
            }
          });
          // handle combos
          if (data.chart.combos) {
            Object.values(data.chart.combos).forEach((item: any) => {
              if (item && item.id) {
                items.push(item as Node);
                this.addNodeToNodeFiltersItems(
                  item,
                  nodeTypeToNodeFilterSection[item.d.type],
                  item.d.isTargetNode
                );
                if (item.combo.nodeIds) {
                  item.combo.nodeIds.forEach((id) => {
                    const index = this.graphData.findIndex(
                      (node) => node.id === id
                    );
                    if (index > -1) {
                      this.graphData[index].parentId = item.id;
                    }
                  });
                }
              }
            });
          }
          this.latestGraphState = {
            type: 'LinkChart',
            items,
          };
          setTimeout(() => {
            this.drawGraph();
          }, 1500);
        } else if (this.target) {
          this.initializeTargetGraph();
        } else if (this.caseId) {
          this.loadCaseData();
        }
      });
    this.subscriptions.push(graphStateSubscription);
  }

  private async getTargetSocialProfiles(target: TargetItem) {
    const filters: RequestOptions = {
      filters: {
        source: [
          DataSource.Twitter,
          DataSource.Facebook,
          DataSource.Instagram,
          DataSource.LinkedIn,
          DataSource.Tiktok,
        ],
        targetId: target.id,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain],
      },
    };
    await this.socialProfilesService
      .getAll(filters)
      .pipe(
        map((result) => Object.values(result)),
        tap((profiles) => {
          this.createTargetSocialProfileNodes(target, profiles);
          this.createSocialProfileNodesFromTarget(target);
        })
      )
      .subscribe();
  }

  private createSocialProfileNodesFromTarget(target: TargetItem): void {
    target.socialProfiles?.forEach((targetLink) => {
      const platform = targetLink.platform as relationTypes;
      const profileId = `${targetLink.userId}@${platform}`;
      const link: Link = this.linkAnalysisService.getLink(
        target.id,
        profileId,
        linkTypes.SOCIAL_PROFILE,
        false,
        LIGHTER_LINK_COLOR
      );
      if (
        !this.linkExists(link, this.graphData) &&
        !this.nodeExists(targetLink)
      ) {
        const nodeData = {
          profileId: profileId,
          platform: platform,
          type: nodeTypes.SOCIAL_PROFILE,
          relation: platform,
        };
        const node = this.linkAnalysisService.createNewNode(
          profileId,
          nodeTypesColors.SOCIAL_PROFILE,
          targetLink.userId || `${target.alias} ${platform} profile`,
          nodeData,
          nodeSize.SMALL,
          [
            this.linkAnalysisService.getGlyph(
              `link-analysis/${platform}.svg`,
              'e'
            ),
          ],
          LabelSize.MEDIUM
        );
        link.d['relation'] = platform;
        this.addNodeToNodeFiltersItems(
          node,
          nodeFilterSections.SOCIAL_PROFILES,
          false
        );
        this.graphData.push(node, link);
      }
    });
  }

  private createTargetSocialProfileNodes(
    target: TargetItem,
    socialProfiles: Profile[]
  ) {
    socialProfiles.forEach((profile) => {
      if (!profile?.profileId || profile?.profileId?.includes('undefined')) {
        return;
      }
      this.handlePlaceFromProfile(target, profile);
      const platform = this.linkAnalysisService.parsePlatform(profile.source);
      const nodeData = this.linkAnalysisService.getSocialProfileNodeData(
        target.id,
        target.id,
        profile
      );
      nodeData['disabled'] = false;
      nodeData['state'] = nodeState.EXPANDED;
      nodeData['isTargetNode'] = true;
      const node = this.linkAnalysisService.createNewNode(
        profile.profileId.toLowerCase(),
        nodeTypesColors.SOCIAL_PROFILE,
        profile.name || `${target.alias} ${platform} profile`,
        nodeData,
        nodeSize.SMALL,
        [
          this.linkAnalysisService.getGlyph(
            `link-analysis/${platform}.svg`,
            'e'
          ),
        ],
        LabelSize.MEDIUM
      );
      // hide for now: parent profiles are now combos: we don't need the glyph indication.
      // if (!this.caseView) {
      //   node.g.push(
      //     this.linkAnalysisService.getGlyph('link-analysis/collapse.svg', 'ne', false, GlyphSize.SMALL, 'white', DEFAULT_LINK_COLOR));
      // }
      const link: Link = this.linkAnalysisService.getLink(
        target.id,
        profile.profileId.toLowerCase(),
        linkTypes.SOCIAL_PROFILE,
        false,
        LIGHTER_LINK_COLOR
      );
      link.d['relation'] = platform;
      if (!this.linkExists(link, this.graphData)) {
        this.addNodeToNodeFiltersItems(
          node,
          nodeFilterSections.SOCIAL_PROFILES,
          false
        );
        this.graphData.push(node, link);
      }
    });
  }

  public klChartEvents(event: any) {
    if (event.name === 'drag-end') {
      this.graphStateSaved = false;
    }
  }

  public async degrees(value: number, userInteraction?: boolean) {
    this.showLoader = true;
    await this.chart.animateProperties(
      this.clearAnimation.concat(this.clearDegreesAnimation),
      { time: 5 }
    );
    this.clearAnimation = [];
    this.clearDegreesAnimation = [];
    if (userInteraction) {
      this.filteredNodeIds = this.caseView ? [this.caseId] : [this.target.id];
      this.clearFilters();
      await this.chart.filter((item) => item.id, { type: 'link' });
    }
    const degrees = this.chart.graph().degrees({ direction: 'any' });
    const nodeIds: string[] = [];
    const sizes = Object.keys(degrees).map((id) => {
      const node = this.chart.getItem(id) as Node;
      if (node && degrees[id] < value && !node.d.isTargetNode) {
        nodeIds.push(id);
      }
      this.clearDegreesAnimation.push({ id: node.id, e: node.e });
      // maybe use this function to calculate node size
      // const calc = this.linkAnalysisService.transformLinkWeight(degrees[id], 614, nodeSize.XSMALL, 7.5, nodeSize.XSMALL);
      return { id: id, e: Math.sqrt(degrees[id]) / 3 };
    });
    this.hideNodes(nodeIds);
    this.chart.animateProperties(sizes, { time: 500 });
    await this.chartLayout();
    this.showLoader = false;
    this.changeDetectorRef.markForCheck();
  }

  public async onQuickFilterChange(event: MatSelectChange) {
    if (event.value === 'None') {
      return;
    }
    const filter = event.value.value;
    this.showLoader = true;
    this.filteredNodeIds = this.caseView ? [this.caseId] : [this.target.id];
    this.clearFilters();
    this.selectedQuickFilterOption = event.value;
    await this.chart.animateProperties(this.clearAnimation, { time: 5 });
    this.clearAnimation = [];
    if (filter.includes('deepOsint')) {
      // check if filter is already loaded in graph state
      const index = filter.indexOf('.request');
      if (this.filtersState[filter.substring(0, index)]) {
        this.chart.load(this.filtersState[filter.substring(0, index)]);
        this.showLoader = false;
        this.changeDetectorRef.markForCheck();
      } else {
        let limits;
        // send query to analytics service
        if (filter === MessageSubject.DeepOsintMutualFriendsRequest) {
          limits = mutualFriendsSliderConfig.start;
        }
        this.expectedEvents[`${this.targetId}_deepOsint`] =
          this.linkAnalysisDataService.createFilterQuery(
            this.target.id,
            filter,
            limits
          );
      }
    } else {
      // filter using graph filtering function
      await this.chart.filter(this.applyQuickFilter.bind(this), {
        type: 'link',
      });
      await this.chartLayout();
      this.showLoader = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  public toggleMapView(event: MatSlideToggleChange) {
    if (event.checked) {
      this.showDetailsSidebar = false;
      this.showFilterSidebar = false;
      this.showEntitiesPanel = false;
      this.showNodes(
        this.linkAnalysisService.getNodeIdsPerType(
          this.chart,
          nodeTypes.LOCATION
        )
      );
      this.chart.map().show();
    } else {
      this.chart.map().hide();
      this.hideNodes(
        this.linkAnalysisService.getNodeIdsPerType(
          this.chart,
          nodeTypes.LOCATION
        )
      );
    }
  }

  private showNewGroupLabel() {
    this.inputLabelProperties.top = `100px`;
    this.inputLabelProperties.left = `40%`;
    this.inputLabelProperties.placeholderText =
      this.translationService.translate('Add group name');
    this.showNewItemLabel();
  }

  public onContextMenuAction(action: contextMenuActions) {
    this.contextMenu.nativeElement.classList.add('hidden');
    this.contextMenuAction = action;
    switch (action) {
      case contextMenuActions.CREATE_CUSTOM_CLUSTER:
        this.showNewGroupLabel();
        break;
      default:
        break;
    }
  }

  private showNewItemLabel() {
    this.inputLabel.nativeElement.value = '';
    this.inputLabel.nativeElement.classList.remove('hidden');
    this.inputLabel.nativeElement.focus();
  }

  public onEnterNewItemLabel(id: string) {
    if (this.newEntityCreationInProgress) {
      // drag&drop enabled
      const node = this.chart.getItem('temporaryId') as Node;
      node.d.newId = id;
      this.selectedNode = node;
    } else {
      this.createNewGroupNode(id);
    }
  }

  public hideNewItemLabel() {
    this.inputLabel.nativeElement.classList.add('hidden');
    if (this.chart.getItem('temporaryId')) {
      this.chart.removeItem('temporaryId');
    }
  }

  public onSelectedEntity(id: string) {
    this.highlightItems([id]);
  }

  public toggleTheme() {
    this.darkMode = !this.darkMode;
    this.chart.options(this.darkMode ? darkThemeOptions : lightThemeOptions);
  }

  public onMutualFriendsSliderUpdate(values: number[]) {
    this.showLoader = true;
    this.expectedEvents[`${this.targetId}_deepOsint`] =
      this.linkAnalysisDataService.createFilterQuery(
        this.target.id,
        MessageSubject.DeepOsintMutualFriendsRequest,
        values
      );
  }

  public async getGraphImage() {
    await this.setGraphImage();
  }

  private async setGraphImage(): Promise<ExportResult> {
    await this.chart.zoom('fit', { animate: true });
    const image = await this.chart.export({
      type: 'png',
      extents: 'chart',
      fitTo: { width: 500, height: 300 },
    });
    this.graphImageLoaded.emit();
    return image;
  }

  public generateGraphImage(): Observable<Blob> {
    return of(this.setGraphImage()).pipe(
      switchMap((image) => image),
      switchMap((image) => fetch(image.url)),
      map((e) => e.blob()),
      switchMap((e) => e)
    );
  }

  private createNewGroupNode(id: string) {
    this.inputLabel.nativeElement.classList.add('hidden');
    const node = this.linkAnalysisService.createNewNode(
      `custom_group_${id}`,
      nodeTypesColors.CUSTOM_CLUSTER,
      id,
      { type: nodeTypes.CUSTOM_CLUSTER, isCustomGroup: true },
      nodeSize.LARGE,
      [],
      LabelSize.MEDIUM
    );
    this.chart.setItem(node);
    this.chart.zoom('fit', { animate: true, time: 400, ids: node.id });
    this.graphData.push(node);
  }

  public finilizeNewEntityCreation(newEntity: Node) {
    this.chart.removeItem('temporaryId');
    this.inputLabel.nativeElement.classList.add('hidden');
    this.addNodeToNodeFiltersItems(
      newEntity,
      nodeTypeToNodeFilterSection[newEntity.d.type],
      false
    );
    this.itemsForNodeFilters = this.itemsForNodeFilters.slice();
    this.getVisibleNodesCounter();
    this.newEntityCreationInProgress = false;
    this.subscriptions.push(
      this.linkAnalysisDataService.createNode(newEntity).subscribe((node) => {
        if (newEntity?.d?.type === nodeTypes.MSISDN) {
          this.selectedNode = newEntity;
          this.contextMenuAction = contextMenuActions.INSTANT_MESSAGING;
        }
        newEntity.id = node.key;
        newEntity.d['entityType'] = node.entityType;
        newEntity.d['key'] = node.key;
        newEntity.d['source'] = node.source;
        this.chart.setItem(newEntity);
        this.graphData.push(newEntity);
        this.graphData = this.graphData.slice();
        this.playgroundEntities.push(newEntity);
        this.showLoader = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  public onEntityDrop(event: {
    container: CdkDropList;
    currentIndex: number;
    distance: { x: number; y: number };
    isPointerOverContainer: boolean;
    item: CdkDrag;
  }) {
    this.newEntityCreationInProgress = true;
    const node = this.linkAnalysisService.createCustomNode(
      event.distance.x,
      event.distance.y,
      event.item
    );
    this.chart.setItem(node);
    const labelPosition = this.chart.labelPosition(node.id);
    // vertical -8: put just below node, horizontal -70: center of node
    this.inputLabelProperties.top = `${labelPosition.y1 - 8}px`;
    this.inputLabelProperties.left = `${labelPosition.x1 - 70}px`;
    this.inputLabelProperties.placeholderText =
      this.translationService.translate(event.item.data.placeholderText);
    this.showNewItemLabel();
  }

  public setNodeProperties(item: NodeProperties) {
    this.chart.setProperties(item);
  }

  public removeItem(id: string) {
    this.chart.removeItem(id);
  }

  public setItem(item: Node | Link) {
    this.chart.setItem(item);
  }

  public addToGraphData(item: Link | Node) {
    this.graphData.push(item);
  }

  public exportPdf() {
    this.exportService.openExportDialog(this.chart);
  }

  private async clickHandler(data: any) {
    const { id } = data;
    this.chart.foreground(() => true, { type: 'all' });
    if (this.selectedNode && this.selectedNode.id === id) {
      return;
    }
    await this.chart.animateProperties(this.clearAnimation, { time: 5 });
    this.clearAnimation = [];
    if (!this.activeAnimatedNode) {
      this.showDetailsSidebar = false;
    }
    const item: Node | Link = this.chart.getItem(id);
    if (item) {
      // Reset the completed osint flag when selecting a new nod
      this.selectedNodeOsintCompleted = false;
      if (this.chart.selection().length > 1) {
        // multiple items selected --> remove previous paths
        this.chart.foreground(
          (node) => node.id === id || this.chart.selection().includes(node.id)
        );
        return;
      }
      if (item.type === 'link') {
        this.selectedNode = null;
        const neighbours: string[] = this.chart.graph().neighbours(id).nodes;
        this.chart.foreground(
          (node) => node.id === id || neighbours.includes(node.id)
        );
        const showLinkDetailsTypes = [linkTypes.FACEBOOK_MUTUAL_FRIENDS];
        if (showLinkDetailsTypes.includes(item.d.type)) {
          this.showFilterSidebar = false;
          this.showDetailsSidebar = true;
          this.selectedLink = item;
        }
      } else {
        this.animatePathToInitialNode(item);
        this.showInfoWindow();
        if (
          this.deepOsintFlagEnabled &&
          this.target?.deepOsintStatus === JobStatus.DONE
        ) {
          const neighbours: string[] = this.chart.graph().neighbours(id).nodes;
          this.chart.foreground(
            (node) => node.id === id || neighbours.includes(node.id)
          );
        }
      }
      // open node details sidebar for some node types
      if (
        item.type === 'node' &&
        item.d.type !== nodeTypes.CASE &&
        item.d.type !== nodeTypes.PLACE &&
        item.d.type &&
        item.d.subtype !== nodeSubtypes.EDUCATION
      ) {
        this.showFilterSidebar = false;
        this.showDetailsSidebar = true;
        this.selectedNode = item;
        this.selectedLink = null;
      }
      // nothing is selected
    } else {
      this.selectedNode = null;
      this.selectedLink = null;
    }
  }

  private animatePathToInitialNode(node: Node) {
    // Return for now in case view because we removed the case root node so there is not an
    // initial node in the graph
    if (this.caseView || this.playgroundView) {
      return;
    }
    const initialNode = this.chart.getItem(
      this.caseView ? this.caseId : this.target.id
    );
    const paths = this.chart
      .graph()
      .shortestPaths(node.id, initialNode.id, { direction: 'any' });
    this.chart.foreground((otherNodes) => paths.items.includes(otherNodes.id));
    paths.items.forEach((id) => {
      const item: Node | Link = this.chart.getItem(id);
      if (item.type === 'node') {
        this.chart.animateProperties(
          {
            id: item.id,
            e: item.e + 0.25,
            b: DEFAULT_SELECTION_COLOR,
            bg: false,
          },
          { time: 30 }
        );
        this.clearAnimation.push({
          id: item.id,
          e: item.e,
          b: item.b,
          bg: false,
        });
      } else if (item.type === 'link') {
        this.chart.animateProperties(
          { id: item.id, c: DEFAULT_SELECTION_COLOR, w: 3.5, bg: false },
          { time: 30 }
        );
        this.clearAnimation.push({
          id: item.id,
          c: item.c,
          w: item.w,
          bg: false,
        });
      }
    });
  }

  async expandChartData(data: (Node | Link)[]) {
    await this.chart.expand(data, {
      layout: { fit: true },
      arrange: { name: 'concentric' },
    });
    this.graphStateSaved = false;
    this.itemsForNodeFilters = this.itemsForNodeFilters.slice();
  }

  private getLinkAnalysisData(target: TargetItem, reload = false) {
    const callsObj = {};
    const socialConnectionsFilter: RequestOptions = {
      filters: {
        source: [
          DataSource.Twitter,
          DataSource.Facebook,
          DataSource.Instagram,
          DataSource.LinkedIn,
          DataSource.Tiktok,
        ],
        targetId: target.id,
        type: EntityType.Profile,
        relationType: [
          EntityRelationType.Friend,
          EntityRelationType.Follower,
          EntityRelationType.Following,
          EntityRelationType.Family,
          EntityRelationType.Author,
          EntityRelationType.WorkedAt,
        ],
        limit: 5000,
        includeAssociatedTargets: true,
      },
    };
    const workplacesFilter = {
      filters: {
        source: [DataSource.LinkedIn, DataSource.Facebook],
        targetId: target.id,
        type: EntityType.Company,
      },
    };
    const eduFilter = {
      filters: {
        source: [DataSource.LinkedIn, DataSource.Facebook],
        targetId: target.id,
        type: EntityType.Education,
      },
    };
    if (!this.caseView) {
      const groupFilters: RequestOptions = {
        filters: {
          source: [DataSource.Facebook],
          targetId: target.id,
          type: EntityType.Group,
          limit: 100,
        },
      };
      callsObj['groups'] = this.groupService
        .getAll(groupFilters)
        .pipe(map((result) => Object.values(result)));
    }

    callsObj['workplaces'] = this.workplacesService
      .getAll(workplacesFilter)
      .pipe(map((result) => Object.values(result)));
    callsObj['education'] = this.eduService
      .getAll(eduFilter)
      .pipe(map((result) => Object.values(result)));
    callsObj['checkins'] = this.loadCheckins();
    callsObj['socialConnection'] = this.socialProfilesService
      .getAll(socialConnectionsFilter)
      .pipe(map((result) => Object.values(result)));
    target.telnos.forEach((telno) => {
      this.createTargetTelnoNode(target.id, telno.trim());
      callsObj[`callLog_${telno.trim()}`] =
        this.linkAnalysisDataService.getCallLogData(telno.trim());
      // callsObj[`phoneLinks_${telno.trim()}`] = this.analyticsService.deviceSwap(telno.trim().replace('+', ''));
    });

    if (this.target) {
      this.subscriptions.push(
        this.callLogsApiService
          .getTargetCallLogTopAssociatesStatistics(this.target.telnos)
          .subscribe((result) => {
            this.createCallLogsTopAssociateItems(
              result.body.associates,
              result.body.gioTargetMetadata
            );
          })
      );
    }

    forkJoin(callsObj).subscribe((results) => {
      Object.entries(results).forEach(([key, value]: any[]) => {
        if (key === 'socialConnection') {
          value.forEach((profile: Profile) => {
            this.createSocialConnection(target.id, profile);
          });
        } else if (key === 'workplaces' || key === 'education') {
          value.forEach((workplace: Company) => {
            this.createOrganizationNode(target.id, workplace);
          });
        } else if (key === 'groups') {
          // remove Facebook groups cluster if there are no facebook groups
          if (value.length === 0) {
            const index = this.graphData.findIndex(
              (item) => item.id === targetViewClusterIds.GROUPS
            );
            this.graphData.splice(index, 1);
          }
          value.forEach((group: Group) => {
            this.createFacebookGroupItems(target.id, group);
          });
        } else if (key === 'checkins') {
          value.forEach((place: Place) => {
            this.createPlaceNode(
              place.placeId || place.name.toLowerCase().trim(),
              `Check-in: ${place.name}`,
              place.sourceEntity.parentId || target.id,
              'Facebook check-in',
              true,
              place.sourceEntity.parentId || target.id
            );
          });
        } else {
          const index = key.indexOf('_');
          const telno = key.substr(index + 1, key.length);
          if (key.includes('callLog')) {
            this.formatCallLogData(target.id, telno, value);
          } else if (key.includes('phoneLinks')) {
            this.getPhoneLinks(target.id, telno, value);
          }
        }
      });
      if (reload) {
        this.reloadChart();
        return;
      }
      // draw if target view or if this is the last target of case view
      if (
        !this.caseView ||
        this.caseTargets[this.caseTargets.length - 1].id === target.id
      ) {
        setTimeout(() => {
          this.drawGraph();
        }, 10000);
      }
    });
  }

  async createTargetNode(target: TargetItem) {
    const targetPhoto =
      target.photos[0] && target.photos[0].length
        ? (this.imageService.getPhotoUrl(target.photos[0], true) as string)
        : '';
    const node = this.linkAnalysisService.createNewNode(
      target.id,
      nodeTypesColors.PERSON,
      target.alias || '',
      {
        type: nodeTypes.PERSON,
        label: target.alias || '',
        disabled: !this.caseView,
        isTargetNode: true,
        image: targetPhoto,
        targetId: target.id,
      },
      nodeSize.LARGE,
      [this.linkAnalysisService.getNodeLabelGlyph('Target')],
      LabelSize.LARGE,
      targetPhoto
    );
    this.addNodeToNodeFiltersItems(node, nodeFilterSections.PERSON, true);
    this.graphData.push(node);
    if (!this.caseView) {
      // we always want to keep the target node when filtering
      this.filteredNodeIds.push(node.id);
    }
  }

  // data for call logs come in keylines format from analytics
  // this function creates combos, handles case view data and adds styling to nodes/links
  private formatCallLogData(
    targetId: string,
    telno: string,
    data: { targetTelno: string; type: string; items: (Node | Link)[] }
  ) {
    if (!data?.items?.length) {
      return;
    }
    data.items.forEach((entry: Node | Link) => {
      // Node
      if (entry.type === 'node') {
        if (entry.id === telno) {
          return;
        }
        if (this.caseView && entry.id !== telno) {
          const parentTelno = this.casePlaygroundView
            ? this.msisdnToDBSimIdDict[telno]
            : telno;
          this.handleCallLogCaseView(entry, parentTelno, targetId);
        } else {
          // for top relations nodes (from call log statistics events)
          const existingNodeIndex = this.graphData.findIndex(
            (item) => item.id === entry.id
          );
          if (
            existingNodeIndex > -1 &&
            this.graphData[existingNodeIndex].d.topRelation
          ) {
            const index = this.graphData.findIndex(
              (item) =>
                item.id ===
                `${entry.id}_${this.target.telnos[0] || this.target.id}_${
                  linkTypes.UNKNOWN
                }`
            );
            if (index > -1) {
              this.graphData.splice(index, 1);
            }
            return;
          }
          entry.d.parent = telno;
          if (entry.id !== telno) {
            entry.parentId = telno;
          }
          this.formatNewCallLogNodeEntry(entry);
        }
        // Link
      } else if (entry.type === 'link') {
        if (entry.d.type === 'data') {
          return;
        }
        entry.d.id1 = entry.id1;
        entry.d.id2 = entry.id2;
        if (this.caseView) {
          // the weight of the link comes from the BE after analysis. In case view the analysis is not valid
          // because it happens per msisdn, not per case. We keep the heighest score in a variable to calculate
          // the link weight just before drawing
          this.caseCallLogsHeighestLinkScore =
            entry.w > this.caseCallLogsHeighestLinkScore
              ? entry.w
              : this.caseCallLogsHeighestLinkScore;
          if (this.casePlaygroundView) {
            // necessary hack to change the one end of the link from msisdn to dbid as its coming from arango  for
            // data fusion implementation
            if (entry.id1 === data.targetTelno) {
              entry.id1 = this.msisdnToDBSimIdDict[data.targetTelno];
            } else {
              entry.id2 = this.msisdnToDBSimIdDict[data.targetTelno];
            }
          }
        }
        entry.c = LIGHTER_LINK_COLOR;
        if (entry.d.type === 'unknown') {
          entry.d.type = linkTypes.UNKNOWN;
        } else {
          const rawtype = entry.d.type;
          entry.d.type =
            entry.id1 === telno
              ? rawtype === 'call'
                ? linkTypes.OUTGOING_CALL
                : linkTypes.OUTGOING_SMS
              : rawtype === 'call'
              ? linkTypes.INCOMING_CALL
              : linkTypes.INCOMING_SMS;
        }
        entry.id = `${entry.id1}_${entry.id2}_${entry.d.type}`;
        if (isNaN(entry.w)) {
          entry.w = 1;
        }
        this.graphDataDictionary[entry.d.type].push(entry);
        this.graphData.push(entry);
      }
    });
  }

  private formatNewCallLogNodeEntry(entry: Node): void {
    entry.e = nodeSize.XSMALL;
    entry.d.label = entry.t;
    entry.d.type = nodeTypes.MSISDN;
    this.addNodeToNodeFiltersItems(entry, nodeFilterSections.MSISDN, false);
    // if call log connection is an existing target: create person node
    if (entry.d.targetId && entry.d.alias) {
      entry.parentId = null;
      this.createExistingTargetItems(
        entry.d.targetId,
        entry.d.alias,
        entry.id,
        entry.d.names,
        entry.u,
        linkTypes.SIM
      );
    }
    entry.u = `assets/static/images/link-analysis/${nodeTypes.MSISDN.toLowerCase()}.svg`;
    this.graphData.push(entry);
  }

  private handleCallLogCaseView(
    entry: Node,
    telno: string,
    targetId: string
  ): void {
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === entry.id
    );
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.isTargetNode
    ) {
      // node already exists and is a target msisdn
      return;
    }
    // for case top relations nodes (from call log statistics events)
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.topRelation
    ) {
      if (entry.d.targetId && entry.d.alias) {
        this.createExistingTargetItems(
          entry.d.targetId,
          entry.d.alias,
          entry.id,
          entry.d.names,
          entry.u,
          linkTypes.SIM
        );
      }
      return;
    }
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.caseData
    ) {
      let msisdns: string[];
      let targetsIds: string[];
      const existingCaseData = this.graphData[existingNodeIndex].d.caseData;
      // keep record in node data of all targets/msisdns is connected to
      if (!existingCaseData.msisdns.includes(telno)) {
        msisdns = existingCaseData.msisdns.concat([telno]);
      }
      if (!existingCaseData.targetsIds.includes(targetId)) {
        targetsIds = existingCaseData.targetsIds.concat([targetId]);
      }
      this.graphData[existingNodeIndex].d.caseData = { targetsIds, msisdns };
      this.graphData[existingNodeIndex].parentId = null;
    } else {
      entry.d.caseData = {
        targetsIds: [targetId],
        msisdns: [telno],
      };
      entry.parentId = telno;
      this.formatNewCallLogNodeEntry(entry);
    }
  }

  // check if sourceEntity parentId is an existing node else connect node to target directly
  private getNodeParentId(
    targetId: string,
    sourceEntityParentId: string
  ): string {
    const index = this.graphData.findIndex(
      (item) => item.id === sourceEntityParentId
    );
    return index > -1 ? sourceEntityParentId : targetId;
  }

  // creates nodes for people from social media channels like facebook, twitter, instagram and family members
  private createSocialConnection(targetId: string, profile: Profile) {
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === profile.profileId.toLowerCase()
    );
    // if the node already exists but its a target profile node return
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.isTargetNode
    ) {
      return;
    }
    let node: Node;
    let link: Link;
    const connectionType: linkTypes =
      this.linkAnalysisService.getConnectionType(
        profile.source,
        profile.relationType
      );
    const parentId = this.getNodeParentId(
      targetId,
      profile.sourceEntity.parentId.toLowerCase()
    );
    if (this.caseView) {
      node = this.getSocialConnectionNodeCaseView(
        existingNodeIndex,
        targetId,
        profile,
        connectionType
      );
    } else {
      node = this.linkAnalysisService.getSocialConnectionNode(profile);
      link = this.linkAnalysisService.getSocialConnectionLink(
        connectionType,
        profile.name,
        parentId,
        profile.profileId.toLowerCase(),
        LIGHTER_LINK_COLOR
      );
      if (this.linkExists(link, this.graphData)) {
        return;
      }
      node.parentId = parentId;
    }
    if (
      profile.profileToTargetInfo?.alias &&
      profile.profileToTargetInfo?.targetId
    ) {
      node.d['belongsToTarget'] = profile.profileToTargetInfo.targetId;
      // TODO: complete this implementation target was already created
      // if (this.graphData.some(item => item.id === profile.profileToTargetInfo.targetId)) {return;}
      this.createExistingTargetItems(
        profile.profileToTargetInfo?.targetId,
        profile.profileToTargetInfo?.alias,
        profile.profileId.toLowerCase(),
        [],
        null,
        linkTypes.SOCIAL_PROFILE
      );
      node.parentId = null;
      if (!this.caseView) {
        this.getExistingTargetConnections(profile);
      }
    }
    this.addNodeToNodeFiltersItems(
      node,
      nodeFilterSections.SOCIAL_PROFILES,
      false
    );
    if (link) {
      this.graphData.push(node, link);
    } else {
      this.graphData.push(node);
    }
    if (Array.isArray(this.graphDataDictionary[connectionType])) {
      this.graphDataDictionary[connectionType] = this.graphDataDictionary[
        connectionType
      ].concat(link ? [link] : []);
    }
  }

  private getSocialConnectionNodeCaseView(
    index: number,
    targetId: string,
    profile: Profile,
    connectionType: linkTypes
  ): Node {
    let node: Node;
    if (index > -1) {
      node = this.graphData[index] as Node;
      const existingCaseData = node.d.caseData;
      if (!existingCaseData) {
        return;
      }
      if (
        !existingCaseData.parentProfileIds.includes(
          profile.sourceEntity.parentId.toLowerCase()
        )
      ) {
        // keep record of all targets/profiles connected to this social profile
        node.d.caseData = {
          targetsIds: existingCaseData.targetsIds.concat([targetId]),
          parentProfileIds: existingCaseData.parentProfileIds.concat([
            profile.sourceEntity.parentId.toLowerCase(),
          ]),
        };
        this.graphData.splice(index, 1);
      }
      if (node.d.caseData && node.d.caseData.parentProfileIds.length > 1) {
        node.d.caseData.parentProfileIds.forEach((id) => {
          const link: Link = this.linkAnalysisService.getSocialConnectionLink(
            connectionType,
            profile.name,
            id,
            profile.profileId.toLowerCase(),
            LIGHTER_LINK_COLOR
          );
          if (!this.linkExists(link, this.graphData)) {
            this.graphData.push(link);
          }
        });
      }
    } else {
      node = this.linkAnalysisService.getSocialConnectionNode(profile);
      node.d['caseData'] = {
        targetsIds: [targetId],
        parentProfileIds: [profile.sourceEntity.parentId.toLowerCase()],
      };
      // for case view we group the social connections in combos. Each target profile is a separate combo
      node.parentId = profile.sourceEntity.parentId.toLowerCase() || targetId;
    }
    if (
      profile.profileToTargetInfo?.alias &&
      profile.profileToTargetInfo?.targetId
    ) {
      const link: Link = this.linkAnalysisService.getSocialConnectionLink(
        connectionType,
        profile.name,
        profile.sourceEntity.parentId,
        profile.profileId.toLowerCase(),
        LIGHTER_LINK_COLOR
      );
      if (!this.linkExists(link, this.graphData)) {
        this.graphData.push(link);
      }
    }
    return node;
  }

  private getExistingTargetConnections(profile: Profile) {
    const socialConnectionsFilter: RequestOptions = {
      filters: {
        source: [profile.source],
        targetId: profile.profileToTargetInfo?.targetId,
        type: EntityType.Profile,
        relationType: [
          EntityRelationType.Friend,
          EntityRelationType.Follower,
          EntityRelationType.Following,
          EntityRelationType.Family,
        ],
        limit: 5000,
      },
    };
    this.subscriptions.push(
      this.socialProfilesService
        .getAll(socialConnectionsFilter)
        .pipe(map((result) => Object.values(result)))
        .subscribe((profiles: Profile[]) => {
          if (profiles?.length) {
            profiles.forEach((connection) => {
              if (connection.profileId === profile.sourceEntity.parentId) {
                return;
              }
              const existingNodeIndex = this.graphData.findIndex(
                (item) => item.id === connection.profileId.toLowerCase()
              );
              if (existingNodeIndex > -1) {
                this.graphData[existingNodeIndex].parentId = null;
              } else {
                const node =
                  this.linkAnalysisService.getSocialConnectionNode(connection);
                node.parentId = profile.profileId.toLowerCase();
                this.graphData.push(node);
              }
              const linkType: linkTypes =
                this.linkAnalysisService.getConnectionType(
                  connection.source,
                  connection.relationType
                );
              const link: Link =
                this.linkAnalysisService.getSocialConnectionLink(
                  linkType,
                  connection.name,
                  profile.profileId.toLowerCase(),
                  connection.profileId.toLowerCase(),
                  LIGHTER_LINK_COLOR
                );
              if (!this.linkExists(link, this.graphData)) {
                this.graphData.push(link);
              }
            });
          }
        })
    );
  }

  async createTargetTelnoNode(
    targetId: string,
    telno: string,
    keepAlwaysWhenFiltering?: boolean
  ) {
    const node = this.linkAnalysisService.createNewNode(
      telno.trim(),
      nodeTypesColors.MSISDN,
      telno.trim(),
      {
        type: nodeTypes.MSISDN,
        parent: targetId,
        targetId: targetId,
        label: telno.trim(),
        disabled: keepAlwaysWhenFiltering || false,
        isTargetNode: true,
      },
      nodeSize.SMALL,
      [],
      LabelSize.MEDIUM
    );
    let link: Link;
    if (this.casePlaygroundView) {
      this.subscriptions.push(
        await this.linkAnalysisDataService
          .createNode(node)
          .subscribe((data) => {
            node.id = `${data.entityType}/${data.key}`;
            node.d['key'] = data.key;
            node.d['entityType'] = data.entityType;
            node.d['source'] = data.source;
            link = this.linkAnalysisService.getLink(
              targetId,
              node.id,
              linkTypes.SIM,
              false,
              LIGHTER_LINK_COLOR
            );
            this.graphData.push(node, link);
            this.msisdnToDBSimIdDict[telno] = node.id;
            this.dBSimIdToMsisdnDict[node.id] = telno;
          })
      );
    } else {
      link = this.linkAnalysisService.getLink(
        targetId,
        telno,
        linkTypes.SIM,
        false,
        LIGHTER_LINK_COLOR
      );
      if (this.linkExists(link, this.graphData)) {
        return;
      }
      if (keepAlwaysWhenFiltering) {
        // we always want to keep the target msisdn when filtering
        this.filteredNodeIds.push(node.id);
      }
      this.graphData.push(node, link);
    }
    this.addNodeToNodeFiltersItems(node, nodeFilterSections.MSISDN, false);
  }

  // show common nodes between selected targets out of combos in case view TODO: refactor!!
  private showOnlyCommonNodes(initialLoad?: boolean): (Node | Link)[] {
    if (this.chart) {
      this.chart.clear();
    }
    const callLogLinkTypes = [
      linkTypes.INCOMING_CALL,
      linkTypes.OUTGOING_CALL,
      linkTypes.INCOMING_SMS,
      linkTypes.OUTGOING_SMS,
    ];
    // clone original data set
    const formattedData: (Node | Link)[] = [...this.graphData];
    formattedData.forEach((item: Node | Link) => {
      if (item.type === 'node' && item.d.caseData?.targetsIds?.length > 1) {
        if (
          (initialLoad &&
            item.d.caseData?.targetsIds?.length === this.caseTargets.length) ||
          // ONLY FOR CALL LOGS: if a node is common for at least two targets: remove from combo.
          // :for the rest of the types a node needs to be common to all the targets to be removed from a target combo
          (item.d.type === nodeTypes.MSISDN &&
            item.d.caseData?.targetsIds?.length > 1)
        ) {
          item.parentId = null;
        } else if (
          this.filteredTargetNodeIds.length &&
          item.d.caseData?.targetsIds?.length >=
            this.filteredTargetNodeIds.length
        ) {
          let counter = 0;
          item.d.caseData.targetsIds.forEach((id) => {
            if (this.filteredTargetNodeIds.includes(id)) {
              counter++;
            }
          });
          if (counter === this.filteredTargetNodeIds.length) {
            item.parentId = null;
          }
        } else {
          // if profile belongs to an existing target remove from combo
          if (item.d.belongsToTarget) {
            item.parentId = null;
            return;
          }
          // parentProfile: for social profiles, msisdns: for call logs
          item.parentId = item.d.caseData?.parentProfileIds
            ? item.d.caseData?.parentProfileIds[0]
            : item.d.caseData?.msisdns[0];
        }
      }
      // for case call logs only: format link weight before drawing
      if (item.type === 'link' && callLogLinkTypes.includes(item.d.type)) {
        item.w = this.linkAnalysisService.transformLinkWeight(
          item.d.count,
          this.caseCallLogsHeighestLinkScore,
          1,
          8,
          1
        );
      }
    });
    return formattedData;
  }

  drawGraph() {
    this.showGraph = true;
    this.changeDetectorRef.markForCheck();
  }

  async klChartReady([chart, timebar]: [Chart, TimeBar]) {
    this.data = {
      type: 'LinkChart',
      items: this.caseView ? this.showOnlyCommonNodes(true) : this.graphData,
    };
    this.chart = chart;
    this.timebar = timebar;
    this.chart.map().options(mapOptions);
    this.initialiseChartInteractions();
    if (this.timebar) {
      this.initialiseTimebarInteractions();
    }
    this.showTopBarFilters = true;
    this.showFilterSidebar = !!this.latestGraphState;
    if (
      this.deepOsintFlagEnabled &&
      !(this.caseView || this.playgroundView) &&
      this.target.deepOsintStatus === JobStatus.DONE
    ) {
      // in the Deep Osint view we load the filter with the 'Top Connections' in initialization
      this.expectedEvents[`${this.targetId}_deepOsint`] =
        this.linkAnalysisDataService.createFilterQuery(
          this.target.id,
          MessageSubject.DeepOsintTopConnections
        );
      this.selectedQuickFilterOption = {
        label: quickFilters.TOP_FACEBOOK_CONNECTIONS,
        value: MessageSubject.DeepOsintTopConnections,
      };
    } else {
      await this.chart.load(this.latestGraphState || this.data);
      await this.chartInitializationActions();
    }
    this.chart.zoom('fit', { animate: true });
  }

  private async chartInitializationActions() {
    this.itemsForNodeFilters = this.itemsForNodeFilters.slice();
    this.setupLegendNodeTypes();
    await this.chartLayout();
    this.getVisibleLinksCounter();
    this.getVisibleNodesCounter();
    this.showLoader = false;
    this.changeDetectorRef.markForCheck();

    // move facebook groups combo because it overlaps with the facebook friend nodes
    if (!(this.caseView || this.playgroundView)) {
      const targetPersonNode = this.chart.getItem(this.target.id) as Node;
      this.setNodeProperties({
        id: targetViewClusterIds.GROUPS,
        x: targetPersonNode.x,
        y: targetPersonNode.y + 200,
      });
    }

    // add node data after advanced osint is completed and we are redirected in link analysis tab
    // from advcanced osint popup
    const index = this.pendingJobs.findIndex(
      (item) => item.active && item.pending
    );
    if (index > -1) {
      this.handleAdvancedOsintFromNodeCompleted(
        this.pendingJobs[index].id,
        index
      );
    }
    await this.getGraphImage();
  }

  initialiseChartInteractions() {
    this.chart.on('click', this.clickHandler.bind(this));
    this.chart.on('hover', this.showChartTooltip.bind(this));
    this.chart.on('hover', this.addPlusIcon.bind(this));
    this.chart.on('double-click', this.toggleNode.bind(this));
    this.chart.on('key-down', this.onKeyDown.bind(this));
    this.chart.on('pointer-down', this.onMouseDown.bind(this));
    this.chart.on('selection-change', this.onSelectionChanged.bind(this));
    this.chart.on('context-menu', this.showContextMenu.bind(this));
    this.chart.on('drag-over', this.onDragOver.bind(this));
    this.chart.on('drag-end', this.onDragEnd.bind(this));
    this.chart.on('drag-start', this.onDragStart.bind(this));
  }

  private initialiseTimebarInteractions() {
    this.timebar.on('hover', (data) => {
      const { type, index, rangeStart, rangeEnd } = data;
      this.pingSelection(type, index, rangeStart, rangeEnd);
    });
    this.timebar.on('change', async () => {
      await this.chart.filter(this.timebar.inRange, {
        animate: false,
        type: 'link',
      });
      this.chart.layout('tweak', { animate: true });
    });
  }

  private onKeyDown(data: any) {
    const { keyCode } = data;
    switch (keyCode) {
      case 46: // delete
        this.onDeleteKey();
        break;
      default:
        break;
    }
  }

  private onDeleteKey() {
    // TODO: maybe add a check to prevent parent node to be hidden (case/target)
    this.chart.selection().forEach((id) => {
      const removedItem = this.chart.getItem(id);
      if (
        removedItem.type === 'node' &&
        this.filteredNodeIds.includes(removedItem.id)
      ) {
        const filteredIndex = this.filteredNodeIds.indexOf(removedItem.id);
        this.filteredNodeIds.splice(filteredIndex, 1);
      }
      if (this.casePlaygroundView) {
        this.removedItem = removedItem;
      }
    });
    this.hideNodes(this.chart.selection());
    // Return true to override the default behaviour (deletes nodes permanently from chart)
    return true;
  }

  private addPlusIcon(data: { id: string }) {
    if (this.playgroundView || this.casePlaygroundView) {
      this.removePlusIcons();
      const item = this.chart.getItem(data.id);
      if (item?.type === 'node') {
        const glyphs = item.g;
        glyphs.push({ p: 'ne', t: '+', c: '#292826', b: 'black' });
        this.setNodeProperties({ id: data.id, g: glyphs });
      }
    }
  }

  private removePlusIcons() {
    this.chart.each({ type: 'node' }, (node) => {
      if (node.g) {
        const glyphs = node.g;
        node.g.forEach((glyph, index) => {
          if (glyph.t === '+') {
            glyphs.splice(index, 1);
            this.setNodeProperties({ id: node.id, g: glyphs });
            return;
          }
        });
      }
    });
  }

  private showChartTooltip(data: {
    id: string;
    x: number;
    y: number;
    subItem: { subId: string | number; type: string; index: number };
  }) {
    this.chartTooltip.nativeElement.innerText = '';
    this.chartTooltip.nativeElement.classList.add('hidden');
    const item = this.chart.getItem(data.id);
    if (item?.type === 'link') {
      this.chartTooltip.nativeElement.innerText =
        this.linkAnalysisService.getChartTooltipText(item as Link);
    } else if (item?.type === 'node') {
      this.chartTooltip.nativeElement.innerText =
        this.linkAnalysisService.getNodeTooltipText(
          this.chart,
          item as Node,
          data.subItem
        );
    }
    if (this.chartTooltip.nativeElement.innerText !== '') {
      this.setTooltipPosition(data.x, data.y, this.chartTooltip);
      // show the tooltip
      this.chartTooltip.nativeElement.classList.remove('hidden');
    }
  }

  private setTooltipPosition(x: number, y: number, element: ElementRef): void {
    if (this.chart.viewOptions().width > x + 250) {
      element.nativeElement.style.top = `${y - 28}px`;
      element.nativeElement.style.left = `${x + 20}px`;
    } else {
      element.nativeElement.style.top = `${y - 28}px`;
      element.nativeElement.style.left = `${x - 230}px`;
    }
  }

  public async chartLayout(event?: MatSelectChange) {
    if (!this.graphData.length) {
      return;
    }
    let caseTargetIds: string[] = [];
    if (this.caseView) {
      // target nodes to be on top of the sequential layout (tree-like traditional hierarchy structure) in case view
      caseTargetIds = this.caseTargets.map((target) => target.id);
    }
    if (event) {
      this.selectedLayout = event.value as LayoutName;
      let options: LayoutOptions = {};
      if (!this.playgroundView) {
        options = {
          top: this.caseView ? caseTargetIds : this.target.id,
          mode: 'adaptive',
          stacking: { arrange: 'grid' },
        };
      }
      await this.chart.layout(
        this.selectedLayout,
        this.selectedLayout === 'sequential' ? options : {}
      );
    } else if (this.caseView) {
      await this.chart.layout('sequential', { top: caseTargetIds });
    } else {
      await this.chart.layout('structural', {
        consistent: true,
        straighten: false,
        tightness: 2,
      });
    }
    this.chart.options({ zoom: { adaptiveStyling: true } });
    const comboIds = this.chart.combo().find(this.filteredNodeIds);
    this.chart.combo().arrange(comboIds);
  }

  public searchGraph(event: KeyboardEvent, nodeId?: string) {
    const matchingNodes = [];
    if (event) {
      const value = (event.target as HTMLInputElement).value;
      if (!value.length) {
        return;
      }
      this.chart.each({ type: 'node' }, (item) => {
        if (
          item.t &&
          (item.t as string).toLowerCase().includes(value.toLowerCase())
        ) {
          matchingNodes.push(item.id);
        }
      });
    } else if (nodeId) {
      matchingNodes.push(nodeId);
    }
    this.highlightItems(matchingNodes);
  }

  private highlightItems(ids: string[]) {
    this.chart.selection(ids);
    if (ids.length) {
      this.chart.zoom('selection', { animate: true, time: 300, ids });
    }
  }

  private getPhoneLinks(
    targetId: string,
    telno: string,
    data: { brand: string; imei: string; model: string }[]
  ) {
    if (!data.length) {
      return;
    }
    data.forEach((device) => {
      const node = this.linkAnalysisService.createNewNode(
        device.imei.toString(),
        nodeTypesColors.PHONE,
        `${device.imei.toString() || ''} ${device.model || ''} ${
          device.brand || ''
        }`,
        {
          model: device.model || '',
          brand: device.brand || '',
          type: nodeTypes.PHONE,
          label: `${device.imei.toString() || ''} ${device.model || ''} ${
            device.brand || ''
          }`,
          parent: telno,
          targetId: targetId,
        },
        nodeSize.SMALL,
        [],
        LabelSize.MEDIUM
      );
      const link: Link = this.linkAnalysisService.getLink(
        this.casePlaygroundView ? this.msisdnToDBSimIdDict[telno] : telno,
        device.imei.toString(),
        linkTypes.DEVICE,
        false,
        LIGHTER_LINK_COLOR
      );
      link.d['model'] = device.model || '';
      link.d['brand'] = device.brand || '';
      if (!this.linkExists(link, this.graphData)) {
        this.addNodeToNodeFiltersItems(node, nodeFilterSections.PHONE, false);
        this.graphData.push(node, link);
      }

      this.analyticsService.simSwap(device.imei).subscribe(
        (msisdns: string[]) => {
          const newTelnos = msisdns.filter((msisdn) => msisdn !== telno);
          newTelnos.forEach((newTelno) => {
            this.createTargetTelnoNode(targetId, `+${newTelno}`);
            const simLink: Link = this.linkAnalysisService.getLink(
              device.imei.toString(),
              `+${newTelno}`,
              linkTypes.DEVICE,
              false,
              LIGHTER_LINK_COLOR
            );
            if (!this.linkExists(link, this.graphData)) {
              this.graphData.push(simLink);
            }
          });
        },
        (error: any) => {
          console.log('No phone links for this imei', device.imei);
        }
      );
    });
  }

  public async togglePhotos(): Promise<void> {
    this.chart.selection().forEach((id) => {
      const item = this.chart.getItem(id);
      if (item && item.type === 'node' && item.d.image) {
        this.setNodeProperties({ id: item.id, u: item.u ? '' : item.d.image });
      }
    });
  }

  public async toggleLabels(event: MatCheckboxChange): Promise<void> {
    await this.chart.each({ type: 'node' }, (item) => {
      if (item.d.label) {
        // update the chart
        this.setNodeProperties({
          id: item.id,
          t: event.checked ? item.d.label : '',
        });
      }
    });
  }

  // expands or collapses node relations
  toggleNode(eventData: any) {
    const { id } = eventData;
    if (this.caseView || (this.target && this.target.id === id)) {
      return;
    }
    const nodeIds: string[] = [];
    let glyphs: Glyph[];
    const item = this.chart.getItem(id);
    if (
      item &&
      item.type === 'node' &&
      this.chart.combo().isCombo(id, { type: 'node' })
    ) {
      return;
    }
    this.chart.selection(undefined);
    if (item && item.type === 'node' && item.d.state) {
      glyphs = item.g;
      this.chart.each({ type: 'node' }, (node) => {
        if (node.d.parent === item.id) {
          nodeIds.push(node.id);
        }
      });
      if (!nodeIds.length) {
        return;
      }
      if (item.d.state === nodeState.EXPANDED) {
        this.hideNodes(nodeIds);
        glyphs.push({
          b: DEFAULT_COMBO_GLYPH_COLOR,
          c: DEFAULT_COMBO_GLYPH_COLOR,
          e: GlyphSize.LARGE,
          p: 'nw',
          fb: false,
          t: nodeIds.length.toString(),
        });
      } else {
        this.showNodes(nodeIds);
        // remove children counter glyph
        const glyphCounterIndex = glyphs.findIndex((glyph) => glyph.p === 'nw');
        glyphs.splice(glyphCounterIndex, 1);
      }
      // change toggle glyph icon
      const glyphToggleIconIndex = glyphs.findIndex(
        (glyph) => glyph.p === 'ne'
      );
      glyphs[glyphToggleIconIndex].u = `${this.imagesPath}link-analysis/${
        item.d.state === nodeState.EXPANDED ? 'expand' : 'collapse'
      }.svg`;
      // update the state of the node (expanded/collapsed) and the glyphs
      const data = item.d;
      data.state =
        data.state === nodeState.EXPANDED
          ? nodeState.COLLAPSED
          : nodeState.EXPANDED;
      this.setNodeProperties({ id: item.id, d: data, g: glyphs });
    }
  }

  private async hideNodes(nodeIds: string | string[]) {
    await this.chart.hide(nodeIds, { animate: true, time: 400 });
    this.chartLayout();
  }

  private async showNodes(nodeIds: string | string[]) {
    await this.chart.show(nodeIds, true, { animate: true, time: 400 });
    this.chartLayout();
  }

  // show/hide sidebar
  public toggleFilterSidebar(event: MatSlideToggleChange) {
    this.showDetailsSidebar = false;
    this.showFilterSidebar = event.checked;
  }

  public addToFilteredData(data: {
    event: MatCheckboxChange;
    item: NodeFilterItem;
  }) {
    const { event, item } = data;
    if (this.caseView && item.type === nodeTypes.PERSON) {
      this.handleSelectedTargets(event, item.id);
    }
    if (event.checked) {
      this.searchGraph(null, item.id);
      if (!this.filteredNodeIds.includes(item.id)) {
        this.filteredNodeIds.push(item.id);
      }
      const node = this.chart.getItem(item.id);
      if (
        node.d.parent &&
        !this.filteredSocialProfileNodeIds.includes(node.d.parent)
      ) {
        this.filteredSocialProfileNodeIds.push(node.d.parent);
      }
    } else {
      this.chart.selection([]);
      const filteredIndex = this.filteredNodeIds.indexOf(item.id);
      this.filteredNodeIds.splice(filteredIndex, 1);
    }
  }

  private retrieveExternalComboLinks(hidden: {
    nodes: string[];
    links: string[];
  }) {
    hidden.links.forEach((id) => {
      const link = this.chart.getItem(id) as Link;
      const items = this.chart.getItem([link.id1, link.id2]);
      if (this.filteredNodeIds.includes(link.id1) || items.length === 2) {
        this.chart.show(id);
      }
    });
  }

  async filterGraph() {
    // clear other filter values
    await this.chart.animateProperties(
      this.clearAnimation.concat(this.clearDegreesAnimation),
      { time: 5 }
    );
    this.clearAnimation = [];
    this.clearDegreesAnimation = [];
    this.selectedQuickFilterOption = null;
    this.showLoader = true;
    if (this.filtersAreEmpty()) {
      this.showAllGraphData();
      this.getVisibleLinksCounter();
      this.getVisibleNodesCounter();
      return;
    }
    if (this.caseView) {
      await this.chart.load({
        type: 'LinkChart',
        items: this.showOnlyCommonNodes(),
      });
    }
    await this.chart
      .filter(this.applyNodeFilters.bind(this), { type: 'node' })
      .then((result) => {
        if (this.caseView) {
          this.retrieveExternalComboLinks(result.hidden);
        }
      });
    if (this.filteredTypes.length) {
      await this.chart.filter(this.applyRelationFilters.bind(this), {
        type: 'link',
      });
    }
    this.chartLayout();
    this.getVisibleLinksCounter();
    this.getVisibleNodesCounter();

    this.showLoader = false;
    this.changeDetectorRef.markForCheck();
  }

  private filtersAreEmpty(): boolean {
    // be able to filter out targets and their MSISDN's
    return this.filteredNodeIds.length === 1 && !this.filteredTypes.length;

    // use the implementation below when targets are disabled (to filter out)
    // and their MSISDN's should always be in the graph:
    // if (this.caseView) {
    //   // we always keep in the filteredNodeIds array the case node, the target nodes and the targets' msisdn nodes
    //   const telnosSum = this.caseTargets.reduce((total, target) => total + target.telnos.length, 0);
    //   return this.filteredNodeIds.length === (1 + this.caseTargets.length + telnosSum) && !this.filteredTypes.length;
    // } else {
    //   // we always keep in the filteredNodeIds array the target node and target msisdn nodes
    //   return this.filteredNodeIds.length === (1 + this.target.telnos.length) && !this.filteredTypes.length;
    // }
  }

  public async showAllGraphData() {
    this.clearFilters();
    this.showLoader = true;
    await this.chart.load({
      type: 'LinkChart',
      items: this.caseView ? this.showOnlyCommonNodes(true) : this.graphData,
    });
    this.chartLayout();
    this.getVisibleLinksCounter();
    this.getVisibleNodesCounter();
    this.graphStateSaved = false;
    this.showLoader = false;
    this.changeDetectorRef.markForCheck();
  }

  private clearFilters() {
    this.filteredTypes = [];
    this.filteredSliderSections = [];
    this.filteredSocialProfileNodeIds = [];
    this.filteredTargetNodeIds = [];
    this.selectedQuickFilterOption = null;
    if (this.playgroundView) {
      return;
    }
    this.filteredNodeIds = this.caseView ? [this.caseId] : [this.target.id];
  }

  private applyNodeFilters(item: Node | Link) {
    const keepCaseSocialConnectionNode = this.handleCaseNodeFilters(item);
    return (
      this.filteredNodeIds.includes(item.id) ||
      this.filteredSocialProfileNodeIds.includes(item.id) ||
      this.filteredNodeIds.includes(item.d.parent) ||
      keepCaseSocialConnectionNode
    );
  }

  private handleCaseNodeFilters(item: Node | Link): boolean {
    let keepNode = false;
    if (this.caseView && item.d.caseData) {
      item.d.caseData.targetsIds.forEach((id) => {
        if (this.filteredNodeIds.includes(id)) {
          keepNode = true;
        }
      });
      if (item.d.caseData.parentProfileIds) {
        item.d.caseData.parentProfileIds.forEach((id) => {
          if (
            this.filteredSocialProfileNodeIds.includes(id) ||
            this.filteredNodeIds.includes(id)
          ) {
            keepNode = true;
          }
        });
      }
    }
    return keepNode;
  }

  private applyRelationFilters(item: Link) {
    // for msisdn relation filters besides the type (ex: incoming call, sms...) we filter also by the count (ex: >=20 times)
    const index = this.filteredSliderSections.findIndex(
      (section) => section.type === item.d.type
    );
    if (index > -1) {
      if (item.d.count) {
        return (
          this.filteredTypes.includes(item.d.type) &&
          item.d.count >= this.filteredSliderSections[index].selectedValue
        );
      } else {
        return (
          this.filteredTypes.includes(item.d.type) &&
          item.d.distance <= this.filteredSliderSections[index].selectedValue
        );
      }
    }
    // keep link if is in the filtered types, if is an MSISDN type, if is a selected social profile or a case target
    return (
      (item.d && this.filteredTypes.includes(item.d.type)) ||
      item.d.type === linkTypes.SIM ||
      this.filteredSocialProfileNodeIds.includes(item.d.relation) ||
      item.d.type === linkTypes.CASE_TARGET
    );
  }

  public onFilterTabChange(event: MatTabChangeEvent) {
    this.activeFilterTab = event.index;
  }

  private getVisibleNodesCounter(): void {
    let counter = 0;
    this.chart.each({ type: 'node' }, (item) => {
      if (!item.hi) {
        counter++;
      }
    });
    this.visibleNodesCounter = counter;
  }

  private getVisibleLinksCounter(): void {
    let counter = 0;
    this.chart.each({ type: 'link' }, (item) => {
      if (!item.hi) {
        counter++;
      }
    });
    this.visibleLinksCounter = counter;
  }

  public async onNewGraphItems(items: (Node | Link)[]) {
    const newItems: (Node | Link)[] = [];
    const showExistingItems: string[] = [];
    items.forEach((item) => {
      if (item.type === 'node') {
        // check if the node already exists in the graph
        const existingNode: Node | Link = this.chart.getItem(item.id);
        if (existingNode && existingNode.parentId) {
          showExistingItems.push(existingNode.id);
        }
        if (!existingNode) {
          newItems.push(item);
          this.addNodeToNodeFiltersItems(
            item,
            nodeTypeToNodeFilterSection[item.d.type],
            false
          );
          if (this.playgroundView && item.d.type !== nodeTypes.LOCATION) {
            this.playgroundEntities.push(item);
          }
        }
      } else {
        if (!this.linkExists(item, this.graphData)) {
          newItems.push(item);
          if (this.graphDataDictionary[item.d.type]) {
            this.graphDataDictionary[item.d.type].push(item);
          } else {
            this.graphDataDictionary[item.d.type] = [item];
          }
        }
      }
    });
    this.graphData = this.graphData.concat(newItems);
    // transfer existing nodes outside of target profile combo
    await this.chart.combo().transfer(showExistingItems, null);
    await this.expandChartData(newItems);
    this.chart.combo().arrange(this.selectedNode?.parentId, { time: 400 });
    this.getVisibleLinksCounter();
    this.getVisibleNodesCounter();
  }

  public selectAllSectionFilters(
    event: MatCheckboxChange,
    nodeFilterElement: NodeFilterElement
  ): NodeFilterElement {
    const expanded = true;
    let { label, data, checkedData, matchedSearchData } = nodeFilterElement;
    if (event.checked) {
      matchedSearchData.forEach((node) => {
        if (!node.disabled) {
          checkedData.push(node);
          this.filteredNodeIds.push(node.id);
        }
      });
      matchedSearchData = matchedSearchData.filter((node) => node.disabled);
    } else {
      checkedData.forEach((node) => {
        const index = this.filteredNodeIds.findIndex((id) => node.id === id);
        this.filteredNodeIds.splice(index, 1);
      });
      checkedData = [];
      matchedSearchData = data.slice();
    }
    return { label, data, checkedData, matchedSearchData, expanded };
  }

  public showSomeCheckedIndication(filterEl: NodeFilterElement): boolean {
    const { checkedData, matchedSearchData } = filterEl;
    if (checkedData.length) {
      const targetNodes = matchedSearchData.filter((node) => node.disabled);
      return matchedSearchData.length > targetNodes.length;
    }
    return false;
  }

  public async updateSelectedNodeData(data: {
    node: Node;
    socialResult: Person;
  }) {
    const currentNodeData = data.node.d;
    currentNodeData.socialSearchResult = data.socialResult;
    await this.setNodeProperties({ id: data.node.id, d: currentNodeData });
  }

  public async updateSelectedNodeParent(data: {
    nodeId: string;
    updateParentTo: string | null;
  }) {
    await this.chart.combo().transfer(data.nodeId, data.updateParentTo);
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === data.nodeId
    );
    if (existingNodeIndex > -1) {
      this.graphData[existingNodeIndex].parentId = data.updateParentTo;
    }
    await this.chart.show(data.nodeId, true);
  }

  private createOrganizationNode(
    targetId: string,
    organization: Company | Education
  ) {
    let node: Node;
    const linkData = {
      type: linkTypes.ORGANIZATION,
      startDate: organization.startDate,
      endDate: organization.endDate,
      organizationName: organization.name,
    };
    if (this.caseView) {
      node = this.handleOrganizationNodesInCaseView(targetId, organization);
    } else {
      const data = {
        type: nodeTypes.ORGANIZATION,
        state: nodeState.EXPANDED,
        parent: organization.sourceEntity.parentId.toLowerCase(),
        parentProfileId: organization.sourceEntity.parentId.toLowerCase(),
        targetId: targetId,
        label: organization.name,
      };
      node = this.linkAnalysisService.createNewNode(
        organization.name,
        nodeTypesColors.ORGANIZATION,
        organization.name,
        data,
        nodeSize.XSMALL,
        [],
        LabelSize.MEDIUM
      );
      node.parentId =
        organization.sourceEntity.parentId.toLowerCase() || targetId;
      this.addNodeToNodeFiltersItems(
        node,
        nodeFilterSections.ORGANIZATION,
        false
      );
    }
    if (organization.type === EntityType.Company) {
      node.id =
        organization.source === DataSource.LinkedIn
          ? `${(organization as Company).companyId}@linkedin` ||
            organization.name
          : node.id;
      // company node
      if ((organization as Company).location) {
        this.createPlaceNode(
          (organization as Company).location.toLowerCase().trim(),
          (organization as Company).location,
          node.id,
          'Company location',
          true,
          organization.sourceEntity.parentId
        );
      }
      node.d['subtype'] = nodeSubtypes.WORKPLACE;
      node.d['industry'] = (organization as Company).industry;
      // TODO: get company/work image from entityMedia
      node.d['image'] = (organization as Company).logo;
      node.d['url'] = (organization as Company).companyUrl;
      node.d['companyId'] = (organization as Company).companyId;
      linkData['positionInOrganization'] = (organization as Company).jobTitle;
    } else {
      // education node
      node.d['subtype'] = nodeSubtypes.EDUCATION;
      linkData['degree'] = (organization as Education).degree;
    }
    node.fi = {
      c: '#fff',
      t: KeyLines.getFontIcon(
        this.linkAnalysisService.getNodeIcon(
          node.d.type,
          node.d.relation,
          node.d.subtype
        )
      ),
    };
    const link: Link = this.linkAnalysisService.getLink(
      organization.sourceEntity.parentId.toLowerCase() || targetId,
      node.id,
      linkTypes.ORGANIZATION,
      false,
      LIGHTER_LINK_COLOR
    );
    link.d = linkData;
    this.graphDataDictionary[linkTypes.ORGANIZATION] = this.graphDataDictionary[
      linkTypes.ORGANIZATION
    ].concat([link]);
    this.graphData.push(node, link);
  }

  private handleOrganizationNodesInCaseView(
    targetId: string,
    organization: Company | Education
  ): Node {
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === organization.name
    );
    let node: Node;
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.caseData
    ) {
      node = this.graphData[existingNodeIndex] as Node;
      const existingCaseData = node.d.caseData;
      if (
        !existingCaseData.parentProfileIds.includes(
          organization.sourceEntity.parentId.toLowerCase()
        )
      ) {
        // keep record of all targets/profiles connected to this social profile
        node.d.caseData = {
          targetsIds: existingCaseData.targetsIds.concat([targetId]),
          parentProfileIds: existingCaseData.parentProfileIds.concat([
            organization.sourceEntity.parentId.toLowerCase(),
          ]),
        };
        node.parentId = null;
        this.graphData.splice(existingNodeIndex, 1);
      }
    } else {
      node = this.linkAnalysisService.createNewNode(
        organization.name,
        nodeTypesColors.ORGANIZATION,
        organization.name,
        {
          type: nodeTypes.ORGANIZATION,
          state: nodeState.EXPANDED,
          caseData: {
            targetsIds: [targetId],
            parentProfileIds: [
              organization.sourceEntity.parentId.toLowerCase(),
            ],
          },
          label: organization.name,
          url: (organization as Company).companyUrl || null,
          companyId: (organization as Company).companyId || null,
        },
        nodeSize.XSMALL,
        [],
        LabelSize.MEDIUM
      );
      node.parentId = organization.sourceEntity.parentId.toLowerCase();
    }
    return node;
  }

  private nodeExists(targetLink: TargetLink): boolean {
    return (
      this.graphData.findIndex((data) => data.d.userId === targetLink.userId) >
      -1
    );
  }

  private linkExists(link: Link, dataToSearchIn: (Node | Link)[]): boolean {
    return dataToSearchIn.some(
      (item) =>
        item.type === 'link' &&
        ((link.id1 === item.id1 && link.id2 === item.id2) ||
          (link.id1 === item.id2 && link.id2 === item.id1)) &&
        item.d.type === link.d.type
    );
  }

  public handleNodeAnimation(showLoader: boolean) {
    this.activeAnimatedNode = showLoader;
    if (showLoader) {
      this.showLoader = true;
    } else {
      this.showLoader = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  public addToPendingJobs(nodeId: string) {
    this.pendingJobs.push({ id: nodeId, active: true, pending: false });
  }

  private getFilteredDataToSerialize(): {} {
    return {
      filteredSliderSections: this.filteredSliderSections,
      filteredNodeIds: this.filteredNodeIds,
      filteredTypes: this.filteredTypes,
      graphData: this.graphData,
      filteredSocialProfileIds: this.filteredSocialProfileNodeIds,
      caseTargets: this.caseTargets,
      playgroundEntities: this.playgroundEntities,
    };
  }

  private createCaseNode(caseData: Case) {
    const node = this.linkAnalysisService.createNewNode(
      caseData.id,
      nodeTypesColors.CASE,
      caseData.caseName || 'Case',
      {
        type: nodeTypes.CASE,
        label: caseData.caseName || 'Case',
      },
      nodeSize.SMALL,
      [],
      LabelSize.MEDIUM
    );
    this.graphData.push(node);
    if (this.caseView) {
      this.caseTargets.forEach((target: TargetItem) => {
        const link: Link = this.linkAnalysisService.getLink(
          caseData.id,
          target.id,
          linkTypes.CASE_TARGET,
          false,
          LIGHTER_LINK_COLOR
        );
        if (this.linkExists(link, this.graphData)) {
          return;
        }
        this.graphData.push(link);
      });
      // we always want to keep the case node when filtering
      this.filteredNodeIds.push(node.id);
    } else {
      const link: Link = this.linkAnalysisService.getLink(
        caseData.id,
        this.target.id,
        linkTypes.CASE_TARGET,
        false,
        LIGHTER_LINK_COLOR
      );
      if (this.linkExists(link, this.graphData)) {
        return;
      }
      this.graphData.push(link);
    }
  }

  private createExistingTargetItems(
    targetId: string,
    alias: string,
    connectToId: string,
    names: string[],
    image: string | null,
    linkType: linkTypes
  ) {
    const nodeData = {
      type: nodeTypes.PERSON,
      label: alias,
      alias,
      targetId,
      names: names.join(', '),
      image: image
        ? (this.imageService.getPhotoUrl(image, true) as string)
        : '',
    };
    const targetNode = this.linkAnalysisService.createNewNode(
      targetId,
      nodeTypesColors.PERSON,
      alias,
      nodeData,
      nodeSize.SMALL,
      [this.linkAnalysisService.getNodeLabelGlyph('Target')],
      LabelSize.MEDIUM
    );
    const link: Link = this.linkAnalysisService.getLink(
      targetNode.id,
      connectToId,
      linkType,
      false,
      LIGHTER_LINK_COLOR
    );
    if (!this.linkExists(link, this.graphData)) {
      this.graphData.push(link);
    }
    this.graphData.push(targetNode);
    this.addNodeToNodeFiltersItems(
      targetNode,
      nodeFilterSections.PERSON,
      false
    );
  }

  private async setLatestGraphImage() {
    this.latestGraphImage = await this.chart.toDataURL(1400, 900, {
      fit: 'oneToOne',
      logo: false,
    });
  }

  private loadCaseData() {
    this.subscriptions.push(
      this.caseService.getCase(this.caseId).subscribe((caseData: Case) => {
        if (caseData.assignedTargets && caseData.assignedTargets.length) {
          this.caseTargets = caseData.assignedTargets;
          this.handleCaseData(caseData);
        } else {
          // this.createCaseNode(caseData);
          this.drawGraph();
        }
      })
    );
  }

  private loadTargetData() {
    this.subscriptions.push(
      this.profilerService
        .getTargetData(this.targetId)
        .subscribe((targetItem: TargetItem) => {
          this.target = targetItem;
          this.getLatestGraphState(this.target.id);
        })
    );
  }

  private initializeTargetGraph() {
    this.showLoader = true;
    this.handleTargetCasesData();
    this.createTargetNode(this.target);
    this.getTargetSocialProfiles(this.target);
    this.getLinkAnalysisData(this.target);
    this.createClusters();
  }

  private handleCaseData(caseData: Case) {
    // this.createCaseNode(caseData);
    this.showLoader = true;
    if (!this.casePlaygroundView) {
      const targetIds = this.caseTargets.map((target) => target.id);
      this.subscriptions.push(
        this.callLogsApiService
          .getCaseCallLogTopAssociatesStatistics(targetIds)
          .subscribe((result) => {
            this.createCallLogsTopAssociateItems(
              result.body.associates,
              result.body.gioTargetMetadata
            );
          })
      );
    }
    this.caseTargets.forEach((target: TargetItem) => {
      this.createTargetNode(target);
      // TODO: remove this after a while if the requirements don't change (again :P)
      // if (this.casePlaygroundView) {
      //   target.telnos.forEach(telno => {
      //     this.createTargetTelnoNode(target.id, telno.trim());
      //   })
      //   setTimeout(() => {
      //     this.drawGraph();
      //   }, 5000);
      // } else {
      this.getTargetSocialProfiles(target);
      this.getLinkAnalysisData(target);
      // }
    });
  }

  private initializeGraphDictionaryValues() {
    Object.values(linkTypes).forEach((type) => {
      this.graphDataDictionary[type] = [];
    });
  }

  private setupLegendNodeTypes() {
    this.legendNodeTypes = [];
    Object.values(nodeTypes).forEach((type) => {
      this.legendNodeTypes.push({
        type,
        icon: `${this.imagesPath}link-analysis/${type.toLowerCase()}.svg`,
        show:
          this.itemsForNodeFilters.filter((item) => item.type === type).length >
          0,
      });
    });
  }

  private handleTargetCasesData() {
    if (this.target.assignedCases && this.target.assignedCases.length) {
      this.target.assignedCases.forEach((caseId) => {
        this.caseService.getCase(caseId).subscribe((caseData: Case) => {
          this.createCaseNode(caseData);
        });
      });
    }
  }

  private handleSelectedTargets(event: MatCheckboxChange, nodeId: string) {
    if (event.checked && !this.filteredTargetNodeIds.includes(nodeId)) {
      this.filteredTargetNodeIds.push(nodeId);
    } else if (!event.checked) {
      this.filteredTargetNodeIds.splice(
        this.filteredTargetNodeIds.indexOf(nodeId),
        1
      );
    }
  }

  public toggleFullscreen(): void {
    this.expandedView = false;
    if (KeyLines.fullScreenCapable()) {
      KeyLines.toggleFullScreen(
        this.fullscreenContainer.nativeElement,
        this.detectFullscreenChange.bind(this)
      );
    } else {
      this.showMessage(
        this.translationService.translate(
          'Your browser is not capable to view in fullscreen. Please switch to a latest browser.'
        )
      );
    }
  }

  private detectFullscreenChange(): void {
    this.fullscreenView = document.fullscreen;
  }

  private onMouseDown(eventData: {
    id: string | null;
    x: number;
    y: number;
  }): void {
    this.infoWindow.nativeElement.classList.add('hidden');
    this.contextMenu.nativeElement.classList.add('hidden');
    if (!this.newEntityCreationInProgress) {
      this.inputLabel.nativeElement.classList.add('hidden');
    }
    if (eventData.id && this.chart.selection().length === 0) {
      const item = this.chart.getItem(eventData.id);
      if (item && item.type !== 'node') {
        return;
      }
      const user = this.localStorageService.getCurrentUser().identity;
      const savedCounter =
        +localStorage.getItem(`${user}_linkAnalysisCounter`) || 0;
      if (savedCounter < 3) {
        this.setTooltipPosition(eventData.x, eventData.y, this.infoWindow);
        this.infoWindow.nativeElement.classList.remove('hidden');
      }
    }
  }

  public closeInfoWindow(): void {
    this.infoWindow.nativeElement.classList.add('hidden');
    let savedCounter =
      +localStorage.getItem(
        `${
          this.localStorageService.getCurrentUser().identity
        }_linkAnalysisCounter`
      ) || 0;
    if (savedCounter < 3) {
      savedCounter = savedCounter + 1;
      localStorage.setItem(
        `${
          this.localStorageService.getCurrentUser().identity
        }_linkAnalysisCounter`,
        savedCounter.toString()
      );
    }
  }

  private onSelectionChanged() {
    if (this.chart.selection().length > 1) {
      this.chart.foreground((node) => this.chart.selection().includes(node.id));
    }
    // if the selection contains a social profile Enable the 'Show photos' button
    this.enableShowPhotosButton = this.chart.selection().some((id) => {
      const item = this.chart.getItem(id);
      return item.d.image?.length;
    });
  }

  private createFacebookGroupItems(targetId: string, group: Group): void {
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === group.groupId.toLowerCase()
    );
    let node: Node;
    if (this.caseView) {
      node = this.getFacebookGroupItemsCaseView(
        existingNodeIndex,
        targetId,
        group
      );
    } else {
      node = this.linkAnalysisService.createNewNode(
        group.groupId.toLowerCase(),
        nodeTypesColors.FACEBOOK_GROUP,
        group.name,
        {
          type: nodeTypes.FACEBOOK_GROUP,
          image: this.imageService.getPhotoUrl(
            group.image?.url,
            true
          ) as string,
          parent: group.sourceEntity.parentId.toLowerCase(),
          relation: relationTypes.FACEBOOK,
          label: group.name,
          url: group.url,
          description: group.description,
          membersCount: group.membersCount,
          sourceEntityId: group.sourceEntity.id,
        },
        nodeSize.XSMALL,
        [this.linkAnalysisService.getGlyph(`link-analysis/facebook.svg`, 'e')],
        LabelSize.MEDIUM
      );
      node.parentId = targetViewClusterIds.GROUPS;
    }
    const link: Link = this.linkAnalysisService.getLink(
      this.getNodeParentId(targetId, group.sourceEntity.parentId.toLowerCase()),
      group.groupId.toLowerCase(),
      linkTypes.FACEBOOK_GROUP,
      false,
      LIGHTER_LINK_COLOR,
      group.name
    );
    if (this.linkExists(link, this.graphData)) {
      return;
    }
    this.addNodeToNodeFiltersItems(node, nodeFilterSections.OTHER, false);
    this.graphData.push(node, link);
    this.graphDataDictionary[linkTypes.FACEBOOK_GROUP] =
      this.graphDataDictionary[linkTypes.FACEBOOK_GROUP].concat([link]);
    this.getFacebookGroupMembers(group);
  }

  private async getFacebookGroupMembers(group: Group) {
    const groupMembersFilters: RequestOptions = {
      filters: {
        source: [DataSource.Facebook],
        targetId: group.targetId,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Joined],
        profileId: `${group.groupId}:fgroup@${relationTypes.FACEBOOK}`,
      },
    };
    this.subscriptions.push(
      await this.socialProfilesService
        .getAll(groupMembersFilters)
        .pipe(
          map((result: { [key: string]: Profile }) => Object.values(result))
        )
        .subscribe((profiles: Profile[]) => {
          profiles.forEach((profile) => {
            const existingNodeIndex = this.graphData.findIndex(
              (item) => item.id === profile.profileId.toLowerCase()
            );
            const link: Link = this.linkAnalysisService.getLink(
              profile.profileId.toLocaleLowerCase(),
              group.groupId.toLowerCase(),
              linkTypes.FACEBOOK_GROUP_MEMBER,
              false,
              LIGHTER_LINK_COLOR
            );
            link.d['facebookGroupName'] = group.name || group.sourceEntity.id;
            this.graphData.push(link);
            if (existingNodeIndex === -1) {
              const node =
                this.linkAnalysisService.getSocialConnectionNode(profile);
              node.parentId = group.groupId.toLowerCase();
              this.graphData.push(node);
            }
          });
        })
    );
  }

  private getFacebookGroupItemsCaseView(
    index: number,
    targetId: string,
    group: Group
  ): Node {
    let node: Node;
    if (index > -1) {
      node = this.graphData[index] as Node;
      const existingCaseData = node.d.caseData;
      if (
        !existingCaseData.parentProfileIds.includes(
          group.sourceEntity.parentId.toLowerCase()
        )
      ) {
        // keep record of all targets/profiles connected to this social profile
        node.d.caseData = {
          targetsIds: existingCaseData.targetsIds.concat([targetId]),
          parentProfileIds: existingCaseData.parentProfileIds.concat([
            group.sourceEntity.parentId.toLowerCase(),
          ]),
        };
        this.graphData.splice(index, 1);
      }
    } else {
      node = this.linkAnalysisService.createNewNode(
        group.groupId.toLowerCase(),
        nodeTypesColors.FACEBOOK_GROUP,
        group.name,
        {
          type: nodeTypes.FACEBOOK_GROUP,
          image: this.imageService.getPhotoUrl(
            group.image?.url,
            true
          ) as string,
          relation: relationTypes.FACEBOOK,
          label: group.name,
          url: group.url,
          caseData: {
            targetsIds: [targetId],
            parentProfileIds: [group.sourceEntity.parentId.toLowerCase()],
          },
        },
        nodeSize.XSMALL,
        [this.linkAnalysisService.getGlyph(`link-analysis/facebook.svg`, 'e')],
        LabelSize.MEDIUM
      );
      node.parentId = group.sourceEntity.parentId.toLowerCase();
    }
    return node;
  }

  private resetAllVariablesValues(): void {
    this.graphDataDictionary = {};
    this.showFilterSidebar = false;
    this.filteredNodeIds = [];
    this.itemsForNodeFilters = [];
    this.filteredSocialProfileNodeIds = [];
    this.graphData = [];
    this.filteredTypes = [];
    this.filteredSliderSections = [];
  }

  public reloadData() {
    if (this.caseView) {
      return;
    }
    this.resetAllVariablesValues();
    this.chart.clear();
    this.initializeGraphDictionaryValues();
    this.initializeTargetGraph();
  }

  private async reloadChart() {
    this.data = {
      type: 'LinkChart',
      items: this.graphData,
    };
    if (
      this.deepOsintFlagEnabled &&
      !this.caseView &&
      this.target.deepOsintStatus === JobStatus.DONE
    ) {
      // in the Deep Osint view we load the filter with the 'Top Connections' in initialization
      this.expectedEvents[`${this.targetId}_deepOsint`] =
        this.linkAnalysisDataService.createFilterQuery(
          this.target.id,
          MessageSubject.DeepOsintTopConnections
        );
    } else {
      await this.chart.load(this.data);
      await this.chartInitializationActions();
    }
  }

  // initial implementation for place nodes
  private createPlaceNode(
    id: string,
    name: string,
    connectToId: string,
    tooltipText: string,
    addToGraphData: boolean,
    parentId: string
  ) {
    // TODO: check if node with similar naming already exists ex: 'Limassol' / 'Limassol, Cyprus'
    // TODO: maybe add an icon from the source of the place (ex: facebook, linkedin)
    const node: Node = this.linkAnalysisService.createNewNode(
      id,
      nodeTypesColors.PLACE,
      name,
      {
        type: nodeTypes.PLACE,
        label: name,
      },
      nodeSize.XSMALL,
      [],
      LabelSize.MEDIUM
    );
    const link: Link = this.linkAnalysisService.getLink(
      connectToId,
      node.id,
      linkTypes.PLACE,
      false,
      LIGHTER_LINK_COLOR,
      tooltipText
    );
    this.addNodeToNodeFiltersItems(node, nodeFilterSections.PLACE, false);
    node.parentId = parentId;
    if (addToGraphData) {
      this.graphData.push(node, link);
    }
  }

  private handlePlaceFromProfile(target: TargetItem, profile: Profile) {
    if (profile.hometown && profile.hometown.title) {
      this.createPlaceNode(
        profile.hometown.title.toLowerCase().trim(),
        profile.hometown.title,
        target.id,
        'Hometown',
        true,
        target.id
      );
    }
    if (profile.currentCity && profile.currentCity.title) {
      this.createPlaceNode(
        profile.currentCity.title.toLowerCase().trim(),
        profile.currentCity.title,
        target.id,
        'Current city',
        true,
        target.id
      );
    }
  }

  private showInfoWindow() {
    const savedCounter =
      +localStorage.getItem(
        `${
          this.localStorageService.getCurrentUser().identity
        }_linkAnalysisCounter`
      ) || 0;
    if (savedCounter < 3) {
      this.setTooltipPosition(250, 250, this.infoWindow);
      this.infoWindow.nativeElement.classList.remove('hidden');
    }
  }

  // from analytics data
  private createSocialProfileNode(profile: DeepOsintProfile) {
    if (profile.userId.includes('E')) {
      return;
    }
    const platform: relationTypes = this.linkAnalysisService.parsePlatform(
      profile.profile.source
    );
    const existingNodeIndex = this.graphData.findIndex(
      (item) => item.id === profile.userId
    );
    // if the node already exists but its a target profile node return
    if (
      existingNodeIndex > -1 &&
      this.graphData[existingNodeIndex].d.isTargetNode
    ) {
      return;
    }
    let node: Node;
    profile.profileId = profile.userId;
    node = this.linkAnalysisService.createNewNode(
      profile.userId,
      nodeTypesColors.SOCIAL_PROFILE,
      profile.profile.name,
      this.linkAnalysisService.getSocialProfileNodeData(
        null,
        null,
        profile.profile,
        `${environment.fileManagerUri}/file/${profile.photos[0]}?useThumbnail=yes`
      ),
      nodeSize.XSMALL,
      [this.linkAnalysisService.getGlyph(`link-analysis/${platform}.svg`, 'e')],
      LabelSize.MEDIUM
    );
    // create place nodes if any
    if (profile.location && profile.location.length) {
      profile.location.forEach((location: { name: string; type: string }) => {
        this.createPlaceNode(
          location.name.toLocaleLowerCase().trim(),
          location.name,
          node.id,
          location.type,
          false,
          node.id
        );
      });
    }
    this.addNodeToNodeFiltersItems(
      node,
      nodeFilterSections.SOCIAL_PROFILES,
      false
    );
    return node;
  }

  private createDeepOsintEventLink(data: DeepOsintLink) {
    let link: Link;
    const connectionType: linkTypes =
      this.linkAnalysisService.getConnectionType(
        data.source,
        data.relationType
      );
    if (!connectionType) {
      return;
    }
    link = this.linkAnalysisService.getLink(
      data.fromUserId,
      data.toUserId,
      connectionType,
      true,
      LIGHTER_LINK_COLOR
    );
    link.id =
      data.source === 'FB'
        ? `${data.source}_${connectionType}_${data.fromUserId}_${data.toUserId}`
        : `${data.source}_${data.fromUserId}_${data.toUserId}`;
    link.d = this.getLinkData(connectionType, data);
    if (this.linkExists(link, this.graphData)) {
      const index = this.graphData.findIndex(
        (item) =>
          item.type === 'link' &&
          ((link.id1 === item.id1 && link.id2 === item.id2) ||
            (link.id1 === item.id2 && link.id2 === item.id1)) &&
          item.d.type === link.d.type
      );
      this.graphData.splice(index, 1);
    }
    return link;
  }

  private getLinkData(connectionType: linkTypes, rawData: DeepOsintLink): any {
    const linkData = this.linkAnalysisService.getDeepOsintEventLinkData(
      connectionType,
      rawData
    );
    if (rawData.importance) {
      this.topConnectionsTopImportanceScore =
        rawData.importance > this.topConnectionsTopImportanceScore
          ? rawData.importance
          : this.topConnectionsTopImportanceScore;
    }
    this.heighestLinkScore =
      linkData.count > this.heighestLinkScore
        ? linkData.count
        : this.heighestLinkScore;
    return linkData;
  }

  private applyQuickFilter(item: Link) {
    switch (this.selectedQuickFilterOption.value) {
      case quickFilters.WORKPLACES:
        // keep place nodes that are connected to organization nodes while filtering
        let linkIsConnectedToOrganizationNode = false;
        if (item.d.type === linkTypes.PLACE) {
          const organizationNode = this.chart.getItem(item.id1);
          linkIsConnectedToOrganizationNode =
            organizationNode?.d.type === nodeTypes.ORGANIZATION;
        }
        return (
          (item.d.type &&
            item.d.type === linkTypes.ORGANIZATION &&
            item.d.positionInOrganization) ||
          linkIsConnectedToOrganizationNode
        );
      case quickFilters.EDUCATION:
        return (
          item.d.type && item.d.type === linkTypes.ORGANIZATION && item.d.degree
        );
      case quickFilters.SAME_PLACE:
        return item.d.type && item.d.type === linkTypes.FACEBOOK_SAME_PLACE;
      case quickFilters.SAME_INSITUTION:
        return (
          item.d.type && item.d.type === linkTypes.FACEBOOK_SAME_INSTITUTION
        );
      default:
        break;
    }
  }

  private createClusters() {
    const clusters: TargetViewCluster[] = [
      {
        label: 'Facebook Groups',
        id: targetViewClusterIds.GROUPS,
        color: nodeTypesColors.FACEBOOK_GROUP,
      },
    ];
    clusters.forEach((cluster) => {
      this.graphData.push(
        this.linkAnalysisService.createNewNode(
          cluster.id,
          cluster.color,
          cluster.label,
          { type: nodeTypes.FACEBOOK_GROUP },
          nodeSize.SMALL,
          [],
          LabelSize.LARGE
        )
      );
      this.filteredSocialProfileNodeIds.push(cluster.id);
    });
  }

  private showContextMenu(data: { id: string; x: number; y: number }) {
    if ((this.playgroundView || this.casePlaygroundView) && data.id) {
      const item = this.chart.getItem(data.id);
      if (item && item.d?.key) {
        this.contextMenuOptions = contextMenuOptionsByType[item.d.type];
        this.clickHandler({ id: data.id });
      }
    } else {
      this.contextMenuOptions = [
        actionToContextMenuOption[contextMenuActions.CREATE_CUSTOM_CLUSTER],
      ];
    }
    this.contextMenu.nativeElement.style.top = `${data.y + 2}px`;
    this.contextMenu.nativeElement.style.left = `${data.x + 8}px`;
    this.contextMenu.nativeElement.classList.remove('hidden');
  }

  private onDragStart(data: {
    id: string;
    type: string;
    subItem: { index: number; subId: string; type: string };
    setDragOptions;
  }) {
    // Allow combo contents to be dragged separately from the top level combo
    data.setDragOptions({ dragCombos: false });
    if (
      (this.playgroundView || this.casePlaygroundView) &&
      data.type === 'node' &&
      data.subItem?.subId
    ) {
      this.startCustomLinkCreation(data.id);
    }
  }

  private onDragOver(data: { id: string | null }) {
    if (data.id) {
      const item = this.chart.getItem(data.id);
      if (item?.type === 'node' && item.d.isCustomGroup) {
        if (
          this.chart.combo().isCombo(item.id) &&
          (item as Node).oc.c !== '#5f6361'
        ) {
          // if existing combo: style background color
          this.clearAnimation.push({
            id: item.id,
            oc: { c: (item as Node).oc.c },
            customCombo: true,
          });
          this.setNodeProperties({
            id: item.id,
            oc: { c: '#5f6361' },
          });
        } else {
          // add plus sign at top right
          this.setNodeProperties({
            id: item.id,
            g: [
              this.linkAnalysisService.getGlyph(
                'link-analysis/plus.svg',
                'ne',
                false,
                GlyphSize.LARGE
              ),
            ],
          });
          this.clearAnimation.push({ id: item.id, g: [], customCombo: true });
        }
      }
    } else {
      // clear hovered node animation only
      const animationsToClear = [];
      this.clearAnimation.forEach((animation) => {
        if (animation.customCombo) {
          animationsToClear.push(animation);
        }
      });
      this.chart.animateProperties(animationsToClear, { time: 5 });
    }
  }

  private onDragEnd(data: {
    type: string;
    dragIds: string | string[];
    id: string | null;
  }) {
    if (data.type !== 'node' || !data.id || !data.dragIds) {
      return;
    }
    const hoveredNode = this.chart.getItem(data.id);
    if (!hoveredNode.d.isCustomGroup) {
      return;
    }
    const nodesToMerge: Node[] = [];
    if (Array.isArray(data.dragIds)) {
      data.dragIds.forEach((id) => {
        const nodeToTransfer = this.chart.getItem(id) as Node;
        this.chart.removeItem(id);
        nodeToTransfer.parentId = data.id;
        nodesToMerge.push(nodeToTransfer);
      });
    } else {
      const nodeToTransfer = this.chart.getItem(data.dragIds) as Node;
      this.chart.removeItem(data.dragIds);
      nodeToTransfer.parentId = data.id;
      nodesToMerge.push(nodeToTransfer);
    }
    this.chart.merge(nodesToMerge);
    this.chart.combo().open(data.id);
    this.chart.combo().arrange(data.id, { time: 400 });
    // clear animations only for the hovered node
    const hoveredNodeAnimations = [];
    this.clearAnimation.forEach((animation) => {
      if (animation.id === data.id) {
        hoveredNodeAnimations.push(animation);
      }
    });
    this.chart.animateProperties(hoveredNodeAnimations, { time: 5 });
  }

  private linkNodes(fromNodeId: string, toNodeId: string, linkId: string) {
    if (!toNodeId) {
      return;
    }
    this.chart.removeItem(linkId);
    const fromNode = this.chart.getItem(fromNodeId) as Node;
    const toNode = this.chart.getItem(toNodeId) as Node;
    const position = this.chart.labelPosition(toNodeId);
    this.linkNodesData = { fromNode, toNode, position };
  }

  private subscribeToRoute() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params['nodeId']) {
          const index = this.pendingJobs.findIndex(
            (item) => item.id === params['nodeId']
          );
          if (index > -1) {
            if (
              this.pendingJobs[index].active &&
              !this.pendingJobs[index].pending
            ) {
              this.pendingJobs[index].pending = true;
              this.handleAdvancedOsintFromNodeCompleted(
                params['nodeId'],
                index
              );
            }
          } else {
            this.pendingJobs.push({
              id: params['nodeId'],
              active: true,
              pending: true,
            });
          }
        }
      })
    );
  }

  private subscribeToWS() {
    this.subscriptions.push(
      this.serverPyWsService.getMessage().subscribe((message: Message) => {
        this.handleWsMessage(message);
      })
    );

    // from server-ts
    this.websocketManager
      .getServerTsConnection()
      .pipe(
        tap((ws) => {
          ws.on('message', (message) => {
            if (
              message?.channel === EventChannel.CdrStatistics &&
              this.expectedEvents[this.caseId || this.target?.id] ===
                message.correlationId
            ) {
              this.createCallLogsTopAssociateItems(
                message.body?.associates,
                message.body.gioTargetMetadata
              );
            }
          });
        })
      )
      .subscribe();
  }

  private handleWsMessage(message: Message) {
    if (
      message.subject.includes('deepOsint') &&
      this.expectedEvents[`${this.targetId}_deepOsint`] ===
        message.body.correlationId
    ) {
      this.handleDeepOsintEvent(transformSnakeToCamel(message));
    }
  }

  private async handleAdvancedOsintFromNodeCompleted(
    nodeId: string,
    index: number
  ) {
    this.showLoader = true;
    const node = this.chart.getItem(nodeId) as Node;
    if (node) {
      if (node.parentId) {
        await this.chart.combo().transfer(node.id, null);
      }
      this.selectedNode = node;
      this.showDetailsSidebar = true;
      this.selectedNodeOsintCompleted = true;
      this.pendingJobs[index].active = false;
      this.pendingJobs[index].pending = false;
      // provide some time to the graph to expand the new nodes. We cannot hide the loader after the expand function
      // since it fires more than once (Person filters, profile filters, connections filters)
      // TODO: maybe change the node-details component to fire only once with the new nodes to expand and not multiple times
      setTimeout(() => {
        this.showLoader = false;
        this.changeDetectorRef.markForCheck();
        this.chart.zoom('fit', { ids: nodeId });
      }, 10000);
      this.chart.foreground(() => true, { type: 'all' });
    }
  }

  private async handleDeepOsintEvent(message: Message) {
    this.heighestLinkScore = 0;
    const payload = message.body.payload;
    const nodes: Node[] = [];
    const links: Link[] = [];
    payload.nodes.forEach((node) => {
      const formattedNode = this.createSocialProfileNode(node);
      if (formattedNode) {
        nodes.push(formattedNode);
      }
    });
    payload.edges.forEach((link) => {
      if (
        message.subject === MessageSubject.DeepOsintMutualFriendsResponse &&
        link.mutualFriends?.length
      ) {
        link.mutualFriends.forEach((friend) => {
          friend.profile.source = 'FB';
          const node = this.createSocialProfileNode(friend);
          const linkWithFriend = this.linkAnalysisService.getLink(
            node.id,
            link.toUserId,
            linkTypes.FACEBOOK_FRIEND,
            true,
            LIGHTER_LINK_COLOR
          );
          const linkWithTarget = this.linkAnalysisService.getLink(
            node.id,
            link.fromUserId,
            linkTypes.FACEBOOK_FRIEND,
            true,
            LIGHTER_LINK_COLOR
          );
          nodes.push(node);
          if (!this.linkExists(linkWithFriend, links)) {
            links.push(linkWithFriend);
          }
          if (!this.linkExists(linkWithTarget, links)) {
            links.push(linkWithTarget);
          }
        });
        // hide top connections that only have 'Friend' relation with the target
      } else if (
        message.subject === MessageSubject.DeepOsintTopConnectionsResponse &&
        link.relationType?.length === 1 &&
        link.relationType[0] === 'Friend'
      ) {
        const fromNodeIndex = nodes.findIndex(
          (node) => node.id === link.fromUserId
        );
        if (fromNodeIndex > -1) {
          nodes[fromNodeIndex].parentId = link.toUserId;
        }
        return;
      }
      link.relationType.forEach((type) => {
        const copyLink = Object.assign({}, link);
        copyLink.relationType = type;
        const formattedLink = this.createDeepOsintEventLink(copyLink);
        if (formattedLink) {
          links.push(formattedLink);
        }
      });
    });
    const formattedData = this.applySizeChangesToIndicateImportance(
      nodes,
      links
    );
    const chartData: ChartData = {
      type: 'LinkChart',
      items: [
        ...this.graphData,
        ...formattedData.nodes,
        ...formattedData.links,
      ],
    };
    await this.chart.clear();
    await this.chart.load(chartData);
    // save filters in filters state (remove '.response' from subject name before saving)
    await this.chartInitializationActions();
    const index = message.subject.indexOf('.response');
    this.filtersState[message.subject.substring(0, index)] =
      this.chart.serialize();
  }

  private applySizeChangesToIndicateImportance(
    nodes: Node[],
    links: Link[]
  ): { nodes: Node[]; links: Link[] } {
    links.forEach((link) => {
      if (
        this.selectedQuickFilterOption.value ===
        MessageSubject.DeepOsintTopConnections
      ) {
        let nodeIndex = nodes.findIndex((node) => node.id === link.id1);
        if (nodeIndex === -1) {
          nodeIndex = nodes.findIndex((node) => node.id === link.id2);
        }
        const node = nodes[nodeIndex];
        if (!node) {
          return;
        }
        node.e = this.linkAnalysisService.transformLinkWeight(
          link.d.importanceScore * 10,
          this.topConnectionsTopImportanceScore * 10,
          nodeSize.XSMALL,
          nodeSize.XLARGE,
          nodeSize.XSMALL
        );
        if (node.d.importanceDetails) {
          node.d.importanceDetails.push(link.d.type);
        } else {
          node.d.importanceDetails = [link.d.type];
        }
        node.g.push(
          this.linkAnalysisService.getNodeLabelGlyph('Top connection')
        );
      }
      link.w = this.linkAnalysisService.transformLinkWeight(
        link.d.count,
        this.heighestLinkScore,
        1,
        8,
        1
      );
    });
    return { nodes, links };
  }

  private addNodeToNodeFiltersItems(
    node: Node,
    section: nodeFilterSections,
    disabled: boolean
  ) {
    if (!this.itemInNodeFilters(node.id)) {
      this.itemsForNodeFilters.push({
        id: node.id,
        type: node.d.type,
        icon: node.g?.length ? node.g[0].u : null,
        label: node.t as string,
        section,
        disabled,
      });
    }
  }

  private itemInNodeFilters(nodeId: string): boolean {
    return (
      this.itemsForNodeFilters.findIndex((item) => item.id === nodeId) > -1
    );
  }

  private createCallLogsTopAssociateItems(
    associates: ClAssociate[],
    targetMetadata: { [key: string]: GioTargetMetadata }
  ) {
    associates.forEach((associate) => {
      const index = this.graphData.findIndex(
        (node) => node.id === associate.msisdn
      );
      if (index > -1) {
        this.graphData[index].g = [
          this.linkAnalysisService.getNodeLabelGlyph('Call log top relation'),
        ];
        this.graphData[index].parentId = null;
      } else {
        const nodeData = {
          type: nodeTypes.MSISDN,
          label: associate.msisdn,
          topRelation: true,
        };
        const associateNode = this.linkAnalysisService.createNewNode(
          associate.msisdn,
          nodeTypesColors.MSISDN,
          associate.msisdn,
          nodeData,
          nodeSize.XSMALL,
          [this.linkAnalysisService.getNodeLabelGlyph('Call log top relation')],
          LabelSize.MEDIUM
        );
        if (targetMetadata[associate.msisdn]) {
          const targetData = targetMetadata[associate.msisdn][0];
          this.createExistingTargetItems(
            targetData.targetId,
            targetData.alias,
            associateNode.id,
            [],
            targetData.photos?.length ? targetData.photos[0] : null,
            linkTypes.SIM
          );
        }
        if (this.target) {
          const link = this.linkAnalysisService.getLink(
            associate.msisdn,
            this.target.telnos[0] || this.target.id,
            linkTypes.UNKNOWN,
            true,
            LIGHTER_LINK_COLOR
          );
          this.graphData.push(link);
        } else if (this.caseView && associate.targets) {
          associate.targets.forEach((target) => {
            const link = this.linkAnalysisService.getLink(
              associate.msisdn,
              target.msisdns[0],
              linkTypes.UNKNOWN,
              true,
              LIGHTER_LINK_COLOR
            );
            this.graphData.push(link);
          });
        }
        this.graphData.push(associateNode);
        this.addNodeToNodeFiltersItems(
          associateNode,
          nodeFilterSections.MSISDN,
          false
        );
      }
    });
  }

  private pingSelection(type: string, index, dt1: Date, dt2: Date) {
    if (type !== 'bar' && type !== 'selection') {
      return;
    }
    const timebarSelection = this.timebar.selection();
    const selection = this.chart.selection();
    let colour = 'blue';
    const ids = this.timebar.getIds(dt1, dt2);
    let hoveredIds = [];
    if (type === 'selection') {
      colour = index === 0 ? 'red' : 'green';
      hoveredIds = ids.filter((id) => timebarSelection[index].includes(id));
    } else if (type === 'bar') {
      hoveredIds = ids;
    }
    // Don't ping nodes that are already selected
    if (hoveredIds.length) {
      const neighbours = this.chart.graph().neighbours(hoveredIds);
      this.chart.ping(
        neighbours.nodes.filter((node) => !selection.includes(node)),
        { c: colour }
      );
    }
  }

  public setTimebarData(
    data: { id: string; dt: Date[]; v: number[]; d?: any }[]
  ) {
    this.timebarData = data;
    const timebarData = {
      type: 'LinkChart',
      items: data,
    };
    this.timebar.load(timebarData);
  }

  private async startCustomLinkCreation(nodeId: string) {
    const style = {
      c: '#282828',
      w: 3,
    };
    const toNode = await this.chart.createLink(
      nodeId,
      `customLinkFrom_${nodeId}`,
      { style }
    );
    this.linkNodes(nodeId, toNode, `customLinkFrom_${nodeId}`);
  }

  private loadGoogleCheckins(): Observable<Place[]> {
    const options: RequestOptions = {
      filters: {
        source: [DataSource.DBSearch],
        targetId: this.targetId,
        type: EntityType.Person,
      },
    };

    return this.personService.getAll(options).pipe(
      map((result: Record<string, Person>) =>
        Object.values(result).filter((item: Person): boolean => !!item.checkIn)
      ),
      map((persons: Person[]): Place[] =>
        getCheckinsFromPersons(persons, this.targetId)
      )
    );
  }

  private loadFbCheckins(): Observable<Place[]> {
    const fbCheckinFilters: RequestOptions = {
      filters: {
        source: [DataSource.Facebook],
        targetId: this.targetId,
        type: EntityType.Place,
        limit: 15,
      },
    };

    return this.placeService
      .getAll(fbCheckinFilters)
      .pipe(map((result) => Object.values(result)));
  }

  private loadCheckins(): Observable<Place[]> {
    return forkJoin([this.loadGoogleCheckins(), this.loadFbCheckins()]).pipe(
      map(([googlePlaces, fbPlaces]: Place[][]): Place[] => {
        return [...googlePlaces, ...fbPlaces];
      })
    );
  }
}
