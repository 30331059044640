import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Query } from 'src/app/shared/models/query-item.model';

@Injectable({
  providedIn: 'root',
})
export class IpLocationService {
  constructor() {}

  private extractIPLocation$: Subject<Query> = new Subject<Query>();

  public emitAction(query: Query): void {
    this.extractIPLocation$.next(query);
  }

  public getAction(): Observable<Query> {
    return this.extractIPLocation$.asObservable();
  }
}
