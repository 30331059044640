import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { DataSource } from 'datalayer/models/platform-models';
import { Profile } from 'datalayer/models/social/profile';
import { OsintService } from 'datalayer/services/osint/osint.service';
import { head, isPlainObject, uniq } from 'lodash-es';
import { Observable, forkJoin } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { TargetTopRelatedPeopleService } from 'src/app/modules/profiler/services/target-top-related-people.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { ImageService } from 'src/app/services/image/image.service';
import { RecoveryAccountsService } from 'src/app/services/recovery-accounts/recovery-accounts.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { RedirectSnackBarComponent } from 'src/app/shared/components/redirect-snack-bar/redirect-snack-bar.component';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { TargetDialogResult } from '../../models/target-dialog-result.model';
@Component({
  selector: 'app-target-dialog',
  templateUrl: './target-dialog.component.html',
  styleUrls: ['./target-dialog.component.scss'],
})
export class TargetDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('targetInput') targetInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  searchResultValue: string;
  urlImage: string = undefined;

  // Target Chips implementation
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  targetCtrl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$'),
    Validators.minLength(3),
    Validators.maxLength(20),
  ]);

  allTargets: TargetItem[];
  selectedTargets: TargetItem[] = [];
  filteredTargets: Observable<any[]>;
  targetQueue: TargetItem[] = [];
  isUnlimitedTheme = false;
  creditsForExpired = 0;
  targetCreditsChargesEnabled = false;
  expireTargetDays: number;
  targetCreditsMessage: string;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  hasTargetsSlotsAvailable = true;
  hasConcurrentLimits = false;
  addToTargetLoader = false;

  constructor(
    private osintService: OsintService,
    private targetService: TargetService,
    private intelSnackBar: MatSnackBar,
    private translateService: TranslationService,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<TargetDialogComponent>,
    public dialog: MatDialog,
    private imageService: ImageService,
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private profilerService: ProfilerService,
    private topRelatedService: TargetTopRelatedPeopleService,
    private redirectSnackBarService: RedirectSnackBarService,
    private readonly recoveryAccountsService: RecoveryAccountsService,
    private userBillingService: UserBillingService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public searchResultData: {
      seedName: string;
      seedCount: number;
      newTarget: TargetItem;
      intelQueueTarget?: TargetItem;
      currentTarget?: TargetItem;
      profile?: Profile;
      imProfiles?: Profile[];
      hideBack: boolean;
      isMobile: boolean;
    }
  ) {
    super();
    this.isUnlimitedTheme =
      this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED;
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.hasConcurrentLimits = this.appConfigService.getConfigVariable(
      'hasConcurrentLimits'
    );
    this.hasTargetsSlotsAvailable =
      !this.hasConcurrentLimits ||
      (this.hasConcurrentLimits &&
        this.userBillingService.hasAvailableConcurrentSlots('Target'));
  }

  ngOnInit() {
    this.billingPlan = this.billingService.getBillingPlan().getValue();
    // Get All Targets
    this.targetService.fetchAllTargets();
    this.subscriptions.push(
      this.targetService.getAllTargets().subscribe({
        next: (target) => {
          this.allTargets = target;
          this.targetCtrl.disable();
          this.targetCtrl.enable();
        },
      })
    );
    this.targetCreditsMessage = this.translateService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );
  }

  ngAfterViewInit() {
    // Filteration for Targets
    this.filteredTargets = this.targetCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map((target: string | null) =>
        target ? this._filter(target) : this.allTargets.slice()
      )
    );
  }

  /**
   * @param  {TargetItem} target
   * @returns void
   */
  remove(target: TargetItem): void {
    const index = this.selectedTargets.indexOf(target);
    if (index >= 0) {
      this.selectedTargets.splice(index, 1);
      this.allTargets.unshift(target);
    }
    this.targetQueue = this.targetQueue.filter(
      (targetItem) => targetItem.alias !== target.alias
    );
    this.checkRenewalCreditsCount();
    this.targetCtrl.disable();
    this.targetCtrl.enable();
  }

  /**
   * @param  {MatAutocompleteSelectedEvent} event
   * @returns void
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    const target: TargetItem = <TargetItem>event.option.value;
    this.selectedTargets.push(target);
    this.allTargets = this.allTargets.filter(
      (targetItem) => targetItem !== target
    );

    this.targetInput.nativeElement.value = '';
    this.targetCtrl.setValue(null);
    this.addToTargetQueue(target.alias);
    this.checkRenewalCreditsCount();
    this.targetCtrl.disable();
    this.targetCtrl.enable();
  }

  checkRenewalCreditsCount() {
    const expiredTargetsCount = this.targetQueue.filter(
      (i) => i.expired
    ).length;
    this.creditsForExpired =
      expiredTargetsCount *
      this.billingPlan[BillingActions.TARGET_MANAGEMENT].cost;
  }

  /**
   * @param  {string} value
   * @returns TargetItem
   */
  private _filter(value: string): TargetItem[] {
    const filterValue = value.toLowerCase();
    return this.allTargets.filter(
      (target) => target.alias.toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**
   * @param  {string} targetAlias
   */
  addToTargetQueue(targetAlias: string) {
    const allTargetsList = [...this.allTargets, ...this.selectedTargets];
    allTargetsList.forEach((target) => {
      if (target.alias === targetAlias) this.targetQueue.push(target);
    });
  }

  createTarget() {
    this.addToTargetLoader = true;
    const newTarget: TargetItem = { ...this.searchResultData.newTarget };
    newTarget.alias = this.targetCtrl.value;
    newTarget.user = this.localStorageService.getCurrentUser().identity;
    this.targetService
      .createTargetProfiler(newTarget, {
        createCase: false,
        addImProfiles: true,
      })
      .subscribe(
        (target: TargetItem) => {
          if (this.searchResultData.imProfiles?.length) {
            this.osintService
              .createIMProfiles([target.id], this.searchResultData.imProfiles)
              .subscribe();
          }

          if (!this.searchResultData.isMobile) {
            this.targetRedirectSnackBar(target.id);
          } else {
            this.showMessage(
              this.translateService.translate(
                'The target changes have been saved.'
              )
            );
          }

          const dialogResult: TargetDialogResult = {
            isNewTarget: true,
            targets: [target],
          };

          if (
            this.searchResultData?.newTarget?.familyRelations?.length ||
            this.searchResultData?.newTarget?.friendRelations?.length ||
            this.searchResultData?.newTarget?.workRelations?.length ||
            this.searchResultData?.newTarget?.otherRelations?.length
          ) {
            this.targetService
              .saveRelationsToTarget(newTarget, target.id)
              .subscribe();
          }

          if (this.searchResultData?.profile?.relations?.length) {
            const topRelations = [];
            for (const userId of this.searchResultData?.profile?.userIds) {
              topRelations.push({
                profile_id: userId,
                from_user_id: this.searchResultData?.profile?.fromUserId,
              });
            }

            this.topRelatedService
              .matchProfileToTarget(
                topRelations,
                this.searchResultData.currentTarget.id
              )
              .subscribe();
          } else if (
            this.searchResultData?.profile &&
            this.searchResultData?.profile?.source !== DataSource.SocialSearch
          ) {
            this.targetService
              .saveHiddenRelationsToTarget(
                this.searchResultData.profile,
                this.searchResultData.currentTarget.id
              )
              .subscribe();
            this.targetService
              .createTargetSocialProfileFromRelationProfile(
                this.searchResultData.profile,
                target.id
              )
              .subscribe();
          }
          this.addToTargetLoader = false;
          this.dialogRef.close(dialogResult);
          if (this.searchResultData.currentTarget) {
            this.profilerService.targetData.next(target);
          }
          this.targetService.addedToTarget.next(true);
        },
        (error: any) => {
          const errorMessage = error?.messages;

          if (
            this.redirectSnackBarService.shouldShowRedirectSnackBar(
              errorMessage
            )
          ) {
            this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
          } else {
            this.showMessage(
              this.translateService.translate(
                error.messages ? error.messages : 'Target has not been created'
              )
            );
          }
          this.addToTargetLoader = false;
        }
      );
  }

  addToExistingTarget() {
    this.addToTargetLoader = true;
    if (this.searchResultData.newTarget) {
      const saveObservables = [];
      const newTargetData = this.searchResultData.intelQueueTarget
        ? { ...this.searchResultData.intelQueueTarget }
        : { ...this.searchResultData.newTarget };

      this.targetQueue.forEach((target: TargetItem) => {
        const newTarget: Partial<TargetItem> = { ...target };
        Object.keys(newTarget).forEach((key: string) => {
          if (newTarget[key]) {
            if (key === 'recoveryAccounts') {
              for (const rightRecoveryAccount of newTargetData[key] ?? []) {
                let merged = false;

                for (const leftRecoveryAccount of target.recoveryAccounts ||
                  []) {
                  if (
                    (merged =
                      this.recoveryAccountsService.mergeRecoveryAccounts(
                        leftRecoveryAccount,
                        rightRecoveryAccount
                      ))
                  ) {
                    break;
                  }
                }

                if (!merged) {
                  newTarget.recoveryAccounts.push(rightRecoveryAccount);
                }
              }
            } else {
              if (Array.isArray(newTargetData[key])) {
                newTarget[key] = newTarget[key].concat(newTargetData[key]);
                newTarget[key] =
                  newTarget[key].length > 0
                    ? uniq(newTarget[key])
                    : newTarget[key];
              } else if (isPlainObject(newTargetData[key])) {
                newTarget.telnoProviders = {
                  ...newTarget.telnoProviders,
                  ...newTargetData.telnoProviders,
                };
              } else {
                newTarget[key] = newTargetData[key];
              }
            }
          } else {
            newTarget[key] = newTargetData[key];
          }
        });

        let shouldSubscribe = false;
        if (newTargetData?.familyRelations?.length) {
          newTarget.familyRelations = newTargetData?.familyRelations;
          shouldSubscribe = true;
        }

        if (newTargetData?.friendRelations?.length) {
          newTarget.friendRelations = newTargetData?.friendRelations;
          shouldSubscribe = true;
        }

        if (newTargetData?.workPlaces?.length) {
          newTarget.workRelations = newTargetData?.workRelations;
          shouldSubscribe = true;
        }

        if (newTargetData?.otherRelations?.length) {
          newTarget.otherRelations = newTargetData?.otherRelations;
          shouldSubscribe = true;
        }

        if (shouldSubscribe) {
          this.targetService
            .saveRelationsToTarget(newTarget, target.id)
            .subscribe();
        }
        saveObservables.push(
          this.targetService.editTargetProfiler(target, newTarget)
        );
        this.targetService.addedToTarget.next(true);
      });

      this.subscriptions.push(
        forkJoin(saveObservables).subscribe(
          (targets: TargetItem[]) => {
            if (!this.searchResultData.isMobile) {
              this.targetRedirectSnackBar(targets[targets.length - 1].id);
            } else {
              this.showMessage(
                this.translateService.translate(
                  'The target changes have been saved.'
                )
              );
            }

            const targetIds: string[] = [];
            for (const target of targets) {
              targetIds.push(target.id);
            }

            if (targetIds.length && this.searchResultData.imProfiles?.length) {
              this.osintService
                .createIMProfiles(targetIds, this.searchResultData.imProfiles)
                .subscribe();
            }
            const dialogResult: TargetDialogResult = {
              isNewTarget: false,
              targets: targets,
            };

            if (this.searchResultData?.profile?.relations?.length) {
              const topRelations = [];
              for (const userId of this.searchResultData?.profile?.userIds) {
                topRelations.push({
                  profile_id: userId,
                  from_user_id: this.searchResultData?.profile?.fromUserId,
                });
              }

              this.topRelatedService
                .matchProfileToTarget(
                  topRelations,
                  this.searchResultData.currentTarget.id
                )
                .subscribe();
            } else if (this.searchResultData?.profile) {
              this.targetService
                .saveHiddenRelationsToTarget(
                  this.searchResultData.profile,
                  this.searchResultData.currentTarget.id
                )
                .subscribe();
              this.targetService
                .createTargetSocialProfileFromRelationProfile(
                  this.searchResultData.profile,
                  targets[0].id
                )
                .subscribe();
            }
            this.addToTargetLoader = false;
            this.dialogRef.close(dialogResult);
            this.targetService.addedToTarget.next(true);
          },
          (msg) => {
            this.showMessage(this.translateService.translate(msg));
            this.addToTargetLoader = false;
          }
        )
      );
    }
  }

  /**
   * @param  {TargetItem} target
   */
  public viewImage(target: TargetItem): string {
    let image = 'assets/static/images/user.svg';
    if (target && target.photos && head(target.photos)) {
      image = <string>this.imageService.getPhotoUrl(head(target.photos), true);
    } else if (this.urlImage !== undefined) {
      image = <string>this.imageService.getPhotoUrl(this.urlImage, true);
    }
    return image;
  }

  disableCreateTarget() {
    if (this.targetCtrl.invalid) return true;
    if (this.selectedTargets.length > 0) return true;
  }

  /**
   * @param  {string} id
   */
  targetRedirectSnackBar(id: string) {
    this.intelSnackBar.openFromComponent(RedirectSnackBarComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        id,
        html: {
          start: `${this.translateService.translate(
            'The target changes have been saved. Click'
          )}`,
          end: `${this.translateService.translate(
            'to view the target profile'
          )}`,
        },
      },
    });
  }

  navigateToTargets(): void {
    this.dialogRef.close();
    this.router.navigate(['core', 'targets']);
  }
}
