import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { ApiQuestionnaireService } from 'src/app/services/questionnaire/api-questionnaire.service';
import {
  FeedbackStates,
  QuestionnaireDialogComponent,
} from 'src/app/shared/components/questionnaire-dialog/questionnaire-dialog.component';
import {
  Investigation,
  InvestigationRequest,
} from 'src/app/shared/models/investigations.model';
import {
  InvestigationUserState,
  UserFeatures,
} from 'src/app/shared/models/questionnaire.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { UserFeaturesService } from '../user-features/user-features.service';
import { LocalStorageService } from './../storage/local-storage.service';

enum InvestigationStates {
  ChooseInvestigation = 'ChooseInvestigation',
  SendInvestigation = 'SendInvestigation',
  None = 'None',
}

export interface QuestionnaireState {
  state: InvestigationStates;
  shouldShow: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService extends BaseService {
  showTypeOfInvestigation = new BehaviorSubject<QuestionnaireState>({
    shouldShow: false,
    state: InvestigationStates.None,
  });

  //TODO Currently set to 10 seconds for testing purposes (should be changed to 15 mins and 2 hours for subsequent calls)
  countdownTime = 10;
  timer: Observable<number>;
  timerFinished = true;

  currentInvestigation: Investigation | null;
  enableQuestionnaires: boolean;
  theme: string;
  isGeolocTheme: boolean;
  isWhiteTheme: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private apiQuestionnaireService: ApiQuestionnaireService,
    private appConfigService: AppConfigService,
    private angulartics2: Angulartics2,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private userFeaturesService: UserFeaturesService,
    public dialog: MatDialog
  ) {
    super(router, snackBar);
    this.timer = timer(1000, 1000).pipe(share());
    this.theme = this.appConfigService.getConfigVariable('theme');

    this.enableQuestionnaires = this.appConfigService.getConfigVariable(
      'enableQuestionnaires'
    );
    this.isGeolocTheme = this.theme === 'GEOLOC';
    this.isWhiteTheme = this.theme === 'WHITE';
  }

  shouldShowQuestionnaire(): void {
    const user = this.localStorageService.getCurrentUser();
    const enableQuestionnaires = this.appConfigService.getConfigVariable(
      'enableQuestionnaires'
    );
    const questionnaireAccepted = localStorage.getItem(
      `${user.identity}_questionnaireAccepted`
    );

    if (
      !enableQuestionnaires ||
      !user ||
      !+questionnaireAccepted ||
      this.typeOfInvestigation.state !== InvestigationStates.None
    ) {
      return;
    }

    if (!this.currentInvestigation) {
      this.apiQuestionnaireService
        .getInvestigation()
        .pipe(take(1))
        .subscribe((response: { result: Investigation | null }) => {
          this.currentInvestigation = transformSnakeToCamel(response.result);
          if (!response.result) {
            this.shouldRunFirstInvestigation();
          } else {
            this.storeCorrelationId(response.result);
            this.shouldRunFollowingInvestigations(true);
          }
        });
    } else {
      this.shouldRunFollowingInvestigations(true && this.timerFinished);
    }
  }

  shouldRunFirstInvestigation(): void {
    if (
      this.typeOfInvestigation.state !==
        InvestigationStates.SendInvestigation &&
      this.timerFinished
    ) {
      this.showTypeOfInvestigation.next({
        shouldShow: true,
        state: InvestigationStates.ChooseInvestigation,
      });
    }
  }

  shouldRunFollowingInvestigations(shouldHandleQueryIncrease = false): void {
    if (shouldHandleQueryIncrease) {
      this.increaseQueryCounter();
    }
    if (this.currentInvestigation.queriesCounter % 5 === 0) {
      if (
        this.typeOfInvestigation.state !==
          InvestigationStates.SendInvestigation &&
        this.timerFinished
      ) {
        this.showTypeOfInvestigation.next({
          shouldShow: true,
          state: InvestigationStates.SendInvestigation,
        });
      }
    }
  }

  displayQuestionnaireDialog(): void {
    const user = this.localStorageService.getCurrentUser();
    const enableQuestionnaires = this.appConfigService.getConfigVariable(
      'enableQuestionnaires'
    );
    if (!enableQuestionnaires || !user) {
      return;
    }

    this.userFeaturesService
      .getUserFeatures()
      .subscribe((result: UserFeatures[]) => {
        if (result[0]) {
          const investigationFeature = result[0];
          localStorage.setItem(
            `${user.identity}_questionnaireAccepted`,
            investigationFeature.featureValue === InvestigationUserState.CLOSED
              ? '0'
              : '1'
          );

          if (
            investigationFeature.featureValue === InvestigationUserState.RUNNING
          ) {
            this.shouldShowQuestionnaire();
          }
        }

        if (result.length === 0) {
          this.dialog.open(QuestionnaireDialogComponent, {
            data: { identity: user.identity, state: FeedbackStates.Feedback },
            panelClass: this.isGeolocTheme
              ? 'geoloc-theme'
              : this.isWhiteTheme
              ? 'white-theme'
              : '',
          });
        }
      });
  }

  get typeOfInvestigation(): QuestionnaireState {
    return this.showTypeOfInvestigation.value;
  }

  isTheSameInvestigation(): void {
    this.showTypeOfInvestigation.next({
      shouldShow: true,
      state: InvestigationStates.SendInvestigation,
    });
  }

  notTheSameInvestigation(): void {
    this.apiQuestionnaireService
      .deleteInvestigation(this.currentInvestigation.id)
      .subscribe();

    this.showTypeOfInvestigation.next({
      shouldShow: true,
      state: InvestigationStates.ChooseInvestigation,
    });
  }

  submitInvestigation(currentUser: string, investigationType: string): void {
    this.startTimer(!!this.currentInvestigation);

    const postInvestigationRequest = {
      createdBy: currentUser,
      investigationType: investigationType,
    } as InvestigationRequest;

    this.apiQuestionnaireService
      .postInvestigation(postInvestigationRequest)
      .subscribe((response) => {
        if (response && response.result) {
          this.currentInvestigation = transformSnakeToCamel(response.result);
          this.storeCorrelationId(response.result);
        }
      });
  }

  startTimer(isSubsequentInvestigation: boolean): void {
    if (isSubsequentInvestigation) {
      this.countdownTime = 20;
    }

    this.timerFinished = false;

    this.timer
      .pipe(
        map((i) => this.countdownTime - i),
        take(this.countdownTime + 1)
      )
      .subscribe({
        next: (i: number) => {
          console.log(i);
        },
        complete: () => {
          if (!isSubsequentInvestigation) {
            this.isTheSameInvestigation();
          }

          this.timerFinished = true;
        },
      });
  }

  increaseQueryCounter(): void {
    const queryParams = {
      queriesCounter: ++this.currentInvestigation.queriesCounter,
    } as Partial<Investigation>;

    this.updateCurrentInvestigation(queryParams);
  }

  handleSameInvestigation(): void {
    const queryParams = {
      updatedAt: new Date().toISOString(),
    } as Partial<Investigation>;

    this.updateCurrentInvestigation(queryParams);
    this.startTimer(true);
    this.shouldRunFollowingInvestigations();
  }

  updateCurrentInvestigation(queryParams: Partial<Investigation>) {
    this.apiQuestionnaireService
      .updateInvestigation(this.currentInvestigation.id, queryParams)
      .subscribe((response: { result: Investigation | null }) => {
        this.currentInvestigation = transformSnakeToCamel(response.result);
        this.storeCorrelationId(response.result);
      });
  }

  storeCorrelationId(currInvestigation: Investigation): void {
    this.angulartics2.setUserProperties.next({
      dimension1: currInvestigation.id,
    });
  }

  close(): void {
    this.showTypeOfInvestigation.next({
      shouldShow: false,
      state: InvestigationStates.None,
    });
  }
}
