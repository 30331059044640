import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DayOfWeek } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss'],
})
export class DaySelectorComponent implements OnInit {
  @Output() dayChange: EventEmitter<DayOfWeek> = new EventEmitter<DayOfWeek>();

  options = [
    { label: 'all', value: DayOfWeek.ALL },
    { label: 'monday', value: DayOfWeek.MONDAY },
    { label: 'tuesday', value: DayOfWeek.TUESDAY },
    { label: 'wednesday', value: DayOfWeek.WEDNESDAY },
    { label: 'thursday', value: DayOfWeek.THURSDAY },
    { label: 'friday', value: DayOfWeek.FRIDAY },
    { label: 'saturday', value: DayOfWeek.SATURDAY },
    { label: 'sunday', value: DayOfWeek.SUNDAY },
    { label: 'weekdays', value: DayOfWeek.WEEKDAYS },
    { label: 'weekends', value: DayOfWeek.WEEKENDS },
  ];

  selectedOptionIndex = 0;

  constructor() {}

  ngOnInit() {}

  isSelected(index: number) {
    return this.selectedOptionIndex === index;
  }

  onSelect(index: number) {
    this.selectedOptionIndex = index;
    this.dayChange.emit(this.options[index].value);
  }
}
