import { EnvironmentFeatures } from 'environment/models';
// @ts-ignore
import { Skins, Themes } from 'src/app/shared/models/skins.model';

// NOTE - Those are default values, overwritten from the database settings.

export const environmentFeatures: EnvironmentFeatures = {
  flavor: Skins.GEO4,
  country: 'mexico',
  defaultLanguage: 'en',
  homeCountryCode: '+357',
  tenantId: 'your-tenant-id',
  callLogsEnabledCountries: ['52', '62'],
  callLogsRequestAvailablePeriods: {
    '52': ['3_MONTHS', '6_MONTHS', '12_MONTHS', '24_MONTHS'],
    '62': ['1_MONTHS', '2_MONTHS', '3_MONTHS'],
  },
  enableUserSettingsV2: true,
  enabledLandingScreen: false,
  enabledAnalytics: true,
  enableTactical: false,
  enableAoIQuery: false,
  enabledRecaptcha: false,
  enableRadicalMonitoring: true,
  mapType: 'GOOGLE',
  theme: Themes.GEO,
  enableTrueCaller: false,
  enableAdvancedGeolocationPopup: true,
  enableDarkwebLive: false,
  enableLinkAnalysis: true,
  enableLiveSession: false,
  enableGeolocationQuery: true,
  enabledOnlyForDemo: false,
  enableTargetSocialActivity: true,
  enableTargetMonitoringAlerts: false,
  enableDeleteTarget: true,
  enableDeleteEventLog: true,
  enableUserManual: true,
  enableCaseOverview: true,
  adIntFrame: false,
  enableAdInt: true,
  enableAdIntV2: false,
  adminCanConfigureUsersOTP: true,
  enabledNewCallLogs: true,
  enabledNewCallLogsUpload: true,
  enabledNewCallLogsRequest: true,
  enabledNewCallLogsExport: true,
  overviewMapInitialMarkerNumber: 1000,
  overviewMapEnableCallLogLoad: true,
  overviewMapEnableAdIntLoad: true,
  enableCaseCallLogs: false,
  enableTestingFrequencies: false,
  enableKnowledgeBase: false,
  enableIntelResultsV2: false,
  enableInvestigationModule: true,
  enableInvestigationIntelSearchResults: false,
  httpResponseCacheLifetimeSeconds: 1,
  sessionIdleAfterMinutesInactive: 15,
  enableCreditChargesForTarget: false,
  themeQueriesLimit: 0,
  enabledTextAnalysis: false,
  enableDeepOsint: false,
  enableAdintFlavour: false,
  enabledUA: true,
  enableAvatars: false,
  enableCreditChargesForCase: false,
  expireTargetDays: 0,
  expireCaseDays: 0,
  enableCreditChargesForCallLogs: false,
  enabledSomedus: false,
  enableQuestionnaires: true,
  enableFeatures: false,
  disableWebintActions: false,
  enableCovid19MX: false,
  enableNIK: false,
  enableProfileUrlsMasking: false,
  enableNewRequestClDialog: false,
  forceLowFrequencySchedules: false,
  enableAvatarsCreation: true,
  enableInvestigationMapAoI: false,
  enableIMSICallLogs: false,
  fileTextAnalysisEnabled: false,
  fileTextAnalysisUrlUploadEnabled: false,
  enableTermsOfUse: false,
  termsOfUseAccountId: 'terms-of-use-account-id',
  termsOfUseEnvironmentUrl: 'https://demo.docusign.net',
  termsOfUseClickWrapId: 'terms-of-use-clickwrap-id',
  enableOsintSplitQueries: false,
  tenantCountryCodes: [51, 52, 48, 55, 53, 54, 387],
  enableGrafanaDashboard: false,
  disableLocatingFunctionality: false,
  enableLocationLog: false,
  enableSearchByKeyword: false,
  hasConcurrentLimits: false,
  disableNavigationToInvestigation: false,
  isCDROnlyEnabled: false,
  enableCDRUploadBySchema: false,
  enabledMobileScreens: false,
  enableDiscoveryV2: false,
  isUrgentSomedusEnabled: false,
  isCdrSummaryReportEnabled: true,
  somedusExpirationPeriodDays: 14,
  enableCdrAnyFormatUpload: false,
  enableGroupAnalyser: false,
  enableCrypto: false,
  enableLicense: false,
};
