import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core-toastr',
  templateUrl: './core-toastr.component.html',
  styleUrls: ['./core-toastr.component.scss'],
})
export class CoreToastrComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
