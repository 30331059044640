import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class LocationQueryService extends BaseService {
  locationQueryData = new Subject();
  locationQueryLoader = new BehaviorSubject<boolean>(false);
  selectedRow = new Subject();

  constructor(
    protected router: Router,
    private httpClient: HttpClient,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  requestAoIQuery(data) {
    this.locationQueryLoader.next(true);
    return this.httpClient.post<any>(`${this.url}/query/aoi`, data).pipe(
      map((result) => {
        this.locationQueryLoader.next(false);
        this.locationQueryData.next(result);
        return result;
      }),
      catchError((error) => this.handleError(error))
    );
  }
}
