import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseMapImplComponent } from 'src/app/modules/mapV2/components/map/base-map-impl.component';
import {
  Button,
  Circle,
  Feature,
  HeatMap,
  MapTypeId,
  Marker,
  Point,
  Polygon,
  Polyline,
  Rectangle,
  StyledMapTypeOptions,
  TileSource,
} from 'src/app/modules/mapV2/models/map.models';
import { ProfilerDashboardSectionsValues } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { CallLogFeatureData } from 'src/app/shared/models/call-log-feature-data.model';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent
  extends BaseMapImplComponent
  implements OnInit, OnChanges
{
  @ViewChild('mapHolder', { static: true }) mapHolder: ElementRef;

  // private markersLayer: LayerGroup<Marker>;
  // map: Map;

  /* need to see where to move this preinitialisation */
  // tileLayer = tileLayer(this.defaultTileSource.tileUrl,
  //   { maxZoom: 18, attribution: this.defaultTileSource.attribution });
  // options = {
  //   layers: [
  //     this.tileLayer
  //   ],
  //   zoom: 15,
  //   center: latLng(46.879966, -121.726909)
  // };

  constructor() {
    super();
  }

  public onMapReady(map) {
    // this.map = map;

    this.MapReady();
  }

  ngOnInit() {
    this.OnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (!this.map) {
    //   return;
    // }

    this.OnChanges(changes);
  }

  maybeEnableCorrectDrawMode() {
    // check for enum value, etc
  }

  clearTileSource() {
    // if (this.map && this.tileLayer) {
    //   this.map.removeLayer(this.tileLayer);
    //   this.tileLayer = null;
    // }
  }

  setTileSource(newTileSource: TileSource) {
    // if (newTileSource) {
    //   this.tileLayer = tileLayer(this.mapTileSource.tileUrl, { maxZoom: 18, attribution: this.mapTileSource.attribution });
    // }
    // if (this.map) {
    //   this.clearTileSource();
    //   this.map.addLayer(this.tileLayer);
    // } else {
    //   this.options.layers = [
    //     this.tileLayer
    //   ];
    // }
  }

  setCenter(center: Point, zoom?: number) {
    // if (!this.map) {
    //   return;
    // }
    // if (zoom) {
    //   this.map.panTo(latLng(center.lat, center.lng));
    // } else {
    //   this.map.setView(latLng(center.lat, center.lng), zoom);
    // }
  }

  setZoom(level: number) {
    // if (this.map) {
    //   this.map.setZoom(level);
    // }
  }

  renderCircles(circles: Circle[]) {}

  renderPolygons(polygons: Polygon[]) {}

  renderMarkers(markers: Marker[]) {
    // if (!this.map) {
    //   return;
    // }
    // if (this.markersLayer) {
    //   this.map.removeLayer(this.markersLayer);
    // }
    // if (!this.markers.length) {
    //   return;
    // }
    // this.markersLayer = layerGroup();
    // this.markers.forEach((markerToAdd: IconMarker, index: number) => {
    //   if (markerToAdd.lat !== undefined && markerToAdd.lng !== undefined) {
    //     const newMarker = marker(latLng(markerToAdd.lat, markerToAdd.lng), {
    //       icon: new Icon({
    //         iconUrl: markerToAdd.iconUrl,
    //         iconAnchor: [markerToAdd.anchorX, markerToAdd.anchorY],
    //       }),
    //       // We are multiplying index with marker height to accomodate
    //       // leaflet interpretation of the zIndexOffset property
    //       // https://github.com/Leaflet/Leaflet/issues/5560
    //       zIndexOffset: index * markerToAdd.iconHeight
    //     });
    //     if (markerToAdd.popupHTML) {
    //       const customPopup = markerToAdd.popupHTML;
    //       const customOptions = {
    //         'className': 'custom'
    //       };
    //       newMarker.bindPopup(customPopup, customOptions);
    //     }
    //     this.markersLayer.addLayer(newMarker);
    //   }
    // });
    // this.map.addLayer(this.markersLayer);
    // // TODO: fix zoomlevel
    // const group = featureGroup(this.markersLayer.getLayers());
    // this.map.fitBounds(group.getBounds());
  }

  toggleClustering(enabled: boolean) {}
  toggleHeatMaps(enabled: boolean) {}
  toggleHistoryTimeline(enabled: boolean) {}
  toggleTraffic(enabled: boolean) {}
  renderButtons(buttons: Button[]) {}
  renderFeature(feature: Feature) {}
  toggleSearching(enabled: boolean) {}
  toggleFreeze(enabled: boolean) {}
  renderCallLogClusteringData(data: CallLogFeatureData) {}
  // TODO: as soon as we implement routing for profiler sidenav remove this and get the active section from router
  setProfilerSection(section: ProfilerDashboardSectionsValues) {}

  renderHeatMap(points: Point[]) {}

  renderRectangles(rectangles: Rectangle[]) {}

  renderPolyLines(polyLines: Polyline[]) {}

  toggleMarkerInfoWindow(show: boolean) {}

  renderHeatMaps(heatMaps: HeatMap[]) {}

  createMapLegend(legendIcons: { [key: string]: string }) {}
  toggleNearbyLocations(enabled: boolean) {}

  setMaptTypeId(mapTypeId: MapTypeId) {
    throw new Error('Method not implemented.');
  }

  setMaxZoomClusterDistance(distance: number) {
    throw new Error('Method not implemented.');
  }

  setStyledMapTypeCustomOptions(mapStyle: StyledMapTypeOptions) {
    throw new Error('Method not implemented.');
  }

  setMapFitBounds() {
    throw new Error('Method not implemented.');
  }
}
